import React from 'react';
import PT from 'prop-types';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';
import { withFormsy } from 'formsy-react';
import Input  from 'components/Input';
import NeedWritePermission from 'components/NeedPermission';
import ImageEditor from 'components/ImageEditor';
import './style.css';
import { FormSelect } from 'components/CustomSelect';
import CardInput from 'components/CardInput';
import CustomFileInput from 'components/FormsyFileInput';
import CheckBox from 'components/CheckBox';
import {formatDate} from 'scripts/dateFormatter';
import { StyledThumb } from '../Planning/OperationDetail/Multislider';
import styled from 'styled-components';
import ReactSlider from 'components/ReactSlider';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    z-index: 0
`;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => {
        if (props.index > 0) {
            return '#DDD';
        }
        return 'rgb(9,84,0)';
    }};
    border-radius: 999px;
`;

class Slider extends React.Component {
    render() {
        return(
        <Row>
            <label className="col-form-label col-sm-4">Kvalita</label>
            <Col sm={8}>
                <StyledSlider
                    min={0}
                    max={10}
                    step={1}
                    value={this.props.value}
                    onChange={val => this.props.setValue(val)}
                    renderThumb={(props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>}
                    renderTrack={(props, state) => <StyledTrack {...props} index={state.index} />}
                />
            </Col>
        </Row>);
    }
}

const SliderInput = withFormsy(Slider);

const default_values = {
    name: '',
    serial_num: '',
    comment: '',
    history_comment: '',
    from_date: '',
    owner_id: '',
    state_id: 1,
    category_id: '',
    photo: '/storage/tools/default.jpeg',
    label: '/storage/tools/default.jpeg',
    quality: 10
};

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.state = {
            validationErrors: {},
            revisionDoc: null,
            auxDoc: null,
            photo: null,
            label: null,
            formSubmitted: false,
            stateChanged: false,
            ownerChanged: false,
        };
    }

    getValue(name) {
        let retval = null;
        if (this.props.tool){
            retval = this.props.tool[name];
        }
        return retval || default_values[name] || '';
    }

    duplicateExists(attribute, value){
        if (value){
            let duplicate = this.props.tools.find( t => {
                return t[attribute] === value;
            });
            if (duplicate && (!this.props.updating || duplicate.id !== this.props.tool.id)){
                return true;
            }
        }
        return false;
    }

    validateForm(values) {
        let validations = {
            name: 'Vyplňte názov',
            serial_num: 'Vyplňte sériové číslo',
            state: 'Zvoľte stav',
            owner: 'Zvoľte vlastníka'
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]){
                errors[key] = validations[key];
            }
        });

        if (this.duplicateExists('serial_num', values.serial_num)){
            errors['serial_num'] = 'Náradie s rovnakým sériovým číslom už existuje';
        }
        if (this.duplicateExists('chip_number', values.chip_number)){
            errors['chip_number'] = 'Náradie s rovnakým číslom čipu už existuje';
        }

        let stateChanged = false;
        let qualityChanged = false;
        let ownerChanged = false;

        if (this.props.updating && this.props.tool) {
            let quality = parseInt(values.quality, 10);

            if (values.state.id !== this.props.tool.state_id){
                stateChanged = true;
            }
            if (quality !== this.props.tool.quality){
                qualityChanged = true;
            }
            if (values.owner && values.owner.id !== this.props.tool.owner_id){
                ownerChanged = true;
            }
            if (!values.history_comment && (stateChanged || qualityChanged)) {
                errors['history_comment'] = 'Vyplňte komentár stavu kvality';
            }
        }
        this.setState({
            validationErrors: errors,
            stateChanged: stateChanged,
            qualityChanged: qualityChanged,
            ownerChanged: ownerChanged,
        });

        return (Object.keys(errors).length > 0);
    }
	
    handleSubmit(values) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(values)){
            return;
        }
        let {state, owner, category, parent, ...obj} = values;

        let newObj = {
            ...obj,
            revision_document: this.state.revisionDoc,
            aux_document: this.state.auxDoc,
            photo: this.state.photo,
            label: this.state.label,
            state_id: state.id,
            owner_id: owner.id,
            category_id: category.id,
            parent_id: parent ? parent.id : null,
        };

        this.props.onSubmit(newObj);
    }

    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }

    render() {
        const { props:P, state:S } = this;

		let defaultOwner = null;
		let defaultCategory = null;
		let defaultState = null;

		if (P.tool) {
            defaultOwner = P.employees.find(e => e.id === P.tool.owner_id);
            defaultCategory = P.categories.find( c => c.id === P.tool.category_id);
            defaultState = P.toolStates.find( s => s.id === P.tool.state_id);
        } else {
            if (P.categories) {
                defaultCategory = P.categories.find( c => c.id === 1);
            }
            if (P.toolStates) {
                defaultState = P.toolStates.find(s => s.name === 'aktívne');
            }
            if (P.identity) {
                defaultOwner = P.identity;
            }
        }

		let toolsOptions = P.tools.filter(t => t.parent_id === null)
            .sort((a, b) => a.name.localeCompare(b.name));

		if (P.updating && P.tool){
            toolsOptions = P.tools.filter(t => t.id !== P.tool.id);
        }

        return (
            <Modal size="xl" show={P.show} onHide={ () => {
                    P.onClose();
                    this.setState({
                        validationErrors: {}
                    });
                }
            }>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        ref={this.formRef}
                        onKeyPress={this.onKeyPress}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                        onValidSubmit={ values => this.handleSubmit(values)}
                        onInvalidSubmit={ values => this.handleSubmit(values)}
                        formNoValidate
                    >
                        {
                        (!P.updating || (P.updating && P.tool && P.tool.has_children === false)) ?
                        <Row>
                            <Col lg={6} md={12}>
                                <FormSelect
                                    name={'parent'}
                                    label={'Rodičovský nástroj'}
                                    placeholder={'Žiadny'}
                                    options={toolsOptions}
                                    getOptionValue={ t => t.id}
                                    getOptionLabel={ t => t.name + ' SN: ' + t.serial_num}
                                    value={P.parent}
                                    isFormSubmitted={() => S.formSubmitted}
                                    required={false}
                                    isClearable={true}
                                />
                            </Col>
                            <Col lg={6} md={12} />
                        </Row> : null
                        }
                        <Row>
                            <Col lg={6} md={12}>
                                <Input
                                    name={'name'}
                                    label={'Názov *'}
                                    value={this.getValue('name')}
                                    isValid={ val => Boolean(val)}
                                    formNoValidate
                                    isFormSubmitted={ () => S.formSubmitted}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <Input
                                    name={'serial_num'}
                                    label={'Sériové číslo *'}
                                    value={this.getValue('serial_num')}
                                    isValid={ val => Boolean(val)}
                                    formNoValidate
                                    isFormSubmitted={ () => S.formSubmitted}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <CardInput
                                    name={'chip_number'}
                                    label={'Číslo čipu'}
                                    value={this.getValue('chip_number')}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <Input
                                    name={'warranty_expiration'}
                                    type={'date'}
                                    label={'Koniec záruky'}
                                    value={formatDate(this.getValue('warranty_expiration'))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <Input
                                    name={'last_revision'}
                                    type={'date'}
                                    label={'Posledná revízia'}
                                    value={formatDate(this.getValue('last_revision'))}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <Input
                                    name={'next_revision'}
                                    type={'date'}
                                    label={'Ďalšia revízia'}
                                    value={formatDate(this.getValue('next_revision'))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <FormSelect
                                    name={'owner'}
                                    label={'Vlastník'}
                                    options={P.employees}
                                    getOptionValue={ e => e.id}
                                    getOptionLabel={ e => e.full_name}
                                    value={defaultOwner}
                                    isFormSubmitted={ () => S.formSubmitted}
                                    required={true}
                                    isClearable={true}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                {(P.updating && S.ownerChanged && this.props.tool.has_children) ?
                                    <CheckBox
                                        name="update_children_owner"
                                        label="Zmeniť vlastníka aj všetkým položkám"
                                        value={true}
                                    /> : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <FormSelect
                                    name={'state'}
                                    label={'Stav'}
                                    value={defaultState}
                                    options={P.toolStates}
                                    getOptionValue={ s => s.id}
                                    getOptionLabel={ s => s.name}
                                    isFormSubmitted={ () => S.formSubmitted}
                                    required
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                {(P.updating && S.stateChanged && this.props.tool.has_children) ?
                                    <CheckBox
                                        name="update_children_state"
                                        label="Zmeniť stav aj všetkým položkám"
                                        value={false}
                                    /> : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <Input
                                    type={'text'}
                                    name={'comment'}
                                    label={'Komentár'}
                                    value={this.getValue('comment')}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <FormSelect
                                    name={'category'}
                                    label={'Kategória'}
                                    options={P.categories.sort( (a, b) => a.name.localeCompare(b.name))}
                                    getOptionValue={ c => c.id}
                                    getOptionLabel={ c => c.name}
                                    value={defaultCategory}
                                    isFormSubmitted={ () => S.formSubmitted}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <SliderInput
                                    name={'quality'}
                                    value={this.getValue('quality')}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <Input
                                    isPristine={() => false}
                                    type={'text'}
                                    name={'history_comment'}
                                    label="Komentár kvality"
                                    value={this.getValue('history_comment')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <CustomFileInput
                                    label={'Revízny dok.'}
                                    onChange={ file => this.setState({revisionDoc: file})}
                                    document={this.getValue('revision_document')}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <CustomFileInput
                                    label={'Prídavný dok.'}
                                    onChange={ file => this.setState({auxDoc: file})}
                                    document={this.getValue('aux_document')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <Row className={'form-group form-row'}>
                                    <label className="col-form-label col-sm-4">Fotka</label>
                                    <Col sm={8}>
                                        <ImageEditor
                                            photo={this.getValue('photo')}
                                            isZoomable={false}
                                            onChange={ file => this.setState({photo: file})}
                                            size={100}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={12}>
                                <Row className={'form-group form-row'}>
                                    <label className="col-form-label col-sm-4">Štítok</label>
                                    <Col sm={8}>
                                        <ImageEditor
                                            photo={this.getValue('label')}
                                            isZoomable={false}
                                            onChange={ file => this.setState({label: file})}
                                            size={100}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                 lg={6} md={12}
                            >
                                <Row className={'form-group form-row'}>
                                <Col sm={{span: 8, offset: 4}}>
                                <NeedWritePermission type="button" need={['tools']}>
                                    <Button
                                        variant="success"
                                        type="submit"
                                    >
                                        { P.updating ? 'Upraviť' : 'Vytvoriť' }
                                    </Button>
                                </NeedWritePermission>
                                </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={12}/>
						</Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    employees: PT.array.isRequired,
    tools: PT.array.isRequired,
    parent: PT.object,
    show: PT.bool.isRequired,
    toolStates: PT.array.isRequired,
    updating: PT.bool,
    onClose: PT.func,
    onSubmit: PT.func.isRequired,
	categories: PT.array.isRequired,
    tool: PT.object,
    identity: PT.object.isRequired,
};

Form.defaultProps = {
    updating: false,
    tool: {},
    parent: null,
    tools: [],
};

export default Form;