import React from 'react';
import ErrorModal from 'components/ErrorModal';
import { connect } from 'react-redux';
import { unsetGlobalError } from 'actions/globalError';
import store from 'store/mainStore';

class GlobalError extends React.Component {

    render() {
        const { props:P } = this;

        return (
            <ErrorModal
                show={P.globalError.show}
                messages = {P.globalError.messages}
                errorType={P.globalError.type}
                is_error={P.globalError.is_error}
                onClose={() => store.dispatch(unsetGlobalError())}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        globalError: state.globalError
    };
};

export default connect(mapStateToProps)(GlobalError);
