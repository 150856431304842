import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { FormSelect} from 'components/CustomSelect';
import { Row, Col } from 'react-bootstrap';
import { getmonthName } from 'scripts/dateFormatter';

const monthOptions = [...Array(12).keys()].map( i => {
    return {
        id: String(i + 1),
        name: getmonthName(i + 1)
    };
});

const yearOptions = (function () {
    let arr = [];
    for (let year=(new Date()).getFullYear(); year > 1990; year-- )
        arr.push({
            name: String(year),
            id: String(year)
        });
    return arr;
})();

class View extends React.Component {

    onYearChange(year) {
        const { props: P} = this;
        P.onDateChange({
            year: year.id,
            month: P.month
        });
    }

    onMonthChange(month) {
        const { props: P} = this;
        P.onDateChange({
            year: P.year,
            month: month.id
        });
    }

    render() {
        const { props:P } = this;

        return (
            <div>
                <Formsy
                    className="choose-employee-form"
                >
                    <Row>
                        <Col lg={3} md={2} sm={12}>
                            <h4>Zvoľte obdobie</h4>
                        </Col>
                        <Col lg={3} md={5} sm={12}>
                            <FormSelect
                                name="year"
                                label="Rok "
                                options={yearOptions}
                                value={yearOptions.find( o => o.id === P.year)}
                                onChange={val => this.onYearChange(val)}
                            />
                        </Col>
                        <Col lg={3} md={5} sm={12}>
                            <FormSelect
                                name="month"
                                label="Mesiac "
                                options={monthOptions} 
                                value={monthOptions.find(m => m.id === P.month)}
                                onChange={val => this.onMonthChange(val)}
                            />
                        </Col>
                        <Col lg={3} md={0} sm={0}/>
                    </Row>
                </Formsy>
            </div>
        );
    }
}

View.defaultProps = {
    isLoading: false,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
};

View.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
};

export default View;
