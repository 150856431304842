import expect from 'helpers/expect';
import store from 'store/mainStore';
import rolesActions from 'actions/roles';

const SET_PERMISSIONS = 'SET_PERMISSIONS';

function setPermissions(permissions) {
    expect(permissions).toBeTypeOf('object');

    return {
        type: SET_PERMISSIONS,
        permissions
    };
}

function reloadLoggedPermissions(new_role) {
    const { roles } = store.getState();

    if (!roles.items.length) {
        store.dispatch(rolesActions.fetchAll()).then(() => {
            store.dispatch(setPermissions(roles.items.find(r => r.id === new_role)));
        });
    } else {
        store.dispatch(setPermissions(roles.items.find(r => r.id === new_role)));
    }
}

export { SET_PERMISSIONS, setPermissions, reloadLoggedPermissions };
