import {updateQueryParams} from 'scripts/queryFunctions';
import call from 'ajax/call';
import store from 'store';
import buildingEntitiesActions from 'actions/building_entities';

export function getUrlParam(location, param) {
    const query = new URLSearchParams(location.search);
    let id = query.get(param);

    if (!id) {
        return null;
    }
    return parseInt(id, 10) || null;
}

export function getUrlParams(location, params) {
    const query = new URLSearchParams(location.search);
    let retVal = {};

    params.forEach(p => {
        let val = query.get(p);
        if (val) {
            val = parseInt(val, 10);
        }
        retVal[p] = val || null;
    });
    return retVal;
}

export function compareLevels(a, b) {
    let suffixA = a.name.slice(-2).toUpperCase();
    let suffixB = b.name.slice(-2).toUpperCase();

    if (suffixA !== suffixB) {
        return suffixB.localeCompare(suffixA);
    }

    let prefixA = a.name.slice(0, -2);
    let prefixB = b.name.slice(0, -2);

    let result = parseInt(prefixA) - parseInt(prefixB);
    return suffixA === 'NP' ? result : -1 * result;
}

export function sortItems(items, type_name) {
    if (type_name.toUpperCase() === 'POSCHODIE') {
        items = items.sort(compareLevels);
    } else {
        items = items.sort((a, b) => a.name.localeCompare(b.name));
    }
    return items;
}

export function loadBuilding(location, history, buildingsList) {
    const url_building_id = getUrlParam(location, 'building_id');
    const storage_building_id = localStorage.getItem('building_id');

    let building_id = null;
    if (url_building_id && buildingIsInBuildingsList(buildingsList, url_building_id)) {
        building_id = parseInt(url_building_id, 10);
        localStorage.setItem('building_id', building_id);
    } else if (storage_building_id && buildingIsInBuildingsList(buildingsList, storage_building_id)) {
        building_id = parseInt(storage_building_id, 10);
        updateQueryParams(location, history, {building_id});
    }

    return building_id;
}

export function setBuilding(location, history, building_id) {
    localStorage.setItem('building_id', building_id);
    updateQueryParams(location, history, {building_id});
}

export function loadEntity(location, history, buildingTopEntities, building_id) {
    const url_entity_id = getUrlParam(location, 'entity_id');
    const storage_entity_id = localStorage.getItem('planning_entity_id');

    let entity_id = null;

    if (url_entity_id && entityIsInBuilding(buildingTopEntities, url_entity_id, building_id)) {
        entity_id = parseInt(url_entity_id, 10);
        localStorage.setItem('planning_entity_id', entity_id);
    } else if (storage_entity_id && entityIsInBuilding(buildingTopEntities, storage_entity_id, building_id)) {
        entity_id = parseInt(storage_entity_id, 10);
        updateQueryParams(location, history, {entity_id});
    } else if (buildingTopEntities[0]) {
        entity_id = parseInt(buildingTopEntities[0].id, 10);
        setEntity(location, history, entity_id);
    }

    return entity_id;
}

export function setEntity(location, history, entity_id) {
    localStorage.setItem('planning_entity_id', entity_id);
    updateQueryParams(location, history, {entity_id});
}

function entityIsInBuilding(buildingTopEntities, entity_id, building_id) {
    const entity = buildingTopEntities.find(x => x.id === parseInt(entity_id, 10));
    return entity ? entity.building_id === parseInt(building_id, 10) : false;
}

function buildingIsInBuildingsList(buildingsList, building_id) {
    return buildingsList.some(b => b.id === parseInt(building_id, 10));
}

export function deletePicture(entity) {
    let updated = {
        ...entity,
        picture: null,
    };

    call(`/api/building_entities/${entity.id}/picture`, {
        method: 'DELETE',
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'ok') {
                store.dispatch(
                    buildingEntitiesActions.didUpdateBuilding_entitiesSucc(entity.id, updated)
                );
            }
        });
}
