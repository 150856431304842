import styled from 'styled-components';
import ReactSlider from 'components/ReactSlider';
import ReactTooltip from 'react-tooltip';
import DV, { extractTime} from 'scripts/dateFormatter';
import {Button} from 'react-bootstrap';
import React, {useState} from 'react';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    z-index: 0;
`;

const StyledThumb = styled.div`
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    background-color: ${props => {
        if (props.active) {
            return '#245600';
        } else {
            return '#999999';
        }
    }};
    color: #fff;
    border-radius: 50%;
    cursor: grab;
    top: -10px
`;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => {
        if (props.index === props.items) {
            return '#DDD';
        }
        if (props.active) {
            return 'rgb(5,65,0)';
        }
        return '#9d9d9d';
    }};
    border-radius: 999px;
`;

function getValue(index, values) {
    if (!(values instanceof Array)) {
        values = [values];
    }

    if (index === 0) {
        return values[0];
    } else {
        return values[index] - values[index - 1];
    }
}

const getToolTip = (id, state, value, wp) => {
    if (wp && state.index < value.length && wp.started_at) {
        return <ReactTooltip
            id={id}
            type='dark'
            place="top"
        >
            <div style={{fontSize: '1.2em'}}>
                {wp.group_names} <br/>
                {
                    DV(wp.started_at, false)
                    + ' ' + extractTime(wp.started_at)
                    + ' - ' + extractTime(wp.finished_at)
                } <br/>
                {getValue(state.index, value) + ' %'}
            </div>
        </ReactTooltip>;
    } else {
        return null;
    }
};

function updateValue(newValues, oldValues, my_index) {
    if (!(newValues instanceof Array)) {
        newValues = [newValues];
    }

    let diff = 0;
    let maxDiff = 100 - oldValues[oldValues.length - 1];
    let minDiff = 0;

    let i = my_index;
    if (newValues[i] === oldValues[i]) {
        return oldValues;
    }

    diff = newValues[i] - oldValues[i];
    if (i === 0) {
        minDiff = -oldValues[0];
    } else {
        minDiff = oldValues[i - 1] - oldValues[i];
    }

    if (i === newValues.length) {
        return newValues;
    }

    if (diff > maxDiff) {
        diff = maxDiff;
    }
    if (diff < minDiff) {
        diff = minDiff;
    }

    newValues[i] = oldValues[i] + diff;
    for (i++; i < oldValues.length; i++) {
        newValues[i] = oldValues[i] + diff;
    }
    return newValues;
}

function prepareData(items) {
    let array = [];
    items.forEach( i => {
        if (array.length > 0) {
            array.push(array[array.length - 1] + i.percent_done);
        } else {
            array.push(i.percent_done);
        }
    });

    return array;
}

const Slider = (props) => {
    const my_index = props.myIndex;
    let step = props.step;
    let initialValue = prepareData(props.items);
    const [value, setValue] = useState(initialValue);

    const prefix = 'track';
    if (!step) {
        step = 5;
    }

    let buttons = (
        <span>
            <Button
                variant={'primary'}
                style={{
                    width: 38,
                    height: 38,
                    margin: 5
                }}
                onClick={() => {
                    let val = value.slice();
                    val[my_index] -= step;
                    let new_val = updateValue(val, value, my_index);
                    setValue(new_val);
                    if (props.onChange) {
                        props.onChange(getValue(my_index, new_val));
                    }
                }}
            >-</Button>
            <Button
                    variant={'primary'}
                    style={{
                        width: 38,
                        height: 38,
                        margin: 5
                    }}
                    onClick={() => {
                        let val = value.slice();
                        val[my_index] += step;
                        let new_val = updateValue(val, value, my_index);
                        setValue(new_val);
                        if (props.onChange) {
                            props.onChange(getValue(my_index, new_val));
                        }
                    }}
            >+</Button>
        </span>
    );

    const disabled = props.items.length === 0 || props.myIndex === null;
    return (
        <div>
            <StyledSlider
                value={value}
                onChange={(newVal, index) => {
                    if (!disabled && index === my_index) {
                        setValue(newVal);
                        if (props.onChange) {
                            props.onChange(getValue(my_index, newVal));
                        }
                    }
                }}
                renderTrack={(inner_props, state) =>
                    <div
                        key={`${prefix}-${state.index}`}>
                        <StyledTrack
                            {...inner_props}
                            active={state.index === my_index}
                            index={state.index}
                            items={state.value instanceof Array ? state.value.length : 1}
                            data-tip data-for={`${prefix}-${state.index}`}
                        />
                        {getToolTip(`${prefix}-${state.index}`, state, value, props.items[state.index])}
                    </div>
                }
                renderThumb={(inner_props, state) =>
                    <StyledThumb
                        {...inner_props}
                        active={state.index === my_index}
                        data-tip data-for={`${prefix}-${state.index}`}
                    >
                        {state.valueNow}
                    </StyledThumb>
                }
                snapDragDisabled={true}
                withTracks={true}
                pearling={false}
                max={100}
                min={0}
                disabled={disabled}
            />
            {disabled ? null : buttons}
        </div>
    );
};

export default Slider;
