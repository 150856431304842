import React from 'react';
import {Row, Col, Container, Tab, Tabs} from 'react-bootstrap';
import store from 'store/mainStore';
import '../style.css';
import {connect} from 'react-redux';
import orderActions from 'actions/orders';
import workDetailActions from 'actions/order_work_detail';
import { parseHours } from 'scripts/dateFormatter';
import { SuperTable } from 'components/PaginationTable';
import './style.css';

function isEmpty(array) {
    return !array || !array.length;
}

class View extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            order_id: parseInt(props.match.params.id, 10),
            employee_id: parseInt(props.match.params.employee_id, 10),
            data: {},
        };
    }

    componentDidMount() {
        let order_id = parseInt(this.props.match.params.id, 10);
        let emp_id = parseInt(this.props.match.params.employee_id, 10);

        if (isEmpty(this.props.order)) {
            store.dispatch(orderActions.fetchConcrete(order_id));
        }
        store.dispatch(workDetailActions.fetchAll({
            order_id: order_id,
            employee_id: emp_id,
        }));
    }

    render() {
        const { props:P} = this;
        let order = P.order;
        let items = P.data.work_records || [];

        let operation_sums = P.data.partial_sum;
        let operations = null;

        if (operation_sums) {
            operations = operation_sums
                .filter(op => op.sum > 0)
                .map(op => {
                return (<Row key={op.id}>
                        <Col xs={6} className="text-right">{op.name}</Col>
                        <Col xs={6} className="text-left">{parseHours(op.sum)}</Col>
                    </Row>);
            });
        }

        return (
            <Container className="buildings">
            <div
                style={{
                    marginBottom: '20pt'
                }}
            >
                <div className='heading'>
                    Výpis odpracovaných hodín na zákazke
                </div>
                <div className={'info'}>
                    <Row>
                        <Col xs={6} className="text-right">Zákazka</Col>
                        <Col xs={6} className="text-left">{order && order.name} </Col>

                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Zamestnanec</Col>
                        <Col xs={6} className="text-left">{P.data.full_name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Celkový odpracovaný čas</Col>
                        <Col xs={6} className="text-left">{parseHours(P.data.work_sum)} hodin</Col>
                    </Row>
                    { operations }
                </div>
            </div>
            <Tabs defaultActiveKey={2} id="operations">
                <Tab eventKey={2} title="Odpracovaný čas">
                    <SuperTable
                        name={'work_records'}
                        items={items}
                        thead={
                            [
                                {name: 'date', sk_name: 'Dátum', type: 'date', sort: true},
                                {name: 'start', sk_name: 'Začatok', type: 'string', sort: false},
                                {name: 'end', sk_name: 'Koniec', type: 'string', sort: false},
                                {name: 'sum', sk_name: 'Hodiny', type: 'float', sort: false},
                            ]
                        }
                        columnSelector
                    />
                </Tab>
            </Tabs>
            </Container>
        );
    }
}

View.propTypes = {
    //data: PT.object.isRequired,
};

View.defaultProps = {
    data: {},
    work_summary: [],
};

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);

    return {
        order: state.orders.items.find(b => b.id === id),
        loading: state.orders.isFetching,
        data: state.orderWorkDetail.items,
    };
};

export default connect(mapStateToProps)(View);
