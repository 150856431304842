import React from 'react';
import PropTypes from 'prop-types';
import {Table, Button, Modal} from 'react-bootstrap';
import Tern from 'components/Or';
import NeedWritePerm from 'components/NeedPermission';
import EmptyDayRecord from './EmptyDayRecord';
import store from 'store/mainStore';
import { MdClose } from 'react-icons/md';
import workingHoursActions from 'actions/working_hours';
import { round_value, daysInMonth, mergeWorkingHours, getDaysInObj } from './monthViewUtils';
import '../style.css';
import { validateWorkTimes } from './recordUtils';

import Record from './Record';
import { parseSeconds } from 'scripts/dateFormatter';
import Spinner from 'components/Spinner';
import { getmonthName } from 'scripts/dateFormatter';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import DV from 'scripts/dateFormatter';
import { setGlobalError } from 'actions/globalError';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

class ConfirmForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.mounted = false;
        this.state = {
            buttonDisabled: false
        };
    }

    render() {
        const {props: P, state: S} = this;
        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Modal.Header>
                    Schválenie dochádzky
                    <div style={{ textAlign: 'right', height: 25}}>
                        <MdClose
                            style={{ cursor: 'pointer'}}
                            size={24}
                            onClick={() => P.onClose()}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        ref="form"
                        onInvalid={() => this.setState({ buttonDisabled: true })}
                        onValid={() => this.setState({ buttonDisabled: false })}
                        onValidSubmit={(model) => P.onSubmit(model)}
                    >
                        <Input
                            type={'text'}
                            name={'comment'}
                            label={'Komentár'}
                        />
                        <Button
                            //style={{marginTop: 20}}
                            variant="success"
                            type="submit"
                            disabled={S.buttonDisabled}
                        >
                            Potvrdiť
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

class MonthView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            updatingId: null,
            updateAttribute: null,
            updatingDay: null,
            lastOrderId: null,
            lastCarId: null,
            creatingDay: null,
            formShow: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        window.addEventListener('resize', () => this.handleWindowSizeChange());
    }

    // when the component is not mounted anymore
    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.handleWindowSizeChange());
    }

    handleWindowSizeChange() {
        if (this.mounted) {
            this.setState({width: window.innerWidth});
        }
    }

    fetchAllData(){
        const { props:P } = this;

        store.dispatch(workingHoursActions.fetchAll({
            id: P.employee_id,
            year: P.year,
            month: P.month,
        })).then(() => {
            this.setState({
                updatingId: null,
                updateAttribute: null,
                updatingDay: null,
                creatingDay: null,
            });
        }).catch( () => {
            this.setState({
                updatingId: null,
                updateAttribute: null,
                updatingDay: null,
                creatingDay: null,
            });
        });
    }

    onUpdateWh(id, day, key, value) {
        const { props:P } = this;

        this.setState({
            updatingId: parseInt(id),
            updateAttribute: key,
            updatingDay: day
        });

        if (key === 'order_id'){
            this.setState({
                lastOrderId: value
            });
        }
        if (key === 'car_id'){
            this.setState({
                lastCarId: value
            });
        }

        let obj = {
            [key]: value,
            employee_id: P.employee_id
        };

        store.dispatch(workingHoursActions.update(id, obj))
            .then(() => this.fetchAllData());
    }

    onCreateWh(lastTime) {
        const { props:P } = this;
        this.setState({
            creatingDay: lastTime.getDate()
        });

        const newEnd = new Date(lastTime.getTime() + 36E5);

        store.dispatch(workingHoursActions.create({
            employee_id: P.employee_id,
            start: this.getDateTimeString(lastTime),
            end: this.getDateTimeString(newEnd),
            type_id: 1
        })).then(() => {
            this.fetchAllData();
        });
    }

    getDateTimeString(date) {
        return date.toISOString();
    }

    getDateString(date, hours, minutes = 0) {
        let copy = new Date(date.getTime());
        copy.setHours(hours);
        copy.setMinutes(minutes);
        copy.setSeconds(0);

        return copy.toISOString();
    }

    onCreateNew(date) {
        this.setState({
            creatingDay: date.getDate()
        });

        store.dispatch(workingHoursActions.create({
            start: this.getDateString(date, 7, 0),
            end: this.getDateString(date, 15, 0),
            employee_id: this.props.employee_id,
            type_id: 1,
            lunch_break: true,
        })).then(() => {
            this.fetchAllData();
        });
    }

    onMonthConfirm(model){
        this.setState({
            formShow: false
        });

        this.props.onSubmitAllHours({
            comment: model.comment
        });
    }

    onConfirm() {
        let wh = this.props.workingHours;

        if (wh.some(w => {
            return (!w.start || !w.end);
        })) {
            store.dispatch(setGlobalError(['V niektorých záznamoch chýba čas príchodu/odchodu'], 'info'));
            return;
        }

        if (wh.some(w => {
            if (w.order_id) {return false;}
            let type = this.props.workingHoursTypes.find( t => t.id === w.type_id);
            return !!type.required_order;
        })) {
            store.dispatch(setGlobalError(['V niektorých záznamoch chýba číslo zákazky'], 'info'));
            return;
        }

        let sortedDays = new Map();
        wh.forEach( w => {
            let date = w.start ? new Date(w.start) : new Date(w.end);
            let index = date.getDate();
            if (!sortedDays.get(index)){
                sortedDays.set(index, {days: []});
            }
            sortedDays.get(index).days.push(w);
        });

        if ( Array.from(sortedDays.values()).some( d => {
            return (validateWorkTimes(d.days).length > 0);
        })) {
            store.dispatch(setGlobalError(['V niektorých záznamoch sa prekrývajú časové intervaly'], 'info'));
            return;
        }

        this.setState({
            formShow: true
        });
    }

    isCurrentMonth(){
        let date = new Date();

        return date.getFullYear() === this.props.year && date.getMonth() + 1 === this.props.month;
    }

    getToolTip(text, id = 'toolTip'){
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        return <span>
            <IoIosHelpCircleOutline
                size = {'1.5em'}
                style={{
                    margin: '0.5em',
                    marginTop: '0.1em'
                }}
                data-tip data-for={id}
            />
            <ReactTooltip
                id={id}
                type='dark'
                place="right"
                effect="solid"
                event="click"
                globalEventOff={ isMobile ? 'touchstart' : undefined }
            >
                <div style={{fontSize: '1.2em'}}>
                {text}
                </div>
            </ReactTooltip>
        </span>;
    }

    customStatistics(workedDaysArray){
        if (this.props.isApproved || !this.isCurrentMonth() || this.props.workingHours.length === 0){
            return null;
        }

        let lastDay = 1;
        workedDaysArray.forEach( w => {
            if (!w.isEmpty && lastDay < w.dayInMonth){
                lastDay = w.dayInMonth;
            }
        });

        let lastWorkDay = 0;
        workedDaysArray.forEach( day => {
            if (!day.isWeekend && !day.isHoliday && lastWorkDay < day.dayInMonth){
                lastWorkDay = day.dayInMonth;
            }
        });

        if (lastDay === lastWorkDay){
            return null;
        }

        const numOfNonEmptyDays = workedDaysArray.filter(day =>
            (!day.isEmpty && !day.isWeekend && !day.isHoliday)
        ).length;

        let nonEmptyDaysStat = (
            <div>
                Hodinový fond za odpracované dni: <strong>{round_value(numOfNonEmptyDays*7.5)}</strong>
                {this.getToolTip(
                    <span>
                        Počet pracovných dní v mesiaci ktoré majú aspoň jeden záznam dochádzky x 7,5 hodiny <br/>
                        Nepočítajú sa prázdne dni bez zápisu, víkendy a sviatky
                    </span>, 'numOfNonEmptyDays')}
            </div>
        );

        let lastDate = new Date(this.props.year, this.props.month - 1, lastDay);
        const numOfWorkDaysTillToday = workedDaysArray.filter(day =>
            (!day.isWeekend && !day.isHoliday && day.dayInMonth <= lastDay)
        ).length;

        let monthStart = new Date(this.props.year, this.props.month - 1, 1);
        let hoursTillToday = (
            <div>
                Celkový hodinový fond do {DV(lastDate, false)}: <strong>
                {round_value(numOfWorkDaysTillToday*7.5) }</strong>
                {this.getToolTip(
                    <span>
                        Počet všetkých pracovných dní od {DV(monthStart, false)} do {DV(lastDate, false)} (vrátane) x 7,5 hodiny <br/>
                        vrátane prázdnych pracovných dní bez záznamov
                    </span>, 'numOfWorkDaysTillToday')}
            </div>
        );

        if (numOfNonEmptyDays === numOfWorkDaysTillToday){
            return hoursTillToday;
        }

        return (
            <div>
                {nonEmptyDaysStat}
                {hoursTillToday}
            </div>
        );
    }

    getStatistics(workedDaysArray){
        const { props: P} = this;

        let total_sum = workedDaysArray.reduce((acc, day) => acc + day.time_sum, 0);
        const workingDays = workedDaysArray.reduce((acc, day) => {
            if (!day.isWeekend && !day.isHoliday) {acc += 1;}
            return acc;
        }, 0);

        return <div className="statistics"
            style={{
                display: 'block'
            }}>
            <div style={{
                marginBottom: '10px'
            }}>
            <div>
                Celkový počet zapísaných hodín za mesiac {getmonthName(P.month)} : <strong>
                        {round_value(total_sum/3600)}
                    </strong> hodín
            </div>
            </div>
            <div>
                Počet pracovných dní za mesiac {getmonthName(P.month)} : <strong>{workingDays}</strong>
            </div>
            <div>
                Mesačný hodinový fond: <strong>{round_value(workingDays*7.5)}</strong>
                {this.getToolTip(
                    <span>
                    Počet všetkých pracovných dní za mesiac {getmonthName(P.month)} x 7,5 hodiny
                </span>, 'workingDays')}
            </div>
            {this.customStatistics(workedDaysArray)}
        </div>;
    }

    compare(date1, date2){
        if (!date1 || !date2) {return false; }

        // compares only the date and time portion of the string with minute precision
        // the date is expected to be in ISO8601 format in UTC timezone e.g.: "2020-04-08T09:12:14.000000Z"
        return date1.substring(0, 16) === date2.substring(0, 16);
    }

    getWorkInfo(work){
        let options = {
            second: 'numeric',
            timeZoneName: 'short'
        };

        let info = [];
        if (work.tablet){
            info.push(<span>Tento záznam bol vytvorený tabletom {work.tablet.name} dňa {DV(work.created_at, true, options)}</span>);
            info.push(<span>Vlastník tabletu v čase zápisu: {work.tablet_owner.full_name}</span>);
        } else if (work.master) {
            info.push(<span>
                Tento záznam bol manuálne vytvorený zamestnancom {work.master.full_name} dňa {DV(work.created_at, true, options)}
            </span>);
        }

        if (work.start_location) {
            info.push(<span>
                Poloha tabletu v čase zápisu príchodu: <a
                    href={'https://maps.google.com/?q='+work.start_location}
                    target="_blank"
                    rel="noopener noreferrer"
                >mapa</a>
            </span>);
        }

        if (work.end_location) {
            info.push(<span>
                Poloha tabletu v čase zápisu odchodu: <a
                    href={ 'https://maps.google.com/?q=' + work.end_location }
                    target="_blank"
                    rel="noopener noreferrer"
            >mapa</a>
            </span>);
        }

        if (
            work.updated_at &&
            work.updated_at !== work.created_at &&
            !this.compare(work.updated_at, work.start) &&
            !this.compare(work.updated_at, work.end) &&
            work.updated_by
        ){
            info.push(<span>
                Tento záznam bol naposledy ručne upravený zamestnancom {work.updated_by.full_name} dňa {DV(work.updated_at, true, options)}
            </span>);
        }

        // if (work.tablet && work.updated_at !== work.created_at && (work.updated_at === work.end || work.updated_at === work.start)){
        //     info.push(<span>
        //         Tento záznam bol naposledy upravený tabletom {work.tablet.name} dňa {DV(work.updated_at, true, true)}<br/>
        //     </span>);
        // }

        return <ul
            style={{
                textAlign: 'left',
                listStyleType: 'none',
                fontSize: '1.2em',
            }}
        >
            {info.map( (val, key) => {
                return <li key={key}>{val}</li>;
            })}
        </ul>;
    }

    render() {
        const { props: P, state: S } = this;
        const { year, month, employee_id } = P;

        if (P.is_loading) {
            return <Spinner/>;
        }

        let daysCount = daysInMonth(year, month - 1);
        let emptyDays = getDaysInObj(daysCount, year, month - 1);
        let workedDays = mergeWorkingHours(emptyDays, P.workingHours);

        const workedDaysArray = [...workedDays.values()].map(function(wd) {
            let time_sum = wd.works.reduce((acc, val) => acc + val.time_in_seconds, 0);

            let diff = time_sum - (7.5 * 60 * 60);
            if (wd.isWeekend || wd.isholiday) {
                diff = round_value(time_sum);
            }

            return {
                ...wd,
                works: wd.works,
                isEmpty: wd.works.length === 0,
                hadLunch: wd.works.some((w) => w.lunch_break),
                time_sum: time_sum,
                difference: diff,
                isSumUpdating: S.updatingDay === wd.dayInMonth &&
                    ( S.updateAttribute === 'start'
                        || S.updateAttribute === 'end'
                        || S.updateAttribute === 'lunch_break'
                    ),
                isLunchUpdating: S.updatingDay === wd.dayInMonth && S.updateAttribute === 'lunch_break',
            };
        });

        let TBODY = workedDaysArray.map((day, di) => {
            if (day.isEmpty) {
                return (
                    <EmptyDayRecord
                        canMakeActions={P.canMakeActions}
                        isAccountant={Boolean(P.permissions.is_accountant)}
                        key={`empty-${di}`}
                        dayLabel={day.label}
                        date={new Date(year, month - 1, day.dayInMonth)}
                        windowInfo={{
                            employeeId: employee_id,
                            humanMonth: month,
                            year,
                        }}
                        isWeekend={day.isWeekend}
                        isHoliday={day.isHoliday}
                        isCreating={day.dayInMonth === S.creatingDay}
                        createWH={(day) => this.onCreateNew(day)}
                    />
                );
            }

            const timeCollisions = validateWorkTimes(day.works);
            return day.works.map((work, i) => {
                const props = {
                    key: work.id,
                    id: work.id,
                    dayInMonth: day.dayInMonth,
                    dayLabel: day.label,
                    firstInDay: i === 0,
                    isEmpty: day.isEmpty,
                    rowspan: day.works.length,
                    start: work.start,
                    end: work.end,
                    start_time: work.start_time,
                    end_time: work.end_time,
                    isCreating: S.creatingDay === day.dayInMonth,
                    isInCollision: timeCollisions.some(collision => collision.id === work.id),
                    order: work.order_id ? P.orders.find(o => o.id === work.order_id): null,
                    lunch: work.lunch_break || false,
                    workingHourType: P.workingHoursTypes.find((t) => t.id === work.type_id),
                    car: work.car_id ? P.cars.find((c) => c.id === work.car_id): null,
                    time_sum: day.time_sum,
                    canMakeActions: P.canMakeActions,
                    isAccountant: Boolean(P.permissions.is_accountant),
                    mappings: {
                        orders: P.orders,
                        cars: P.cars,
                        workingHoursTypes: P.workingHoursTypes
                    },
                    isHoliday: day.isHoliday,
                    isWeekend: day.isWeekend,
                    dayRecords: day.works,
                    isSumUpdating: day.isSumUpdating || S.creatingDay === day.dayInMonth,
                    isLunchUpdating: day.isLunchUpdating,
                    updating: S.updatingId === work.id,
                    updateAttribute: S.updateAttribute,
                    lastOrderId: S.lastOrderId,
                    lastCarId: S.lastCarId,
                    workInfo: this.getWorkInfo(work),
                    listInfo: {
                        year: P.year,
                        humanMonth: P.month,
                        dayInMonth: day.dayInMonth,
                        employeeId: P.employee_id
                    },
                    createWH: (last) => {this.onCreateWh(last);},
                    updateWH: (id, day, key, value) => {this.onUpdateWh(id, day, key, value);},
                };
                return <Record {...props} />;
            });
        });

        let total_sum = workedDaysArray.reduce((acc, day) => acc + day.time_sum, 0);

        return (
            <div className="month-view">
                <Table className="month-view" bordered responsive>
                    <thead>
                    <tr>
                        <th>Deň</th>
                        <th>Zákazka</th>
                        <th>Príchod</th>
                        <th>Odchod</th>
                        <th>Typ</th>
                        <th>Hodiny</th>
                        <th>Obed</th>
                        <th>ŠPZ</th>
                        <Tern expression={P.canMakeActions}>
                            <th/>
                        </Tern>
                        <Tern expression={P.canMakeActions}>
                            <th/>
                        </Tern>
                        <Tern expression={P.permissions.is_accountant}>
                            <th/>
                        </Tern>
                    </tr>
                    </thead>
                    <tbody>
                    {TBODY}
                    <tr style={{ textAlign: 'center', borderTop: '3px solid black' }}>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{parseSeconds(total_sum)}</td>
                        <td />
                        <td />
                        <Tern expression={P.canMakeActions}>
                            <td/>
                        </Tern>
                        <Tern expression={P.canMakeActions}>
                            <td/>
                        </Tern>
                        <Tern expression={P.permissions.is_accountant}>
                            <td/>
                        </Tern>
                    </tr>
                    </tbody>
                </Table>
                <Tern expression={!P.isApproved && P.show_approval}>
                    <NeedWritePerm type="button" need={['working_hours']}>
                        <Button
                            variant="success"
                            onClick={() => this.onConfirm()}
                            style={{marginTop: 10}}
                        >
                            Schváliť dochádzku
                        </Button>
                    </NeedWritePerm>
                </Tern>
                <ConfirmForm
                    show={S.formShow}
                    onSubmit={model => this.onMonthConfirm(model)}
                    onClose={() => this.setState({formShow: false})}
                />
                {P.isApproved ?
                    <div
                        className="statistics spacing"
                        style={{marginBottom: 20}}
                    >
                        <span>
                            Tento mesiac bol schválený zamestnancom
                        <strong> {P.approval.approved_by ? P.approval.approved_by.full_name : ''} </strong>
                        dňa
                        <strong> {DV(P.approval.approved_at)} </strong>
                        <Tern expression={Boolean(P.approval.comment)}>
                            <div>
                                Komentár ku schváleniu: {P.approval.comment}
                            </div>
                        </Tern>
                        </span>
                        <Tern expression={P.permissions.is_accountant}>
                            <span style={{textAlign: 'right'}}>
                                 <Button
                                     variant="danger"
                                     type="submit"
                                     onClick={() => {
                                         P.onDeleteApproval(P.approval.id);
                                     }}
                                 >
                                 Zrušiť schválenie
                            </Button>
                            </span>
                        </Tern>
                    </div> : <div/>
                }
                {this.getStatistics(workedDaysArray, false, [])}
            </div>
        );
    }
}

MonthView.propTypes = {
    onSubmitAllHours: PropTypes.func.isRequired,
    cars: PropTypes.array.isRequired,
    holidays: PropTypes.array.isRequired,
    canMakeActions: PropTypes.bool.isRequired,
    show_approval: PropTypes.bool.isRequired,
    workingHours: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.object.isRequired,
};

export default MonthView;
