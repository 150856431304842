import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import 'css/custom-select-style.css';
import { MdClose } from 'react-icons/md';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import call from '../../ajax/call';
import { formatDate } from 'scripts/dateFormatter';

class UpdateOwnProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.state = {
            errors: {},
            formSubmitted: false
        };
    }

    handleFormSubmit(model) {
        this.setState({
            formSubmitted: true
        });

        call('/api/update_profile', {
            method: 'PUT',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(model)})
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.props.onSubmit();
                }
            });
    }

    render() {
        const {props: P} = this;

        const onKeyPress = event => {
            if (event.which === 13 /* Enter */) {
                event.preventDefault();
            }
        };

        return (
            <Modal
                size="md"
                className="update-profile-form"
                show
                onHide={this.props.onClose}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: 25
                        }}
                    >
                        Upraviť profil
                        <MdClose
                            size={24}
                            onClick={ () => {this.props.onClose();}}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onKeyPress={onKeyPress}
                        onValidSubmit={model => this.handleFormSubmit(model)}
                        onInvalidSubmit={ model => this.handleFormSubmit(model)}
                        validationErrors={this.state.errors}
                        autoComplete={'off'}
                    >
                        <Input
                            name="mobile_number"
                            label="Telefónne číslo"
                            value={P.employee.mobile_number || ''}
                            autoComplete={'mobile_number'}
                        />
                        <Input
                            name="email"
                            label="E-mail"
                            type={'email'}
                            value={P.employee.email || ''}
                            validations="isEmail"
                            autoComplete={'email'}
                        />
                        <Input
                            name="address"
                            label="Adresa"
                            value={P.employee.address || ''}
                            autoComplete={'address'}
                        />
                        <Input
                            type="date"
                            name="birthday"
                            label="Dátum narodenia"
                            value={formatDate(P.employee.birthday)}
                        />
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Upraviť
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

UpdateOwnProfileForm.propTypes = {
    updating: PropTypes.bool.isRequired,
    employee: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default UpdateOwnProfileForm;