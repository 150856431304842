import React from 'react';
import PT from 'prop-types';
import Formsy from 'formsy-react';
import { Button, Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

class DateForm extends React.Component {

    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.state = {
            date: new Date()
        };
    }

    render() {
        const { props:P } = this;
        return (
            <div className="dateForm">
                <Formsy
                    ref={this.form}
                    onValidSubmit={() => P.onSubmit(this.state.date)}
                >
                    <Row style={{marginTop: 20}}>
                        <Col md={4}>
                            <h4>Zvoľte dátum</h4>
                        </Col>
                        <Col md={4}>
                            <DatePicker
                                selected={this.state.date}
                                onChange={date => {
                                    console.log(date);
                                    this.setState({date: date});
                                }}
                                todayButton={'Dnes'}
                                locale={'sk'}
                                calendarStartDay={1}
                                dateFormat="d. MMMM yyyy"
                                dropdownMode={'select'}
                                showYearDropdown
                                showMonthDropdown
                            />
                        </Col>
                        <Col md={4}>
                            <Button
                                type="submit"
                                variant="success"
                            >
                                Zobraziť
                            </Button>
                        </Col>
                    </Row>
                </Formsy>
            </div>
        );
    }
}

DateForm.propTypes = {
    onSubmit: PT.func
};

DateForm.defaultProps = {
    onSubmit: () => {}
};

export default DateForm;
