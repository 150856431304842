import {SET_PERMISSIONS} from 'actions/permissions';

// TODO: this array should be obtained from backend service as new permissions are added constantly
const ALLOWED_PERMISSIONS = [
    'buildings',
    'orders',
    'levels',
    'roles',
    'tablets',
    'tools',
    'employees',
    'working_hours',
    'current_view',
    'is_accountant',
    'is_logistics',
    'material',
    'material_prices',
    'certifications',
    'cars',
    'logs',
    'current_view',
    'operations',
    'operation_types',
    'webapp_access',
    'groups',
    'planning',
    'storage',
    'gdpr',
    'time_notifications',
];

let defaultState = (function () {
    let obj = {};
    ALLOWED_PERMISSIONS.forEach(v => { 
        obj[v] = 0;
    });
    return obj;
})();

let localState = JSON.parse(localStorage.getItem('permissions'));

if (localState) {
    defaultState = localState;
}

const getAllowedValues = obj => {
    let new_obj = {};
    ALLOWED_PERMISSIONS.forEach(v => {
        new_obj[v] = obj[v];
    });
    return new_obj;
};


const permissions = (state = defaultState, action) => {
    if (action.type === SET_PERMISSIONS) {
        return getAllowedValues(action.permissions);
    } else {
        return state;
    }
};

export default permissions;