import React from 'react';
import Menu from './Menu';
import { Container } from 'react-bootstrap';

class Home extends React.Component {

    render() {
        return (
            <Container>
                <Menu />
            </Container>
        );
    }
}

export default Home;