import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormSelect} from 'components/CustomSelect';
import Form from 'formsy-react';

function getLabelName(category) {
    return category.text + ' (' + category.count + ')';
}

class MaterialCategoriesForm extends Component {

    render() {
        const {props: P} = this;

        const selectBoxes = [];
        let options = P.categories.filter(c => c.parent_id === null);

        selectBoxes.push(
            <FormSelect
                key={0}
                name={'category'}
                label={'Zvoľte kategóriu'}
                options={options}
                placeholder={'Vyberte kategóriu'}
                getOptionValue={c => c.id}
                getOptionLabel={getLabelName}
                onChange={c => P.changeHandler(c, 0)}
                value={P.value[0]}
                searchable={true}
                isClearable={true}
            />
        );

        for (let i = 1; i < Object.values(P.value).length + 1; i++) {
            if (P.value[i - 1]) {
                const options = P.categories.filter(c => c.parent_id === P.value[i - 1].id);
                if (options.length) {
                    selectBoxes.push(
                        <FormSelect
                            key={i}
                            name={'category'}
                            label={''}
                            options={options}
                            placeholder={'Vyberte kategóriu'}
                            getOptionValue={c => c.id}
                            getOptionLabel={getLabelName}
                            onChange={c => P.changeHandler(c, i)}
                            value={P.value[i]}
                            searchable={true}
                            isClearable={true}
                        />
                    );
                }
            }
        }

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'left',
            }}>
                <Form className={'col-sm-12'}>
                    {selectBoxes}
                </Form>
            </div>
        );
    }
}

MaterialCategoriesForm.propTypes = {
    categories: PropTypes.array.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeHandler: PropTypes.func.isRequired,
};

export default MaterialCategoriesForm;
