import React from 'react';
import store from 'store';
import storesActions from 'actions/stores';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { SuperTable } from 'components/PaginationTable';

class Stores extends React.Component {

    componentDidMount() {
        store.dispatch(storesActions.fetchAll());
    }

    render() {
        const { props: P } = this;
        return (
            <Container>
                <h2>Zoznam skladov</h2>
                <SuperTable
                    name={'stores'}
                    items={P.stores}
                    thead={[
                        {name: 'code', sk_name: 'Kód', type: 'string'},
                        {name: 'name', sk_name: 'Názov', type: 'string'}
                    ]}
                    searchable
                    onRowClick={id => this.props.history.push(`/stores/content/${id}`)}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stores: state.stores.items,
    };
};

export default connect(mapStateToProps)(Stores);