import React from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import 'css/custom-select-style.css';
import call from '../../ajax/call';
import Form from 'react-bootstrap/cjs/Form';
import store from '../../store';
import {setGlobalError} from '../../actions/globalError';
import Spinner from 'components/Spinner';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.state = {
            errors: {},
            formSubmitted: false,
            isFetching: false,
        };
        this.email = React.createRef();
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.email.current.value && !this.state.isFetching) {
            this.setState({
                formSubmitted: true,
                isFetching: true
            });

            call('/api/forgot_password', {
                method: 'POST',
                headers: {'Content-type': 'application/json'},
                body: JSON.stringify({'email': this.email.current.value})
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        isFetching: false
                    });
                    if (data.status === 'ok') {
                        store.dispatch(setGlobalError([data.message], 'info', false));
                        this.props.history.push('/login');
                    } else {
                        store.dispatch(setGlobalError([data.message], 'info'));
                    }
                });
        }
    }

    componentDidMount() {
        this.email.current.focus();
    }

    render() {
        const { state: S } = this;

        return (
            <Container className='login-page'>
                <Row>
                    <Col
                        lg={{span: 4, offset: 4}}
                        md={12}
                        className="login-form"
                    >
                        <div>
                            <Form
                                className="form"
                                onSubmit={this.handleFormSubmit.bind(this)}
                                autoComplete={'off'}
                            >
                                <h2>Zabudnuté heslo</h2>
                                {S.isFetching ? <Spinner/> : null}
                                <p>Zadajte emailovú adresu nastavenú v profile, na ktorú Vám zašleme link na obnovenie hesla</p>
                                <div>
                                    <input
                                        name='email'
                                        ref={this.email}
                                        type="email"
                                        placeholder="Emailová adresa"
                                    />
                                </div>
                                <input
                                    className={this.state.isFetching ? 'btn disabled': ''}
                                    style={this.state.isFetching ? {cursor: 'not-allowed'} : {}}
                                    aria-disabled={this.state.isFetching}
                                    type="submit"
                                    value="Odoslať"
                                />
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ForgotPassword;