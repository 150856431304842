import React from 'react';
import store from 'store';
import buildingLastPerformancesActions from 'actions/building_last_performances';
import buildingsWithPerformancesActions from 'actions/buildings_with_performances';
import {loadBuilding, setBuilding} from '../Planning/utils';
import SectionForm from '../WorkOperations/SectionForm';
import {SuperTable} from 'components/PaginationTable';
import {Container} from 'react-bootstrap';
import Formsy from 'formsy-react';
import {connect} from 'react-redux';
import Spinner from 'components/Spinner';


class RecentPerformances extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            building_id: null,
        };
    }

    componentDidMount() {
        store.dispatch(buildingsWithPerformancesActions.fetchAll())
            .then(data => {
                if (data.status === 'ok') {
                    const building_id = loadBuilding(this.props.location, this.props.history, data.data);
                    this.setState({
                        building_id
                    });

                    if (building_id) {
                        store.dispatch(buildingLastPerformancesActions.fetchAll({building_id}));
                    }
                }
            });
    }

    redirect(building_id, entity_id) {
        this.props.history.push(`/operation_work/?building_id=${building_id}&entity_id=${entity_id}`);
    }

    render() {
        const {props: P, state: S} = this;

        if (P.isLoading) {
            return <Spinner/>;
        }

        return (
            <Container>
                <Formsy>
                    <SectionForm
                        onChange={newParams => {
                            setBuilding(P.location, P.history, newParams.building_id);
                            this.setState({
                                building_id: newParams.building_id
                            }, () => {
                                store.dispatch(buildingLastPerformancesActions.fetchAll({
                                    building_id: newParams.building_id
                                }));
                            });
                        }}
                        locked={false}
                        params={{
                            building_id: S.building_id,
                        }}
                        leaf_required={false}
                        onlyBuildingsWithPerformances={true}
                    />
                </Formsy>
                <div>
                    <h4>Posledné zapísané operácie</h4>
                    <SuperTable
                        name={'building_last_performances'}
                        thead={[
                            {name: 'group', sk_name: 'Skupina', type: 'string'},
                            {name: 'building_name', sk_name: 'Budova', type: 'string'},
                            {name: 'entity', sk_name: 'Pozícia', type: 'string'},
                            {name: 'operation', sk_name: 'Operácia', type: 'string'},
                            {name: 'status_percent_pie_chart', sk_name: 'Odpracované', type: 'pie_chart'},
                            {name: 'status_percent', sk_name: '%', type: 'integer'},
                            {name: 'date', sk_name: 'Dátum', type: 'short_date'},
                            {name: 'start', sk_name: 'Začiatok', type: 'time'},
                            {name: 'finish', sk_name: 'Koniec', type: 'time'},
                            {name: 'total_time', sk_name: 'čas', type: 'seconds'},
                            {name: 'description', sk_name: 'Poznámka', type: 'string', hidden: true}
                        ]}
                        searchable
                        columnSelector={true}
                        items={P.buildingLastPerformances}
                        is_loading={P.is_loading}
                        onRowClick={id => {
                            const performance = P.buildingLastPerformances.find(x => x.id === id);
                            this.redirect(performance.building_id, performance.entity_id);
                        }}
                        rowStyleFn={(column, obj) => {
                            let val = {};

                            if (column === 'group') {val.whiteSpace = 'normal';}

                            if (column === 'finish' && obj.finish === null) {val.backgroundColor = 'rgba(255, 0, 10, 0.3)';}
                            if (column === 'start' && obj.start === null) {val.backgroundColor = 'rgba(255, 0, 10, 0.3)';}

                            return val;
                        }}
                    />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    buildingLastPerformances: state.buildingLastPerformances.items,
    buildingsWithPerformances: state.buildingsWithPerformances.items,
    isLoading:
        state.buildingLastPerformances.isFetching ||
        state.buildingsWithPerformances.isFetching
});

export default connect(mapStateToProps)(RecentPerformances);