import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import NotFoundPage from './components/NotFoundPage';

import Home from './components/Home';
import Template from './components/Template';
import Employees from './Employees';
import Roles from './Roles';
import Orders from './Orders';
import OrderDetail from './Orders/Detail/index.jsx';
import OrderDocumentMaterialItems from './Orders/Detail/OrderDocumentMaterialItems';
import OrderMaterialItemDocuments from './Orders/Detail/OrderMaterialItemDocuments';
import Buildings from './Buildings';
import Implements from './Tools';
import Attendance from './WorkingHours';
import AttendanceDetail from './WorkingHours/Detail';
import Tablets from './Tablets';
import Login from './components/Login';
import EmployeeDetail from './Employees/EmployeeDetail';
import LoginValidator from '../components/LoginValidator';
import Professions from './Professions';
import TabletDetail from './Tablets/TabletDetail';
import CertificationTypes from './CertificationTypes';
import Cars from './Cars';
import CarsDetail from './Cars/Detail';
import Logs from './Logs';
import NoAccess from './components/NoAccess';
import ToolDetail from './Tools/Detail';
import BuildingDetail from './Buildings/Detail/index.jsx';
import BuildingIssueDetail from './BuildingIssues/Detail';
import TodayWork from './TodayWork';
import PerformancesOverview from './Performances/PerformancesOverview';
import RecentPerformances from './Performances/RecentPerformances';
import ToolCategories from './Tools/Categories';
import OrderWorkDetail from './Orders/Detail/EmployeeHoursDetail';
import Notifications from './Notifications';
import Subscriptions from './Subscriptions';
import Groups from './Groups';
import 'bootstrap/dist/css/bootstrap.css';
import GroupDetail from './Groups/GroupDetail';
import Training from './Training';
import TrainingDetail from './Training/Detail';
import Planning from './Planning';
import SectionDetail from './Planning/SectionDetail';
import OperationDetail from './Planning/OperationDetail';
import ForgotPassword from './Employees/ForgotPassword';
import ResetPassword from './Employees/ResetPassword';
import BusinessTripsCars from './WorkingHours/BusinessTripsCars';
import Statistics from './Planning/Statistics';
import MyOperationsDetail from './MyOperations/Detail';
import MyOperations from './MyOperations/list';
import MaterialOverview from './MaterialOverview/MaterialOverview';
import Operations from './WorkOperations';
import OperationTypes from './OperationTypes';
import StoreCard from './MaterialOverview/StoreCardDetail';
import Stores from './Storage';
import StoreContent from './Storage/detail';
import BuildingEquipment from './BuildingEquipment';

import 'App.css';

class App extends Component {
    isAuthorized(path) {
        if (['/', '/login', '/forgot_password'].includes(path)) {
            return true;
        }

        if (path.startsWith('/employees/detail/') || path.startsWith('/reset_password')) {
            return true;
        }

        const paths = {
            'notifications': 'webapp_access',
            'subscriptions': 'webapp_access',
            'attendance': 'working_hours',
            'certification_types': 'certifications',
            'worktoday': 'current_view',
            'performances_overview': 'planning',
            'material_overview': 'material',
            'recent_performances': 'planning',
            'rewards': 'working_hours',
            'reward_multipliers': 'is_accountant',
            'trainings': 'certifications',
            'operation_work': 'planning',
            'operation_statistics': 'planning',
            'building_issues': 'buildings',
            'building_equipment': 'buildings',
            'my_operations': 'operations',
            'storecards': 'material',
            'stores': 'storage',
        };

        for (let p in paths) {
            if (path.includes(p)) {
                return this.props.permissions[paths[p]] > 0;
            }
        }

        // assumes that path starts with '/'
        let perm = path.split('/')[1];
        return this.props.permissions[perm] > 0;
    }

    render() {
        const paths = {
            '/login': Login,
            '/': Home,
            '/forgot_password': ForgotPassword,
            '/reset_password': ResetPassword,
            '/employees': Employees,
            '/employees/detail/:id': EmployeeDetail,
            '/employees/professions': Professions,
            '/roles': Roles,
            '/orders': Orders,
            '/orders/detail/:id': OrderDetail,
            '/buildings': Buildings,
            '/buildings/detail/:id': BuildingDetail,
            '/building_issues/detail/:id': BuildingIssueDetail,
            '/building_equipment/detail/:id': BuildingEquipment,
            '/tools': Implements,
            '/tools/detail/:id': ToolDetail,
            '/attendance': Attendance,
            '/attendance/cars': BusinessTripsCars,
            '/attendance/:id': AttendanceDetail,
            '/tablets': Tablets,
            '/tablets/:id': TabletDetail,
            '/employees/certification_types': CertificationTypes,
            '/cars': Cars,
            '/cars/:id': CarsDetail,
            '/logs': Logs,
            '/worktoday': TodayWork,
            '/performances_overview': PerformancesOverview,
            '/material_overview': MaterialOverview,
            '/recent_performances': RecentPerformances,
            '/notifications': Notifications,
            '/subscriptions': Subscriptions,
            '/tools/categories': ToolCategories,
            '/orders/detail/:id/employee/:employee_id': OrderWorkDetail,
            '/orders/detail/:id/:code/documents': OrderMaterialItemDocuments,
            '/orders/detail/:id/:documentType/:schema/:documentId/material_items': OrderDocumentMaterialItems,
            '/groups': Groups,
            '/groups/:id': GroupDetail,
            '/trainings': Training,
            '/trainings/:id': TrainingDetail,
            '/planning': Planning,
            '/planning/entity': SectionDetail,
            '/operations': Operations,
            '/operation_types': OperationTypes,
            '/operation_work': OperationDetail,
            '/operation_statistics': Statistics,
            '/my_operations/:id': MyOperationsDetail,
            '/my_operations': MyOperations,
            '/storecards/:id': StoreCard,
            '/stores': Stores,
            '/stores/content/:id': StoreContent,
        };

        let routes = Object.keys(paths).map(path => {
            if (this.isAuthorized(path)) {
                return <Route
                    key={path}
                    path={path}
                    exact
                    component={paths[path]}
                />;
            } else {
                return <Route
                    key={path}
                    path={path}
                    exact
                    component={NoAccess}
                />;
            }
        });

        routes.push(
            <Route
                key={'*'}
                path={'*'}
                component={NotFoundPage}
            />);

        return (
            <HashRouter>
                <Template>
                    <LoginValidator>
                        <Switch>
                            {routes}
                        </Switch>
                    </LoginValidator>
                </Template>
            </HashRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions,
        identity: state.identity
    };
};

export default connect(mapStateToProps)(App);
