import React from 'react';
import {connect} from 'react-redux';
import {Button, Container} from 'react-bootstrap';
import carsActions from 'actions/cars';
import employeesActions from 'actions/employees';
import store from 'store/mainStore';
import NeedWritePerm from 'components/NeedPermission';
import {DefaultSelector, SuperTable} from 'components/PaginationTable';
import ModalForm from 'components/ModalForm';

class Cars extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            objToUpdate: undefined,
        };
    }

    componentDidMount() {
        // TODO: This does not work well when multiple users are modifying the same data
        // Storing all data locally in browser and refresh only when it is necessary
        // is better for performance but could result in problems with multiple users working on same view
        // on the other hand, manually fetching all data every time to ensure they are up to date, is far worse solution

        // ideally backed should support fetching changes only, based on item hashes or modified timestamps
        if (this.props.cars.length < 2) {
            store.dispatch(carsActions.fetchAll());
        }
        store.dispatch(employeesActions.fetchAll());
    }

    rowStyleFn(column, obj) {
        if (column === 'technical_control' || column === 'emission_control') {
            if (obj[column]) {
                let today = new Date();
                let date = new Date(obj[column]);
                let diff = date - today;

                if (diff <= 0) {
                    return ({
                        backgroundColor: '#ff0000'
                    });
                }

                let diffInDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
                if (diffInDays <= 60) {
                    let brightness = diffInDays / 60.0 * 20 + 50;

                    return ({
                        backgroundColor: `hsl(${diffInDays}, 100%, ${brightness}%)`
                    });
                }
            }
        }
        return {};
    }

    render() {
        const {props: P, state: S} = this;

        return (
            <Container className="cars">
                <div style={{marginBottom: 20}}>
                    <NeedWritePerm type="button" need={['cars']}>
                        <Button
                            variant="success"
                            onClick={() => this.setState({
                                    objToUpdate: undefined,
                                    formActive: true,
                                }
                            )}
                        >
                            Pridať auto
                        </Button>
                    </NeedWritePerm>
                </div>
                <NeedWritePerm type="table" need={['cars']}>
                    <SuperTable
                        name={'cars'}
                        thead={[
                            {name: 'active', sk_name: 'V prevádzke', type: 'boolean', hidden: true},
                            {name: 'type', sk_name: 'Typ', type: 'string'},
                            {name: 'spz', sk_name: 'ŠPZ', type: 'string'},
                            {name: 'owner_name', sk_name: 'Vlastník', type: 'string'},
                            {name: 'technical_control', sk_name: 'Platnosť TK do', type: 'short_date'},
                            {name: 'emission_control', sk_name: 'Platnosť EK do', type: 'short_date'},
                            {name: 'color', sk_name: 'Farba', type: 'string', hidden: true},
                            {name: 'name', sk_name: 'Názov', type: 'string', hidden: true},
                            {name: 'power', sk_name: 'Výkon', type: 'string', hidden: true},
                            {name: 'consumption', sk_name: 'Spotreba', type: 'string', hidden: true},
                            {name: 'file', sk_name: 'Dokument', type: 'file'}
                        ]}
                        columnSelector
                        items={P.cars}
                        onEditItem={id => {
                            this.setState({
                                objToUpdate: this.props.cars.find(c => c.id === id),
                                formActive: true,
                            });
                        }}
                        onRowClick={(id) => this.props.history.push(`/cars/${id}`)}
                        onDeleteItem={id => store.dispatch(carsActions.delete(id))}
                        selectors={DefaultSelector}
                        rowStyleFn={this.rowStyleFn}
                        delOps
                        editOps
                        searchable
                        export={'auta'}
                        is_loading={P.is_loading}
                    />
                </NeedWritePerm>

                <ModalForm
                    show={S.formActive}
                    formElements={[
                        {name: 'spz', label: 'ŠPZ', type: 'text', required: true},
                        {name: 'color', label: 'Farba', type: 'text', placeholder: 'Biela'},
                        {
                            name: 'type',
                            label: 'Typ',
                            type: 'text',
                            placeholder: 'Citroen Jumper',
                            required: true,
                        },
                        {name: 'name', label: 'Názov', type: 'text', placeholder: 'Citroen', required: true},
                        {name: 'power', label: 'Výkon', type: 'text', placeholder: '75KW'},
                        {name: 'consumption', label: 'Spotreba', type: 'text', placeholder: '6.5L/100km'},
                        {name: 'technical_control', label: 'Platnosť TK do', type: 'date'},
                        {name: 'emission_control', label: 'Platnosť EK do', type: 'date'},
                        {
                            name: 'owner',
                            label: 'Vlastník',
                            type: 'select',
                            options: {
                                items: P.employees,
                                labelKey: 'full_name',
                            },
                            selectKey: 'owner_id',
                            required: true,
                        },
                        {name: 'file', label: 'Dokument', type: 'file'},
                        {name: 'active', label: 'Aktívne', type: 'checkbox'},
                    ]}
                    updatingObj={S.objToUpdate}
                    action={carsActions}
                    afterSave={() => this.setState({formActive: false, objToUpdate: undefined})}
                    onClose={() => this.setState({formActive: false, objToUpdate: undefined})}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        cars: state.cars.items,
        employees: state.employees.items.filter(e => e.active),
        is_loading: state.employees.isFetching || state.cars.isFetching
    };
};

export default connect(mapStateToProps)(Cars);
