import React from 'react';
import PT from 'prop-types';
import {Button, Col, Row, Tab, Tabs} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import {SuperTable} from 'components/PaginationTable';
import NeedWritePerm from 'components/NeedPermission';
import ModalForm from 'components/ModalForm';
import {Link} from 'react-router-dom';
import carsHistoryActions from 'actions/cars_history';
import carsActions from 'actions/cars';
import store from 'store/mainStore';
import './style.css';
import DV from 'scripts/dateFormatter';

class View extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            objToUpdate: undefined,
        };
    }

    afterUpdate() {
        this.setState({
            formActive: false,
        });
        store.dispatch(carsActions.fetchConcrete(this.props.data.id));
    }

    onRowClick(id) {
        let item = this.props.carsHistory.find(obj => obj.id === parseInt(id, 10));

        this.setState({
            objToUpdate: item,
            formActive: true,
        });
    }

    handleItemDelete(id) {
        store.dispatch(carsHistoryActions.delete(id))
            .then(data => {
                    if (data.status === 'ok') {
                        this.afterUpdate();
                    }
                }
            );
    }

    afterSave(data) {
        if (data.data.type === 'STK' || data.data.type === 'EK') {
            store.dispatch(carsActions.fetchConcrete(this.props.data.id));
        }

        this.setState({formActive: false});
    }

    render() {
        const {props: P, state: S} = this;

        const current_mileage = P.carsHistory && P.carsHistory.length > 0
            ? P.carsHistory[P.carsHistory.length - 1].current_mileage
            : '--';

        return (
            <div>
                <div className="info">
                    <ModalForm
                        key={S.objToUpdate ? S.objToUpdate.id : 'new'}
                        show={S.formActive}
                        updatingObj={S.objToUpdate}
                        formElements={[
                            {
                                name: 'type',
                                label: 'Typ',
                                type: 'select',
                                options: {
                                    items: [
                                        {id: 'Servis', name: 'Servis'},
                                        {id: 'STK', name: 'STK'},
                                        {id: 'EK', name: 'EK'},
                                    ]
                                },
                                selectKey: 'type',
                                selectFirstValue: true,
                                required: true,
                            },
                            {name: 'date', label: 'Dátum', type: 'date', value: new Date(), required: true},
                            {name: 'description', label: 'Popis', type: 'textarea', required: true},
                            {name: 'price', label: 'Cena', type: 'number'},
                            {
                                name: 'current_mileage',
                                label: 'Aktuálne najazdených km',
                                type: 'text',
                                placeholder: '150 tis. Km'
                            },
                            {name: 'document', label: 'Dokument', type: 'file'},
                            {name: 'car_id', label: 'Car', type: 'hidden', value: P.data.id}
                        ]}
                        action={carsHistoryActions}
                        afterSave={data => this.afterSave(data)}
                        onClose={() => this.setState({formActive: false})}
                    />
                    <Row style={{
                        marginBottom: 15,
                        marginTop: 15,
                    }}>
                        <Col xs={6} className="text-right">Názov</Col>
                        <Col xs={6} className="text-left">{P.data.name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Typ</Col>
                        <Col xs={6} className="text-left">{P.data.type}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">ŠPZ</Col>
                        <Col xs={6} className="text-left">{P.data.spz}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Farba</Col>
                        <Col xs={6} className="text-left">{P.data.color}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Výkon</Col>
                        <Col xs={6} className="text-left">{P.data.power}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Spotreba</Col>
                        <Col xs={6} className="text-left">{P.data.consumption}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Počet km</Col>
                        <Col xs={6} className="text-left">{current_mileage}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Platnosť STK</Col>
                        <Col xs={6} className="text-left">{DV(P.data.technical_control, false)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Platnosť EK</Col>
                        <Col xs={6} className="text-left">{DV(P.data.emission_control, false)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Vlastník</Col>
                        <Col xs={6} className="text-left">
                            <Link to={`/employees/detail/${P.data.owner_id}`}>{P.data.owner_name}</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Aktívne</Col>
                        <Col xs={6} className="text-left">{
                            P.data.active ? <FaCheck/> : <MdClose/>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Dokument</Col>
                        <Col xs={6} className="text-left">
                            {
                                P.data.file ?
                                    <a
                                        target="_blank"
                                        href={P.data.file}
                                        rel="noopener noreferrer"
                                    >
                                        Zobraziť
                                    </a> :
                                    '-'
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center" style={{marginTop: 25}}>
                            <NeedWritePerm need={['cars']} type={'button'}>
                                <Button
                                    variant="success"
                                    onClick={() => P.onEdit()}
                                >
                                    Upraviť
                                </Button>
                            </NeedWritePerm>
                        </Col>
                    </Row>
                </div>
                <Tabs defaultActiveKey={1} id='history'>
                    <Tab eventKey={1} title="Servisná história">
                        <NeedWritePerm type="button" need={['cars']}>
                            <Button
                                onClick={() => this.setState({formActive: true, objToUpdate: null})}
                                variant="success"
                                disabled={false}
                                style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                }}
                            >
                                Pridať záznam
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm type={'table'} need={['cars']}>
                            <SuperTable
                                name={'service_history'}
                                items={P.carsHistory.filter(x => x.type !== 'Zmena vlastníka')}
                                thead={
                                    [
                                        {name: 'date', sk_name: 'Dátum', type: 'date'},
                                        {name: 'type', sk_name: 'Typ', type: 'string'},
                                        {name: 'description', sk_name: 'Popis', type: 'string'},
                                        {name: 'price', sk_name: 'Cena', type: 'float'},
                                        {name: 'current_mileage', sk_name: 'Počet km', type: 'string'},
                                        {name: 'document', sk_name: 'Dokument', type: 'file'}
                                    ]
                                }
                                columnSelector
                                onDeleteItem={id => this.handleItemDelete(id)}
                                onRowClick={id => this.onRowClick(id)}
                                delOps
                                defaultSortKey={'date'}
                                searchable
                            />
                        </NeedWritePerm>
                    </Tab>
                    <Tab eventKey={2} title="Vlastníci">
                        <NeedWritePerm type={'table'} need={['cars']}>
                            <SuperTable
                                name={'history_change_owner'}
                                items={P.carsHistory.filter(x => x.type === 'Zmena vlastníka')}
                                thead={
                                    [
                                        {name: 'date', sk_name: 'Dátum', type: 'date-time'},
                                        {name: 'description', sk_name: 'Popis', type: 'string'},
                                        {name: 'owner_name', sk_name: 'Vlastník', type: 'string'},
                                    ]
                                }
                                columnSelector
                                defaultSortKey={'date'}
                                searchable
                            />
                        </NeedWritePerm>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

View.propTypes = {
    data: PT.object.isRequired,
    onEdit: PT.func,
};

View.defaultProps = {
    onEdit: () => {
    },
};

export default View;
