import React from 'react';
import store from 'store/mainStore';
import { withRouter } from 'react-router-dom';
import {Button, Container} from 'react-bootstrap';
import employeesActions from 'actions/employees';
import '../style.css';
import Spinner from 'components/Spinner';
import NeedWritePerm from 'components/NeedPermission';
import Form from './Form';
import ChooseMaster from './ChooseMasterForm';
import call from 'ajax/call';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {setGlobalError} from 'actions/globalError';
import { setUnsavedChanges, deleteUnsavedChanges } from 'actions/unsaved_changes';
import DF from 'scripts/dateFormatter';
import AssignForm from './AssignForm';
import Row from './Row';
import EmpDetailForm from './EmpDetailForm';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const remove = (list, index) => {
    let result = Array.from(list);
    result.splice(index, 1);
    return result;
};

const append = (list, index, newItem) => {
    let result = Array.from(list);
    result.splice(index, 0, newItem);
    return result;
};

class GroupDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addEmpFormActive: false,
            chooseMasterFormActive: false,
            id: parseInt(props.match.params.id, 10),
            groups: {},
            group_keys: [],
            current_entities: {},
            assignments: {},
            employee_map: new Map(),
            isFetching: true,
            reassignEmpId: null,
            reassignGroupId: null,
            changes: false,
            originalState: {},
            showAssignForm: false,
            work_intervals: {},
            work_history: {},
            default_positions: {},
            group_id: null,
            employee_detail_id: null,
            showEmpDetail: false,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this._mounted = false;
    }

    saveGroupData(data) {
        if (data.data) {
            if (this._mounted) {
                this.setState({
                    groups: data.data.groups,
                    current_entities: data.data.current_entities,
                    group_keys: Object.keys(data.data.groups),
                    isFetching: false,
                    changes: false,
                    assignments: {},
                    originalState: {
                        groups: data.data.groups,
                        default_positions: data.data.default_positions,
                    },
                    work_history: data.data.work_history,
                    work_intervals: data.data.work_intervals,
                    default_positions: data.data.default_positions,
                });
                store.dispatch(deleteUnsavedChanges());
            }
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;

        store.dispatch(employeesActions.fetchAll())
            .then(() => {
                let employees = store.getState().employees.items;
                let employeeMap = new Map();
                employees.forEach(e => {
                    employeeMap.set(String(e.id), e);
                });
                this.setState({
                    employee_map: employeeMap
                });
                call(`/api/employees/${this.state.id}/my_groups`)
                    .then(response => response.json())
                    .then(data => this.saveGroupData(data));
            });
    }

    onFormSubmit(data){
        data['master_id'] = this.state.id;
        let others = this.state.groups.others;
        data.employees.forEach(id => {
            others.push(String(id));
        });

        this.setState({
            addEmpFormActive: false,
            groups: {
                ...this.state.groups,
                'others': others
            }
        });

        call('/api/groups/assign_master', {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    //store.dispatch(employeesActions.fetchAll());
                } else {
                    store.dispatch(setGlobalError([data.message], '', true));
                }
            });
    }

    assignToAnotherMaster(id){
        this.setState({chooseMasterFormActive: false});
        if (id === null){
            return;
        }

        let {state: S } = this;

        if (S.reassignEmpId !== null) {
            let prevGroup = S.groups[S.reassignGroupId].filter(id => id !== String(S.reassignEmpId));
            let groups = {
                ...S.groups,
                [S.reassignGroupId]: prevGroup
            };

            let group_keys = [...S.group_keys];
            if (prevGroup.length === 0 && S.reassignGroupId !== 'others') {
                delete groups[S.reassignGroupId];
                group_keys = S.group_keys.filter(k => k !== S.reassignGroupId);
            }
            let assignments = S.assignments;
            if (!assignments[id]) {
                assignments[id] = [String(S.reassignEmpId)];
            } else {
                assignments[id].push(String(S.reassignEmpId));
            }

            this.setState({
                groups,
                group_keys,
                assignments,
                changes: true
            });
        } else {
            let prevGroup = S.groups[S.reassignGroupId];
            let groups = {...S.groups};
            let group_keys = S.group_keys;

            if (S.reassignGroupId !== 'others') {
                delete groups[S.reassignGroupId];
                group_keys = S.group_keys.filter(k => k !== S.reassignGroupId);
            }

            let assignments = S.assignments;

            if (!assignments[id]) {
                assignments[id] = prevGroup.map( k => String(k));
            } else {
                prevGroup.forEach(k => assignments[id].push(String(k)));
            }

            this.setState({
                groups,
                group_keys,
                assignments,
                changes: true
            });
        }
        store.dispatch(setUnsavedChanges({}));
    }

    saveChanges() {
        if (!this.state.changes) {
            return;
        }

        let dataObj = {
            'master_id': this.state.id,
            'groups': {},
            'groups_positions': [],
            'groups_work_positions': {},
            'assignments': this.state.assignments
        };
        this.state.group_keys.forEach( k => {
            let key = k;
            if (k !== 'others') {
               key = k.slice(6);
            }
            dataObj['groups'][key] = this.state.groups[k];
            dataObj['groups_positions'].push(key);
            if (this.state.default_positions[k]) {
                dataObj['groups_work_positions'][key] = `${this.state.default_positions[k].entity_id}`;
            }
        });

        call('/api/groups/save_changes', {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(dataObj)})
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(setGlobalError([data.message], 'update', false));
                    this.setState({
                        changes: false,
                        assignments: {},
                        originalState: {
                            groups: this.state.groups,
                            default_positions: this.state.default_positions,
                        }
                    });
                    store.dispatch(deleteUnsavedChanges());
                }  else if (data.message) {
                    store.dispatch(setGlobalError([data.message], 'update', true));
                    this.saveGroupData(data);
                }
            });
    }

    getUpdatedPositions(source_group_id, destination_group_id){
        let positions = {};

        let groups = this.state.groups;

        if (Object.prototype.hasOwnProperty.call(groups, source_group_id)) {
            this.state.groups[source_group_id].forEach((id, index) => {
                positions[id] = index;
            });
        }

        if (source_group_id === destination_group_id){
            return positions;
        }

        if (destination_group_id) {
            this.state.groups[destination_group_id].forEach((id, index) => {
                positions[id] = index;
            });
        }

        return positions;
    }

    updateState(newState, changes) {
        this.setState({
            ...newState,
            changes
        });

        if (changes) {
            store.dispatch(setUnsavedChanges({}));
        } else {
            store.dispatch(deleteUnsavedChanges());
        }
    }

    groupsChanged(groups){
        return JSON.stringify(this.state.originalState) !== JSON.stringify({
            groups,
            default_positions: this.state.default_positions
        }) || Object.keys(this.state.assignments).length > 0;
    }

    onDragEnd(result){
        const {source, destination} = result;

        if (!destination){
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.index === destination.index){return;}

            const list = reorder(this.state.groups[source.droppableId], source.index, destination.index);
            let groups = {
                ...this.state.groups,
                [source.droppableId]: list
            };
            this.updateState({groups}, this.groupsChanged(groups));
            return;
        }

        let src_group_list = this.state.groups[source.droppableId];
        let draggableId = src_group_list[source.index];
        src_group_list = remove(src_group_list, source.index);

        let groups = {
            ...this.state.groups,
            [source.droppableId]: src_group_list
        };
        let group_keys = [...this.state.group_keys];
        if (src_group_list.length === 0 && source.droppableId !== 'others'){
            delete groups[source.droppableId];
            group_keys = group_keys.filter( k => k !== source.droppableId);
        }

        // if we are moving away the group leader, we must assign a new one
        let src_group_id = source.droppableId;
        if (draggableId === src_group_id.slice(6) && src_group_list.length > 0) {
            let new_leader = src_group_list[0];

            let list = Array.from(groups[src_group_id]);
            let new_group_key = 'group-' + new_leader;
            group_keys = group_keys.map(k => {
                if (k !== src_group_id) {
                    return k;
                } else {
                    return new_group_key;
                }
            });

            delete groups[src_group_id];
            groups = {
                ...groups,
                [new_group_key]: list,
            };
            src_group_id = new_group_key;
        }

        let dest_group_id = destination.droppableId;


        if (dest_group_id.slice(0, 13) === 'add_new_group') {
            let key = dest_group_id.slice(13);
            dest_group_id = 'group-' + draggableId;
            groups = {
                ...groups,
                [dest_group_id]: [draggableId]
            };

            if (key) {
                let position = group_keys.indexOf(key);
                group_keys = append(group_keys, position, dest_group_id);
            } else {
                group_keys.push(dest_group_id);
            }
        } else {
            let dst_group_list = this.state.groups[dest_group_id];

            groups = {
                ...groups,
                [dest_group_id]: append(dst_group_list, destination.index, draggableId)
            };
        }

        this.updateState({groups, group_keys}, this.groupsChanged(groups));
    }

    onGroupAssignPosition(model){
        let default_positions = {
            ...this.state.default_positions,
            [this.state.group_id]: model,
        };

        let changes = JSON.stringify(this.state.originalState) !== JSON.stringify({
            groups: this.state.groups,
            default_positions
        }) || Object.keys(this.state.assignments).length > 0;

        this.setState({
            showAssignForm: false,
            group_id: null,
            default_positions,
            changes: changes,
        });

        if (changes) {
            store.dispatch(setUnsavedChanges({}));
        } else {
            store.dispatch(deleteUnsavedChanges());
        }
    }

    isWorking(group_id) {
        let entity = this.state.work_intervals[group_id];
        return entity !== null && entity !== undefined;
    }

    render() {
        const { state: S } = this;
        if (S.isFetching) {
            return <Spinner/>;
        }
        const master = S.employee_map.get(String(S.id));

        let saveButton = null;

        if (S.changes) {
            saveButton = <div>
                <Button
                    variant="danger"
                    onClick={() => this.saveChanges()}
                    style={{
                        marginBottom: 10,
                        marginLeft: 10
                    }}
                >
                    Uložiť zmeny
                </Button>
            </div>;
        }
        let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        let empDetailTitle = 'História pracovných záznamov';
        if (S.employee_detail_id) {
            let name = S.employee_map.get(String(S.employee_detail_id)).full_name;

            empDetailTitle = name + ' - ' + empDetailTitle;
        }

        return (
            <div
                className="groups"
                style={{width: '100%'}}
            >
                <Container xl={12} md={12} fluid={true}
                >
                    <h4
                        style={{
                            textAlign: 'center',
                            fontSize: '24pt',
                            fontWeight: 'bold',
                        }}
                    >
                        Majster: {master ? master.full_name : ''}
                    </h4>
                    <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                    }}>
                        <div className="group-item trans"
                             style={{boxShadow: 'none'}}
                        >
                            <span className="group-icon">
                                <img src={master.thumbnail}
                                     height={100}
                                     alt="obrázok"
                                />
                            </span>
                        </div>
                    </div>
                    <NeedWritePerm type="button" need={['groups']} >
                        <Button
                            variant="success"
                            onClick={() => {
                                this.setState({
                                    addEmpFormActive: true
                                });
                            }}
                            style={{
                                marginBottom: 10,
                                marginLeft: 10
                            }}
                        >
                            Pridať zamestnancov
                        </Button>
                    </NeedWritePerm>
                    {saveButton}
                    <DragDropContext
                        onDragEnd={this.onDragEnd}
                        //onBeforeCapture={this.onBeforeCapture}
                    >
                        <Row
                            group_id={'others'}
                            is_working={false}
                            heading={'Nezaradení'}
                            scrollbarWidth={scrollbarWidth}
                            work_interval={null}
                            employee_map={S.employee_map}
                            group={S.groups['others']}
                            assignable={false}
                            withDropZone={false}
                            onAssignEmpToMaster={(id) => this.setState({
                                chooseMasterFormActive: true,
                                reassignEmpId: id,
                                reassignGroupId: 'others',
                            })}
                            onShowEmpHistory={(id) => this.setState({
                                employee_detail_id: id,
                                showEmpDetail: true,
                            })}
                        />
                        <div style={{
                            margin: '0 15px 0 15px',
                        }}>
                            <h5>Skupiny</h5>
                        </div>
                        {S.group_keys.filter(g_id => g_id !== 'others').map( group_id => {
                            let is_working = this.isWorking(group_id);
                            let heading = null;
                            if (is_working){
                                heading = S.work_intervals[group_id].position
                                    + ' - ' + S.work_intervals[group_id].operation_name
                                    + ' od ' + DF(S.work_intervals[group_id].started_at);
                            } else {
                                if (S.default_positions[group_id]) {
                                    heading = S.default_positions[group_id].name;
                                }
                            }
                            return (
                                <Row
                                    key={group_id}
                                    group_id={group_id}
                                    is_working={is_working}
                                    heading={heading}
                                    scrollbarWidth={scrollbarWidth}
                                    work_interval={S.work_intervals[group_id]}
                                    employee_map={S.employee_map}
                                    group={S.groups[group_id]}
                                    assignable={true}
                                    withDropZone={true}
                                    onAssignGroupToPosition={() => this.setState({
                                        showAssignForm: true,
                                        group_id: group_id,
                                    })}
                                    onAssignGroupToMaster={() => this.setState({
                                        chooseMasterFormActive: true,
                                        reassignEmpId: null,
                                        reassignGroupId: group_id,
                                    })}
                                    onAssignEmpToMaster={(id) => this.setState({
                                        chooseMasterFormActive: true,
                                        reassignEmpId: id,
                                        reassignGroupId: group_id,
                                    })}
                                    onShowEmpHistory={(id) => {
                                        this.setState({employee_detail_id: id, showEmpDetail: true});
                                    }}
                                />
                            );
                        })}
                        <Droppable
                            droppableId={'add_new_group'}
                            direction='horizontal'
                        >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={'add_new'}
                                >
                                    <div className={'drop_text'}>
                                        Vytvoriť novú skupinu
                                    </div>
                                    {
                                        provided.placeholder
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {saveButton}
                    <Form
                        onSubmit={ (data) => this.onFormSubmit(data) }
                        show={S.addEmpFormActive}
                        employees={Array.from(S.employee_map.values()).filter( e => (e.active && e.boss_id === null))}
                        onClose={() => this.setState({addEmpFormActive: false})}
                    />
                    <ChooseMaster
                        onSubmit={(id) => this.assignToAnotherMaster(id)}
                        onClose={() => this.setState({chooseMasterFormActive: false})}
                        show={S.chooseMasterFormActive}
                        employees={Array.from(S.employee_map.values()).filter( e => (
                            e.active && e.role_id === 4 && e.id !== S.id
                        ))}
                        title={
                            S.reassignEmpId ?
                                'Presunúť osobu ' + S.employee_map.get(String(S.reassignEmpId)).full_name + ' k inému majstrovi'
                                : 'Presunúť skupinu k inému majstrovi'
                        }
                    />
                    <AssignForm
                        show={S.showAssignForm}
                        onClose={() => this.setState({showAssignForm: false})}
                        onSubmit={model => this.onGroupAssignPosition(model)}
                        params={S.group_id ? (S.default_positions[S.group_id] || null): null}
                    />
                    <EmpDetailForm
                        show={S.showEmpDetail}
                        onClose={() => {
                            this.setState({showEmpDetail: false, employee_detail_id: null});
                        }}
                        title={empDetailTitle}
                        records={S.work_history[S.employee_detail_id] || []}
                    />
                </Container>
            </div>
        );
    }
}

export default withRouter(GroupDetail);
