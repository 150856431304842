import React, {Component} from 'react';
import PT from 'prop-types';
import {SuperTable} from 'components/PaginationTable';
import {withRouter} from 'react-router-dom';
import business_trips_cars from 'actions/business_trips_cars';
import {connect} from 'react-redux';
import store from 'store';
import View from 'components/MonthChooser';
import {updateQueryParams} from 'scripts/queryFunctions';

class BusinessTripsCars extends Component {

    constructor(props) {
        super(props);

        const url = new URLSearchParams(props.location.search);
        const year = url.get('year') || 0;
        const month = url.get('month') || 0;
        const dateNow = new Date();

        this.state = {
            month: month || dateNow.getMonth() + 1,
            year: year || (dateNow.getFullYear()),
        };
    }

    componentDidMount() {
        const {state: S} = this;
        store.dispatch(business_trips_cars.fetchAll({
            month: S.month,
            year: S.year
        }));
    }

    onDateChange(obj) {
        store.dispatch(business_trips_cars.fetchAll({
            month: obj.month,
            year: obj.year
        }));

        this.setState({
            year: obj.year,
            month: obj.month
        }, () => {
            const {location, history} = this.props;

            updateQueryParams(location, history, {
                year: obj.year,
                month: obj.month,
            });
        });
    }

    render() {
        const {props: P, state: S} = this;
        return (
            <div className={'container'}>
                <h3 className={'text-center'}>Služobné cesty - autá</h3>
                <View
                    year={String(S.year)}
                    month={String(S.month)}
                    onDateChange={obj => this.onDateChange(obj)}
                />
                <SuperTable
                    name={'business_trips_cars'}
                    thead={[
                        {name: 'full_name', sk_name: 'Meno', type: 'string'},
                        {name: 'date', 'sk_name': 'Dátum', type: 'date'},
                        {name: 'start', sk_name: 'Od', type: 'time'},
                        {name: 'end', sk_name: 'Do', type: 'time'},
                        {name: 'spz', sk_name: 'ŠPZ', type: 'string'},
                    ]}
                    items={P.businessTripsCars}
                    searchable
                    is_loading={P.isLoading}
                />
            </div>
        );
    }
}

BusinessTripsCars.propTypes = {
    isLoading: PT.bool.isRequired,
    businessTripsCars: PT.arrayOf(PT.object).isRequired,
};

const mapStateToProps = (state) => ({
    businessTripsCars: state.business_trips_cars.items,
    isLoading: state.business_trips_cars.isFetching,
});

export default withRouter(connect(mapStateToProps)(BusinessTripsCars));
