export default function(items) {

	let agg = {};

	items.forEach(item => {

		const { building_id } = item;

		if (agg[building_id] === undefined) {
			agg[building_id] = {
				info: {
					building_id: item.building_id,
					building_name: item.building_name,
					building_address: item.building_address,
				},
				children: []
			};
		}

		agg[building_id].children.push({
			...item,
			id: `${item.employee_id} - ${item.start}`,
			full_name: item.employee_last_name + ' ' + item.employee_first_name,
		});
	});

	return agg;
}