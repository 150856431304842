import React from 'react';
import PropTypes from 'prop-types';

class Or extends React.Component {

    render() {
        const Children = React.Children.toArray(this.props.children);
        return this.props.expression ? (Children[0] || null) : (Children[1] || null);
    }
}

Or.propTypes = {
    expression: PropTypes.any.isRequired
};

export default Or;