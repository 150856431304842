import React from 'react';

class ProgressBar extends React.Component {

    render() {
        let bar = null;
        const {props: P} = this;
        let progress = 100.0 * P.used_budget/P.reserved_budget;

        if (P.used_budget <= P.reserved_budget) {
            progress = 100.0 * P.used_budget/P.reserved_budget;

            bar = <div className="w3-light-grey w3-round-xlarge">
                <div
                    className="w3-container green w3-round-xlarge"
                    style={{width: progress + '%'}}
                />
            </div>;
        } else {
            let graphProgress = 100.0 * P.reserved_budget/P.used_budget;
            bar = <div className="w3-red w3-round-xlarge">
                <div
                    className="green w3-round-xlarge"
                    style={{width: graphProgress + '%'}}
                />
                <div
                    className="w3-red"
                    style={{width: 100 - graphProgress + '%',}}
                />
            </div>;
        }

        return bar;
    }
}

export default ProgressBar;
