import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
import { MdTabletMac } from 'react-icons/md';
import NeedWritePerm from 'components/NeedPermission';
import DV from 'scripts/dateFormatter';

class TabletView extends React.PureComponent {
    render() {
        const { tablet } = this.props;
        return (
            <div className="tablet-info">
                <Row>
                    <Col md={12}>
                        <h2><MdTabletMac size={32} color="black" /> Špecifikácia tabletu</h2>
                        <NeedWritePerm type="button" need={['tablets']}>
                            <Button
                                variant="success"
                                onClick={() => this.props.onButtonClick()}
                            >
                                Upraviť
                            </Button>
                        </NeedWritePerm>
                    </Col>
                    
                    <Col md={12}><hr /></Col>
                    <Col md={4}><h4>Názov:</h4></Col>
                    <Col md={8}><h3>{tablet.name}</h3></Col>

                    <Col md={4}><h4>Prihlasovacie meno</h4></Col>
                    <Col md={8}><h3>{tablet.login_name || '-'}</h3></Col>

                    <Col md={4}><h4>Imei</h4></Col>
                    <Col md={8}><h3>{tablet.imei || '-'}</h3></Col>

                    <Col md={4}><h4>Sériové číslo</h4></Col>
                    <Col md={8}><h3>{tablet.serial_num || '-'}</h3></Col>

                    <Col md={4}><h4>Posledná synchronizácia</h4></Col>
                    <Col md={8}><h3>{DV(tablet.last_synchronization)}</h3></Col>
                </Row>
            </div>
        );
    }
}

TabletView.propTypes = {
    tablet: PropTypes.object.isRequired,
    onButtonClick: PropTypes.func
};

TabletView.defaultProps = {
    onButtonClick: () => {}
};

export default TabletView;