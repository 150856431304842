import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { MdCheck } from 'react-icons/md';

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true
        };   
    }
    
    acceptHandler() {
        this.props.onAccept && this.props.onAccept();
        this.setState({active: false});    
    }

    declineHandler() {
        this.props.onDecline && this.props.onDecline();
        this.setState({active: false});
    }

    render() {
        const { message } = this.props;
         
         return (
            <Modal show={this.state.active}>
                <Modal.Header>
                    <MdCheck size={40} color="green" />
                    <div style={{
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        {this.props.title || 'Operácia prebiehla úspešne...'}
                    </div>
                </Modal.Header>
                
                { this.props.message && 
                <Modal.Body >
                    <div>
                        <p>{ message }</p>
                    </div>
                </Modal.Body>

                }
                <Modal.Footer>
                    <Button variant="success" onClick={this.acceptHandler.bind(this)}>
                        {this.props.acceptTitle || 'Ok'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    acceptTitle: PropTypes.string,
    onClose: PropTypes.func
};

export default ConfirmModal;