import React from 'react';
import { connect } from 'react-redux';
import { Table} from 'react-bootstrap';
import store from 'store/mainStore';
import Spinner from 'components/Spinner';
import entity_operation_actions from 'actions/entity_operations';

import {
    BsPlayFill,
    BsThreeDots,
} from 'react-icons/bs';
import { FaRegCheckSquare } from 'react-icons/fa';
import './style.css';
import ReactTooltip from 'react-tooltip';
import PT from 'prop-types';
import PieChart from 'components/PieChart/PieChart';

class SectionDetail extends React.Component {

    fetchSectionData(entity_id) {
        if (entity_id) {
            store.dispatch(entity_operation_actions.fetchAll({id: entity_id}));
        }
    }

    componentDidMount() {
        this.fetchSectionData(this.props.entity_id);
    }

    onStart(entity_operation_id) {
        let entity_operation = this.props.entity_operations.find( op => op.id === entity_operation_id);
        if (!entity_operation) {
            return;
        }
        this.props.onStart(entity_operation);
    }

    render() {
        const { props:P } = this;

        if (P.is_loading) {
            return <Spinner/>;
        }

        return (
            <div className="info">
                <div>
                    <h5 style={{marginTop: 20}}>Operácie:</h5>
                    <Table
                        style={{
                            marginTop: 20, 
                            marginBottom: 20,
                            borderStyle: 'solid',
                            borderRadius: '5px'
                        }}
                        responsive={true}
                    >
                        <tbody>
                        {
                            P.entity_operations.filter(e => (!P.show_driving_operation && e.entity_id === P.entity_id))
                                .sort( (a, b) => a.number - b.number)
                                .map( item => {
                                    let showPlay = (
                                        !P.block_start && (item.status === 'new' || item.status === 'paused')
                                    );
                                    if (P.isWorking && item.id === P.entityOperationId) {
                                        showPlay = true;
                                    }
                                    let inProgress = item.status === 'working';

                                    return <tr className={'simple-row'}
                                               key = {item.id}
                                    >
                                        <td><b>{item.operation_name}</b></td>
                                        <td> {item.status_percent} % </td>
                                        <td><PieChart value={item.status_percent}/></td>
                                        <td>
                                            <BsThreeDots
                                                hidden={!inProgress}
                                                size={'2em'}
                                                //color={'orange'}
                                                data-tip
                                                data-for={`${item.id}_tool_tip`}
                                            />
                                            <ReactTooltip
                                                id={`${item.id}_tool_tip`}
                                                type='dark'
                                                place='top'
                                                effect='solid'
                                                delayUpdate={500}
                                            >
                                                Aktuálne na operácii pracujú: {item.current_work ? item.current_work.group_names : ''}
                                            </ReactTooltip>
                                            <BsPlayFill
                                                color='green'
                                                size={'2em'}
                                                hidden={!showPlay}
                                                className={'action_button'}
                                                data-tip
                                                data-for={`${item.id}_start`}
                                                onClick={() => this.onStart(item.id)}
                                            />
                                            <ReactTooltip
                                                id={`${item.id}_start`}
                                                type='dark'
                                                place='top'
                                                effect='solid'
                                                delayUpdate={500}
                                            >
                                                Začať operáciu
                                            </ReactTooltip>
                                            <FaRegCheckSquare
                                                hidden={item.status !== 'finished'}
                                                color='green'
                                                size={'2em'}
                                                data-tip
                                                data-for={`${item.id}_finished`}
                                            />
                                            <ReactTooltip
                                                id={`${item.id}_finished`}
                                                type='dark'
                                                place='top'
                                                effect='solid'
                                                delayUpdate={500}
                                            >
                                                Operácia je ukončená
                                            </ReactTooltip>
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </Table>
                    <Table
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            borderStyle: 'solid',
                            borderRadius: '5px'
                        }}
                        responsive={true}
                    >
                        <tbody>
                        {
                            P.operation_types.filter(op => {
                                if (P.show_driving_operation) {
                                    return op.overhead && op.is_driving;
                                } else {
                                    return op.overhead && !op.is_driving;
                                }
                            })
                                .map(item => {
                                    let showPlay = !P.block_start;
                                    if (P.isWorking && item.id === P.operation_type_id) {
                                        showPlay = true;
                                    }

                                    return <tr className={'simple-row'}
                                               key={item.id}
                                    >
                                        <td><b>{item.name}</b></td>
                                        <td>
                                            <BsPlayFill
                                                color='green'
                                                size={'2em'}
                                                hidden={!showPlay}
                                                className={'action_button'}
                                                data-tip
                                                data-for={`${item.id}_start`}
                                                onClick={() => this.props.onStartOverhead(item)}
                                            />
                                            <ReactTooltip
                                                id={`${item.id}_start`}
                                                type='dark'
                                                place='top'
                                                effect='solid'
                                                delayUpdate={500}
                                            >
                                                Začať operáciu
                                            </ReactTooltip>
                                        </td>
                                    </tr>;
                                })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

SectionDetail.propTypes = {
    entity_id: PT.number.isRequired,
    onStart: PT.func.isRequired,
    block_start: PT.bool.isRequired,
    isWorking: PT.bool.isRequired,
    entityOperationId: PT.number,
    show_driving_operation: PT.bool,
};

const mapStateToProps = state => {
    return {
        entity_operations: state.entity_operations.items,
        is_loading: state.entity_operations.isFetching
    };
};

export default connect(mapStateToProps)(SectionDetail);
