import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Col, Row} from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validationErrors: {},
            formSubmitted: false,
            selected_employee: null
        };
    }

    submitHandler() {
        this.setState({
            formSubmitted: true,
        });

        this.props.onSubmit({
            master_id: this.state.selected_employee
        });

        this.setState({
            validationErrors: {},
            formSubmitted: false,
        });
    }

    markSelected(id){
        this.setState({
            selected_employee: id
        });
    }

    render() {
        const { props:P } = this;

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
                size={'md'}
            >
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            fontSize: '14pt',
                        }}
                    >
                        {P.title}
                        <MdClose
                            className = {'closeButton'}
                            size={24}
                            onClick={P.onClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                        {
                            P.employees.map( e => (
                                <Row key={'row' + e.id}
                                     onClick={() => this.markSelected(e.id)}
                                     style={{
                                         backgroundColor: this.state.selected_employee === e.id ? '#1e6eff'
                                             : 'white'
                                     }}
                                >
                                    <Col sm={3}>
                                        <span className="group-icon">
                                            <img src={e.thumbnail}
                                                 height={70}
                                                 alt="obrázok"
                                                 style={{
                                                     borderRadius: '50%',
                                                     margin: 10
                                                 }}
                                            />
                                        </span>
                                    </Col>
                                    <Col sm={9}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContext: 'center',
                                            fontSize: '18pt',
                                        }}>
                                            {e.full_name}
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        }
                        <div style={{
                            marginTop: 20,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Button 
                                variant="success"
                                type="submit"
                                onClick={() => this.props.onSubmit(this.state.selected_employee)}
                            >
                                Potvrdiť
                            </Button>
                            <Button
                                variant="success"
                                type={'cancel'}
                                onClick={() => this.props.onClose()}
                            >
                                Zrušiť
                            </Button>
                        </div>
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    employees: PropTypes.array.isRequired,
    title: PropTypes.string,
};

Form.defaultProps = {
    title: 'Presúť k zamestnanca inému majstrovi'
};

export default Form;
