import getHolidays from 'scripts/getHolidays';
const SHOULD_HAVE_WORKED = 7.5;

export function round_value(value) {
    let val = Math.round(value * 100) / 100;
    return val.toString().replace('.', ',');
}

export function getHoursBalance(is_holiday, day) {
	// check for weekend and special holiday
	if (day.dayInWeek === 5 || day.dayInWeek === 6 || is_holiday) {
		return round_value(day.hours_done);
	}
	return round_value(day.hours_done - SHOULD_HAVE_WORKED);
}

export function daysInMonth(year, humanMonth) {
	return new Date(year, humanMonth + 1, 0).getDate();
}

export function getDaysInObj(daysCount, year, month) {
	const DAYS = ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'];
	let holidays = getHolidays(year, month);
	let objOfDays = new Map();

	for (let i = 1; i <= daysCount; i++) {
		let date = new Date(year, month, i);

		objOfDays.set(i, {
			dayInMonth: i,
			works: [],
			hadLunch: false,
			hours_done: -1,
			isEmpty: true,
			isWeekend: (date.getDay() === 0 || date.getDay() === 6),
			isHoliday: holidays.indexOf(i) > -1,
			label: `${i}. ${DAYS[date.getDay()]}`
		});
	}
	return objOfDays;
}

function getWorkDate(w) {
	return w.start ? new Date(w.start) : new Date(w.end);
}

export function mergeWorkingHours(daysObj, workingHours) {
	workingHours.forEach((wh) => {
		let startDate = wh.start ? wh.start : wh.end;
		startDate = new Date(startDate).getDate();

		if (daysObj.has(startDate)) {
			daysObj.get(startDate).works.push(wh);
		}
	});

	for (let key of daysObj.keys()) {
		let day = daysObj.get(key);
		let works = day.works.slice();

		day.works = works.sort((a, b) => {
			let aDate = getWorkDate(a);
			let bDate = getWorkDate(b);

			if (aDate < bDate) return -1;
			if (aDate > bDate) return 1;
			return 0;
		});
	}
	return daysObj;
}

/**
 *
 * @param year - integer
 * @param month - integer in range from 0 to 11 where 0 is January and 11 is December
 * @returns {[]}
 */
export function getFreeDays(year, month) {
	let date = new Date();

	date.setMonth(month);
	date.setFullYear(year);
	date.setDate(1);

	let days = [];
	let holidays = getHolidays(year, month);

	while(date.getMonth() === month) {
		if (date.getDay() !== 6
			&& date.getDay() !== 0
			&& !holidays.some( h => h === date.getDate())
		) {
			days.push(new Date(date));
		}
		date.setDate(date.getDate() + 1);
	}
	return days;
}

/**
 *
 * @param approvals
 * @param year
 * @param month
 * @param employee_id
 * @param type
 * @returns {*}
 */
export function getApproval(approvals, year, month, employee_id, type = 1) {

	return approvals.find((ap) => {
		return (
			ap.year === year &&
			ap.month === month &&
			ap.employee_id === employee_id &&
			ap.approval_type === type
		);
	});
}

export function checkParams(params) {
	if (!params.match(/^[0-9]*-[0-9]*-[0-9]*$/)) {
		throw new Error(`
				tail parameter does not match accepted format.\n
				Accepted format is dd-dd-dddd
			`);
	}
}