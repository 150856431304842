// TODO: redefine permissions in database and backend
// This array should be fetched dynamically from backend service
// however at the moment, the database contains just names of particular permissions

export const permissions = [
	{name: 'employees', sk_name: 'Zamestnanci'},
	{name: 'gdpr', sk_name: 'Opravnenie GDPR', isCheckbox: true},
	{name: 'groups', sk_name: 'Skupiny'},
	{name: 'cars', sk_name: 'Autá', isForPrivateView: true},
	{name: 'roles', sk_name: 'Roly'},
	{name: 'working_hours', sk_name: 'Dochádzka', isForPrivateView: true},
	{name: 'operations', sk_name: 'Pracovné operácie', isForPrivateView: true},
	{name: 'operation_types', sk_name: 'Typy operácií'},
	{name: 'logs', sk_name: 'História'},
	{name: 'buildings', sk_name: 'Budovy'},
	{name: 'planning', sk_name: 'Plánovanie'},
	{name: 'orders', sk_name: 'Zákazky'},
	{name: 'time_notifications', sk_name: 'Časové notifikácie z dochádzky'},
	{name: 'tools', sk_name: 'Náradie', isForPrivateView: true},
	{name: 'tablets', sk_name: 'Tablety', isForPrivateView: true},
    {name: 'certifications', sk_name: 'Školenia'},
	{name: 'is_logistics', sk_name: 'Logistika'},
	{name: 'material', sk_name: 'Prehľad materiálu'},
	{name: 'storage', sk_name: 'Sklady'},
	{name: 'material_prices', sk_name: 'Ceny materiálu'},
	{name: 'current_view', sk_name: 'Aktuálny prehľad', isCheckbox: true},
	{name: 'is_accountant', sk_name: 'Účtovnička', isCheckbox: true},
	{name: 'webapp_access', sk_name: 'Prístup do Aplikácie', isCheckbox: true},
];