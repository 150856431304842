const booleanValuesArrayToObject = (array, keyField, valueField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = (item[valueField] === true);
        return obj;
    }, {});

export {booleanValuesArrayToObject};

export function compareRows(a, b, sort_by_key, asc = true) {
    let first = a[sort_by_key];
    let second = b[sort_by_key];

    if (typeof first === 'string' || typeof second === 'string') {
        first = (first || '').toLowerCase();
        second = (second || '').toLowerCase();

        return asc ? first.localeCompare(second) : second.localeCompare(first);
    } else {
        if (a[sort_by_key] > b[sort_by_key]) {
            return asc ? 1 : -1;
        }
        if (a[sort_by_key] < b[sort_by_key]) {
            return asc ? -1 : 1;
        }
        return 0;
    }
}
