import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import store from 'store/mainStore';
import {setLogout} from 'actions/login';
import call from 'ajax/call';
import { getCookie } from 'scripts/cookie';

const allowedRoutes = [
    '/login',
    '/forgot_password',
    '/reset_password',
];

class LoginValidator extends React.Component {

    componentDidMount() {
        window.onload = this.updateCurrentRelease.bind(this);
        this.checkRefreshInterval = this.getCheckRefreshInterval();

        this.checkCookie();
    }

    checkCookie() {
        let cookie = getCookie('XSRF-TOKEN');

        let pathName = this.props.history.location.pathname;
        if ((!this.props.login.logged || !cookie) && allowedRoutes.every(r => r !== pathName)) {
            //session expired or the user is not logged in
            store.dispatch(setLogout());
            this.props.history.push('/login');
        }
    }

    componentDidUpdate() {
        this.checkCookie();
    }

    updateCurrentRelease() {
        this.getNewRelease()
            .then(data => {
                if (data && data.data && data.data.release) {
                    const newRelease = data.data.release;
                    localStorage.setItem('release', newRelease);
                }
            });
    }

    getCheckRefreshInterval() {
        return setInterval(() => {
            this.getNewRelease()
                .then(data => {
                    if (data && data.data && data.data.release) {
                        const newRelease = data.data.release;
                        const currentRelease = localStorage.getItem('release');
                        if (!currentRelease || newRelease > currentRelease) {
                            const message = 'Na načítanie novej verzie aplikácie je potrebné obnoviť stránku. ' +
                                'Chcete obnoviť stránku teraz?';
                            if (confirm(message)) {
                                location.reload();
                            }
                            localStorage.setItem('release', newRelease);
                        }
                    }
                });
        }, 30 * 60 * 1000);
    }

    getNewRelease() {
        return call('/api/release', {
            method: 'GET',
            headers: {'Content-type': 'application/json'},
        })
            .then(response => response.json());
    }

    componentWillUnmount() {
        clearInterval(this.checkRefreshInterval);
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        identity: state.identity,
    };
};

export default withRouter(connect(mapStateToProps)(LoginValidator));