import { SET_UNSAVED_CHANGES, DELETE_UNSAVED_CHANGES } from 'actions/unsaved_changes';

let defaultState = {
    status: false,
    message: '',
    data: null
};

function handleLeavePage(e) {
    e.preventDefault();
    const confirmationMessage = 'Máte neuložené zmeny. Po zavretí stránky sa zmeny vymažú. Chcete pokračovať ?';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
}

function unsavedChanges(state= defaultState, action) {
    switch (action.type) {
        case SET_UNSAVED_CHANGES:
            window.addEventListener('beforeunload', handleLeavePage);
            return {
            status: true,
            ...action.data
        };

        case DELETE_UNSAVED_CHANGES:
            window.removeEventListener('beforeunload', handleLeavePage);
            return defaultState;

        default: return state;
    }
}

export default unsavedChanges;
