import call from 'ajax/call';

export const FETCHING = 'TOOL_HISTORY_FETCHING';
export const FETCHED_SUCC = 'TOOL_HISTORY_FETCHED_SUCC';
export const FETCHED_ERR = 'TOOL_HISTORY_FETCHED_ERR';

export default {
    fetchAll: (data) => {

        return (dispatch) => {

            dispatch({ type: FETCHING });

            call(`/api/tools/${data.id}/history`)
            .then(response => response.json())
            .then(dataResponse => {
                if (dataResponse.status === 'ok')
                    dispatch({ type: FETCHED_SUCC, data:dataResponse.data });
                else
                    dispatch({ type: FETCHED_ERR, data:dataResponse.data });
            }).catch( e => {
                console.error(e);
            });
        };
    }
};