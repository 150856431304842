import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { SuperTable } from 'components/PaginationTable';
import Detail from './Detail';
import DateForm from './DateForm';
import './style.css';
import logsActions from 'actions/logs';
import store from 'store/mainStore';
import { addDependency } from 'actions/dependency';

class LogsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDetail: false,
            showId: null,
        };
    }

    handleFormSubmit(date) {
        store.dispatch(logsActions.fetchAll(date));
    }

    componentDidMount() {
        store.dispatch(logsActions.fetchAll(new Date));
    }

    render() {
        let log = null;
        if (this.state.showId){
            log = this.props.logs.find(log => log.id === this.state.showId);
        }

        let items = this.props.logs.map( item => ({
            ...item,
            emp_name: item.employee.full_name
        }));

        return (
            <Container className="logs">
                <DateForm onSubmit={date => this.handleFormSubmit(date)} />
                
                <SuperTable
                    name={'logs'}
                    thead={[
                        {name: 'table_name', sk_name: 'Tabuľka', type: 'string'},
                        {name: 'method', sk_name: 'Metóda', type: 'string'},
                        {name: 'url', sk_name: 'Url', type: 'string'},
                        {name: 'date_time', sk_name: 'Dátum', type: 'date'},
                        {name: 'emp_name', sk_name: 'Zamestnanec', type: 'string'}
                    ]}
                    columnSelector
                    items={items}
                    onRowClick={id => this.setState({
                        showId: id,
                        showDetail: true
                    })}
                    searchable
                    is_loading={this.props.isLoading}
                />
                <Detail
                    body={ log ? log.body : null }
                    show={ this.state.showDetail }
                    onClose={() => this.setState({
                        showDetail: false,
                        body: ''
                    })}
                />
            </Container>
        );
    }
}

addDependency('logs', ['webapp_access', 'employees']);

const mapStateToProps = state => {
    return {
        logs: state.logs.items,
        isLoading: state.logs.isFetching || state.employees.isFetching
    };
};

export default connect(mapStateToProps)(LogsContainer);
