export function updateQueryParams(location, history, newParams, overwrite = true, push = false) {
    const currentParams = new URLSearchParams(location.search);

    let queryObject = {};
    currentParams.forEach((value, key) => {
        queryObject[key] = value;
    });

    Object.keys(newParams).forEach( key => {
        queryObject[key] = overwrite ? newParams[key] : queryObject[key] || newParams[key];
    });

    const newQueryString = Object.keys(queryObject)
        .map( key => `${key}=${queryObject[key]}`)
        .join('&');

    let newQuery = `${location.pathname}?${newQueryString}`;

    if (push) {
        history.push(newQuery);
    } else {
        history.replace(newQuery);
    }
}