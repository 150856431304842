import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import Formsy from 'formsy-react';
import {FormSelect} from 'components/CustomSelect';
import Input from 'components/Input';
import { MdClose } from 'react-icons/md';
import './style.css';
import CustomFileInput from 'components/FormsyFileInput';
import {formatDate} from 'scripts/dateFormatter';

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validationErrors: {},
            formSubmitted: false,
            file: null
        };
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)){
            return;
        }

        this.props.onSubmit({
            ...model,
            document: this.state.file,
            type_id: parseInt(model.type.id),
        });

        this.setState({
            validationErrors: {},
            formSubmitted: false,
            file: null,
        });
    }

    addDays(in_date, days){
        var date = new Date(in_date.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.selectedTypeId === null && nextProps.types && nextProps.types.length > 0){
            return {
                ...state,
                selectedTypeId: nextProps.types[0].id
            };
        }
        return null;
    }

    validateForm(values) {
        let validations = {
            valid_from: 'Vyplňte začiatočný dátum',
            valid_to: 'Vyplňte koncový dátum',
            type: 'Zvoľte typ',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]) {
                errors[key] = validations[key];
            }
        });

        if (values.valid_from && values.valid_to){
            let from = new Date(values.valid_from);
            let to = new Date(values.valid_to);

            if (to <= from){
                errors['valid_to'] = 'Koncový dátum musí byť neskôr ako začiatočný';
            }
        }

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    render() {
        const { props:P, state:S } = this;

        let dateString = formatDate(P.defaultValues.valid_from || new Date());

        let validToDate = new Date();
        if (S.selectedTypeId){
            let type = P.types.find( t => t.id === S.selectedTypeId);
            validToDate = this.addDays(validToDate, type.duration);
        } else {
            validToDate.setFullYear(validToDate.getFullYear() + 1);
        }
        let dateToString = formatDate(P.defaultValues.valid_to || validToDate);

        let typeValue = null;
        if (P.types.length > 0) {
            if (P.defaultValues.type_id) {
                typeValue = P.types.find( o => o.id === P.defaultValues.type_id);
            }
            if (!typeValue) {
                typeValue = P.types[0];
            }
        }

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Modal.Header>
                    <div className={'closeBox'}
                    >
                        <MdClose
                            className = {'closeButton'}
                            size={24}
                            onClick={P.onClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={(model) => this.submitHandler(model)}
                        onInvalidSubmit={(model) => this.submitHandler(model)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                    >
                        <FormSelect
                            name="type"
                            label="Typ osvedčenia" 
                            options={P.types}
                            value={typeValue}
                        />
                        <Input
                            name="valid_from"
                            label="Platný od"
                            type="date" 
                            value={ dateString }
                            //required
                        />
                        <Input
                            name="valid_to"
                            label="Platný do"
                            type="date"
                            value={ dateToString }
                            //required
                        />
                        <CustomFileInput
                            name="document"
                            label="Dokument"
                            onChange={file => this.setState({file: file})}
                            document={P.defaultValues.document || null}
                        />
                        <Button
                            variant="success"
                            type="submit"
                        >
                        { P.updating ? 'Upraviť' : 'Vytvoriť'}
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    types: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    defaultValues: PropTypes.object,
    updating: PropTypes.bool.isRequired,
};

Form.defaultProps = {
    updating: false,
    defaultValues: {
        type_id: '',
        valid_from: '',
        valid_to: ''
    }
};


export default Form;
