import React from 'react';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import NeedWritePerm from 'components/NeedPermission';
import {FormSelect} from 'components/CustomSelect';
import PT from 'prop-types';

class OperationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validationErrors: {},
        };
    }

    onSubmit(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)){
            return;
        }

        this.props.onSubmit(model);
    }

    validateForm(values) {
        let validations = {
            operation: 'Zvoľte operáciu',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]){
                errors[key] = validations[key];
            }
        });

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    render() {
        const { props:P, state:S } = this;
        let operation = null;
        if (P.updating) {
            operation = P.work_operations.find(o => o.id === P.obj.operation_type_id);
        }

        return (
            <Modal
                show={P.show}
                className="input-form"
                onHide={P.onClose}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={ obj => this.onSubmit(obj)}
                        onInvalidSubmit={ obj => this.onSubmit(obj)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                    >
                        <FormSelect
                            name={'operation'}
                            label={'Operácia'}
                            options={P.work_operations}
                            placeholder={'Vyberte operáciu'}
                            isClearable
                            getOptionValue={ o => o.id}
                            getOptionLabel={ o => o.name}
                            required
                            value={operation}
                        />
                        <Textarea
                            name="description"
                            label="Popis"
                            value={P.obj.description || ''}
                        />
                        <Input
                            min={0}
                            type="number"
                            step="0.1"
                            name="estimated_time"
                            label="Odhadovaný čas (hod.)"
                            value={P.updating ? String(P.obj.estimated_time/3600) : '0'}
                        />
                        <Input
                            min={0}
                            type="number"
                            name="number"
                            label="Poradie"
                            value={P.obj.number ? String(P.obj.number) : '1'}
                        />
                        <Input
                            min={0}
                            type="number"
                            name="quantity"
                            label="Počet"
                            step={0.1}
                            value={P.obj.quantity ? String(P.obj.quantity) : '1.0'}
                        />
                        <Row>
                            <Col md={{span: 8, offset: 4}}>
                                <NeedWritePerm type="button" need={['planning']}>
                                    <Button
                                        disabled={S.buttonDisabled}
                                        type="submit"
                                        variant="success"
                                    >
                                        Uložiť
                                    </Button>
                                </NeedWritePerm>
                            </Col>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

OperationForm.propTypes = {
    work_operations: PT.arrayOf(PT.object).isRequired,
    obj: PT.object,
    updating: PT.bool.isRequired
};

OperationForm.defaultProps = {
    obj: {},
    updating: false,
};

export default OperationForm;
