import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import TableRow from 'components/PaginationTable/TableRow';

function getCellExportValue(value, columnType) {
    if (columnType === 'boolean') {
        return value ? 'Áno' : 'Nie';
    }
    return TableRow.getCellValue(value, columnType);
}

function isExportableColumn(column) {
    return !['image', 'file', 'pie_chart'].includes(column.type) && column.name !== '#';
}

function handleExport(event, columns, data, name) {
    event.preventDefault();

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    name = typeof name === 'string' && name.length ? name : 'export';
    const columnWidths = [];

    //deep copy array of items
    let exportItems = data.map(item => {
        return {...item};
    });

    exportItems = exportItems.map(item => {
        let obj = {};
        for (let column of columns) {
            if (!isExportableColumn(column)) {
                continue;
            }

            let columnKey = column.name;
            //map items by column names and with formatted cell value
            const columnName = column.sk_name;
            let cellValue = getCellExportValue(item[columnKey], column.type);

            obj[columnName] = cellValue;
            //calculate max columnWidths
            if (!columnWidths[columnKey]) {
                columnWidths[columnKey] = {wch: columnName.length};
            }

            const valueLength = cellValue ? cellValue.length : 0;
            if (columnWidths[columnKey] && valueLength > columnWidths[columnKey]['wch']) {
                columnWidths[columnKey] = {wch: valueLength};
            }
        }
        return obj;
    });

    //get columnNames and columnWidths in same order as thead_items
    const columnNames = columns
        .filter(item => isExportableColumn(item))
        .map((item, index) => {
            columnWidths[index] = columnWidths[item.name];
            delete columnWidths[item.name];
            return item.sk_name;
        });

    const ws = XLSX.utils.json_to_sheet(exportItems, {
        header: columnNames
    });
    ws['!cols'] = columnWidths;
    const wb = {Sheets: {[name]: ws}, SheetNames: [name]};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const blobData = new Blob([excelBuffer], {type: fileType});

    FileSaver.saveAs(blobData, `${name}.xlsx`);
}

export default handleExport;
