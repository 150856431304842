import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
import {FormSelect} from 'components/CustomSelect';
import FormCheckBox from 'components/CheckBox';
import CustomFileInput from 'components/FormsyFileInput';
import NewFileInput from 'components/NewFileInput';
import {formatDate, formatDateTime} from 'scripts/dateFormatter';
import store from 'store';

class ModalForm extends React.Component {
    constructor(props) {
        super(props);
        let new_files = {};

        if (props.updatingObj && props.formElements) {
            props.formElements.filter(e => e.type === 'file_input')
                .forEach(e => {
                    new_files[e.name] = props.updatingObj[e.name];
                });
        }

        this.state = {
            validationErrors: {},
            files: {},
            new_files: new_files,
        };
    }

    submitHandler(formData) {
        const {props: P} = this;
        const requestData = this.processFormData(formData);

        let options = {
            stringify: false,
            contentType: false,
        };

        let action;

        if (P.updatingObj) {
            requestData.append('_method', 'PUT');
            action = P.action.update(P.updatingObj.id, requestData, {
                ...options,
                method: 'POST'
            });
        } else {
            action = P.action.create(requestData, options);
        }

        store.dispatch(action)
            .then(data => {
                if (data && data.status === 'ok') {
                    P.afterSave(data);
                }
            }).catch(e => console.warn(e));
    }

    renderElement(element) {
        if (element.type === 'hidden') {
            return null;
        }
        const allowedElementTypesMethods = {
            'text': () => this.renderTextElement(element),
            'textarea': () => this.renderTextAreaElement(element),
            'number': () => this.renderNumberElement(element),
            'date': () => this.renderDateElement(element),
            'datetime': () => this.renderDateTimeElement(element),
            'select': () => this.renderSelectElement(element),
            'checkbox': () => this.renderCheckboxElement(element),
            'file': () => this.renderFileElement(element),
            'file_input': () => this.renderFileInput(element),
            'multiselect': () => this.renderMultiSelectElement(element),
        };
        if (element.type in allowedElementTypesMethods) {
            return allowedElementTypesMethods[element.type](element);
        } else {
            console.warn('Incorrect element type ' + element.type, element);
            return null;
        }
    }

    renderTextElement(element) {
        const {props: P} = this;
        return <Input
            name={element.name}
            label={element.label}
            required={element.required}
            value={this.formatElementValue(element, P.updatingObj, '')}
        />;
    }

    renderTextAreaElement(element) {
        const {props: P} = this;
        let value;
        value = P.updatingObj && P.updatingObj[element.name] === null
            ? ''
            : this.formatElementValue(element, P.updatingObj, '');

        return <Textarea
            name={element.name}
            label={element.label}
            required={element.required}
            value={value}
        />;
    }

    renderNumberElement(element) {
        const {props: P} = this;
        return <Input
            name={element.name}
            label={element.label}
            type={'number'}
            min={element.min}
            step={element.step || 1}
            required={element.required}
            value={this.formatElementValue(element, P.updatingObj, 0)}
        />;
    }

    renderDateElement(element) {
        const {props: P} = this;
        return <Input
            name={element.name}
            type={'date'}
            label={element.label}
            required={element.required}
            value={formatDate(this.formatElementValue(element, P.updatingObj, null))}
        />;
    }

    renderDateTimeElement(element) {
        const {props: P} = this;
        return <Input
            name={element.name}
            type={'datetime-local'}
            label={element.label}
            required={element.required}
            value={formatDateTime(this.formatElementValue(element, P.updatingObj, null))}
        />;
    }

    renderSelectElement(element) {
        const {props: P} = this;
        return <FormSelect
            name={element.name}
            label={element.label}
            options={element.options.items}
            getOptionValue={o => o.id}
            getOptionLabel={o => element.options.labelKey ? o[element.options.labelKey] : o.name}
            required={element.required}
            isClearable={!element.required}
            value={this.formatSelectValue(element, P.updatingObj, null)}
        />;
    }

    renderMultiSelectElement(element) {
        const {props: P} = this;
        return <FormSelect
            name={element.name}
            label={element.label}
            isMulti={true}
            options={element.options.items}
            getOptionValue={o => o.id}
            getOptionLabel={o => element.options.labelKey ? o[element.options.labelKey] : o.name}
            required={element.required}
            isClearable={!element.required}
            value={this.formatMultiSelectValue(element, P.updatingObj, null)}
        />;
    }

    renderCheckboxElement(element) {
        const {props: P} = this;
        return <FormCheckBox
            name={element.name}
            label={element.label}
            value={this.formatElementValue(element, P.updatingObj, false)}
        />;
    }

    renderFileElement(element) {
        const {props: P} = this;
        return <CustomFileInput
            name={element.name}
            label={element.label}
            onChange={file => {
                this.setState({
                    files: {
                        ...this.state.files,
                        [element.name]: file,
                    }
                });
            }}
            document={P.updatingObj ? P.updatingObj[element.name] : null}
        />;
    }

    addNewFileToState(element, file) {
        this.setState( (oldState) => {
            return {
                new_files: {
                    ...oldState.new_files,
                    [element.name]: file,
                }
            };
        });
    }

    onFileChange(element, file) {
        let oldFile = this.state.new_files[element.name];
        if (!oldFile) {
            this.addNewFileToState(element, file);
            return;
        }

        this.setState({
            new_files: {
                ...this.state.files,
                [element.name]: file,
            }
        });
    }

    renderFileInput(element) {
        let file = this.state.new_files[element.name];
        let server_name = null;

        if (file) {
            server_name = file.created_at ? file.file_name : null;
        }

        return <NewFileInput
            key={element.name}
            onChange={f => this.onFileChange(element, f)}
            file_name={element.label}
            server_name={server_name}
            file={file}
            editable={true}
            accept={element.accept ?? undefined}
        />;
    }

    formatElementValue(element, obj, defaultValue) {
        if (obj && obj[element.name] !== undefined) {
            return obj[element.name];
        } else if (element.defaultValue !== undefined) {
            return element.defaultValue;
        } else {
            return defaultValue;
        }
    }

    formatSelectValue(element, obj, defaultValue) {
        if (obj && obj[element.selectKey]) {
            return element.options.items.find(o => o.id === obj[element.selectKey]);
        } else if (element.selectFirstValue && element.options.items && element.options.items.length) {
            return element.options.items[0];
        } else {
            return defaultValue;
        }
    }

    formatMultiSelectValue(element, obj, defaultValue) {
        let values = [];

        if (obj && obj[element.selectKey]) {
            values = obj[element.selectKey].split(';');
        } else if (defaultValue) {
            return values = defaultValue.split(';');
        }

        if (!values) {
            return null;
        }
        return element.options.items.filter(o => values.some(v => v == o.id));
    }

    processFormData(formData) {
        const {props: P, state: S} = this;
        const requestData = new FormData();

        P.formElements.forEach(element => {
            let value = null;
            let key = element.name;

            switch (element.type) {
                case 'select' :
                    if (formData[element.name]) {
                        value = formData[element.name].id;
                    }
                    key = element.selectKey;
                    break;
                case 'multiselect':
                    key = element.selectKey;
                    if (formData[element.name] && Array.isArray(formData[element.name]) && formData[element.name].length > 0) {
                        value = formData[element.name].map(o => o.id).join(';');
                        value = ';' + value + ';';
                    }
                    break;
                case 'file':
                    value = S.files[element.name] ?? null;
                    break;
                case 'file_input':
                    value = S.new_files[element.name] ?? null;
                    break;
                case 'hidden':
                    value = element.value;
                    break;
                default:
                    value = formData[element.name];
            }

            requestData.append(key, value);
        });
        return requestData;
    }

    render() {
        const {state: S, props: P} = this;

        return (
            <Modal
                onHide={() => P.onClose()}
                show={P.show}>
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={this.props.onClose}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Formsy
                            validationErrors={S.validationErrors}
                            onValidSubmit={formData => this.submitHandler(formData)}
                        >
                            {P.formElements.map(element =>
                                <React.Fragment key={element.name}>
                                    {this.renderElement(element)}
                                </React.Fragment>
                            )}
                            <Row>
                                <Col md={{span: 8, offset: 4}}>
                                    <Button variant="success" type="submit">
                                        {this.props.updatingObj ? 'Upraviť' : 'Vytvoriť'}
                                    </Button>
                                </Col>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

ModalForm.propTypes = {
    show: PropTypes.bool.isRequired,
    formElements: PropTypes.arrayOf(PropTypes.object).isRequired,
    updatingObj: PropTypes.object,
    action: PropTypes.object.isRequired,
    afterSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ModalForm;