import React from 'react';
import { connect } from 'react-redux';
import call from 'ajax/call';
import { Container, Button } from 'react-bootstrap';
import employeeActions from 'actions/employees';
import workingHoursActions from 'actions/working_hours';
import workingHoursTypesActions from 'actions/working_hours_types';
import ordersActions from 'actions/orders';
import approvalActions from 'actions/approvals';
import carsActions from 'actions/cars';

import store from 'store/mainStore';
import MonthView from './MonthView';
import Vacation from './Vacation';
import './slider.css';
import NeedWritePerm from 'components/NeedPermission';
import getHolidays from 'scripts/getHolidays';
import { getmonthName } from 'scripts/dateFormatter';
import { getFreeDays, getApproval } from './monthViewUtils';
import MonthChooser from 'components/MonthChooser';

class Detail extends React.Component {
	constructor(props) {
		super(props);

		if (!props.match.params.id.match(/^[0-9]*-[0-9]*-[0-9]*$/)) {
			throw new Error(`
				last tail parameter does not match accepted format.\n
				Accepted format is dd-dd-dddd
			`);
		}

		const [id, month, year] = props.match.params.id.split('-');

		this.state = {
			id: parseInt(id, 10),
			month: parseInt(month, 10),
			year: parseInt(year, 10),
			is_fetching: false
		};
	}

	onDeleteApproval(id) {
		store.dispatch(approvalActions.delete(id));
	}

	handleSubmitAllHours(obj) {
		const S = this.state;
		store.dispatch(
				approvalActions.create({
					year: S.year,
					month: S.month,
					comment: obj.comment,
					employee_id: S.id
				})
			);
	}

	canMakeActions() {
		const { state: S, props: P } = this;

		if (P.permissions['working_hours'] === 5) {
			return false;
		}

		const approval = getApproval(P.approvals, S.year, S.month, S.id, 1);
		const is_approved = approval !== undefined;
		return (P.permissions['working_hours'] === 2) && !is_approved;
	}

	handleVacation(obj) {
		const { state: S } = this;

		call('/api/working_hours/set_vacation', {
			method: 'POST',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({
				start: obj.from,
				end: obj.to,
				type_id: obj.type.id,
				employee_id: S.id,
                order_id: obj.order ? obj.order.id : null,
				lunch_break: obj.lunch_break,
				car_id: obj.car ? obj.car.id : null,
			})})
			.then(response => response.json())
			.then(data => {
				if (data.status === 'ok') {
					store.dispatch(
						workingHoursActions.fetchAll({
							id: S.id,
							month: S.month,
							year: S.year
						})
					);
				}
			});

		this.setState({
			vacationShow: false
		});
	}

	componentDidMount() {
		const [id, month, year] = this.props.match.params.id.split('-');

		store.dispatch(
			workingHoursActions.fetchAll({
				id: id,
				month: month,
				year: year
			})
		);

		store.dispatch(carsActions.fetchAll());
		store.dispatch(employeeActions.fetchAll());
		store.dispatch(workingHoursTypesActions.fetchAll());
		store.dispatch(ordersActions.fetchAll());
		store.dispatch(
			approvalActions.fetchAll({
				year: year,
				month: month,
				type: 1
			})
		);
	}

	getEmployeeName(id){
		let employee = this.props.employees.find((e) => e.id === id);
		if (employee){
			return employee.full_name;
		}
		return '';
	}

	getUsedCars(){
		return Object.keys(
			this.props.workingHours.reduce((acc, val) => {
				if (val.car_id){
					let car = this.props.cars.find((c) => c.id === val.car_id);
					if (car) {
						acc[car.spz] = true;
					}
				}
				return acc;
			}, {})
		);
	}

	onDateChange(obj){
		const { state: S } = this;

		this.setState({
			is_fetching: true,
			year: parseInt(obj.year),
			month: parseInt(obj.month),
		});
		store.dispatch(workingHoursActions.fetchAll({
			year: obj.year,
			month: obj.month,
			id: S.id,
		})).then(() => this.setState({is_fetching: false}));

		store.dispatch(approvalActions.fetchAll({
			year: obj.year,
			month: obj.month,
			type: 1
		}));

		this.props.history.replace(`/attendance/${S.id}-${obj.month}-${obj.year}`);
	}

	render() {
		const { props: P, state: S } = this;
		let approval = getApproval(P.approvals, S.year, S.month, S.id, 1);
		let is_approved = approval !== undefined;

		return (
			<Container className="working-hour-detail">
				<div>
					<h3> Dochádzka </h3>
					<h3>
						{this.getEmployeeName(S.id)} - {getmonthName(S.month)} {S.year}
					</h3>
					<MonthChooser
						year={String(S.year)}
						month={String(S.month)}
						onDateChange={obj => this.onDateChange(obj)}
					/>
					<NeedWritePerm type="button" need={['working_hours']}>
						<Button
							variant="primary"
							onClick={() => this.setState({vacationShow: !S.vacationShow})}
						>
							Hromadné vkladanie
						</Button>
					</NeedWritePerm>
				</div>
				<Vacation
					key={`${S.year}-${S.month}`}
					types={P.workingHoursTypes}
					show={S.vacationShow}
					month={S.month}
					year={S.year}
					orders={P.orders}
					cars={P.cars}
					onSubmit={(obj) => this.handleVacation(obj)}
					onClose={() => this.setState({vacationShow: false})}
					min={new Date(S.year, S.month - 1, 1)}
					max={new Date(S.year, S.month, 0)}
					includeDates={getFreeDays(S.year, S.month - 1)}
				/>
				<MonthView
					approval={approval}
					isApproved={is_approved}
					isFullTimeEmployee={P.identity.type_id === 2}
					canMakeActions={this.canMakeActions.call(this)}
					workingHours={P.workingHours}
					workingHoursTypes={P.workingHoursTypes}
					orders={P.orders}
					onSubmitAllHours={this.handleSubmitAllHours.bind(this)}
					cars={P.cars}
					holidays={getHolidays(S.year, S.month - 1)}
					employee_id={S.id}
					show_approval={P.permissions.working_hours === 2}
					is_loading={P.is_loading || S.is_fetching}
					permissions={P.permissions}
					onDeleteApproval={id => this.onDeleteApproval(id)}
					{...S}
				/>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {

	let is_loading =
			state.orders.isFetching
		|| state.employees.isFetching
		|| state.approvals.isFetching
		|| state.permissions.isFetching
		|| state.identity.isFetching
		|| state.cars.isFetching
		|| state.workingHoursTypes.isFetching
	;

	return {
		orders: state.orders.items,
		employees: state.employees.items,
		approvals: state.approvals.items,
		permissions: state.permissions,
		identity: state.identity,
		cars: state.cars.items,
		workingHoursTypes: state.workingHoursTypes.items,
		workingHours: state.workingHours.items,
		is_loading: is_loading
	};
};

export default connect(mapStateToProps)(Detail);
