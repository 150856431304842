import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import {MdZoomIn, MdEdit, MdExpandLess, MdExpandMore, MdAttachFile, MdDelete} from 'react-icons/md';
import classNames from 'classnames';
import DF from 'scripts/dateFormatter';
import { parseHours, parseSeconds, format} from 'scripts/dateFormatter';
import {Button} from 'react-bootstrap';
import PieChart from '../PieChart/PieChart';

const ShowCell = (props) => {
    return (
        <td>
            <Button
                size={'sm'}
                variant={'outline-success'}
                onClick={() => props.onShowItem()}
            >
                <MdZoomIn style={{padding: 0}}/>
            </Button>
        </td>
    );
};

const EditCell = (props) => {
    return (
        <td>
            <Button
                size={'sm'}
                variant={'outline-success'}
                onClick={() => props.onEditItem()}
            >
                <MdEdit style={{padding: 0}}/>
            </Button>
        </td>
    );
};

const DeleteCell = (props) => {
    return (
        <td>
            <Button
                size={'sm'}
                variant={'outline-danger'}
                onClick={() => props.onDeleteItem()}
            >
                <MdDelete style={{padding: 0}}/>
            </Button>
        </td>
    );
};

const getIcon = (bool) => {
    return bool ? <FaRegCheckSquare size={'1.2em'}/> : <FaRegSquare size={'1.2em'}/>;
};

function AddPadding(props) {
    let padding = 30*props.level;
    const margin = padding + 'px';

    return (
            <div style={{marginLeft: margin}}>
                {props.children}
            </div>
    );
}

function getImage(src){
    const defaultImg = '/storage/no-image.jpg';
    let href = defaultImg;
    if (src) {
        href = src;
    }

    return (<div className="image">
        <img
            src={href}
            alt={'obrazok'}
            onError={ (e) => {
                e.target.onerror = null;
                e.target.src = defaultImg;
            }}
        />
    </div>);
}

export function getNumber(input) {
    if (input === undefined || input === null) {
        return '--';
    }
    return parseFloat(input).toLocaleString('sk', {minimumFractionDigits: 0, maximumFractionDigits: 2});
}

class TableRow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true
        };
    }

    static getCellValue(val, type = null) {
        switch (type) {
            case 'image':
                return getImage(val);
            case 'boolean':
                return getIcon(val);
            case 'short_date':
                return DF(val, false, {month: 'numeric'});
            case 'date':
                return DF(val, false);
            case 'date-time':
                return DF(val, true);
            case 'time':
                return format(val, {hour: 'numeric', minute: 'numeric'});
            case 'long_time':
                return format(val, {hour: 'numeric', minute: 'numeric', second: 'numeric'});
            case 'float':
                return parseFloat(val).toFixed(2).replace('.', ',');
            case 'number':
                return getNumber(val);
            case 'seconds':
                return parseSeconds(val);
            case 'hours':
                return parseHours(val);
            case 'file':
                return (val) ? (
                    <a
                        target="_blank"
                        href={val}
                        rel="noopener noreferrer">
                        <MdAttachFile/>
                    </a>
                ) : '-';
            case 'pie_chart':
                return <PieChart value={val}/>;
            default:
                return val;
        }
    }

    expandClicked(){
        this.setState((prevState) => {
            return {collapsed: !prevState.collapsed};
        });
    }

    getStyle(column, obj){
        if (!this.props.rowStyleFn){
            return {};
        }

        return this.props.rowStyleFn(column, obj);
    }

    render() {
        const {props: P} = this;
        const rowClassName = classNames({
            'simple-row': true,
            'row-clickable': P.onClick,
        });

        const obj = P.obj;
        const fn = P.onClick ? () => P.onClick(obj.id) : null;
        const firstItem = this.props.values[0];
        const restItems = this.props.values.slice(1);

        const has_children = P.obj.children && P.obj.children.length > 0;
        let expand_icon = ' ';
        let children = null;
        if (has_children) {
            if (this.state.collapsed) {
                expand_icon = <MdExpandMore
                    size={24}
                    onClick={this.expandClicked.bind(this)}/>;
            } else {
                expand_icon = <MdExpandLess
                    size={24}
                    onClick={this.expandClicked.bind(this)}/>;
            }

            if (P.searching || !this.state.collapsed) {

                children = P.obj.children.map(child => {
                    return <TableRow
                        key={child.id}
                        onClick={P.onClick}
                        onShow={P.onShow}
                        onEdit={P.onEdit}
                        onDelete={P.onDelete}
                        values={P.values}
                        obj={child}
                        editOps={P.editOps}
                        delOps={P.delOps}
                        class={P.class}
                        paddingLevel={P.paddingLevel + 1}
                        treeStructure={P.treeStructure}
                        highlighting={P.highlighting}
                        highlightColor={P.highlightColor}
                    />;
                });
            }
        }
        const expand = <td> {expand_icon} </td>;
        let background = '#FFF';
        let highlighted = obj.highlighted || false;

        if (P.searching || (P.highlighting && highlighted)) {
            background = P.highlightColor;
        }

        const row = (
            <tr key={ obj.id + background }
                className={rowClassName}
                style={{backgroundColor: background}}
            >
                {P.showRowNumbers ? <td>{P.rowNumber}</td> : null}
                {P.treeStructure ? expand: null}
                {
                    <td
                        className={'text'}
                        onClick={fn}
                        style={ this.getStyle(firstItem.name, obj) }
                    >
                        <AddPadding level={P.paddingLevel}>
                            {TableRow.getCellValue(obj[firstItem.name], firstItem.type)}
                        </AddPadding>
                    </td>
                }
                {
                    restItems.map(item => {
                        return (
                            <td
                                className={'text'}
                                onClick={item.type !== 'file' ? fn : null}
                                key={item.name}
                                style={ this.getStyle(item.name, obj) }
                            >
                                {TableRow.getCellValue(obj[item.name], item.type)}
                            </td>
                        );
                    })
                }
                {P.showOps && <ShowCell onShowItem={() => P.onShow(obj.id)} id={obj.id}/>}
                {P.editOps && <EditCell onEditItem={() => P.onEdit(obj.id)} id={obj.id}/>}
                {P.delOps && <DeleteCell onDeleteItem={() => P.onDelete(obj.id)} id={obj.id}/>}
            </tr>
        );

        return [row, children];
    }
}

TableRow.propTypes = {
    onClick: PropTypes.func,
    onShow: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    values: PropTypes.array.isRequired,
    obj: PropTypes.object.isRequired,
    showOps: PropTypes.bool,
    editOps: PropTypes.bool,
    delOps: PropTypes.bool,
    class: PropTypes.string,
    paddingLevel: PropTypes.number,
    treeStructure: PropTypes.bool,
    searching: PropTypes.bool,
    background: PropTypes.string,
    highlighting: PropTypes.bool,
    highlightColor: PropTypes.string,
    rowStyleFn: PropTypes.func,
    showRowNumbers: PropTypes.bool,
    rowNumber: PropTypes.number,
};

TableRow.defaultProps = {
    onClick: () => {},
    onDelete: () => {},
    onEdit: () => {},
    values: [],
    obj: {},
    showOps: false,
    editOps: false,
    delOps: false,
    paddingLevel: 0,
    class: '',
    treeStructure: false,
    background: 'fff',
    searching: false,
    highlighting: false,
    highlightColor: '#cdffc7',
    showRowNumbers: false
};

export default TableRow;