import React from 'react';
import PT from 'prop-types';
import store from 'store/mainStore';

const getComponent = {
    'unknown': (_, Class) => {
        return Class.props.showOtherwise;
    },
    'button': () => null,
    'table': children => {
        return (
            <span>
                { 
                    React.cloneElement(children, {
                        delOps: false,
                        editOps: false,
                        onRowEdit: () => {},
                        onRowDelete: () => {}
                    }) 
                }
            </span>
        );
    }
};

class NeedWritePermission extends React.PureComponent {
    render() {
        const permissions = store.getState().permissions;
        const { need } = this.props;
        const has_needed = need.every(n => permissions[n] === 2); // must have write permissions to all resources specified in need prop
        let C = null;
        
        if (!has_needed) {
            C = getComponent[this.props.type](this.props.children, this);
        }
        else
            C = this.props.children;
        return C;
    }
}

NeedWritePermission.propTypes = {
    type: PT.oneOf(['table', 'button', 'unknown']),
    need: PT.array.isRequired,
    showOtherwise: PT.any
};

NeedWritePermission.defaultProps = {
    type: 'unknown', // will hide everything... not render children
    showOtherwise:  <span style={{ display: 'none'}} />
};

export default NeedWritePermission;
