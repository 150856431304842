const defaultState = {
	show: false,
	messages: [],
	is_error: false,
	type: 'info'
};

export default function (state=defaultState, action) {
	switch(action.type) {
		case 'SET_GLOBAL_ERROR':
			
			if (!(action.payload instanceof Array)) {
				throw new Error('PAYLOAD MUST BE ARRAY!!!');
			}

			return {
				show: true,
				messages: action.payload,
				type: action.error_type,
				is_error: action.is_error,
			};
		case 'UNSET_GLOBAL_ERROR':
			return {
				...state,
				show: false,
				messages: [],
			};
		default: return state;
	}
}
