import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { SuperTable} from 'components/PaginationTable';
import CertificationForm from './CertificationForm';
import certificationTypesActions from 'actions/certification_types';
import certificationActions from 'actions/certifications';
import store from 'store/mainStore';
import NeedWritePermission from '../../../../components/NeedPermission';
import Trainings from './Trainings';

class Certifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objToUpdate: undefined,
            formActive: false,
        };
    }

    deleteCertificationHandler(id) {
        store.dispatch(certificationActions.delete(id));
    }

    editCertificationHandler(id) {
        const {props:P} = this;
        this.setState({
            objToUpdate: P.certifications.find(c => c.id === id),
            formActive: true
        });
    }

    submitHandler(obj) {
        obj.employee_id = this.props.employeeId;
        let formData = new FormData();

        for (let key of Object.keys(obj)){
            formData.append(key, obj[key]);
        }

        let action = {};
        if (this.state.objToUpdate) {
            formData.append('_method', 'PUT');
            action = certificationActions.update(this.state.objToUpdate.id, formData, {
                stringify: false,
                contentType: false,
                method: 'POST',
            });
        } else {
            action = certificationActions.create(formData, {
                stringify: false,
                contentType: false,
            });
        }

        store.dispatch(action).then((data) => {
            if (data.status === 'ok') {
                this.setState({
                    formActive: false,
                    objToUpdate: undefined
                });
            }
        });
    }

    componentDidMount() {
        store.dispatch(certificationActions.fetchAll({
            id: this.props.employeeId
        }));
        store.dispatch(certificationTypesActions.fetchAll());
    }

    render() {
        const { state:S, props:P } = this;
        const items = P.certifications.map(c => ({
            ...c,
            type: c.type.name
        }));

        return (
            <div style={{ paddingTop: 40 }}>
                <div>
                    <ButtonToolbar>
                        <NeedWritePermission
                            need={['certifications']}
                            type={'button'}
                        >
                            <Button
                                onClick={() => this.setState({ formActive: true})}
                                variant="success"
                            >
                                Pridať osvedčenie
                            </Button>
                        </NeedWritePermission>
                    </ButtonToolbar>
                </div>
                <NeedWritePermission
                    need={['certifications']}
                    type={'table'}
                >
                <SuperTable
                    name={'certifications'}
                    thead={[
                        {name: 'valid_from', sk_name: 'Platné od', type: 'date'},
                        {name: 'valid_to', sk_name: 'Platné do', type: 'date'},
                        {name: 'type', sk_name: 'Typ osvedčenia', type: 'string'},
                        {name: 'document', sk_name: 'Dokument', type: 'file'},
                    ]}
                    columnSelector
                    items={items}
                    onEditItem={this.editCertificationHandler.bind(this)} 
                    onDeleteItem={this.deleteCertificationHandler.bind(this)} 
                    editOps
                    delOps
                />
                </NeedWritePermission>

                <CertificationForm
                    key={S.objToUpdate ? S.objToUpdate.id : 'new_cert'}
                    onClose={() => this.setState({formActive: false, objToUpdate: undefined})}
                    types={P.certificationTypes}
                    show={S.formActive}
                    defaultValues={S.objToUpdate}
                    updating={!!S.objToUpdate} 
                    onSubmit={obj => this.submitHandler(obj)}
                />
                <Trainings employeeId={this.props.employeeId}/>
            </div>
        );
    }
}

Certifications.propTypes = {
   employeeId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        certificationTypes: state.certificationTypes.items,
        certifications: state.certifications.items
    };
};

export default connect(mapStateToProps)(Certifications);
