import React from 'react';
import PT from 'prop-types';
import {Button, Modal, Col, Row} from 'react-bootstrap';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import 'react-datepicker/dist/react-datepicker.css';
import { MdClose } from 'react-icons/md';
import './style.css';
import { formatDate } from 'scripts/dateFormatter';
import DatePicker from 'react-datepicker';
import {FormCreatableSelect, FormSelect} from 'components/CustomSelect';
import FormCheckBox from 'components/CheckBox';

class CustomInput extends React.Component {
    render () {
        return (
            <input
                type={'text'}
                className={'form-control'}
                onClick={this.props.onClick}
                value={this.props.value}
                onChange={() => {}}
            />
        );
    }
}

CustomInput.propTypes = {
    onClick: PT.func,
    value: PT.string
};

class Vacation extends React.Component {
    constructor(props) {
        super(props);

        this.mounted = false;
        this.formRef = React.createRef();
        let date = new Date(props.year, props.month - 1, 1);

        this.state = {
            width: window.innerWidth,
            buttonDisabled: false,
            from: date,
            to: date,
            validationErrors: {},
            lunch_break: false,
        };
    }

    validateForm(obj) {
        const {state: S} = this;

        let errors = {};
        if (S.type && S.type.required_order && !obj.order) {
            errors['order'] = 'Musíte zvoliť zákazku';
        }

        this.setState({
            validationErrors: errors,
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.mounted = true;
    }

    // when the component is not mounted anymore
    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange() {
        if (this.mounted) {
            this.setState({width: window.innerWidth});
        }
    }

    onChangeDate(val, is_start = true) {
        if (!val){return;}
        let date = new Date(val);

        this.setState((prev) => {
            let from = new Date(prev.from);
            let to = new Date(prev.to);
            if (is_start) {
                from = date;
                if (date > to ){
                    to = date;
                }
            } else {
                to = date;
                if (date < from) {
                    from = date;
                }
            }
            return {from, to};
        });
    }

    handleSubmit(model) {
        const { props:P, state: S } = this;
        let from = S.from, to = S.to;
        let options = { year: 'numeric', month: 'numeric', day: 'numeric'};

        P.onSubmit({
            from: from.toLocaleDateString('en-US', options),
            to: to.toLocaleDateString('en-US', options),
            date_from: from,
            date_to: to,
            order: model.order,
            type: model.type,
            car: model.car,
            lunch_break: Boolean(model.lunch_break),
        });
    }

    getWebDatePicker(is_start = true) {
        const {props: P, state: S} = this;

        let dateProps = {};
        if (is_start) {
            Object.assign( dateProps, {
                selected: S.from,
                selectsStart: true,
            });
        } else {
            Object.assign( dateProps, {
                selected: S.to,
                selectsEnd: true,
                minDate: S.from
            });
        }

        return <Row className={'mb-2'}>
            <label className={'col-form-label col-sm-4'}>
                {is_start ? 'Začiatok' : 'Koniec'}
                <span className={'required-symbol'}> *</span>
            </label>
            <div className={'col-sm-8'}>
            <DatePicker
                customInput={<CustomInput />}
                onChange={date => this.onChangeDate(date, is_start)}
                startDate={S.from}
                endDate={S.to}
                dateFormat="d MMMM yyyy"
                dropdownMode={'select'}
                locale='sk'
                calendarStartDay={1}
                showDisabledMonthNavigation
                includeDates={P.includeDates || []}
                {...dateProps}
            />
            </div>
        </Row>;
    }

    getNativeDatePicker(is_start = true) {
        const {props: P, state: S} = this;

        return <Input
            type={'date'}
            value={formatDate(is_start ? S.from : S.to)}
            onChange={val => this.onChangeDate(val, is_start)}
            label={is_start ? 'Začiatok' : 'Koniec'}
            name={is_start ? 'from' : 'to' }
            required
            max={formatDate(P.max)}
            min={formatDate(P.min)}
        />;
    }

    getOrdersOptions() {
        return this.props.orders
            .sort( (a, b) => {
                if (a.serial_number < b.serial_number) return -1;
                if (a.serial_number > b.serial_number) return 1;
                return 0;
            }).filter( o => o.active );
    }

    getCarsOptions() {
        return this.props.cars
            .sort( (a, b) => {
                if (a.spz < b.spz) return -1;
                if (a.spz > b.spz) return 1;
                return 0;
            }).filter( c => c.active );
    }

    isValidNewOption(inputValue) {
        let order = this.props.orders.find( o => {
            return o.serial_number === inputValue;
        });

        return order && !order.active;
    }

    getNewOptionData(inputValue, optionLabel){
        return this.props.orders.find( o => {
            return o.serial_number === optionLabel;
        });
    }

    render() {
        const { props:P, state:S } = this;
        let options = P.types;

        const { width } = this.state;
        const isMobile = width < 1024;

        const filterOptions = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start',
        };
        let type = null;
        if (P.types) {
            type = P.types.find(t => t.name === 'Dovolenka');
        }

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Modal.Header>
                    <div style={{ textAlign: 'right', height: 25, width: '100%'}}>
                        <MdClose
                            style={{ cursor: 'pointer'}}
                            size={24}
                            onClick={() => P.onClose()}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                <Formsy
                    ref={this.formRef}
                    onInvalid={() => {
                        this.setState({buttonDisabled: true});
                    }}
                    onValid={() => {
                        this.setState({ buttonDisabled: false });
                    }}
                    onValidSubmit={(model) => this.handleSubmit(model)}
                    onChange={obj => this.validateForm(obj)}
                    validationErrors={S.validationErrors}
                >
                    {isMobile ? this.getNativeDatePicker(true) : this.getWebDatePicker(true)}
                    {isMobile ? this.getNativeDatePicker(false) : this.getWebDatePicker(false)}
                    <FormSelect
                        name="type"
                        label="Typ"
                        value={type}
                        options={options}
                        getOptionValue={o => o.id}
                        getOptionLabel={o => o.name}
                        onChange={ val => this.setState({type: val})}
                    />
                    {
                        (S.type && S.type.required_order) ? <div>
                        <FormCreatableSelect
                            filterOptions={filterOptions}
                            options={this.getOrdersOptions()}
                            getOptionValue={ o => o.id}
                            getOptionLabel={ o => o.serial_number}
                            name={'order'}
                            label={'Zákazka'}
                            required={S.type && S.type.required_order}
                            onCreate={ o => this.onOrderCreate(o)}
                            formatCreateLabel={ val => val}
                            isValidNewOption={this.isValidNewOption.bind(this)}
                            getNewOptionData={this.getNewOptionData.bind(this)}
                        />
                        <FormSelect
                            filterOptions={filterOptions}
                            options={this.getCarsOptions()}
                            getOptionValue={ c => c.id}
                            getOptionLabel={ c => c.spz}
                            name={'car'}
                            label={'Auto'}
                            required={false}
                            isClearable={true}
                        />
                        <FormCheckBox
                            label={'Obed'}
                            name={'lunch_break'}
                            id={'lunch_break'}
                            required={false}
                            value={S.lunch_break}
                        />
                        </div> : null
                    }
                    <Row>
                        <Col sm={{offset: 4, span: 8}}>
                            <Button
                                variant="success"
                                type="submit"
                                disabled={S.buttonDisabled}
                            >
                                Vytvoriť hromadný záznam
                            </Button>
                        </Col>
                    </Row>
                </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

Vacation.propTypes = {
    year: PT.number.isRequired,
    month: PT.number.isRequired,
    show: PT.bool,
    max: PT.object.isRequired,
    min: PT.object.isRequired,
    orders: PT.arrayOf(PT.object).isRequired,
    cars: PT.arrayOf(PT.object).isRequired,
};

export default Vacation;
