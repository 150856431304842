import React from 'react';
import PT from 'prop-types';
import { Modal, Button, FormGroup, Row, FormLabel, Col, Form } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { getDependencies } from '../../scripts/deps';
import './styles.css';
import Formsy from 'formsy-react';
import Input  from 'components/Input';

class RoleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formSubmitted: false,
            model: props.defaultValues || RoleForm.getModelObject(props.permissions)
        };
    }

    static getModelObject(permissions){
        return permissions.reduce((acc, val) => {
            acc[val.name] = 0;
            return acc;
        }, {name: ''});
    }

    handleFormSubmit(model) {
        this.setState({
            formSubmitted: true
        });

        if (this.validateForm(model)) {
            return;
        }

        let obj = Object.assign({}, this.state.model);
        this.props.onSubmit(obj);

        this.setState({
            errors: {},
            formSubmitted: false,
            //model: RoleForm.getModelObject(this.props.permissions)
        });
    }

    changeHandler(e, name) {
        const { target } = e;
        const type = target.type;
        const isCheckbox = type === 'checkbox';
        const value = isCheckbox ? target.checked : target.value;
        // set clicked FormCheck to clicked value
        let new_model = {};
        new_model[name] = isCheckbox ? 2*(value + 0) : parseInt(value, 10);
        
        /* 
            get another permissions required to be at least readable 
            but only if checked value is more than 0
        */
        if ((value > 0) && (value < 4)) {
            getDependencies(name).forEach(rp => {
                if (this.state.model[rp] === 0) {
                    new_model[rp] = 1;
                }
            });
        }
        this.setState({
            model: Object.assign(this.state.model, new_model)
        });
    }

    validateForm(model){
        let errors = {};

        if (!model.name){
            errors['name'] = 'Zvoľte názov';
        }

        this.setState({
            errors: errors
        });

        return Object.keys(errors).length > 0;
    }

    render() {
        const { props:P, state:S } = this;

        return (
            <Modal
                show={P.show}
                className={'roleForm'}
                onHide={P.onClose}
                size={'lg'}
            >
                <Modal.Header>
                    <div className={'closeBox'}>
                        <MdClose
                            className = {'closeButton'}
                            onClick={() => P.onClose()}
                            size={'1.5em'}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={model => this.handleFormSubmit(model)}
                        onInvalidSubmit={ model => this.handleFormSubmit(model)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={this.state.errors}
                        formNoValidate
                    >
                        <Input
                            name="name"
                            label="Názov *"
                            value={S.model.name}
                            onChange={ val =>
                                this.setState({
                                    'model': Object.assign(
                                        this.state.model,
                                        {'name': val}
                                        )
                                })
                            }
                            isValid={ val => Boolean(val)}
                            isFormSubmitted={ () => S.formSubmitted}
                            placeholder="Majster"
                        />
                    {
                        P.permissions.map(p => {
                            if (p.isCheckbox) {
                                return (
                                    <FormGroup key={p.name}>
                                        <Row className={'mb-2'}>
                                            <Col sm={{span: 9, offset: 3}}>
                                                <Form.Check
                                                    id={`${p.name}`}
                                                    type={'checkbox'}
                                                    checked={!!S.model[p.name]}
                                                    onChange={e => this.changeHandler(e, p.name)}
                                                    label = {p.sk_name}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                );
                            }
                            let radioForPrivateView = '';
                            if  (p.isForPrivateView) {
                                radioForPrivateView = <Form.Check
                                    id={`${p.name}-5`}
                                    type={'radio'}
                                    value={5} 
                                    checked={S.model[p.name] === 5} 
                                    name={p.name} 
                                    inline
                                    label = {p.private_name || 'Iba svoje'}
                                    readOnly
                                />;
                            }
                            
                            return (
                                <FormGroup
                                    key={p.name}
                                    value={S.model[p.name]}
                                    onChange={e => this.changeHandler(e, p.name)}
                                    readOnly
                                >
                                    <Row>
                                        <Col md={3}>
                                            <FormLabel column={1}>{p.sk_name}</FormLabel>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Check
                                                id={`${p.name}-0`}
                                                value={0}
                                                type={'radio'}
                                                checked={S.model[p.name] === 0}
                                                name={p.name}
                                                inline
                                                label = {'Žiadne'}
                                                readOnly
                                            />
                                            {' '}
                                            <Form.Check
                                                id={`${p.name}-1`}
                                                value={1}
                                                type={'radio'}
                                                checked={S.model[p.name] === 1}
                                                name={p.name}
                                                inline
                                                label = {'Čítanie'}
                                                readOnly
                                            />
                                            {' '}
                                            <Form.Check
                                                id={`${p.name}-2`}
                                                type={'radio'}
                                                value={2}
                                                checked={S.model[p.name] === 2}
                                                name={p.name}
                                                inline
                                                label = {'Čítanie a zápis'}
                                                readOnly
                                            />
                                            {' '}
                                            {radioForPrivateView}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            );
                        })
                    }
                    <Row>
                        <Col sm={{span: 9, offset: 3}}>
                            <Button
                                variant="success"
                                type="submit"
                            >
                                { P.updating ? 'Upraviť' : 'Vytvoriť' }
                            </Button>
                        </Col>
                    </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

RoleForm.propTypes = {
    roles: PT.array,
    updating: PT.bool,
    defaultValues: PT.object,
    show: PT.bool
};

RoleForm.defaultProps = {
    roles: [],
    updating: false,
    defaultValues: null,
    show: false
};

export default RoleForm;