import React from 'react';
import {Container} from 'react-bootstrap';
import {SuperTable} from 'components/PaginationTable';
import {getUrlParams} from './utils';
import call from 'ajax/call';
import Spinner from 'components/Spinner';
import SectionForm from '../WorkOperations/SectionForm';
import Formsy from 'formsy-react';

class Detail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            is_loading: true,
        };
    }

    componentDidMount() {
        let {building_id, entity_id} = getUrlParams(this.props.location, [
            'building_id',
            'entity_id'
        ]);

        this.fetchOperationStatistics({building_id, entity_id});
    }

    fetchOperationStatistics(params) {
        this.setState({
            is_loading: true
        });

        let url = `/api/buildings/${params.building_id}/operation_statistics`;
        if (params.entity_id) {
            url += '/' + params.entity_id;
        }

        call(url)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.setState({
                        stats: data.data,
                        is_loading: false,
                    });
                } else {
                    this.setState({
                        stats: {},
                        is_loading: false,
                    });
                }
            });
    }
    
    render() {
        const { state: S } = this;
        
        if (S.is_loading) {
            return <Spinner/>;
        }

        let entity_types = S.stats.entity_types || {};

        let data = Object.keys(entity_types).filter(key => entity_types[key].length > 0)
            .map(key => {
            let items = entity_types[key];

            return <div key={key}>
                <h4>{key} : </h4>
                <SuperTable
                    name={key}
                    thead={[
                        {name: 'operation_name', sk_name: 'Názov operácie', type: 'string', sort: true},
                        {name: 'time', sk_name: 'Čas [normohodiny]', type: 'seconds', sort: true},
                        {name: 'total_length', sk_name: 'Celková dĺžka [m]', type: 'number', sort: true},
                        {name: 'ratio', sk_name: 'Čas/dĺžka [sekundy]', type: 'number', sort: true},
                    ]}
                    items={items}
                    columnSelector={false}
                />
            </div>;
        });

        let {building_id, entity_id} = getUrlParams(this.props.location, [
            'building_id',
            'entity_id'
        ]);

        let summary = null;
        if (data.length > 1) {
            summary = <div>
                <h4>Zhrnutie : </h4>
                <SuperTable
                    name={'operations_stats'}
                    thead={[
                        {name: 'name', sk_name: 'Názov operácie', type: 'string', sort: true},
                        {name: 'time', sk_name: 'Celkový čas [normohodiny]', type: 'seconds', sort: true},
                        //{name: 'info', sk_name: 'info', type: 'html', sort: false},
                    ]}
                    items={S.stats.total_operation_statistics.filter(item => !item.overhead)}
                    columnSelector={false}
                    is_loading={S.is_loading}
                />
            </div>;
        }

        let overhead_operations = null;
        if (!entity_id) {
            overhead_operations = <div>
                <h4>Réžijné operácie : </h4>
                <SuperTable
                    name={'overhead_operations'}
                    thead={[
                        {name: 'name', sk_name: 'Názov operácie', type: 'string', sort: true},
                        {name: 'time', sk_name: 'Celkový čas [normohodiny]', type: 'seconds', sort: true},
                        //{name: 'info', sk_name: 'info', type: 'html', sort: false},
                    ]}
                    items={S.stats.total_operation_statistics.filter(item => item.overhead)}
                    columnSelector={false}
                    is_loading={S.is_loading}
                />
            </div>;
        }

        return (
            <Container className="cars">
                <Formsy>
                    <SectionForm
                        locked={true}
                        params={{
                            building_id,
                            entity_id,
                        }}
                        leaf_required={false}
                    />
                </Formsy>
                <div className="info">
                    <div style={{
                        marginTop: 15,
                        marginBottom: 15,
                        textAlign: 'center'
                    }}>
                        <h3>Štatistika pracovných operácií:</h3>
                    </div>
                </div>
                {data}
                {summary}
                {overhead_operations}
            </Container>
        );
    }
}

export default Detail;
