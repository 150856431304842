import store from 'store/mainStore';

const isInArray = (item, arr) => arr.indexOf(item) > -1;
const isNotInArray = (item, arr) => !isInArray(item, arr);

export function getDependencies(roleName) {
    if (roleName === undefined) {
        throw new Error('function exptecting one argument: roleName');
    }

    const deps = store.getState().dependency;
    let roleDependencies = deps[roleName].slice();
    let notResolved = deps[roleName].slice();
    let item;

    while (notResolved.length) {
        item = notResolved.shift();
        if (isNotInArray(item, roleDependencies)) {
            roleDependencies.push(item);
            notResolved = notResolved.concat(deps[item]);
        }
    }

    return roleDependencies;
}