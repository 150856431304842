import React from 'react';
import {withFormsy} from 'formsy-react';
import PT from 'prop-types';
import {Form, Row} from 'react-bootstrap';
import classNames from 'classnames';

class InvertedCheckBox extends React.Component {

    changeValue(e) {
        let value = e.target.checked;
        this.props.setValue(value);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const {props: P} = this;

        return <Row className={'mb-2'}>
            <div className={'col-sm-1'}>
                <Form.Check
                    style={{marginTop: '7px'}}
                    onChange={e => this.changeValue(e)}
                    checked={P.value}
                    name={P.name}
                    id={P.name}
                    type={P.type}
                    label={''}
                />
            </div>
            <label htmlFor={P.name} className={
                classNames({
                    'col-form-label': true,
                    'col-sm-11': true,
                    'text-danger': Boolean(!P.isValid)
                })}>
                {P.label}
                {P.required ?
                    <span className={'required-symbol'}> *</span> : null
                }
            </label>
        </Row>;
    }
}

InvertedCheckBox.propTypes = {
    style: PT.object,
    name: PT.string.isRequired,
    label: PT.string.isRequired,
    value: PT.bool,
    onChange: PT.func,
    type: PT.string.isRequired,
};

InvertedCheckBox.defaultProps = {
    value: false,
    type: 'checkbox'
};

export default withFormsy(InvertedCheckBox);
