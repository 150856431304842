import React from 'react';
import PT from 'prop-types';
import classnames from 'classnames';
import {Dots} from 'react-activity';
import 'react-activity/dist/Dots.css';
import {Button} from 'react-bootstrap';
import Tern from 'components/Or';

class EmptyDayRecord extends React.Component {
	
	handleRowClick() {
		if (!this.props.canMakeActions) {
			return;
		}
		this.props.createWH(this.props.date);
	}
	
	render() {
		const { props: P } = this;
		const classes = classnames({
			'weekend': P.isWeekend,
			'row-holiday': P.isWeekend ? false : P.isHoliday,
		});

		return (
			<tr
				onClick={() => this.handleRowClick()}
				style={{height: '49px'}}
			>
				<td className={classes}>{P.dayLabel}</td>
				<td />
				<td />
				<td />
				<td />
				<td />
				<td />
				<td />
				<Tern expression={P.canMakeActions}>
					<td />
				</Tern>
				<Tern expression={P.canMakeActions}>
					<td rowSpan={P.rowspan}
						className="spz"
					>
						<Tern expression={P.isCreating}>
							<Dots/>
							<Button
								size={'sm'}
								// onClick={() => this.handleRowClick()}
								variant="success"
								disabled={!P.canMakeActions}
							>
								+
							</Button>
						</Tern>
					</td>
				</Tern>
				<Tern expression={P.isAccountant}>
					<td />
				</Tern>
			</tr>
		);
	}
}

EmptyDayRecord.propTypes = {
	dayLabel: PT.string.isRequired,
	date: PT.instanceOf(Date).isRequired,
	windowInfo: PT.shape({
		employeeId: PT.number.isRequired,
		year: PT.number.isRequired,
		humanMonth: PT.number.isRequired
	}).isRequired,
	canMakeActions: PT.bool.isRequired,
	isAccountant: PT.bool.isRequired,
	isCreating: PT.bool.isRequired,
	isWeekend: PT.bool.isRequired,
	isHoliday: PT.bool.isRequired,
};

export default EmptyDayRecord;
