import React from 'react';
import classname from 'classnames';
import { MdHistory } from 'react-icons/md';

// removes accents/diacritics from input text
export const toAscii = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const renderSuggestionContainer = ({ containerProps, children }) => {
    let hidden = (!children || children.length === 0);

    return (
        <div
            {...containerProps}
            className={classname('sugg-container', { hidden: hidden }, containerProps.className)}
        >
            {children}
        </div>
    );
};

export const showAllSuggestions = (arr) => {
    return () => {
        return arr;
    };
};

export const renderSuggestion = (fn) => {
    return function(suggestion) {
        let innerText = suggestion.name;

        if (fn) {
            innerText = fn(suggestion);
        }

        const cn = suggestion._meta === 'history' ? 'sugg history' : 'sugg';
        const HistoryIcon = suggestion._meta === 'history' ? <MdHistory /> : (<div />);

        return (
            <div className={cn}>
                { HistoryIcon }
                <p>{innerText}</p>
            </div>
        );
    };
};

export const decorateOnSuggestionSelected = (what, callback, context, attributeToSet = 'id') => {
    let decoratedFunction = function(some, { suggestion }) {
        // get cached items
        const lastCachedItems = JSON.parse(localStorage.getItem(what)) || [];

        const itemsWithoutActualSugg = lastCachedItems.filter((item) => item.id !== suggestion.id);

        // get that chosen suggestion on the first place
        const newItemsArray = [suggestion, ...itemsWithoutActualSugg];

        localStorage.setItem(what, JSON.stringify(newItemsArray));

        if (typeof callback === 'function') {
            setTimeout(() => callback(), 50);
        }

        this.setState({
            selected: {
                ...this.state.selected,
                [what]: attributeToSet ? suggestion[attributeToSet] : suggestion
            }
        });
    };

    return context !== undefined ? decoratedFunction.bind(context) : decoratedFunction;
};

export const getSuggestions = (value, arr, builderFn, showAll = false) => {
    value = value || '';

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (value === '' && showAll) {
        return arr;
    }

    if (inputLength === 0) {
        return arr.filter((item) => item._meta === 'history');
    }

    return arr.filter((item) =>
        toAscii(builderFn(item))
            .toLowerCase()
            .slice(0, inputLength) === inputValue).slice(0, 10);
};

export const getSuggestionValue = (value) => value.name;

export const onSuggestionsClearRequestedDecorator = (what) => {
    return () => {
        let items = [];
        // try to get object items
        try {
            items =
                JSON.parse(localStorage.getItem(what)).map((item) => ({
                    ...item,
                    _meta: 'history'
                })) || [];
        } catch (e) {
            items = JSON.parse(localStorage.getItem(what)) || [];
        }

        return items;
    };
};

const validTimeRegexp = /^(([0-1]*[0-9])|(2[0-3])):[0-5][0-9]$/;

export function timeParser(rawTime) {
    if (typeof rawTime !== 'string' || rawTime.length === 0) {
        return null;
    }
    if (rawTime.match(validTimeRegexp)) {
        return rawTime;
    } else if (rawTime.includes(':')) {
        return null;
    }

    const len = rawTime.length;
    if (len === 1) {
        return `0${rawTime}:00`;
    } else if (len === 2) {
        const numeric = parseInt(rawTime, 10);
        if (numeric > 23) {
            if (parseInt(rawTime[1], 10) > 5) {
                return null;
            }
            return `0${rawTime[0]}:${rawTime[1]}0`;
        } else {
            return `${rawTime}:00`;
        }
    } else if (len === 3) {
        const hourSlice = rawTime.slice(0, 2);
        const numericHours = parseInt(hourSlice, 10);
        if (numericHours > 23) {
            if (parseInt(rawTime[1], 10) > 5) {
                return null;
            }
            return `0${rawTime[0]}:${rawTime[1]}${rawTime[2]}`;
        } else {
            if (parseInt(`${rawTime[2]}0`, 10) > 59) {
                return `0${rawTime[0]}:${rawTime[1]}${rawTime[2]}`;
            }
            return `${`0${numericHours}`.slice(-2)}:${rawTime[2]}0`;
        }
    } else if (len === 4) {
        const hourSlice = rawTime.slice(0, 2);
        const minutesSlice = rawTime.slice(2, 4);
        const numericHours = parseInt(hourSlice, 10);
        const numericMinutes = parseInt(minutesSlice, 10);
        if (numericHours > 23 || numericMinutes > 59) {
            return null;
        } else {
            return `${hourSlice}:${minutesSlice}`;
        }
    }
    return null;
}

/* 
	Returns empty array if no collisions are detected,
	if not empty, then array contains works which are in some collisions
*/
export function validateWorkTimes(works) {
    if (works.length === undefined) {
        throw new Error(`works argument should be an array but got ${works}`);
    }

    return works.filter(work => {
        const start = new Date(work.start);
        const end = new Date(work.end);

        return works.some((anotherWork) => {
            if (work.id === anotherWork.id) {
                return false;
            }

            const anotherStart = new Date(anotherWork.start);
            const anotherEnd = new Date(anotherWork.end);
            return (anotherStart <= start && anotherEnd > start) || (anotherStart >= start && anotherStart < end);
        });
    });
}

export function validateOperations(data) {
    if (!data || !data.length) {
        return false;
    }

    return data.every(item => {
        const start = new Date(item.started_at);
        const end = new Date(item.finished_at);

        return !data.some((other) => {
            if (item.id === other.id) {
                return false;
            }

            const anotherStart = new Date(other.started_at);
            const anotherEnd = new Date(other.finished_at);
            return (anotherStart <= start && anotherEnd > start) || (anotherStart >= start && anotherStart < end);
        });
    });

}

export function getDifferenceStyle(operations, work_time, applyStyle) {
    if (!applyStyle || !work_time) {
        return {};
    }

    return {
        backgroundColor: `rgba(40, 167, 69, ${Math.min(0.8*operations/work_time, 1.0)})`
    };
}

export const selectorStyles =
    {
        control: () => ({
            border: 0,
            textAlign: 'center',
            width: '100%',
            padding: 0,
        }),
        singleValue : (provided) => ({
            ...provided,
            textAlign: 'center',
            width: '100%',
            margin: 0,
            left: 0,
        }),
        placeholder : (styles) => ({
            ...styles,
            textAlign: 'center',
            width: '100%',
            left: 0
        }),
        menu : (provided) => ({
            ...provided,
            width: '150px'
        })
    };