import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { FormSelect } from 'components/CustomSelect';
import { FaQuestionCircle } from 'react-icons/fa';
import Formsy from 'formsy-react';
import { MdFileDownload } from 'react-icons/md';
import call from 'ajax/call';

const yearOptions = (function () {
    let arr = [];
    for (let year=(new Date()).getFullYear(); year > 1990; year-- )
        arr.push({
            name: String(year),
            id: String(year)
        });
    return arr;
})();

class YearChooser extends React.Component {

    constructor(props) {
        super(props);
        let year = new Date().getFullYear();

        this.state = {
            year: year
        };
    }

    acceptHandler() {
        this.props.onAccept && this.props.onAccept(this.state.year);
        this.props.onClose();
    }

    declineHandler() {
        this.props.onDecline && this.props.onDecline();
        this.props.onClose();
    }

    handleExport(event, year, employeeId) {
        event.preventDefault();
        call(`/api/working_hours/export/${year}/employee/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json'
            }
        }).catch(e => console.log(e));
    }

    render() {
        const { props:P } = this;
         
         return (
            <Modal
                show={P.show}
                onHide={P.onClose}
                onClose={P.onClose}
            >
                <Modal.Header>
                    <FaQuestionCircle
                        size={30}
                        color={'#00950e'}
                    />
                    <div style={{
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        { P.title }
                    </div>
                </Modal.Header>
                <Modal.Body>
                <Formsy>
                    <FormSelect
                        name="year"
                        label="Rok "
                        options={yearOptions}
                        value={this.state.year}
                        onChange={ val => {
                            this.setState({year: val});
                        }}
                    />
                </Formsy>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={(event) => this.handleExport(event, this.state.year.id, P.emp_id)}
                    >
                        <MdFileDownload/>
                        Stiahnuť
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => this.declineHandler()}>
                        Zrušiť
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

YearChooser.propTypes = {
    show: PropTypes.bool,
    emp_id: PropTypes.number,
    title: PropTypes.string,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func, // not required
    onClose: PropTypes.func,
};

YearChooser.defaultProps = {
    show: false,
    onClose: () => {},
    onAccept: () => {},
    onDecline: () => {},
    title: 'Zvoľte rok'
};

export default YearChooser;

