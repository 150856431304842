import { Spinner as ReactSpinner} from 'react-activity';
import 'react-activity/dist/Spinner.css';

import React from 'react';

export default function Spinner() {
    return <div style={{
        marginTop: '10pt',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <ReactSpinner />
    </div>;
}