import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Col, Row} from 'react-bootstrap';
import Formsy from 'formsy-react';
import { MdClose } from 'react-icons/md';
import CustomSelect from 'react-select';
import {createFilter} from 'react-select';
import { TiDelete } from 'react-icons/ti';

class Form extends React.Component {

    constructor(props) {
        super(props);

        this.employeeRef = React.createRef();
        this.state = {
            employees: [],
        };
    }

    submitHandler() {
        this.props.onSubmit({
            employees: this.state.employees.map(e => e.id),
        });

        this.setState({
            employees: [],
        });
    }

    addEmployee() {
        let value = this.employeeRef.current.getValue();

        if (value.length > 0) {
            let emp = value[0];
            if (this.state.employees.every(e => e.id !== emp.id)) {
                this.setState({
                    employees: [
                        emp,
                        ...this.state.employees,
                    ]
                });
            }
        }
    }

    render() {
        const { props:P } = this;

        const onKeyPress = event => {
            if (event.which === 13 /* Enter */) {
                event.preventDefault();
                this.addEmployee();
            }
        };

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
                size={'lg'}
            >
                <Modal.Header>
                    <div className={'closeBox'}
                    >
                        <MdClose
                            className = {'closeButton'}
                            size={24}
                            onClick={P.onClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={(model) => this.submitHandler(model)}
                        onChange={ values => this.validateForm(values)}
                        onKeyPress={onKeyPress}
                    >
                        <Row
                            style={{marginBottom: 15}}
                        >
                            <Col sm={3}>
                                Pridať zamestnanca
                            </Col>
                            <Col sm={6}>
                                <CustomSelect
                                    ref={this.employeeRef}
                                    options={P.employees}
                                    isClearable={true}
                                    filterOption={createFilter(P.filterOptions || {})}
                                    getOptionValue={ e => e.id}
                                    getOptionLabel={ e => e.full_name}
                                    placeholder={'Vyberte...'}
                                />
                            </Col>
                            <Col sm={3}>
                                <Button
                                    variant="success"
                                    onClick={() => {
                                        this.addEmployee();
                                    }}
                                >
                                    Pridať
                                </Button>
                            </Col>
                        </Row>
                        {
                            this.state.employees.map( e => (
                                <Row key={'row' + e.id}>
                                    <Col sm={3}/>
                                    <Col sm={9}>
                                        <input
                                            key={e.id}
                                            name={e.full_name}
                                            value={e.full_name}
                                            disabled
                                            style={{
                                                borderStyle: 'solid',
                                                borderRadius: 5,
                                                textAlign: 'center',
                                                color: '#303030'
                                            }}
                                        />
                                        <TiDelete
                                            //key={'clear' + e.id}
                                            color={'red'}
                                            size={30}
                                            style={{
                                                marginLeft: 5,
                                                marginBottom: 2,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.setState({
                                                employees: this.state.employees.filter( emp => e.id !== emp.id)
                                            })}
                                        />
                                    </Col>
                                </Row>
                            ))
                        }
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Potvrdiť
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    employees: PropTypes.array.isRequired,
};

export default Form;
