import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PT from 'prop-types';
import {SuperTable} from 'components/PaginationTable';
import ModalForm from 'components/ModalForm';
import {connect} from 'react-redux';
import store from 'store';
import buildingIssues from 'actions/building_issues';
import buildingIssueRecords from 'actions/building_issue_records';
import employees from 'actions/employees';
import buildingIssueStates from 'actions/building_issue_states';
import {Button, Col, Container, Row} from 'react-bootstrap';
import Spinner from 'components/Spinner';
import {format} from 'scripts/dateFormatter';

class BuildingIssueDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recordObjToUpdate: undefined,
            issueObjToUpdate: undefined,
            recordFormActive: false,
            issueFormActive: false,
        };
    }

    componentDidMount() {
        const issueId = parseInt(this.props.match.params.id);
        store.dispatch(buildingIssues.fetchConcrete(issueId));
        store.dispatch(buildingIssueRecords.fetchAll({issue_id: issueId}));
        store.dispatch(employees.fetchAll());
        store.dispatch(buildingIssueStates.fetchAll());
    }

    render() {
        const {props: P, state: S} = this;

        if (P.isLoading) {
            return <Spinner/>;
        }

        if (!P.issue) {
            return <h3 style={{textAlign: 'center'}}>Servisná udalosť bola vymazaná</h3>;
        }

        let notity_names = '';
        if (P.issue.notify_employees) {
            notity_names = P.issue.notify_employees
                .split(';')
                .filter(i => i)
                .reduce((result, id) => {
                    let emp = P.employees.find(e => e.id == id);
                    if (emp) {
                        if (result.length > 0) {
                            result = result + ', ';
                        }
                        return result + emp.full_name;
                    }
                    return result;
                }, '');
        }

        return (
            <div>
                <Row>
                    <Col xs={6} className="text-right"><h3>Servisná udalosť</h3></Col>
                    <Col xs={3} className="text-left"><h3>{P.issue.name}</h3></Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Budova</Col>
                    <Col xs={3} className="text-left">{P.issue.issue_building_name}</Col>
                </Row>
                {

                    P.issue.order_number &&
                    <Row>
                        <Col xs={6} className="text-right">Zákazka</Col>
                        <Col xs={3} className="text-left">{P.issue.order_number}</Col>
                    </Row>
                }
                <Row>
                    <Col xs={6} className="text-right">Poznámka</Col>
                    <Col xs={3} className="text-left">{P.issue.note}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Vytvoril</Col>
                    <Col xs={6} className="text-left">{P.issue.creator_name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Vytvorené dňa</Col>
                    <Col xs={6} className="text-left">{format(P.issue.created_at)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Zodpovedný</Col>
                    <Col xs={6} className="text-left">{P.issue.responsible_name}</Col>
                </Row>
                {
                    P.issue.notify_employees && <Row>
                        <Col xs={6} className="text-right">Upozorniť</Col>
                        <Col xs={6} className="text-left">{notity_names}</Col>
                    </Row>
                }
                {
                    P.issue.attachment &&
                    <Row>
                        <Col xs={6} className="text-right">Príloha</Col>
                        <Col xs={6} className="text-left">
                            <a
                                target="_blank"
                                href={P.issue.attachment}
                                rel="noopener noreferrer"
                            >
                                <Button variant={'primary'}>
                                    Stiahnuť
                                </Button>
                            </a>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={6} className="text-right">Stav</Col>
                    <Col xs={6} className="text-left">{P.issue.issue_state_name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Vyriešené dňa</Col>
                    <Col xs={6} className="text-left">{format(P.issue.finished_at)}</Col>
                </Row>
                <Container>
                    <div className={'text-center'} style={{marginTop: 25}}>
                        <Button
                            onClick={() => this.setState({issueFormActive: true, issueObjToUpdate: P.issue})}
                            variant="success"
                        >
                            Upraviť
                        </Button>
                    </div>
                    <h2 className='text-center' style={{marginTop: 25}}>Záznamy servisnej udalosti</h2>
                    <Row>
                        <Col xs={12} style={{marginTop: 25}}>
                            <Button
                                variant="success"
                                onClick={() => this.setState({recordFormActive: true})}
                            >
                                Pridať záznam
                            </Button>
                        </Col>
                    </Row>
                    <SuperTable
                        name={'building_issue_records'}
                        thead={[
                            {name: 'name', sk_name: 'Popis', type: 'string'},
                            {name: 'note', sk_name: 'Poznámka', type: 'string'},
                            {name: 'creator_name', sk_name: 'Vytvoril', type: 'string'},
                            {name: 'date', sk_name: 'Dátum', type: 'date-time'},
                            {name: 'created_at', sk_name: 'Vytvorené', type: 'date-time', hidden: true},
                            {name: 'attachment', sk_name: 'Príloha', type: 'file'},
                        ]}
                        items={P.issueRecords}
                        searchable
                        columnSelector
                        defaultSortKey={'created_at'}
                        defaultAsc={false}
                        editOps
                        delOps
                        onEditItem={(id) => {
                            this.setState({
                                recordObjToUpdate: P.issueRecords.find(r => r.id === id),
                                recordFormActive: true,
                            });
                        }}
                        onDeleteItem={(id) => {
                            store.dispatch(buildingIssueRecords.delete(id));
                        }}
                        rowStyleFn={
                            (column) => ['name', 'note'].includes(column)
                                ? {whiteSpace: 'normal'}
                                : {}
                        }
                        is_loading={P.isLoading}
                        noData={'Žiadne záznamy'}
                    />
                    <ModalForm
                        show={S.issueFormActive}
                        updatingObj={S.issueObjToUpdate}
                        action={buildingIssues}
                        afterSave={() => this.setState({issueObjToUpdate: undefined, issueFormActive: false})}
                        onClose={() => this.setState({issueObjToUpdate: undefined, issueFormActive: false})}
                        formElements={[
                            {name: 'name', label: 'Názov', type: 'text', required: true},
                            {name: 'note', label: 'Poznámka', type: 'textarea'},
                            {
                                name: 'responsible',
                                label: 'Zodpovedný',
                                type: 'select',
                                options: {'items': P.employees, labelKey: 'full_name'},
                                selectKey: 'responsible_id',
                            },
                            {
                                name: 'notify_employees',
                                label: 'Upozorniť',
                                type: 'multiselect',
                                options: {'items': P.employees, labelKey: 'full_name'},
                                selectKey: 'notify_employees',
                                required: false,
                            },
                            {name: 'building_id', type: 'hidden', value: P.issue.building_id},
                            {
                                name: 'order',
                                label: 'Zákazka',
                                type: 'select',
                                options: {'items': P.orders, labelKey: 'serial_number'},
                                selectKey: 'order_id',
                            },
                            {
                                name: 'issue_state',
                                label: 'Stav',
                                type: 'select',
                                options: {'items': P.issueStates},
                                selectKey: 'state_id',
                                selectFirstValue: true,
                                required: true,
                            },
                            {name: 'attachment', label: 'Príloha', type: 'file'},
                            {name: 'notify_database', label: 'Notifikovať v aplikácii', type: 'checkbox'},
                            {name: 'notify_email', label: 'Notifikovať cez email', type: 'checkbox'},
                            {name: 'notify_sms', type: 'hidden', value: false}
                        ]}
                    >
                    </ModalForm>
                    <ModalForm
                        show={S.recordFormActive}
                        updatingObj={S.recordObjToUpdate}
                        action={buildingIssueRecords}
                        afterSave={() => this.setState({recordObjToUpdate: undefined, recordFormActive: false})}
                        onClose={() => this.setState({recordObjToUpdate: undefined, recordFormActive: false})}
                        formElements={[
                            {name: 'name', label: 'Popis', type: 'text', required: true},
                            {name: 'note', label: 'Poznámka', type: 'textarea'},
                            {
                                name: 'date',
                                label: 'Dátum',
                                type: 'datetime',
                                defaultValue: Date.now(),
                                required: true
                            },
                            {name: 'issue_id', type: 'hidden', value: parseInt(this.props.match.params.id)},
                            {name: 'attachment', label: 'Príloha', type: 'file'},
                        ]}
                    >
                    </ModalForm>
                </Container>
            </div>
        );
    }
}

BuildingIssueDetail.propTypes = {
    issue: PropTypes.object,
    issueRecords: PropTypes.array,
    orders: PT.array.isRequired,
    employees: PT.array.isRequired,
    issueStates: PT.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);
    const issue = state.buildingIssues.items.find(i => i.id === id);
    let orders = [];
    if (issue) {
        orders = state.orders.items.filter(o => o.building_id === issue.building_id);
    }

    return ({
        issue,
        issueRecords: state.buildingIssueRecords.items,
        orders: orders,
        employees: state.employees.items,
        issueStates: state.buildingIssueStates.items,
        isLoading: !state.buildingIssues.items.length ||
            state.buildingIssueRecords.isFetching ||
            state.orders.isFetching ||
            state.employees.isFetching ||
            state.buildingIssueStates.isFetching,
    });
};

export default connect(mapStateToProps)(BuildingIssueDetail);
