/**
 * 
 * 
 * @export
 * @param {string} str 
 * @returns {string}
 */
export default function(str) {
    if (typeof str !== 'string') 
        throw new Error('must be string');
    
    const d = new Date(str);
    const year = d.getFullYear();
    let month = d.getMonth() ;
    month = month < 10 ? '0' + month : month;
    let day = d.getDate();
    day = day < 10 ? '0' + day : day;
    let hour = d.getHours();
    hour = hour < 10 ? '0' + hour : hour;
    let minute = d.getMinutes();
    minute = minute < 10 ? '0' + minute : minute;
    let second = d.getSeconds();
    second = second < 10 ? '0' + second : second ;
    
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`; 
}