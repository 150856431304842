import {applyMiddleware, combineReducers, createStore} from 'redux';
import permissions from 'reducers/permissions';
import thunkMiddleware from 'redux-thunk';
import roles from 'reducers/roles';
import professions from 'reducers/professions';
import employees from 'reducers/employees'; /* lot of items */
import employee from 'reducers/employee'; /* one id */
import employeeTypes from 'reducers/employee_types';
import employeeOrder from 'reducers/employee_orders';
import employeesMastersProjectManagers from 'reducers/employees_masters_project_managers';
import login from 'reducers/login';
import orders from 'reducers/orders';
import order_material_quantity from 'reducers/order_material_quantity';
import order_material_item_documents from 'reducers/order_material_item_documents';
import order_material_documents from 'reducers/order_material_documents';
import order_document_material_items from 'reducers/order_document_material_items';
import buildings from 'reducers/buildings';
import buildingRunningPerformances from 'reducers/building_running_performances';
import buildingLastPerformances from 'reducers/building_last_performances';
import buildingsWithPerformances from 'reducers/buildings_with_performances';
import buildingTopEntities from 'reducers/building_top_entities';
import buildingIssues from 'reducers/building_issues';
import buildingIssueRecords from 'reducers/building_issue_records';
import buildingIssueStates from 'reducers/building_issue_states';
import workingHours from 'reducers/working_hours';
import workingHoursTypes from 'reducers/working_hours_types';
import tablets from 'reducers/tablets';
import tablet from 'reducers/tablet';
import tablet_owners from 'reducers/tablet_owners';
import unlockedEmployees from 'reducers/unlocked_employees';
import identity from 'reducers/identity';
import certificationTypes from 'reducers/certification_types';
import certifications from 'reducers/certifications';
import cars from 'reducers/cars';
import approvals from 'reducers/approvals';
import toolStates from 'reducers/tool_states';
import tools from 'reducers/tools';
import toolHistory from 'reducers/tool_history';
import logs from 'reducers/logs';
import toolsAtEmployees from 'reducers/tools_at_employees';
import toolsAtBuildings from 'reducers/tools_at_buildings';
import dependency from 'reducers/dependency';
import todayInfo from 'reducers/today_info';
import globalError from 'reducers/globalError';
import rewards from 'reducers/rewards';
import rewardMultipliers from 'reducers/reward_multipliers';
import toolCategories from 'reducers/tool_categories';
import roomTypes from 'reducers/room_types';
import workOperations from 'reducers/work_operations';
import operationList from 'reducers/operation_list';
import carsHistory from 'reducers/cars_history';
import orderWorkDetail from 'reducers/order_work_detail';
import notifications from 'reducers/notifications';
import subscriptions from 'reducers/subscriptions';
import masterEmployees from 'reducers/master_employees';
import trainings from 'reducers/trainings';
import employee_trainings from 'reducers/employee_trainings';
import material_types from 'reducers/material_types';
import pipe_dimensions from 'reducers/pipe_dimensions';
import material from 'reducers/material';
import entity_operations from 'reducers/entity_operations';
import work_performance from 'reducers/work_performance';
import operation_work from 'reducers/operation_work';
import unsavedChanges from 'reducers/unsaved_changes';
import group_history from 'reducers/group_history';
import all_entity_operations from 'reducers/all_entity_operations';
import entity_types from 'reducers/entity_types';
import building_entities from 'reducers/building_entities';
import business_trips_cars from 'reducers/business_trips_cars';
import my_operations from 'reducers/my_operations';
import my_group_history from 'reducers/my_group_history';
import operations_employees_list from 'reducers/operations_employees_list';
import category_material_items from 'reducers/category_material_items';
import material_categories from 'reducers/material_categories';
import storecard from 'reducers/storecard';
import default_entity_operations from 'reducers/default_entity_operations';
import stores from 'reducers/stores';
import store_content from 'reducers/store_content';
import material_search_results from 'reducers/material_search_results';
import products from 'reducers/products';
import building_equipment from 'reducers/building_equipment';

const rootReducer = combineReducers({
    permissions,
    roles,
    professions,
    employees,
    employee,
    employeeOrder,
    employeesMastersProjectManagers,
    employeeTypes,
    orders,
    order_material_quantity,
    order_material_documents,
    order_material_item_documents,
    order_document_material_items,
    category_material_items,
    material_categories,
    business_trips_cars,
    buildings,
    buildingRunningPerformances,
    buildingLastPerformances,
    buildingsWithPerformances,
    buildingTopEntities,
    buildingIssues,
    buildingIssueRecords,
    buildingIssueStates,
    workOperations,
    roomTypes,
    workingHours,
    tablets,
    tablet,
    workingHoursTypes,
    tablet_owners,
    unlockedEmployees,
    identity,
    unsavedChanges,
    certificationTypes,
    certifications,
    cars,
    approvals,
    toolStates,
    tools,
    toolHistory,
    logs,
    rewards,
    toolsAtEmployees,
    toolsAtBuildings,
    dependency,
    todayInfo,
    rewardMultipliers,
    toolCategories,
    login,
    globalError,
    operationList,
    carsHistory,
    orderWorkDetail,
    notifications,
    subscriptions,
    masterEmployees,
    trainings,
    employee_trainings,
    material_types,
    pipe_dimensions,
    material,
    entity_operations,
    all_entity_operations,
    work_performance,
    operation_work,
    group_history,
    entity_types,
    building_entities,
    my_operations,
    my_group_history,
    operations_employees_list,
    storecard,
    stores,
    store_content,
    default_entity_operations,
    material_search_results,
    products,
    building_equipment,
});

const store = createStore(
    rootReducer,
    {},
    applyMiddleware(thunkMiddleware)
);

export default store;
