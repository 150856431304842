import { setPermissions } from 'actions/permissions';
import { setIdentity } from 'actions/identity';
import notificationActions from 'actions/notifications';
import call from 'ajax/call';

export const SET_PENDING = 'SET_PENDING';
export const SET_RESOLVED_ERR = 'SET_RESOLVED_ERR';
export const SET_RESOLVED_DATA = 'SET_RESOLVED_DATA';
export const UNSET_ERR = 'UNSET_ERROR';
export const SET_LOGOUT = 'SET_LOGOUT';

function setPending() {
    return {
        type: SET_PENDING,
    };
}

function setResolvedErr(err) {
    return {
        type: SET_RESOLVED_ERR,
        err
    };
}

function setResolvedData(data) {
    return {
        type: SET_RESOLVED_DATA,
        permissions: data.permissions,
    };
}

function unsetErr() {
    return {
        type: SET_RESOLVED_ERR
    };
}

export function setLogout() {
    return {
        type: SET_LOGOUT
    };
}

function afterLogin(data, dispatch) {
    if (data.status === 'error') {
        dispatch(setResolvedErr());
        setTimeout(() => {
            dispatch(unsetErr());
        }, 5000);
    } else {
        localStorage.setItem('permissions', JSON.stringify(data.permissions));
        localStorage.setItem('identity', JSON.stringify(data.user));

        dispatch(setPermissions(data.permissions));
        dispatch(setIdentity(data.user));
        dispatch(setResolvedData({
            permissions: data.permissions
        }));
        dispatch(notificationActions.fetchAll());
    }
}

export function login(obj) {
    return function(dispatch) {
        dispatch(setPending());

            return call('/api/login', {
                headers: {'Content-type': 'application/json'},
                method: 'POST',
                body: JSON.stringify(obj),
            })
            .then(response => response.json())
            .then(data => afterLogin(data, dispatch));
    };
}

export function getIdentity() {
    return function(dispatch) {
        dispatch(setPending());

        return call('/api/identity', {method: 'GET'})
            .then(response => response.json())
            .then(data => afterLogin(data, dispatch));
    };
}

export function logout() {
    return function(dispatch) {
        dispatch(setPending());

        return (
            call('/api/logout')
                .then(response => response.json())
                .then( responseData => {
                    if (responseData.status === 'ok') {
                        dispatch(setLogout());
                    } else {
                        dispatch(setResolvedErr(responseData.message));
                    }
                }).catch( e => console.error(e))
        );
    };
}
