import React from 'react';
import {connect} from 'react-redux';
import store from 'store';
import parseData from './parseData';
import todayInfoActions from 'actions/today_info';
import NoDataInfo from './NoData';

import {Col, Container, Row} from 'react-bootstrap';
import classnames from 'classnames';
import './style.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Spinner from 'components/Spinner';
import { SuperTable } from 'components/PaginationTable';
import {updateQueryParams} from '../../scripts/queryFunctions';
import {withRouter} from 'react-router-dom';
import {formatDate} from 'scripts/dateFormatter';

class TodayWorkContainer extends React.Component {

	constructor(props) {
		super(props);

		let today = new Date();
		this.state = {
			collapsed_buildings: []
		};

		const { location, history } = this.props;
		updateQueryParams(location, history, {date: formatDate(today)}, false);
	}

	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);

		const date = query.get('date') || formatDate(new Date());
		store.dispatch(todayInfoActions.fetchAll(date));
	}

	toggleVisibility(what, id) {
		const { state:S } = this;
		const key = `collapsed_${what}`;
		const is_in_array = S[key].indexOf(id) > -1;

		if (is_in_array) {
			this.setState({
				[key]: S[key].filter(arr_id => arr_id !== id)
			});
		} else {
			this.setState({
				[key]: [].concat(S[key], [id])
			});
		}
	}

	handleChange(date) {
		if (!date){
			return;
		}

		const { location, history } = this.props;
		let dateStr = formatDate(date);
		updateQueryParams(location, history, {date: dateStr});

		store.dispatch(todayInfoActions.fetchAll(dateStr));
	}

	getCount(items){
		return new Set(items.map( i => i.employee_id)).size;
	}

	render() {
		const { props:P, state:S } = this;
		let obj = parseData(P.todayInfo.items);

		const query = new URLSearchParams(this.props.location.search);
		const date = query.get('date') || formatDate(new Date());
		let tableHead = [
			{name: 'full_name', sk_name: 'Meno', type: 'string'},
			{name: 'order_name', sk_name: 'Názov zákazky', type: 'string'},
			{name: 'order_serial_number', sk_name: 'Číslo zákazky', type: 'string'},
		];

		if (P.permissions.working_hours > 0) {
			tableHead.splice(
				2,
				0,
				{name: 'start', sk_name: 'Príchod', type: 'time'},
				{name: 'end', sk_name: 'Odchod', type: 'time'}
			);
		}

		let table_items = [];
		if (P.todayInfo.items.length === 0) {
			table_items =  <NoDataInfo />;
		} else {
			table_items =
				Object.values(obj)
					.sort((a, b) => a.info.building_name.localeCompare(b.info.building_name))
					.map(b => {
						return (
							<div className="building" key={b.info.building_id}>
								<div
									onClick={() => this.toggleVisibility('buildings', b.info.building_id)}
									className="info"
								>
									<span>{b.info.building_name}</span>
									<span>{b.info.building_address}</span>
									<span>Počet ľudí:  {this.getCount(b.children)}</span>
								</div>
								<div
									className={
										classnames('children',
											{hidden: S.collapsed_buildings.indexOf(b.info.building_id) < 0}
										)}>
									<SuperTable
										name={'employees'}
										thead={tableHead}
										items={b.children}
										searchable={false}
										showPages={false}
										showRowNumbers={true}
										defaultSortKey={'order_serial_number'}
										treeStructure={false}
										onRowClick={id => this.props.history.push(`/employees/detail/${parseInt(id)}`)}
									/>
								</div>
							</div>
						);
					});
		}

		const loading = P.todayInfo.isFetching;
		if (loading) {
			table_items = <Spinner/>;
		}

		return (
			<Container className="today_info">
				<div className="choose-employee-form">
					<Row>
						<Col md={3}>
							<h4>Zvoľte dátum</h4>
						</Col>
						<Col md={3}>
							<DatePicker
								selected={new Date(date)}
								onChange={date => this.handleChange(date)}
								todayButton={'Dnes'}
								locale={'sk'}
								calendarStartDay={1}
								dateFormat="d MMMM yyyy"
								dropdownMode={'select'}
								showYearDropdown
								showMonthDropdown
							/>
						</Col>
					</Row>
				</div>
				{table_items}
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		todayInfo: state.todayInfo,
		permissions: state.permissions,
	};
};

export default withRouter(connect(mapStateToProps)(TodayWorkContainer));
