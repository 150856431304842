import React, { PureComponent } from 'react';
import PT from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';

class Form extends PureComponent {
	render() {
		return (
			<Row>
				<Col md={12}>
					<Button
						onClick={this.props.onCreateClick}
						variant="success"
					>
						Pridať kategóriu
					</Button>
				</Col>
			</Row>
		);
	}
}

Form.propTypes = {
	onCreateClick: PT.func.isRequired,
};

export default Form;
