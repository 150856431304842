import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Col, Button, Row } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import ImageEditor from 'components/ImageEditor';
import {FormCreatableSelect} from '../../components/CustomSelect';
import FormCheckBox from '../../components/CheckBox';

class RegisterForm extends React.Component { 

    constructor(props) {
        super(props);

        let countries = ['SK', 'CZ', 'PL', 'HU'];
        countries = countries.map( (c, i) => ({
            id: i,
            name: c,
        }));

        this.formRef = React.createRef();
        this.state = {
            form: {
                active: true
            },
            photo: null,
            formSubmitted: false,
            validationErrors: {},
            countries: countries,
            selectedCountry: countries[0]
        };
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'FormCheck' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            form: Object.assign({},this.state.form , {[name]: value})
        });
    }

    handleSubmit(model) {
        this.setState({
            formSubmitted: true,
        });
        if (this.validateForm(model)){
            return;
        }

        let {country, ...obj} = model;

		let newObj = {
            ...obj,
            photo: this.state.photo,
            country: country.name
        };

        this.props.onSubmit(newObj);
    }

    validateForm(values) {
        let validations = {
            name: 'Vyplňte názov',
            address: 'Vyplňte adresu',
            country: 'Vyplňte krajinu',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]){
                errors[key] = validations[key];
            }
        });

        if (values.active === false && this.props.hasActiveOrders){
            errors['active'] = 'Táto budova obsahuje aktívne zákazky!';
        }

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    render() {
		const { defaultValues } = this.props;
		const { state: S} = this;

		let countries = S.countries;
		let selectedCountry = S.countries[0];
		if (defaultValues && defaultValues.country){
            selectedCountry = S.countries.find( c => c.name === defaultValues.country);
            if (!selectedCountry) {
                selectedCountry = {
                    id: S.countries.length,
                    name: defaultValues.country
                };
                countries.push(selectedCountry);
            }
        }

		return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.props.onClose}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        ref={this.formRef}
                        className="register-form"
                        onValidSubmit={ model => this.handleSubmit(model)}
                        onInvalidSubmit={ model => this.handleSubmit(model)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                    >
                        <Row>
                            <Col lg={8} md={12} sm={12} xs={12} >
                                <Input
                                    name="name"
                                    label="Názov *"
                                    value={defaultValues.name || ''}
                                    isValid={ val => Boolean(val)}
                                    formNoValidate
                                    isFormSubmitted={ () => S.formSubmitted}
                                />
                                <Input
                                    name="address"
                                    label="Adresa *"
                                    value={defaultValues.address|| ''}
                                    isValid={ val => Boolean(val)}
                                    formNoValidate
                                    isFormSubmitted={ () => S.formSubmitted}
                                />
                                <FormCreatableSelect
                                    options={ countries }
                                    getOptionValue={ c => c.id}
                                    getOptionLabel={ c => c.name}
                                    name={'country'}
                                    label={'Krajina'}
                                    required={true}
                                    value={ selectedCountry }
                                    isFormSubmitted={ () => S.formSubmitted}
                                    onCreate={ val => {
                                        let new_id = S.countries.length;
                                        this.setState({
                                            countries: [...S.countries, {id: new_id, name: val}]
                                        });
                                    }}
                                />
                                <Input
                                    name="comment"
                                    label="Komentár"
                                    value={defaultValues.comment || ''}
                                />
                                <FormCheckBox
                                    name="active"
                                    label="Aktívna"
                                    value={defaultValues.active}
                                />
                            </Col>
                            <Col
                                xs={{span: 12, offset: 0}}
                                sm={{span: 12, offset: 3}}
                                md={{span: 12, offset: 3}}
                                lg={{span: 4, offset: 0}}
                            >
                            <ImageEditor
                                photo={defaultValues.photo || ''}
                                isZoomable={false}
                                onChange={file => this.setState({photo: file})}
                            />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Col
                                sm={{ span: 9, offset: 3}}
                                lg={{span: 10, offset: 2}}
                            >
                            <Button
                                type="submit"
                                variant="success"
                            >
                                { this.props.updating ? 'Upraviť' : 'Vytvoriť' }
                            </Button>
                            </Col>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}


RegisterForm.propTypes = {
    show: PropTypes.bool,
    onSubmit: PropTypes.func,
    updating: PropTypes.bool.isRequired,
    defaultValues: PropTypes.object,
	onClose: PropTypes.func,
	hasActiveOrders: PropTypes.bool.isRequired,
};

RegisterForm.defaultProps = {
    show: false,
    onSubmit: () => {},
    onClose: () => {},
    defaultValues: {
        name: '',
        address: '',
        comment: '',
        active: true,
        photo: '/storage/buildings/default.jpeg'
    }
};

export default RegisterForm;