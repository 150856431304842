import mapFunction from './mapResource';

export default mapFunction('employee', {
    isFetching: false,
    data: {
        id: 0,
        first_name: '',
        last_name: '',
        curr_card: '-',
        birthday: '-',
        phone_number: '-',
        username: '-',
        photo: null
    } 
});