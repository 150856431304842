import mapFunction from './mapResource';

export default mapFunction('storecard', {
    isFetching: false,
    data: {
        id: null,
        name: '',
        code: '',
    }
});
