import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import employeesActions from 'actions/employees';
import { FormSelect } from 'components/CustomSelect';
import store from 'store';
import FormCheckBox from 'components/CheckBox';

class TabletForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validationErrors: {},
            formSubmitted: false
        };
    }

    isTabletUniq(value) {
        if (this.props.updating) 
            return true;

        return !this.props.tablets.some(t => t.serial_num === value);
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)){
            return;
        }
        let {owner, ...obj} = model;

        let newObj = {
            ...obj,
            owner_id: owner.id,
        };

        this.props.onSubmit(newObj);
    }

    componentDidMount() {
        if (this.props.employees.length === 0) {
            store.dispatch(employeesActions.fetchAll());
        }
    }

    validateForm(values) {
        let validations = {
            name: 'Vyplňte názov',
            serial_num: 'Vyplňte sériové číslo',
            owner: 'Zvoľte vlastníka',
            login_name: 'Vyplňte prihlasovacie meno',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]){
                errors[key] = validations[key];
            }
        });

        if (!this.isTabletUniq(values.serial_num)){
            errors['serial_num'] = 'Toto sériové číslo tabletu už existuje';
        }

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    render() {
        const ownersOptions = this.props.employees.filter( emp => {
            return emp.active;
        }).sort((a, b) => a.full_name.localeCompare(b.full_name));

        const { props: P, state: S } = this;
        const { defaultValues: tablet } = this.props;

        let defaultOwner = null;
        if (tablet && tablet.owner_id && P.employees){
            defaultOwner = P.employees.find(e => e.id === tablet.owner_id);
        }

         return (
            <Modal
                show={this.props.show}
                onHide={this.props.onClose}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={model => this.submitHandler(model)}
                        onInvalidSubmit={ model => this.submitHandler(model)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                    >
                        <Input 
                            label="Názov *"
                            name="name" 
                            value={tablet.name}
                            //required
                        />
                        <Input 
                            label="Sériové číslo"
                            name="serial_num" 
                            value={tablet.serial_num}
                            //required
                            validations={{
                                isUniq: this.isTabletUniq.bind(this)
                            }}/>
                        <Input 
                            label="IMEI"
                            name="imei" 
                            value={tablet.imei}/>
                        <Input 
                            label="Prihlasovacie meno"
                            name="login_name" 
                            value={tablet.login_name}
                            //required
                        />
                        <Input 
                            label="Prihlasovacie heslo"
                            name="password"
                            type="password"
                            />
                        <FormSelect
                            name={'owner'}
                            label={'Vlastník'}
                            options={ownersOptions}
                            getOptionValue={ o => o.id}
                            getOptionLabel={ o => o.full_name}
                            value={defaultOwner}
                            isFormSubmitted={ () => S.formSubmitted}
                            required={true}
                            isClearable={true}
                        />
						<FormCheckBox
                            label="Overovanie dochádzky"
                            name="authentication"
                            value={Boolean(tablet.authentication)}
                        />
                        <Row>
                            <Col md={{span: 8, offset: 4}}>
                                <Button variant="success" type="submit">
                                    { this.props.updating ? 'Upraviť' : 'Vytvoriť' }
                                </Button>
                            </Col>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

TabletForm.propTypes = {
    tablets: PropTypes.array,
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    updating: PropTypes.bool.isRequired,
    defaultValues: PropTypes.object,
    employees: PropTypes.array.isRequired
};

TabletForm.defaultProps = {
    tablets: [],
    onClose: () => {},
    defaultValues : {
        name: '',
        imei: '',
        login_name: '',
        serial_num: '',
        password: '',
        owner_id: ''
    }
};

export default TabletForm;