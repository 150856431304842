import React from 'react';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import NeedWritePerm from 'components/NeedPermission';
import ImageEditor from 'components/ImageEditor';
import PT from 'prop-types';
import FormCheckBox from 'components/CheckBox';

const defaultValues = {
    id: 0,
    name: '',
    description: '',
    photo: '/storage/operations/default.jpg',
    difficulty_level: 1,
    overhead: false,
    unit: '',
};

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validationErrors: {},
            photo: null,
        };
    }

    onSubmit(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)){
            return;
        }

        let newObj = {
            ...model,
            photo: this.state.photo,
        };

        this.props.onSubmit(newObj);
    }

    validateForm(values) {
        let validations = {
            name: 'Zvoľte názov',
            difficulty_level: 'Zvoľte náročnosť',
            unit: 'Zvoľte mernú jednotku',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]) {
                errors[key] = validations[key];
            }
        });

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    render() {
        const { props:P, state:S } = this;
        let DV = {};

        Object.keys(defaultValues).forEach(key => {
            DV[key] = P.updating ? (P.operation[key] || defaultValues[key]) : defaultValues[key];
        });

        return (
            <Modal
                show={P.show}
                className="input-form"
                onHide={P.onClose}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={ obj => this.onSubmit(obj)}
                        onInvalidSubmit={ obj => this.onSubmit(obj)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                    >
                        <Input
                            name="name"
                            label="Názov operácie *"
                            //required
                            value={DV.name}
						/>
                        <Textarea
                            style={{width: '100%', display: 'block'}}
                            name="description"
                            label="Popis"
                            value={DV.description}
                        />
                        <Input
                            name="unit"
                            label="Merná jednotka"
                            //required
                            value={DV.unit}
                        />
                        <Input
                            min={1}
                            max={100}
                            type="number"
                            name="difficulty_level"
                            label="Náročnosť"
                            //required
                            value={String(DV.difficulty_level)}
                        />
                        <Row className={'form-group form-row'}>
                            <label className="col-form-label col-sm-4">Ikona</label>
                            <Col sm={8}>
                                <ImageEditor
                                    photo={DV.photo}
                                    isZoomable={false}
                                    onChange={ file => this.setState({photo: file})}
                                    size={70}
                                />
                            </Col>
                        </Row>
                        <FormCheckBox
                            name="overhead"
                            label="Réžia"
                            value={DV.overhead}
                        />
                        <Col md={{span: 8, offset: 4}}>
                            <NeedWritePerm type="button" need={['operation_types']}>
                                <Button
                                    disabled={S.buttonDisabled}
                                    type="submit"
                                    variant="success"
                                >
                                    { P.updating ? 'Upraviť' : 'Vytvoriť'}
                                </Button>
                            </NeedWritePerm>
                        </Col>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    operation: PT.object,
};

Form.defaultProps = {
    operation: {}
};

export default Form;