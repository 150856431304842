import React from 'react';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';
import store from 'store/mainStore';
import buildingsActions from 'actions/buildings';
import ordersActions from 'actions/orders';
import toolsAtBuildingsActions from 'actions/tools_at_buildings';
import buildingsWithPerformancesActions from 'actions/buildings_with_performances';
import buildingIssueStates from 'actions/building_issue_states';
import buildingIssues from 'actions/building_issues';
import defaultOperationsActions from 'actions/default_entity_operations';
import entityTypesActions from 'actions/entity_types';
import employees from 'actions/employees';
import View from './View';
import BuildingForm from '../RegisterForm';
import {buildingHasActiveOrders} from '../utils';
import Spinner from 'components/Spinner';
import {withRouter} from 'react-router-dom';
import {isNotEmpty} from 'scripts/valueNormalizer';
import operationTypesActions from 'actions/work_operations';
import buildingEquipmentActions from 'actions/building_equipment';

class Detail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: parseInt(props.match.params.id, 10),
            data: {},
            buildingFormActive: false
        };
    }

    buildingSubmitHandler(obj) {
        const formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }

        formData.append('_method', 'PUT');
        let id = parseInt(this.props.match.params.id, 10);

        store.dispatch(buildingsActions.update(id, formData, {
            stringify: false,
            contentType: false,
            method: 'POST'
        })).then((data) => {
            if (data.status === 'ok') {
                this.setState({
                    buildingFormActive: false
                });
            }
        });
    }

    componentDidMount() {
        store.dispatch(buildingsActions.fetchConcrete(this.state.id));

        if (this.props.orders.length === 0) {
            store.dispatch(ordersActions.fetchAll());
        }

        const building_id = parseInt(this.props.match.params.id, 10);
        store.dispatch(toolsAtBuildingsActions.fetchAll({id: this.state.id}));
        store.dispatch(defaultOperationsActions.fetchAll({id: this.state.id}));
        store.dispatch(entityTypesActions.fetchAll());
        store.dispatch(operationTypesActions.fetchAll());

        store.dispatch(buildingsWithPerformancesActions.fetchAll());
        store.dispatch(buildingIssues.fetchAll({building_id}));
        store.dispatch(buildingEquipmentActions.fetchAll({building_id}));
        store.dispatch(employees.fetchAll());
        store.dispatch(buildingIssueStates.fetchAll());
    }

    onDeleteBuilding() {
        store.dispatch(buildingsActions.delete(this.props.building.id))
            .then(data => {
                if (data.status === 'ok') {
                    this.props.history.push('/buildings');
                }
            });
    }

    render() {
        const {props: P, state: S} = this;
        const id = parseInt(P.match.params.id, 10);
        const building = P.building;

        return (
            <Container className="building-detail" style={{paddingBottom: 50}}>
                {
                    (building) ?
                        (<div>
                            <View
                                data={P.building}
                                id={id}
                                toolsAtBuildings={P.usedTools}
                                orders={P.orders.filter(o => o.building_id === id)}
                                employees={P.employees}
                                buildingIssues={P.buildingIssues}
                                issueStates={P.issueStates}
                                onBuildingEdit={() => this.setState({buildingFormActive: true})}
                                onDeleteBuilding={() => this.onDeleteBuilding()}
                                permissions={P.permissions}
                                buildingHasPerformance={P.buildingHasPerformance}
                                entity_types={P.entity_types}
                                default_operations={P.default_operations}
                                operation_types={P.operation_types}
                                building_equipment={P.building_equipment}
                            />
                            <BuildingForm
                                show={S.buildingFormActive}
                                defaultValues={building}
                                onSubmit={obj => this.buildingSubmitHandler(obj)}
                                onClose={() => this.setState({buildingFormActive: false})}
                                hasActiveOrders={buildingHasActiveOrders(building, P.orders)}
                                updating
                            />
                        </div>
                        ) : <Spinner/>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);

    return {
        orders: state.orders.items,
        employees: state.employees.items,
        building: state.buildings.items.find(b => b.id === id),
        buildingHasPerformance: state.buildingsWithPerformances.items.some(b => b.id === id),
        buildingIssues: state.buildingIssues.items,
        usedTools: state.toolsAtBuildings.items,
        issueStates: state.buildingIssueStates.items,
        entity_types: state.entity_types.items,
        operation_types: state.workOperations.items,
        default_operations: state.default_entity_operations.items,
        building_equipment: state.building_equipment.items,
        permissions: state.permissions,
        loading: (
            state.buildings.isFetching ||
            state.orders.isFetching ||
            state.employees.isFetching ||
            state.toolsAtBuildings.isFetching ||
            state.buildingIssues.isFetching ||
            state.buildingIssueStates.isFetching
        ),
    };
};

export default withRouter(connect(mapStateToProps)(Detail));
