import React from 'react';
import PropTypes from 'prop-types';
import { SuperTable } from 'components/PaginationTable';

class HistoryOwners extends React.Component {

    render() {
        return (
            <div>
                <SuperTable
                    name={'history_owners'}
                    thead={[
                        {name: 'full_name', sk_name: 'Meno', type: 'string'},
                        {name: 'from_date', sk_name: 'Dátum prevzatia', type: 'date-time'}
                    ]}
                    columnSelector
                    items={this.props.owners}
                    onRowClick={id => this.props.onClick(id)} />
            </div>
        );
    }
}

HistoryOwners.propTypes = {
    owners: PropTypes.array.isRequired,
    onClick: PropTypes.func
};

HistoryOwners.defaultProps = {
    onClick: () => {}
};

export default HistoryOwners;
