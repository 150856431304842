import React, { useState } from 'react';
import PT from 'prop-types';
import {SuperTable} from 'components/PaginationTable';
import EmployeeOrderForm from './EmployeeOrderForm';
import store from 'store/mainStore';
import employee_order from 'actions/employee_orders';
import {Button} from 'react-bootstrap';


EmployeeOrders.propTypes = {
    employeeOrder: PT.arrayOf(PT.object).isRequired,
    permissionValue: PT.number.isRequired,
    employee_id: PT.number.isRequired,
    isMyProfile: PT.bool,
};

function createSelectors() {
    return [
        {
            placeholder: 'Zobraziť aktívne',
            name: 'active_selector',
            default_value: 1,
            values: [
                {
                    value: 0,
                    name: 'Zobraziť všetky',
                    filter: () => true
                },
                {
                    value: 1,
                    name: 'Zobraziť aktívne',
                    filter: obj => !obj.date_to
                }
                ],
            isMulti: false,
        },
    ];
}

function EmployeeOrders({employeeOrder, employee_id, orders, permissionValue}) {
    let [showForm, setFormShow] = useState(false);
    let [obj, setObj] = useState({});

    return (
        <div>
            {
                permissionValue === 2 ?
                    <Button
                        style={{marginTop: 15}}
                        onClick={() => setFormShow(true)}
                        variant='success'
                    >
                        Pridať
                    </Button> : null
            }
            <SuperTable
                name={'employee-employee-order'}
                thead={[
                    {name: 'order_serial', sk_name: 'Číslo zak.', type: 'string'},
                    {name: 'order_name', sk_name: 'Názov zákazky', type: 'string'},
                    {name: 'date_from', sk_name: 'Od', type: 'date'},
                    {name: 'date_to', sk_name: 'Do', type: 'date'},
                ]}
                items={employeeOrder}
                selectors={createSelectors()}
                searchable={true}
                editOps={true}
                delOps={true}
                onEditItem={(id) => {
                    setObj(employeeOrder.find(e => e.id === id));
                    setFormShow(true);
                }}
                onDeleteItem={id => store.dispatch(employee_order.delete(id))}
            />
            <EmployeeOrderForm
                show={showForm}
                onSubmit={(id, obj, updating) => {
                    store.dispatch(updating ? employee_order.update(id, obj) : employee_order.create(obj));
                    setFormShow(false);
                    setObj({});
                }}
                onClose={() => {
                    setFormShow(false);
                    setObj({});
                }}
                employee_id={employee_id}
                updating={Object.keys(obj).length > 0}
                objectToUpdate={obj}
                orders={orders}
            />
        </div>
    );
}

export default EmployeeOrders;
