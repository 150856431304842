import React from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import {Row, Col, Button } from 'react-bootstrap';
import DF from 'scripts/dateFormatter';
import NeedWritePermission from 'components/NeedPermission';
import ImageViewer from 'components/ImageViewer';
import { ConfirmModal } from 'components/modals';
import NeedWritePerm from 'components/NeedPermission';
import FilesForm from '../../components/FilesForm';
import call from '../../ajax/call';

class MainInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            confirm_show: false,
            confirm_message: '',
            files: [],
        };
    }

    getPhotoUrl(photo) {
        if (!photo) {
            return null;
        }
        return '/api/documents/' + photo.file_name;
    }

    componentDidMount() {
        if (this.props.data.id) {
            this.fetchFiles(this.props.data.id);
        }
    }

    fetchFiles(entity_id) {
        this.setState({loading_files: true});

        call(`/api/building_equipment/${entity_id}/files`, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.setState({
                        files: data.data,
                        loading_files: false,
                    });
                }
            });
    }

    render() {
        const data = this.props.data;
        const {state: S} = this;

        return (
            <div>
                <Row>
                    <Col xs={12} className="text-center" style={{ marginBottom: 25 }}>
                        <ImageViewer
                            thumbnail={this.getPhotoUrl(data.photo)}
                            full_size={this.getPhotoUrl(data.photo)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Názov</Col>
                    <Col xs={6} className="text-left">{data.name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Typ</Col>
                    <Col xs={6} className="text-left">{data.type}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Poznámka</Col>
                    <Col xs={6} className="text-left">{data.note}</Col>
                </Row>

                <Row>
                    <Col xs={6} className="text-right">Dátum inštalácie</Col>
                    <Col xs={6} className="text-left">{DF(data.installation_date, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Dátum uvedenia do prevádzky</Col>
                    <Col xs={6} className="text-left">{DF(data.putting_into_operation_date, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Uviedol do prevádzky</Col>
                    <Col xs={6} className="text-left">
                        {
                            data.put_into_operation_by ?
                                <Link to={`/employees/detail/${data.put_into_operation_by}`}>
                                    {data.put_into_operation_by_name}
                                </Link>
                                :
                                null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Dátum poslednej revízie</Col>
                    <Col xs={6} className="text-left">{DF(data.revision_date, false)}</Col>
                </Row>

                <Row style={{ marginTop: 22 }}>
                    <Col xs={12} className="text-center">
                        <NeedWritePermission type="button" need={['buildings']}>
                            <Button
                                variant="success"
                                onClick={() => this.props.onEdit(data.id)}
                                style={{ marginRight: 10 }}
                            >
                                Upraviť
                            </Button>
                        </NeedWritePermission>
                        <NeedWritePerm type="button" need={['buildings']}>
                            <Button
                                style={{marginLeft: 15}}
                                variant="danger"
                                onClick={() => this.setState({
                                    confirm_show: true,
                                })}
                            >
                                Vymazať
                            </Button>
                        </NeedWritePerm>
                    </Col>
                </Row>
                <div style={{
                    marginTop: 15,
                    marginBottom: 15,
                    textAlign: 'left'
                }}>
                    <FilesForm
                        heading={'Súbory'}
                        files={this.state.files}
                        files_url={`/api/building_equipment/${data.id}/files`}
                        editable={this.props.permissions.buildings === 2}
                        afterChange={() => this.fetchFiles(data.id)}
                        loading={this.state.loading_files}
                    />
                </div>

                <ConfirmModal
                    message={'Vymazať zariadenie ' + data.name}
                    show={S.confirm_show}
                    onClose={() => this.setState({ confirm_show: false })}
                    onAccept={ () => this.props.onDelete(data.id)}
                    acceptTitle="Vymazať"
                />

            </div>
        );
    }
}

MainInfo.propTypes = {
    tool: PT.object,
    parent: PT.object,
    onEdit: PT.func,
    onDelete: PT.func,
    onCreate: PT.func,
    onCopy: PT.func,
    permissions: PT.object.isRequired
};

MainInfo.defaultProps = {
    data: {},
    onEdit: () => {},
    onDelete: () => {},
};

export default MainInfo;
