import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SuperTable} from 'components/PaginationTable';
import empTrainingsActions from 'actions/employee_trainings';
import store from 'store/mainStore';
import NeedWritePermission from '../../../../components/NeedPermission';
import { withRouter } from 'react-router-dom';

class Trainings extends React.Component {

    componentDidMount() {
        store.dispatch(empTrainingsActions.fetchAll({
            id: this.props.employeeId
        }));
    }

    render() {
        const { props:P } = this;
        const items = P.trainings.map(c => ({
            ...c,
            type: c.type.name
        }));

        if (!items.length){
            return null;
        }

        return (
            <div style={{ paddingTop: 40 }}>
                <h4>
                    Hromadné školenia
                </h4>
                <NeedWritePermission
                    need={['certifications']}
                    type={'table'}
                >
                <SuperTable
                    name={'trainings'}
                    thead={[
                        {name: 'event_name', sk_name: 'Názov', type: 'string'},
                        {name: 'event_time', sk_name: 'Dátum konania', type: 'date-time'},
                        {name: 'valid_from', sk_name: 'Platné od', type: 'date'},
                        {name: 'valid_to', sk_name: 'Platné do', type: 'date'},
                        {name: 'type', sk_name: 'Typ osvedčenia', type: 'string'},
                        {name: 'document', sk_name: 'Dokument', type: 'file'}
                    ]}
                    columnSelector
                    items={items}
                    onRowClick={id => this.props.history.push(`/trainings/${id}`)}
                />
                </NeedWritePermission>
            </div>
        );
    }
}

Trainings.propTypes = {
   employeeId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        certificationTypes: state.certificationTypes.items,
        trainings: state.employee_trainings.items
    };
};

export default withRouter(connect(mapStateToProps)(Trainings));
