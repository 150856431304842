import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import {Button, Row} from 'react-bootstrap';
import Tern from 'components/Or';
import PropTypes from 'prop-types';

const defaultPhoto = '/storage/default.jpeg';

class ImageEditor extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.editorRef = React.createRef();

        this.state = {
            editorImage: null,
            loadedFromFile: false,
            imgScaled: 1,
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        this.inputRef = null;
        this.editorRef = null;
    }

    getBlob(key, obj) {
        return new Promise((resolve) => {
            if (this.mounted && this.state.loadedFromFile) {
                let canvas = this.editorRef.current.getImage();
                canvas.toBlob((blob) => {
                    let newObj = Object.assign({}, obj, {
                        [key]: blob
                    });
                    resolve(newObj);
                }, 'image/jpeg', 1.0);
            } else {
                resolve(obj);
            }
        });
    }

    handlePhotoFileLoad(e) {
        if (e.target.files.length === 0){
            return;
        }

        let file = e.target.files[0];
        if (!file.type.match(/image.*/)) {
            return;
        }

        if (this.mounted) {
            this.setState({
                editorImage: file,
                loadedFromFile: true
            });
            this.props.onChange(file);
        }
    }

    getPhoto() {
        if (this.state.editorImage){
            return this.state.editorImage;
        }

        return this.props.photo ? this.props.photo : defaultPhoto;
    }

    render () {
        const {state: S, props: P} = this;

        return <div style={{ marginTop: '5px'}}>
            <AvatarEditor
                image={this.getPhoto()}
                ref={this.editorRef}
                width={P.size}
                height={P.size}
                border={5}
                color={[0,0,0, 0.25]} // RGBA
                scale={S.imgScaled}
            />
            <Row style={{marginLeft: 0, marginBottom: 5}}>
                <span className={'px-0'}>
                <Button
                    variant={'primary'}
                    style={{marginRight: '5px'}}
                    onClick={() => {this.inputRef.current.click();}}
                >
                    Vybrať
                </Button>
                <input
                    ref={this.inputRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={e => this.handlePhotoFileLoad(e) }
                    accept={'image/*'}
                />
                </span>
                <Tern expression={P.isZoomable && S.loadedFromFile}>
                    <Button
                        variant={'outline-primary'}
                        className={'scaleButton'}
                        onClick={ () => {
                            if (this.mounted) {
                                this.setState({
                                    imgScaled: S.imgScaled + 0.2
                                });
                            }
                        }}>+</Button>
                </Tern>
                <Tern expression={P.isZoomable && S.loadedFromFile}>
                    <Button
                        variant={'outline-primary'}
                        className={'scaleButton'}
                        onClick={ () => {
                            if (this.mounted) {
                                this.setState({
                                    imgScaled: S.imgScaled - 0.2
                                });
                            }
                        }}>-</Button>
                </Tern>
            </Row>
        </div>;
    }
}

ImageEditor.propTypes = {
    onChange: PropTypes.func,
    isZoomable: PropTypes.bool.isRequired,
    size: PropTypes.number,
};

ImageEditor.defaultProps = {
    onChange: () => {},
    isZoomable: false,
    size: 150,
};

export default ImageEditor;
