import { SET_IDENTITY, UNSET_IDENTITY } from 'actions/identity';

let defaultState = {
    name: '',
    id: 0
};

let localIdentity = JSON.parse(localStorage.getItem('identity'));

if (localIdentity) {
    defaultState = localIdentity;
}

function identity(state=defaultState, action) {
    switch (action.type) {
        case SET_IDENTITY: return Object.assign({}, state, action.obj);
        case UNSET_IDENTITY: return defaultState;
        
        default: return state;
    }
}

export default identity;
