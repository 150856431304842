import React from 'react';
import CreatableSelect from 'react-select/creatable/dist/react-select.esm';
import Select from 'react-select';
import {createFilter} from 'react-select';
import { withFormsy } from 'formsy-react';
import PT from 'prop-types';
import classNames from 'classnames';
import { Row } from 'react-bootstrap';

class MyCreatableSelect extends React.Component {
    changeValue(val) {
        this.props.setValue(val);
    }

    isValidNewOption(inputValue, selectValue, selectOptions) {
        return (inputValue.trim().length > 0 && !selectOptions.find(option => option.name === inputValue));
    }

    static formatCreateLabel(val) {
        return `Vytvoriť: ${val}`;
    }

    static getNewOptionData(inputValue, optionLabel) {
        return {
            id: inputValue,
            name: optionLabel,
        };
    }

    render() {
        const errorMessage = this.props.errorMessage;
        const {props: P} = this;
        const show_error = !P.isValid && (!P.isPristine || P.isFormSubmitted);

        return <Row className={'mb-2'}>
            <label className={
                classNames({
                    'col-form-label': true,
                    'col-sm-4': true,
                    'text-danger': Boolean(!P.isValid)
                })}>
                {P.label}
                {P.required ? <span className={'required-symbol'}> *</span> : null }
            </label>
            <div className={'col-sm-8'}>
                <CreatableSelect
                    name={'order'}
                    options={P.options}
                    isClearable
                    filterOption={createFilter(P.filterOptions || {})}
                    isValidNewOption={ P.isValidNewOption || this.isValidNewOption }
                    value={P.value}
                    getOptionValue={option => P.getOptionValue(option)}
                    getOptionLabel={option => P.getOptionLabel(option)}
                    onChange={ val => this.changeValue(val)}
                    formatCreateLabel={ P.formatCreateLabel || MyCreatableSelect.formatCreateLabel}
                    getNewOptionData={P.getNewOptionData || MyCreatableSelect.getNewOptionData}
                    isDisabled={P.isDisabled}
                    placeholder={P.placeholder || 'Vyberte...'}
                    isSearchable={P.searchable}
                />
                { show_error ?
                    <span
                        className={'invalid-feedback'}
                        style={{display: 'block'}}
                    >
                        { errorMessage }
                    </span> : null
                }
            </div>
        </Row>;
    }
}

MyCreatableSelect.propTypes = {
    options: PT.arrayOf(PT.object).isRequired,
    getOptionName: PT.func,
    getOptionLabel: PT.func,
    onCreate: PT.func.isRequired,
    isDisabled: PT.bool,
    filterOptions: PT.object,
    searchable: PT.bool
};

MyCreatableSelect.defaultProps = {
    isDisabled: false,
    searchable: true,
    filterOptions: {},
    getOptionValue: o => o.id,
    getOptionLabel: o => o.name,
};

export const FormCreatableSelect = withFormsy(MyCreatableSelect);

class MySelect extends React.Component {
    changeValue(val) {
        this.props.setValue(val);
        if (this.props.onChange) {
            this.props.onChange(val);
        }
    }

    render() {
        const {props: P} = this;
        const errorMessage = this.props.errorMessage;

        const show_error = !P.isValid && (!P.isPristine || P.isFormSubmitted);

        return <Row className={'mb-2'}>
            <label className={
                classNames({
                    'col-form-label': true,
                    'col-sm-4': true,
                    'text-danger': show_error
                })}>
                {P.label}
                {P.required ? <span className={'required-symbol'}> *</span> : null }
            </label>
            <div
                className={'col-sm-8'}
                style={{
                    ...P.style
                }}
            >
                <Select
                    options={P.options}
                    isClearable={P.isClearable}
                    filterOption={createFilter(P.filterOptions || {})}
                    value={P.value}
                    getOptionValue={option => P.getOptionValue(option)}
                    getOptionLabel={option => P.getOptionLabel(option)}
                    onChange={ val => this.changeValue(val)}
                    isDisabled={P.isDisabled}
                    placeholder={P.placeholder || 'Vyberte...'}
                    noOptionsMessage={() => 'Žiadne možnosti'}
                    isMulti={P.isMulti || false}
                    isSearchable={P.searchable}
                />
                { show_error ?
                    <span
                        className={'invalid-feedback'}
                        style={{display: 'block'}}
                    >
                        { errorMessage }
                    </span> : null
                }
            </div>
        </Row>;
    }
}

MySelect.propTypes = {
    options: PT.arrayOf(PT.object).isRequired,
    getOptionValue: PT.func,
    getOptionLabel: PT.func,
    isDisabled: PT.bool,
    filterOptions: PT.object,
    onChange: PT.func,
    style: PT.object,
    searchable: PT.bool
};

MySelect.defaultProps = {
    isDisabled: false,
    isClearable: false,
    searchable: true,
    getOptionValue: o => o.id,
    getOptionLabel: o => o.name,
    filterOptions: {},
    onChange: () => {},
    style: {},
};

export const FormSelect = withFormsy(MySelect);
