import React from 'react';
import { connect } from 'react-redux';
import {Col, Container, Row, Button} from 'react-bootstrap';
import store from 'store/mainStore';
import NeedWritePerm from 'components/NeedPermission';
import trainingActions from 'actions/trainings';
import DF from 'scripts/dateFormatter';
import CertificationTypesActions from 'actions/certification_types';
import EmployeesActions from 'actions/employees';
import { SuperTable } from 'components/PaginationTable';
import InsertForm from './addForm';
import call from 'ajax/call';
import EditForm from '../EditForm';

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            form2Active: false,
            id: parseInt(props.match.params.id, 10),
        };
    }

    componentDidMount() {
        store.dispatch(trainingActions.fetchAll());
        store.dispatch(CertificationTypesActions.fetchAll());
        store.dispatch(EmployeesActions.fetchAll());
    }

    submitHandler(obj) {
        let formData = new FormData();
        for (let key of Object.keys(obj)){
            formData.append(key, obj[key]);
        }
        formData.append('_method', 'PUT');

        store.dispatch(trainingActions.update(this.state.id, formData, {
            stringify: false,
            contentType: false,
            method: 'POST',
        })).then((data) => {
            if (data.status === 'ok') {
                this.setState({
                    formActive: false,
                    form2Active: false,
                });
            }
        });
    }

    insertCertifications(model){
        model = {
            training_id: this.state.id,
            ...model,
        };

        call('/api/trainings/add_employees', {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(model)})
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(trainingActions.fetchAll());
                    this.setState({
                        formActive: false,
                        form2Active: false,
                    });
                }
            });
    }

    deleteEmployee(id){
        call('/api/trainings/delete_employee', {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                training_id: this.state.id,
                employee_id: id,
            })})
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(trainingActions.fetchAll());
                    this.setState({
                        formActive: false,
                        form2Active: false,
                    });
                }
            });
    }

    render() {
        const { props: P, state: S } = this;

        if (!P.training){
            return <div>Loading...</div>;
        }

        let employees = P.employees.map( e => ({...e, full_name: e.last_name + ' ' + e.first_name}))
            .sort( (a, b) => a.full_name.localeCompare(b.full_name))
            .filter(e => (e.active && P.training.employees.every( em => e.id !== em.id)));

        return (
            <Container className="order-detail" style={{ paddingBottom: 50 }}>
                    <div>
                        <h2 style={{textAlign: 'center'}}>
                            {P.training.event_name}
                        </h2>
                        <Row>
                            <Col xs={6} className="text-right">Dátum školenia</Col>
                            <Col xs={6} className="text-left">{DF(P.training.event_time, false)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-right">Platnosť od</Col>
                            <Col xs={6} className="text-left">{DF(P.training.valid_from, false)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-right">Platnosť do</Col>
                            <Col xs={6} className="text-left">{DF(P.training.valid_to, false)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-right">Typ</Col>
                            <Col xs={6} className="text-left">{P.training.type.name}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-right">Počet ľudí</Col>
                            <Col xs={6} className="text-left">{P.training.employees.length}</Col>
                        </Row>
                        { P.training.document ?
                        <Row>
                            <Col xs={6} className="text-right">Dokument</Col>
                            <Col xs={6} className="text-left">
                                <a
                                    target="_blank"
                                    href={P.training.document}
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        variant={'primary'}
                                        //style={{height: 15}}
                                    >
                                        Stiahnuť
                                    </Button>
                                </a>
                            </Col>
                        </Row>  : null }

                        <div style={{
                            marginTop: 15,
                            textAlign: 'center',
                        }}>
                            <NeedWritePerm type="button" need={['certifications']}>
                                <Button
                                    variant="success"
                                    onClick={() => this.setState({form2Active: true})}
                                >
                                    Upraviť
                                </Button>
                            </NeedWritePerm>
                        </div>
                        <Row style={{
                            marginTop: 15
                        }}>
                            <h4>
                                Zamestnanci, ktorí sa zúčastnili školenia:
                            </h4>
                        </Row>
                        <NeedWritePerm type="button" need={['certifications']}>
                            <Button
                                variant="success"
                                onClick={() => {
                                    this.setState({
                                        formActive: true
                                    });
                                }}
                                //style={{ marginBottom: 15 }}
                            >
                                Pridať
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm type="table" need={['certifications']}>
                            <SuperTable
                                name={'employees'}
                                items={P.training.employees}
                                thead={[
                                    {name: 'thumbnail', sk_name: 'Fotka', type: 'image'},
                                    {name: 'last_name', sk_name: 'Priezvisko', type: 'string'},
                                    {name: 'first_name', sk_name: 'Meno', type: 'string'},
                                ]}
                                columnSelector
                                onDeleteItem={id => this.deleteEmployee(id)}
                                clickable
                                defaultSortKey='last_name'
                                onRowClick={id => this.props.history.push(`/employees/detail/${id}`)}
                                delOps
                                searchable
                                is_loading = {P.is_loading}
                            />
                        </NeedWritePerm>
                        <InsertForm
                            onClose={() => this.setState({formActive: false })}
                            show={S.formActive}
                            onSubmit={obj => this.insertCertifications(obj)}
                            employees={employees}
                        />
                        <EditForm
                            key={S.id}
                            show={S.form2Active}
                            onClose={() => this.setState({form2Active: false })}
                            onSubmit={(model) => this.submitHandler(model)}
                            updating={ true }
                            defaultValues={P.training}
                        />
                    </div>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);

    return {
        training: state.trainings.items.find(t => t.id === id),
        loading: state.trainings.isFetching,
        certificationTypes: state.certificationTypes.items,
        employees: state.employees.items,
    };
};

export default connect(mapStateToProps)(Detail);
