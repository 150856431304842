const MAP = {
    'ž' : 'z',
    'č' : 'c',
    'ľ' : 'l',
    'á' : 'a',
    'é' : 'e',
    'ť' : 't',
    'ň' : 'n',
    'ď' : 'd',
    'ĺ' : 'l',
    'ú' : 'u',
    'ô' : 'o',
    'š' : 's',
    'ý' : 'y',
    'í' : 'i',
    'ó' : 'o'
};


const convert = word => [...word].map(c => MAP[c] ? MAP[c] : c).join('');

function searcher(items, query, allowedColumns)
{
    query = convert(query.toLowerCase());

    if (items.length === 0) {
        return items;
    }

    //filter only available columns
    let allColumns = Object.keys(items[0]);
    let columns = allColumns.filter(column => allowedColumns.includes(column));

    //split query to words
    let queryWords = query.split(' ');

    return items.filter(row => {
        return columns.some(column => {
            //raw cell value in row and column
            const rawCellValue = convert(String(row[column]).toLowerCase());

            if (rawCellValue === null) {
                return false;
            }

            //cell value contains all of query words
            return queryWords.every(
                word =>
                rawCellValue.indexOf(word) > -1 ||
                rawCellValue.replace(/\s/g, '').indexOf(word) > -1
            );
        });
    });
}

export default searcher;