import React from 'react';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';
import store from 'store/mainStore';
import View from './view';
import buildingEquipmentActions from 'actions/building_equipment';
import employeesActions from 'actions/employees';

import Spinner from 'components/Spinner';
import {withRouter} from 'react-router-dom';
import ModalForm from 'components/ModalForm';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: parseInt(props.match.params.id, 10),
            formActive: false,
            objToUpdate: undefined,
        };
    }

    componentDidMount() {
        store.dispatch(buildingEquipmentActions.fetchConcrete(this.state.id));
        store.dispatch(employeesActions.fetchAll());
    }

    render() {
        const {props: P, state: S} = this;

        if (!P.building_equipment) {
            return <Spinner/>;
        }

        return (
            <Container className="building-detail" style={{paddingBottom: 50}}>
                <ModalForm

                    key={S.objToUpdate ? S.objToUpdate.id : 'new_item'}
                    show={S.formActive}
                    updatingObj={P.building_equipment}
                    action={buildingEquipmentActions}

                    afterSave={() => {
                        this.setState({
                            objToUpdate: undefined,
                            formActive: false,
                        });
                    }}

                    onClose={() => {
                        this.setState({
                            objToUpdate: undefined,
                            formActive: false,
                        });
                    }}

                    formElements={[
                        {name: 'name', label: 'Názov', type: 'text', required: true},
                        {name: 'type', label: 'Type', type: 'text', required: true},
                        {name: 'note', label: 'Poznámka', type: 'textarea'},
                        {name: 'building_id', type: 'hidden', value: P.building_equipment.building_id},
                        {
                            name: 'installation_date',
                            label: 'Dátum inštalácie',
                            type: 'date',
                        },
                        {
                            name: 'putting_into_operation_date',
                            label: 'Dátum uvedenia do prevádzky',
                            type: 'date',
                        },
                        {
                            name: 'put_into_operation_by',
                            label: 'Uviedol do prevádzky',
                            type: 'select',
                            options: {'items': P.employees, labelKey: 'full_name'},
                            selectKey: 'put_into_operation_by',
                            required: false,
                        },
                        {
                            name: 'revision_date',
                            label: 'Dátum poslednej revízie',
                            type: 'date',
                        },
                        {
                            name: 'photo',
                            label: 'Fotka',
                            type: 'file_input',
                            accept: 'image/*'
                        }
                    ]}
                />
                <div>
                    <View
                        data={P.building_equipment}
                        id={this.state.id}
                        onEdit={() => this.setState({formActive: true})}
                        onDelete={ id => store.dispatch(buildingEquipmentActions.delete(id))
                            .then(P.history.push(`/buildings/detail/${P.building_id}#tab=equipment`)) }
                        permissions={ P.permissions }
                    />
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);
    let obj = state.building_equipment.items.find(item => item.id === id);
    let building_id = null;

    if (obj) {
        building_id = obj.building_id;
    }

    return {
        building_equipment: obj,
        building_id: building_id,
        permissions: state.permissions,
        employees: state.employees.items,
        loading: (
            state.building_equipment.isFetching
        ),
    };
};

export default withRouter(connect(mapStateToProps)(Detail));
