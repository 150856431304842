import React from 'react';
import { connect } from 'react-redux';
import tabletAction from 'actions/tablet';
import store from 'store/mainStore';
import employeesActions from 'actions/employees';
import tabletActions from 'actions/tablets';
import TabletView from './TabletView';
import OwnerView from './OwnerView';
import { Container, Row, Col } from 'react-bootstrap';
import HistoryOwners from './HistoryOwners';
import tabletOwnersActions from 'actions/tablet_owners';
import Form from '../TabletForm';
import { MdHistory } from 'react-icons/md';
import './style.css';

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

class TabletDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formActive: false
        };
    }

    rowClickHandle(id) {
        const owner_id = this.props.tablet_owners.find(to => to.id === id).employee_id;
        this.props.history.push('/employees/detail/' + owner_id);
    }

    buttonClickHandle() {
        this.setState({ formActive: true });
    }

    submitFormHandle(obj) {
        store.dispatch(tabletActions.update(this.props.tablet.id, obj))
            .then((data) => {
                if (data.status === 'ok') {
                    this.setState({ formActive: false });
                    store.dispatch(tabletAction.fetchId(this.props.tablet.id));
                }
        });
    }
    
    componentDidMount() {
        let param_id = this.props.match.params.id;

        if (isNumeric(param_id)) {
            store.dispatch(tabletAction.fetchId(param_id));
            store.dispatch(employeesActions.fetchAll());
            store.dispatch(tabletOwnersActions.fetchAll(param_id));
        }
    }

    render() {
        const { employees, tablet_owners } = this.props;
        const owners = tablet_owners.map(owner => {
            const emp = employees.find(emp => emp.id === owner.employee_id);

            return Object.assign(
                {}, 
                owner, 
                { full_name: emp ? emp.full_name : ''},
                { from_date: owner.from_date }
            );
        });

        return (
            <Container className="tablet-detail">
                <Row>
                    <Col md={6}>
                        <TabletView 
                            tablet={this.props.tablet}
                            onButtonClick={() => this.buttonClickHandle()} />
                    </Col>

                    <Col md={6}>
                        <OwnerView
                            owner={this.props.employees.find(em => em.id === this.props.tablet.owner_id)}
                            fromDate={this.props.tablet.from_date}
                        />
                    </Col>
                </Row>
                <hr />
                <Row className="tablet-history">
                    <h2><MdHistory size={32} /> História vlastníkov</h2>
                    <Col md={12}>
                        <HistoryOwners 
                            owners={owners} 
                            onClick={id => this.rowClickHandle(id)} />
                    </Col>
                </Row>
                
                <Form
                    defaultValues={this.props.tablet} 
                    show={this.state.formActive}
                    employees={this.props.employees}
                    onSubmit={obj => this.submitFormHandle(obj)}
                    onClose={() => this.setState({ formActive: false })}
                    updating />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    
    const tablet_owners = state.tablet_owners.items
    .sort((a, b) => {
        return new Date(a.from_date) < new Date(b.from_date) ? 1 : -1;
    });
    
    return {
        tablet: state.tablet.data,
        employees: state.employees.items,
        tablet_owners
    };
};

export default connect(mapStateToProps)(TabletDetail);