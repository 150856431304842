import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { FormSelect} from 'components/CustomSelect';
import Input from 'components/Input';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import store from 'store';
import CertificationTypesActions from 'actions/certification_types';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/md';
import './style.css';
import CustomFileInput from 'components/FormsyFileInput';
import { formatDate } from 'scripts/dateFormatter';

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            validationErrors: {},
            formSubmitted: false,
            file: null
        };
    }

    componentDidMount() {
        if (this.props.types.length === 0) {
            store.dispatch(CertificationTypesActions.fetchAll());
        }
    }

    validateForm(values) {
        let validations = {
            event_name: 'Vyplňte názov školenia',
            event_time: 'Zvoľte dátum konia školenia',
            valid_from: 'Vyplňte začiatočný dátum',
            valid_to: 'Vyplňte koncový dátum',
            type: 'Zvoľte typ',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]){
                errors[key] = validations[key];
            }
        });

        if (values.valid_from && values.valid_to){
            let from = new Date(values.valid_from);
            let to = new Date(values.valid_to);

            if (to <= from){
                errors['valid_to'] = 'Koncový dátum musí byť neskôr ako začiatočný';
            }
        }

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)){
            return;
        }

        this.props.onSubmit({
            ...model,
            document: this.state.file,
            type_id: parseInt(model.type.id),
            employees: []
        });

        this.setState({
            validationErrors: {},
            formSubmitted: false,
            file: null,
        });
    }

    addDays(in_date, days){
        var date = new Date(in_date.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.selectedTypeId === null && nextProps.types && nextProps.types.length > 0){
            return {
                ...state,
                selectedTypeId: nextProps.types[0].id
            };
        }
        return null;
    }

    render() {
        const { state: S, props: P } = this;
        let dateString = formatDate(P.defaultValues.valid_from || new Date());

        let validToDate = new Date();
        if (S.selectedTypeId){
            let type = P.types.find( t => t.id === S.selectedTypeId);
            validToDate = this.addDays(validToDate, type.duration);
        } else {
            validToDate.setFullYear(validToDate.getFullYear() + 1);
        }
        let dateToString = formatDate(P.defaultValues.valid_to || validToDate);

        let typeValue = null;
        if (P.types.length > 0) {
            typeValue = P.types.find( t => t.id === P.defaultValues.type_id) || P.types[0];
        }
        return (
            <Modal
                onHide={ () => P.onClose() }
                show={P.show}>
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <Formsy
                            onChange={ values => this.validateForm(values)}
                            validationErrors={S.validationErrors}
                            onInvalidSubmit={ model => this.submitHandler(model)}
                            onValidSubmit={ model => this.submitHandler(model) }
                        >
                            <Input
                                name="event_name"
                                label="Názov"
                                placeholder={'Názov školenia'}
                                value={P.defaultValues.event_name || ''}
                                required
                            />
                            <FormSelect
                                name="type"
                                label="Typ osvedčenia"
                                options={P.types}
                                value={typeValue}
                            />
                            <Input
                                name="event_time"
                                label="Dátum konania"
                                type="date"
                                value={dateString}
                                required
                            />
                            <Input
                                name="valid_from"
                                label="Platný od"
                                type="date"
                                value={dateString}
                                required
                            />
                            <Input
                                name="valid_to"
                                label="Platný do"
                                type="date"
                                value={dateToString}
                                required
                            />
                            <CustomFileInput
                                name="document"
                                label="Dokument"
                                onChange={file => {this.setState({file: file});}}
                                document={P.defaultValues.document || null}
                            />
                            <Row>
                                <Col md={{span: 8, offset: 4}}>
                                    <Button variant="success" type="submit">
                                        { this.props.updating ? 'Upraviť' : 'Vytvoriť' }
                                    </Button>
                                </Col>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

RegisterForm.propTypes = {
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    defaultValues: PropTypes.object,
    onSubmit: PropTypes.func
};

RegisterForm.defaultProps = {
    onSubmit: () => {},
    updating: false,
    defaultValues: {
        type_id: '',
        valid_from: '',
        valid_to: ''
    }
};

const mapStateToProps = (state) => {
    return {
        types: state.certificationTypes.items,
    };
};

export default connect(mapStateToProps)(RegisterForm);