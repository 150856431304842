import React from 'react';
import { SuperTable } from 'components/PaginationTable';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import store from 'store/mainStore';
import store_content_actions from 'actions/store_content';
import stores_actions from 'actions/stores';

class OrdersContainer extends React.Component {

    componentDidMount() {
        const id = this.props.match.params.id;

        store.dispatch(stores_actions.fetchAll());
        store.dispatch(store_content_actions.fetchAll({id: id}));
    }

    redirect(id) {
        this.props.history.push('/storecards/' + id);
    }

    render() {
        const { props: P } = this;

        return (
            <Container className="buildings">
                <div>
                    <h3>
                        Stav zásob na sklade {P.store.code} - {P.store.name}
                    </h3>
                </div>
                <SuperTable
                    name={'trainings'}
                    items={P.store_content}
                    thead={[
                        {name: 'image_url', sk_name: 'Foto', type: 'image'},
                        {name: 'code', sk_name: 'Kod', type: 'string'},
                        {name: 'name', sk_name: 'Názov', type: 'string'},
                        {name: 'quantity', sk_name: 'Pocet', type: 'number'},
                        {name: 'unit', sk_name: 'Jednotka', type: 'string'},
                    ]}
                    columnSelector={true}
                    onRowClick={id => this.redirect(id)}
                    defaultSortKey='event_time'
                    searchable
                    isLoading={P.loading}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;

    return {
        store: state.stores.items.find(s => s.id === id),
        store_content: state.store_content.items,
        loading: state.stores.isFetching || state.store_content.isFetching,
    };
};

export default connect(mapStateToProps)(OrdersContainer);
