import React from 'react';
import {Button, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {IoIosHelpCircleOutline} from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import DF from 'scripts/dateFormatter';

class NewFileInput extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    onFileLoad(e) {
        let file = null;
        if (e.target.files.length > 0) {
            file = e.target.files[0];
        }
        this.props.onChange(file);
    }

    getToolTip(file) {
        if (!file || !file.created_at) {
            return null;
        }

        let id = String(file.id);
        return (
            <span>
                <IoIosHelpCircleOutline
                    size = {'1.5em'}
                    style={{
                        margin: '0.5em',
                        marginTop: '0.1em'
                    }}
                    data-tip data-for={id}
                />
                <ReactTooltip
                    id={id}
                    type='dark'
                    place="right"
                    effect="solid"
                >
                    <div style={{fontSize: '1.2em'}}>
                        Súbor bol vložený používatľom {file.created_by.full_name} dňa {DF(file.created_at)}
                    </div>
                </ReactTooltip>
            </span>
        );
    }

    render() {
        const {props: P} = this;

        return <Row className={'mb-2'}>
            <span style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <input
                    ref={this.inputRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={e => this.onFileLoad(e)}
                    accept={P.accept || '*'}
                    multiple={false}
                />
                <label style={{
                    marginRight: 'auto'
                }}>
                    {P.file_name}
                    {this.getToolTip(P.file)}
                </label>
                {
                    P.editable ?
                    <Button
                        variant={'primary'}
                        onClick={() => this.inputRef.current.click()}
                    >
                        {P.file ? 'Zmeniť' : 'Pridať súbor'}
                    </Button> : null
                }
                {
                    P.server_name ?
                        <span>
                            <a href={`/api/documents/${P.server_name}`}>
                                <Button
                                    variant={'primary'}
                                    style={{marginLeft: '5px'}}
                                >
                                    Zobraziť
                                </Button>
                            </a>
                        </span> : null
                }
                {
                    (P.file && P.editable) ?
                        <Button
                            variant={'danger'}
                            style={{marginLeft: '5px'}}
                            onClick={() => {
                                this.inputRef.current.value = '';
                                this.props.onChange(null);
                            }}
                        >
                            Vymazať
                        </Button> : null
                }
            </span>
        </Row>;
    }
}

NewFileInput.propTypes = {
    onChange: PropTypes.func,
    file_name: PropTypes.string,
    server_name: PropTypes.string,
    editable: PropTypes.bool.isRequired,
};

NewFileInput.defaultProps = {
    onChange: () => {},
    file_name: null,
    server_name: null,
    editable: true,
};

export default NewFileInput;
