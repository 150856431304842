import React from 'react';
import PT from 'prop-types';
import { MdSearch } from 'react-icons/md';
import { Form, InputGroup } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

class Search extends React.Component {

    render() {
        return (
            <Form.Group>
                <InputGroup>
                    <InputGroup.Text id="inputGroupPrepend">
                        <MdSearch/>
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder={this.props.placeholder || 'Hľadať'}
                        aria-describedby="inputGroupPrepend"
                        name="search"
                        value={this.props.value}
                        onChange={e => this.props.onChange(e.target.value)}
                    />
                    {
                        this.props.value ?
                            <MdClear
                                size={'1.3em'}
                                style={{
                                    marginLeft: '-2em',
                                    marginTop: '0.6em',
                                    zIndex: 10,
                                    cursor: 'pointer'
                                }}
                                onClick={ () => this.props.onClear()}
                            /> : null
                    }
                </InputGroup>
            </Form.Group>
        );
    }
}

Search.propTypes = {
    onChange: PT.func,
	visible: PT.bool,
	value: PT.string,
};

Search.defaultProps = {
    value: '',
    visible: false,
    onChange: () => {}
};

export default Search;
