import React from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import DF from 'scripts/dateFormatter';
import NeedWritePermission from 'components/NeedPermission';
import ImageViewer from 'components/ImageViewer';
import { ConfirmModal } from 'components/modals';

class MainInfo extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            confirm_show: false,
            confirm_message: '',
        };
    }

    render() {
        const tool = this.props.tool;
        const {state: S} = this;

        return (
            <div>
                { (tool.parent_id && this.props.parent) ? (
                    <Row >
                        <h3 style={{
                            textAlign: 'center',
                            width: '100%',
                        }}>
                            Táto položka patrí do sady: <Link to={`/tools/detail/${tool.parent_id}`}>{this.props.parent.name}</Link>
                        </h3>
                    </Row>
                ) : null}
                <Row>
                    <Col xs={12} className="text-center" style={{ marginBottom: 25 }}>
                        <ImageViewer
                            thumbnail={tool.thumbnail}
                            full_size={tool.photo}
                        />
                        <ImageViewer
                            thumbnail={tool.label_thumbnail}
                            full_size={tool.label}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Názov</Col>
                    <Col xs={6} className="text-left">{tool.name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Sériové číslo</Col>
                    <Col xs={6} className="text-left">{tool.serial_num}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Číslo čipu</Col>
                    <Col xs={6} className="text-left">{tool.chip_number}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Kategória</Col>
                    <Col xs={6} className="text-left">{tool.category_name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Posledná revízia</Col>
                    <Col xs={6} className="text-left">{DF(tool.last_revision, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Ďalšia revízia</Col>
                    <Col xs={6} className="text-left">{DF(tool.next_revision, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Koniec záruky</Col>
                    <Col xs={6} className="text-left">{DF(tool.warranty_expiration, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Stav</Col>
                    <Col xs={6} className="text-left">{tool.state_name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Kvalita</Col>
                    <Col xs={6} className="text-left">{tool.quality}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Komentár</Col>
                    <Col xs={6} className="text-left">{tool.comment}</Col>
                </Row>
				<Row>
                    <Col xs={6} className="text-right">Komentár stavu kvality</Col>
                    <Col xs={6} className="text-left">{tool.history_comment}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Vlastník</Col>
                    <Col xs={6} className="text-left"><Link to={`/employees/detail/${tool.owner_id}`}>{tool.owner_name}</Link></Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Dátum prevzatia</Col>
                    <Col xs={6} className="text-left">{DF(tool.from_date)}</Col>
                </Row>
                {
                    tool.revision_document ?
                        <Row>
                            <Col xs={6} className="text-right">Revízny dokument</Col>
                            <Col xs={6} className="text-left">
                                <a
                                    target="_blank"
                                    href={tool.revision_document}
                                    rel="noopener noreferrer"
                                >
                                    Zobraziť
                                </a>
                            </Col>
                        </Row> : null
                }
                {
                    tool.aux_document ?
                        <Row>
                            <Col xs={6} className="text-right">Dodatočný dokument</Col>
                            <Col xs={6} className="text-left">
                                <a
                                    target="_blank"
                                    href={tool.aux_document}
                                    rel="noopener noreferrer"
                                >
                                    Zobraziť
                                </a>
                            </Col>
                        </Row> : null
                }
                { tool.assigned_by ?
                    <Row className={'mt-3'}>
                        <Col style={{textAlign: 'center'}}>
                            Tento nástroj bol manuálne priradený súčasnému vlastníkovi používateľom {tool.assigned_by_name} dňa {DF(tool.from_date)}
                        </Col>
                    </Row> : null
                }
                <Row style={{ marginTop: 22 }} >
                    <Col xs={12} className="text-center">
                        <NeedWritePermission type="button" need={['tools']}>
                            <Button
                                style={{ marginRight: 10 }}
                                variant="success"
                                onClick={() => this.props.onCopy(tool)}
                            >
                                Kopírovať
                            </Button>
                        </NeedWritePermission>
                        { tool.parent_id === null ?
                        <NeedWritePermission type="button" need={['tools']}>
                            <Button
                                style={{ marginRight: 10 }}
                                variant="success"
                                disabled={tool.parent_id !== null}
                                onClick={() => this.props.onCreate(tool.id)}
                            >
                                Pridať položku
                            </Button>
                        </NeedWritePermission> : null}
                        <NeedWritePermission type="button" need={['tools']}>
                            <Button
                                variant="success"
                                onClick={() => this.props.onEdit(tool.id)}
                                style={{ marginRight: 10 }}
                            >
                                Upraviť
                            </Button>
                        </NeedWritePermission>
                    </Col>
                </Row>
                <ConfirmModal
                    message={'Vymazať nástroj ' + tool.name}
                    show={S.confirm_show}
                    onClose={() => this.setState({ confirm_show: false })}
                    onAccept={ () => this.props.onDelete(tool.id)}
                    acceptTitle="Vymazať"
                />
            </div>
        );
    }
}

MainInfo.propTypes = {
    tool: PT.object,
    parent: PT.object,
    onEdit: PT.func,
    onDelete: PT.func,
    onCreate: PT.func,
    onCopy: PT.func,
};

MainInfo.defaultProps = {
    tool: {},
    onEdit: () => {},
    onDelete: () => {},
    onCreate: () => {}
};

export default MainInfo;

