import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { MdCheck, MdCancel } from 'react-icons/md';

const errorTitles = {
    'create': 'Chyba pri vytváraní záznamu',
    'delete': 'Chyba pri vymazávaní',
    'get': 'Nepodarilo sa zobraziť',
    'update': 'Chyba pri úprave záznamu',
    'info': 'Operácia sa nepodarila',
    'logout' : 'Prihlásenie vypršalo',
};

const successTitles = {
    'create': 'Úspešne vytvorené',
    'delete': 'Úspešne odstránené',
    'update': 'Úspešne upravené',
    'info': 'Operácia sa podarila',
    'get': 'Operácia sa podarila',
    'logout' : 'Úspešne priglásený',
};

class ErrorModal extends React.PureComponent {
    render() {
        const titles = this.props.is_error ? errorTitles : successTitles;
        const icon = this.props.is_error ?
            <MdCancel size={40} color="red" /> :
            <MdCheck size={40} color="green" />;

        let body = null;
        if (this.props.messages.length > 0){
            body =
                <Modal.Body >
                    <div>{
                        this.props.messages.map( (message, i) => {
                            return <p key={i}> { message } </p>;
                        })
                    }
                    </div>
                </Modal.Body>;
        }

        return (
            <Modal 
                show={this.props.show}
                onHide={() => this.props.onClose()}
            >    
                <Modal.Header>
                    {icon}
                    <div style={{
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        {titles[this.props.errorType]}
                    </div>
                </Modal.Header>
                {body}
                <Modal.Footer>
                    <Button variant="success" onClick={() => this.props.onClose()}>Ok</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ErrorModal.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    errorType: PropTypes.oneOf(['create', 'update', 'delete', 'get', 'info', 'logout']),
    onClose: PropTypes.func,
    show: PropTypes.bool,
    is_error: PropTypes.bool,
};

ErrorModal.defaultProps = {
    show: false,
    onClose: () => {},
	messages: ['Error'],
	errorType: 'info',
    is_error: true,
};

export default ErrorModal;
