const DefaultSelector = [{
    name: 'active_selector',
    default_value: 1,
    values: [
        {
            value: 0,
            name: 'Zobraziť všetky',
            filter: () => true
        },
        {
            value: 1,
            name: 'Zobraziť aktívne',
            filter: obj => obj.active
        },
        {
            value: 2,
            name: 'Zobraziť neaktívne',
            filter: obj => !obj.active
        }
    ]
}];

export default DefaultSelector;