import React from 'react';
import store from 'store';
import buildingsWithPerformancesActions from 'actions/buildings_with_performances';
import buildingTopEntitiesActions from 'actions/building_top_entities';
import {loadBuilding, loadEntity, setBuilding, setEntity} from 'views/Planning/utils';
import {Button, Col, Container, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import Formsy from 'formsy-react';
import {connect} from 'react-redux';
import call from 'ajax/call';
import PieChart from 'components/PieChart/PieChart';
import {FaRegCheckSquare} from 'react-icons/fa';
import dateFormat, {format, parseSeconds} from 'scripts/dateFormatter';
import Spinner from 'components/Spinner';
import Select from 'react-select';
import {FormSelect} from 'components/CustomSelect';
import './style.css';

class PerformancesOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            building_id: null,
            entity_id: null,
            childrenEntities: [],
            fetchingData: false
        };
    }

    componentDidMount() {
        store.dispatch(buildingsWithPerformancesActions.fetchAll())
            .then(data => {
                if (data.status === 'ok') {
                    const {props: P} = this;
                    const building_id = loadBuilding(P.location, P.history, data.data);

                    if (building_id) {
                        this.setState({building_id}, () => {
                            store.dispatch(buildingTopEntitiesActions.fetchAll({building_id}))
                                .then((data) => {
                                    const buildingTopEntities = data.data;

                                    if (buildingTopEntities) {
                                        const {location, history} = this.props;
                                        const entity_id = loadEntity(location, history, buildingTopEntities, building_id);
                                        setEntity(location, history, entity_id);

                                        if (entity_id) {
                                            this.setState(
                                                {entity_id},
                                                () => this.loadChildrenEntities());
                                        }
                                    }
                                });
                        });
                    }
                }
            });
    }

    loadChildrenEntities() {
        const {state: S} = this;

        this.setState({
            fetchingData: true
        });

        call(`/api/building_entities/${S.entity_id}/performances_overview`, {
            method: 'GET',
            headers: {'Content-type': 'application/json'},
        })
            .then(response => response.json())
            .then(data => {
                const entity = data.data;
                if (entity && entity.id) {
                    this.setState(() => ({
                        childrenEntities: [entity],
                        fetchingData: false
                    }));
                } else {
                    this.setState({
                        childrenEntities: [],
                        fetchingData: false
                    });
                }
            });
    }

    getLoader() {
        return <Spinner/>;
    }

    redirect(building_id, entity_id) {
        this.props.history.push(`/operation_work/?building_id=${building_id}&entity_id=${entity_id}`);
    }

    getColumnWidthPercentage(columnsCount) {
        return `${(100 / (columnsCount + 1)) - 1}%`;
    }

    renderTopEntity(entity) {
        const length = entity.entityTypeOperations.length;
        const colWidth = this.getColumnWidthPercentage(length);
        return <React.Fragment>
            {
                Object.values(entity.entitiesByEntityTypes)
                    .map(entityByEntityType => {
                            const length = entityByEntityType.entityTypeOperations.length;
                            const colWidth = this.getColumnWidthPercentage(length);
                            return this.renderTopEntityTable(
                                entityByEntityType.entityType,
                                entityByEntityType.entityTypeOperations,
                                entityByEntityType.entities,
                                colWidth,
                            );
                        }
                    )
            }
            {
                Object.values(entity.operationsWorkPerformances).length > 0 &&
                this.renderTopEntityWithOperations(entity, colWidth)
            }
        </React.Fragment>;
    }

    renderEntityChild(entity, operations) {
        const colWidth = this.getColumnWidthPercentage(operations.length);
        return (
            <tr>
                <td
                    style={{cursor: 'pointer', width: colWidth, height: '4rem',}}
                    className={'py-2'}
                    onClick={() => {
                        this.redirect(this.state.building_id, entity.id);
                    }}>
                    {
                        entity.status_percent >= 100
                            ? <FaRegCheckSquare
                                color='green'
                                size={'2em'}
                            />
                            : <PieChart value={entity.status_percent}/>
                    }
                    <span style={{textDecoration: 'underline'}}>{entity.name}</span>
                </td>
                {
                    operations.map(operation =>
                        <React.Fragment key={operation.id}>
                            {this.renderEntityOperation(entity, operation, colWidth)}
                        </React.Fragment>)
                }
            </tr>
        );
    }

    renderTopEntityWithOperations(entity, colWidth) {
        return this.renderTopEntityTable(entity.entity_type, entity.entityTypeOperations, [entity], colWidth);
    }

    renderTopEntityTable(entityType, operations, children, colWidth) {
        return (
            <table
                className={'table table-striped table-hover table-responsive table-fixed performance-overview'}
                key={entityType.id}
            >
                <thead>
                <tr>
                    <th style={{width: colWidth}}>
                        {entityType.plural_name}
                    </th>
                    {
                        operations.map(operation =>
                            <th style={{width: colWidth}} key={operation.id} className={'text-center'}>
                                {operation.name}
                            </th>
                        )
                    }
                </tr>
                </thead>
                <tbody>
                {
                    children.map(child =>
                        <React.Fragment key={child.id}>
                            {this.renderEntityChild(child, operations)}
                        </React.Fragment>
                    )
                }
                </tbody>
            </table>
        );
    }

    renderEntityOperation(entity, operation, colWidth) {
        let entityOperations = entity.operationsWorkPerformances && entity.operationsWorkPerformances[operation.id];
        if (!entityOperations) {
            return <td style={{width: colWidth, height: '4rem'}}/>;
        }

        return <td
                key={operation.id}
                className={'py-2'}
                style={{
                    width: colWidth,
                    height: '4rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <OverlayTrigger
                    placement='auto'
                    trigger={['hover', 'focus', 'click']}
                    overlay={
                        <Popover
                            id={`popover-operation-${operation.id}`}
                            style={{maxWidth: '1200px', paddingRight: '10px'}}
                        >
                            <Popover.Header as="h3">
                                {entity.name} - {operation.name}
                            </Popover.Header>
                            <Popover.Body>
                                {this.renderPopoverContent(entityOperations)}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    {
                        entityOperations.status_percent >= 100
                            ? <div>
                                <FaRegCheckSquare
                                color='green'
                                size={'3em'}
                                style={{
                                    alignSelf: 'center'
                                }}/>
                            </div>
                            : <PieChart value={entityOperations.status_percent}/>
                    }
                </OverlayTrigger>
            </td>;
    }

    renderPopoverContent(entityOperations) {
        return <div>
            <Row style={{
                marginLeft: 10,
                marginBottom: 10,
                marginTop: -10
            }}>Dokončené: {entityOperations.status_percent}%</Row>
            {
                entityOperations.work_performances &&
                Object.values(entityOperations.work_performances).map(workPerformance =>
                    <ul key={workPerformance.id}>
                        <li>
                            <div>
                                <strong>{dateFormat(workPerformance.started_at, false, {month: 'short'})}</strong>, &nbsp;
                                {workPerformance.percent_done} %
                            </div>
                            <div>
                                {format(workPerformance.started_at, {hour: 'numeric', minute: 'numeric'})} - &nbsp;
                                {format(workPerformance.finished_at, {hour: 'numeric', minute: 'numeric'})}, &nbsp; čas: &nbsp;
                                {parseSeconds(workPerformance.total_time)}
                            </div>
                            <div>
                                {workPerformance.group.group_members_names}
                            </div>
                        </li>
                    </ul>
                )
            }
            {
                !Object.values(entityOperations.work_performances).length &&
                <p>Žiadne operácie</p>
            }
        </div>;
    }

    render() {
        const {props: P, state: S} = this;

        if (P.isLoading || S.fetchingData) {
            return this.getLoader();
        }

        return (
            <Container>
                <Formsy>
                    <FormSelect
                        name={'building'}
                        label={'Budova'}
                        options={P.buildingsWithPerformances}
                        placeholder={'Vyberte budovu'}
                        getOptionValue={b => b.id}
                        getOptionLabel={b => b.name}
                        value={P.buildingsWithPerformances.find(b => b.id === S.building_id)}
                        onChange={b => {
                            //update building, clear entity, clear entity state
                            setBuilding(P.location, P.history, b.id);
                            setEntity(P.location, P.history, null);
                            this.setState({
                                building_id: b.id,
                                entity_id: null,
                                childrenEntities: [],
                            }, () => {
                                if (S.building_id) {
                                    //load top entities and first top entity
                                    store.dispatch(buildingTopEntitiesActions.fetchAll({
                                        building_id: this.state.building_id
                                    }))
                                        .then(data => {
                                            if (data.data[0]) {
                                                const first_entity_id = parseInt(data.data[0].id, 10);
                                                setEntity(P.location, P.history, first_entity_id);
                                                this.setState(
                                                    {entity_id: first_entity_id},
                                                    () => this.loadChildrenEntities()
                                                );
                                            }
                                        });
                                }
                            });
                        }}
                    />
                </Formsy>
                <Button
                    style={{margin: 15}}
                    variant="success"
                    onClick={() => {
                        P.history.push(`/recent_performances?building_id=${S.building_id}`);
                    }}
                >
                    Nedávne operácie
                </Button>
                <Row className={'pb-5'}>
                    <Col xs={3}>
                        <Formsy>
                            <Select
                                options={P.buildingTopEntities}
                                value={P.buildingTopEntities.find(e => e.id === S.entity_id)}
                                getOptionValue={entity => entity.id}
                                getOptionLabel={entity => entity.name}
                                onChange={item => {
                                    if (item && item.id) {
                                        const {props: P} = this;
                                        setEntity(P.location, P.history, item.id);
                                        this.setState(
                                            {entity_id: item.id},
                                            () => this.loadChildrenEntities()
                                        );
                                    }
                                }}
                                placeholder={'Vyberte umiestnenie'}
                                noOptionsMessage={() => 'Žiadne možnosti'}
                            />
                        </Formsy>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {
                            S.childrenEntities.length > 0 &&
                            S.childrenEntities.map((topEntity) =>
                                (
                                    <React.Fragment key={topEntity.id}>
                                        <h4>{topEntity.name}</h4>
                                        {this.renderTopEntity(topEntity)}
                                    </React.Fragment>
                                )
                            )
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    buildingsWithPerformances: state.buildingsWithPerformances.items,
    buildingTopEntities: state.buildingTopEntities.items,
    isLoading: state.buildingsWithPerformances.isFetching || state.buildingTopEntities.isFetching
});

export default connect(mapStateToProps)(PerformancesOverview);