function valueOrEmptyString(value) {
	if (value === null || value === undefined) {
		return '';
	}
	return value;
}

export default function (valueOrFn) {
	try {
		if ((typeof valueOrFn) === 'function') {
			let output = valueOrFn();
			if (output === undefined) {
				console.warn('passed function returns undefined, do you really mean to do that ?');
			}
			return valueOrEmptyString(output);	
		}
		return valueOrEmptyString(valueOrFn);
	} catch (e) {
		console.log('trowed error, but value normalized to empty string...');
		return '';
	}
}

export function hoursToTime(value) {
	let num = Math.abs(value);
	const hours = '' + Math.floor(num);
	const minutes = ('0' + Math.floor((num - hours)*60)).slice(-2);

	let string = `${hours}:${minutes}`;

	return value < 0 ? '-' + string : string;
}

export function isEmpty(value) {
	return value === null || value === undefined || value === '';
}

export function isNotEmpty(value) {
	return value !== null && value !== undefined;
}