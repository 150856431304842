import React from 'react';
import {Container} from 'react-bootstrap';
import UserView from './view';
import employeesActions from 'actions/employees';
import employee_order from 'actions/employee_orders';
import orders_actions from 'actions/orders';
import InfoPanel from './InfoPanel';
import store from 'store';
import {connect} from 'react-redux';
import UpdateForm from '../RegisterEmployee';
import ChangePasswd from './PasswordForm';
import Tern from 'components/Or';
import {addDependency} from 'actions/dependency';
import Spinner from 'components/Spinner';
import NoAccess from 'views/components/NoAccess';
import {isNotEmpty} from 'scripts/valueNormalizer';
import UpdateOwnProfileForm from 'views/Employees/UpdateOwnProfileForm';

class EmployeeDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            formOwnProfileActive: false,
            changePasswd: false,
        };
    }

    submitFormHandler(obj) {
        const formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (key !== 'photo' && isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }
        if (obj.photo) {
            formData.append('photo', obj.photo, 'photo.jpg');
        }
        formData.append('_method', 'PUT');
        let id = parseInt(this.props.match.params.id, 10);

        store.dispatch(employeesActions.update(id, formData, {
            stringify: false,
            contentType: false,
            method: 'POST'
        })).then((data) => {
                if (data.status === 'ok') {
                    this.setState({
                        formActive: false
                    });
                }
        });
    }

    submitOwnFormHandler() {
        store.dispatch(employeesActions.fetchAll());
        this.setState({ formOwnProfileActive: false });
    }

    deleteHandler() {
        const id = parseInt(this.props.match.params.id, 10);
        store.dispatch(employeesActions.delete(id))
            .then((data) => {
                if (data.status === 'ok') {
                    this.props.history.push('/employees');
                }
        });
    }

    componentDidMount() {
        const { props:P } = this;
        let id = parseInt(P.match.params.id, 10);
        store.dispatch(employeesActions.fetchConcrete(id));

        if (P.permissions['orders'] > 0) {
            store.dispatch(employee_order.fetchAll({
                type: 'employee',
                employee_id: id
            }));
            store.dispatch(orders_actions.fetchAll());
        }
    }

    render() {
        const { props:P } = this;
        let data  = this.props.employee;

        if (P.isFetching || !data){
            return <Spinner/>;
        }

        let isMyProfile = P.identity.id === data.id;

        if (P.permissions['employees'] === 0 && !isMyProfile){
            return <NoAccess/>;
        }

        return (
            <Container className="employees-detail">
                <div>
                    <UserView
                        onUpdate={() => this.setState({formActive: true})}
                        onUpdateOwn={() => this.setState({formOwnProfileActive: true})}
                        onDelete={() => this.deleteHandler()}
                        onChangePasswd={() => this.setState({changePasswd: true})}
                        {...data}
                        permissions={P.permissions}
                        canChangePassword={P.identity.id === data.id}
                    />
                    <InfoPanel
                        id={ this.props.match.params.id }
                        onToolClick={id => P.history.push(`/tools/detail/${id}`)}
                        isMyProfile={isMyProfile}
                    />
                </div>
                <Tern expression={this.state.formActive}>
                    <UpdateForm
                        gdpr={P.permissions.gdpr > 0}
                        onClose={ () => this.setState({ formActive: false }) }
						onSubmit={ obj => this.submitFormHandler(obj) }
						defaultValues = { this.props.employee } 
						updating
					/>
                </Tern>
                <Tern expression={this.state.formOwnProfileActive}>
                    <UpdateOwnProfileForm
                        onClose={ () => this.setState({ formOwnProfileActive: false }) }
                        onSubmit={ obj => this.submitOwnFormHandler(obj) }
                        employee = { this.props.employee }
                        updating
                    />
                </Tern>
                <Tern expression={this.state.changePasswd}>
                    <ChangePasswd
                        employee_id={P.identity.id}
                        force = {false}
                        onClose={ () => this.setState({ changePasswd: false }) }
                        onSubmit={ () => this.setState({ changePasswd: false })}
                    />
                </Tern>
            </Container>
        );
    }
}

addDependency('employees', ['webapp_access']);

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);

    return {
        employee: state.employees.items.find( e => e.id === id),
        isFetching: state.employees.isFetching,
        identity: state.identity,
        permissions: state.permissions,
    };
};

export default connect(mapStateToProps)(EmployeeDetail);
