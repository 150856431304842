export default function dateFormat(input_str, with_time = true, custom_options = {}) {

    if (input_str === null || input_str === '' || input_str === undefined) {
        return '---';
    }

    let date;
    if (input_str instanceof Date) {
        date = input_str;
    } else {
        date = new Date(input_str);
    }

    var options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...custom_options
    };

    if (with_time) {
        Object.assign(options, {
            hour: 'numeric',
            minute: 'numeric',
            //timeZoneName: 'short'
        });
    }

    return date.toLocaleDateString('sk-SK', options);
}

export function format(input_str, options) {

    if (input_str === null || input_str === '') {
        return '---';
    }

    const date = new Date(input_str);
    return date.toLocaleString('sk-SK', options);
}

export function formatDate(date) {
    if (!date) return '';

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().slice(0, 10);
}

export function formatDateTime(date) {
    if (!date) return '';

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().slice(0, 16);
}

export function parseHours(num) {
    if (num === undefined) {
        return '';
    }

    num = parseFloat(num);
    const hours = Math.floor(num);
    let minutes = Math.round((num - hours) * 60);
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes}`;
}

export function parseSeconds(seconds) {
    if (seconds === undefined || seconds === null) {
        return '';
    }
    let sign = '';
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        sign = '-';
    }

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor(seconds / 60);

    minutes = ('00' + (minutes % 60) + '').slice(-2);

    return `${sign}${hours}:${minutes}`;
}

export function extractTime(date) {
    if (!date) return '';

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return date.toTimeString().substring(0, 8);
}

export function getmonthName(num) {
    let objDate = new Date(2018, num - 1, 1, 0, 0, 0);
    let locale = 'sk-sk';
    let name = objDate.toLocaleString(locale, {month: 'long'});
    name = name[0].toUpperCase() + name.substring(1);

    return name;
}
