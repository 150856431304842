import React from 'react';
import { connect } from 'react-redux';
import {Button} from 'react-bootstrap';
import store from 'store/mainStore';
import { SuperTable } from 'components/PaginationTable';
import Spinner from 'components/Spinner';
import material_actions from 'actions/material';
import {animateScroll as scroll } from 'react-scroll';
import './style.css';
import PT from 'prop-types';

class SectionMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMaterial: false,
        };
        this.buttonRef = React.createRef();
    }

    fetchSectionData(entity_id){
        if (entity_id) {
            store.dispatch(material_actions.fetchAll({id: entity_id}));
        }
    }

    componentDidMount() {
        this.fetchSectionData(this.props.entity_id);
    }

    render() {
        const { props:P } = this;

        if (P.is_loading){
            return <Spinner/>;
        }

        if (P.material.length === 0) {
            return null;
        }

        return (
            <div className="info">
                <Button
                    ref={this.buttonRef}
                    variant="success"
                    onClick={() => {
                        if (!this.state.showMaterial) {
                            this.setState({
                                showMaterial: true
                            }, () => scroll.scrollToBottom({
                                duration: 300,
                                delay: 0
                            }));
                        } else {
                            let coordinates = this.buttonRef.current.getBoundingClientRect();
                            let scrollPosition = window.pageYOffset + coordinates.bottom - window.innerHeight + 25;
                            scroll.scrollTo(scrollPosition, {
                                duration: 300,
                                delay: 0
                            });
                            setTimeout(() => this.setState({showMaterial: false}), 400);
                        }
                    }}
                    style={{
                        marginBottom: 20
                    }}
                >
                    {this.state.showMaterial ? 'Skryť' : 'Zobraziť'} materiál
                </Button>
                {this.state.showMaterial && P.material.length > 0 ?
                    <SuperTable
                        name={'material'}
                        id={'material'}
                        thead={[
                            {name: 'short_name', sk_name: 'Názov', type: 'string', sort: false},
                            {name: 'dimension', sk_name: 'DN', type: 'string', sort: false},
                            {name: 'count', sk_name: 'Počet', type: 'string', sort: false},
                        ]}
                        items={P.material}
                        is_loading = {P.is_loading}
                    /> : null
                }
            </div>
        );
    }
}

SectionMaterial.propTypes = {
    entity_id: PT.number.isRequired
};

const mapStateToProps = state => {
    return {
        material: state.material.items,
        is_loading: state.material.isFetching
    };
};

export default connect(mapStateToProps)(SectionMaterial);
