import expect from 'helpers/expect';

/*
    Created by Martin Schnurer

    this is a wrapper for Actions
*/

import call from 'ajax/call';

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function (resource_name, options = {}) {

    let obj = {};
    const upercasedName = resource_name.toUpperCase();
    const CapitalizedName = capitalize(resource_name);

    const fetching = `FETCHING_${upercasedName}`;
    const creating = `CREATING_${upercasedName}`;
    const updating = `UPDATING_${upercasedName}`;

    const fetchConcrete_url = options.fetch_concrete_url || ((id) => `/api/${resource_name}/${id}`);
    const fetchAll_url = options.fetchAll_url || (() => `/api/${resource_name}`);
    const update_url = options.update_url || ( id => `/api/${resource_name}/${id}`) ;
    const delete_url = options.delete_url || ( id => `/api/${resource_name}/${id}`) ;
    const create_url = options.create_url || (() => `/api/${resource_name}`) ;


    /************************** TEST ************************************************/
    options.fetchAll_url && expect(options.fetchAll_url).toBeTypeOf('function');
    options.update_url && expect(options.update_url).toBeTypeOf('function');
    options.delete_url && expect(options.delete_url).toBeTypeOf('function');
    options.create_url && expect(options.create_url).toBeTypeOf('function');
    /******************************************************************************/

    obj['UNSET_ERR'] = 'UNSET_ERR';
    obj[fetching] = `FETCHING_${upercasedName}`;
    obj[`${fetching}_SUCC`] = `FETCHING_${upercasedName}_SUCC`;
    obj[`${fetching}_ERR`] = `FETCHING_${upercasedName}_ERR`;
    
    obj[creating] = creating;
    obj[`${creating}_SUCC`] = `${creating}_SUCC`;
    obj[`${creating}_ERR`] = `CREATING_${upercasedName}_ERR`;

    obj[updating] = `UPDATING_${upercasedName}`;
    obj[`${updating}_SUCC`] = `UPDATING_${upercasedName}_SUCC`;
    obj[`${updating}_ERR`] = `UPDATING_${upercasedName}_ERR`;

    obj[`DELETING_${upercasedName}`] = `DELETING_${upercasedName}`;
    obj[`DELETING_${upercasedName}_SUCC`] = `DELETING_${upercasedName}_SUCC`;
    obj[`DELETING_${upercasedName}_ERR`] = `DELETING_${upercasedName}_ERR`;

    obj[`FETCHING_CONCRETE_${upercasedName}`] = `FETCHING_CONCRETE_${upercasedName}`;
    obj[`FETCHING_CONCRETE_${upercasedName}_SUCC`] = `FETCHING_CONCRETE_${upercasedName}_SUCC`;
    obj[`FETCHING_CONCRETE_${upercasedName}_ERR`] = `FETCHING_CONCRETE_${upercasedName}_ERR`;

/******************************************************************************************/ 

    obj['unsetError'] = () => ({type: obj['UNSET_ERR']});

    obj[`willFetchConcrete${CapitalizedName}`] = (id) => ({ type: obj[`FETCHING_CONCRETE_${upercasedName}`], id });
    obj[`didFetchConcrete${CapitalizedName}Succ`] = (id, data) => ({type: obj[`FETCHING_CONCRETE_${upercasedName}_SUCC`], obj: data, id });
    obj[`didFetchConcrete${CapitalizedName}Err`] = (id, err) => ({type: obj[`FETCHING_CONCRETE_${upercasedName}_ERR`], id, err });

    obj[`willFetch${CapitalizedName}`] = () => ({ type: obj[`FETCHING_${upercasedName}`] });
    obj[`didFetch${CapitalizedName}Succ`] = (data, append = false) => ({type: obj[`FETCHING_${upercasedName}_SUCC`], items: data, append});

    obj[`didFetch${CapitalizedName}Err`] = () => ({type: obj[`FETCHING_${upercasedName}_ERR`] });
    
    obj[`willCreate${CapitalizedName}`] = () => ({type: `CREATING_${upercasedName}`});
    obj[`didCreate${CapitalizedName}Succ`] = (data) => ({type: `CREATING_${upercasedName}_SUCC`, data});
    obj[`didCreate${CapitalizedName}Err`] = (err) => ({type: `CREATING_${upercasedName}_ERR`, err});

    obj[`willUpdate${CapitalizedName}`] = () => ({ type: obj[`UPDATING_${upercasedName}`]});
    obj[`didUpdate${CapitalizedName}Succ`] = (id, data) => ({ type: obj[`UPDATING_${upercasedName}_SUCC`], id, data});
    obj[`didUpdate${CapitalizedName}Err`] = (id, err) => ({ type: obj[`UPDATING_${upercasedName}_ERR`], id, err });

    obj[`willDelete${CapitalizedName}`] = () => ({type: obj[`DELETING_${upercasedName}`] });
    obj[`didDelete${CapitalizedName}Succ`] = (id) => ({type: obj[`DELETING_${upercasedName}_SUCC`], id });
    obj[`didDelete${CapitalizedName}Err`] = (id, err, data) => ({type: obj[`DELETING_${upercasedName}_ERR`], id, err, data});

/*********************************************CALLING ACTIONS**********************************************************/

    obj['create'] = function(data, options) {
        options = {
            stringify: true,
            contentType: 'application/json',
			method: 'POST',
			...options
        };
        if (typeof data !== 'object') {
            throw Error('Create action: data argument should be type of object');
        }
        
        return function (dispatch) {
            dispatch(obj[`willCreate${CapitalizedName}`]());
            
            let opts = options.contentType === false ? {
                method: options.method,
                body: options.stringify ? JSON.stringify(data) : data 
            } : {
                method: options.method,
                headers: {'Content-Type': options.contentType},
                body: options.stringify ? JSON.stringify(data) : data 
            };

            return (
                call(create_url(), opts)
                .then(response => response.json())
                .then( responseData => {
                    if (responseData.status === 'ok') {
                        dispatch(obj[`didCreate${CapitalizedName}Succ`](responseData.data));
                        return responseData; 
                    } else {
                        dispatch(obj[`didCreate${CapitalizedName}Err`](responseData.message));
                        return responseData; 
                    }
                }).catch( e => {
                    dispatch(obj[`didCreate${CapitalizedName}Err`]('Error parsing json'));
                    console.error(e);
                })
            );
        };
    };

    obj['delete'] = function(id) {
        if (typeof id !== 'number')
            throw Error('Id must be number');
        
        return function (dispatch) {
           dispatch(obj[`willDelete${CapitalizedName}`]());

            return ( 
                call(delete_url(id), {
                    method: 'DELETE',
                    headers: {'Content-type': 'application/json'} 
                })
                .then(response => response.json())
                .then( responseData => {
                    if (responseData.status === 'ok') {
                        //console.info("RECEIVED UPDATE OK", data)
                        dispatch(obj[`didDelete${CapitalizedName}Succ`](id));
                        return responseData; 
                    } else {
                        //console.info("RECEIVED DELETE ERR", responseData);
                        dispatch(obj[`didDelete${CapitalizedName}Err`](id, responseData.message, responseData.object));
                        return responseData; 
                    }
                }).catch( e => {
                    dispatch(obj[`didDelete${CapitalizedName}Err`](id, 'Error parsing json'));
                    console.error(e);
                })
            );  
        };
    };

    obj['update'] = function(id, data, options) {
        options = Object.assign({}, {
            stringify: true,
            contentType: 'application/json',
            method: 'PUT'
        }, options);

        if (typeof data !== 'object')
            throw Error('Update action creator: data is not an object !');

        return function (dispatch) {
            dispatch(obj[`willUpdate${CapitalizedName}`]());

            let opts = options.contentType === false ? {
                method: options.method,
                body: options.stringify ? JSON.stringify(data) : data 
            } : {
                method: options.method,
                headers: {'Content-type': options.contentType},
                body: options.stringify ? JSON.stringify(data) : data 
            };

            return (
                call(update_url(id), opts)
                .then(response => response.json())
                .then(responseData => {
                    if (responseData.status === 'ok') {
                        //console.info("RECEIVED UPDATE OK", data)
                        dispatch(obj[`didUpdate${CapitalizedName}Succ`](id, responseData.data));
                        return responseData; 
                    } else {
                        //console.info("RECEIVED UPDATE ERR", responseData)
                        dispatch(obj[`didUpdate${CapitalizedName}Err`](id, responseData.message));
                        return responseData; 
                    }
                }).catch( e => {
                    dispatch(obj[`didUpdate${CapitalizedName}Err`](id, 'Error parsing json'));
                    console.error(e);
                })
            );
        };
    };

    // Data can contain additional information
    obj['fetchAll'] = function(arg_options, append = false) {
        return function (dispatch) {
           dispatch(obj[`willFetch${CapitalizedName}`]());
           let url = fetchAll_url(arg_options);

            return (
                call(url)
                .then(response => response.json())
                .then( data => {
                    if (data.status === 'ok') {
                        //console.info("RECEIVED GET OK", data)
                        dispatch(obj[`didFetch${CapitalizedName}Succ`](data.data, append));
                        return data;
                    } else {
                        //console.info("RECEIVED GET ERR", data)
                        dispatch(obj[`didFetch${CapitalizedName}Err`](data.message));
                        return data;
                    }
                }).catch( e => {
                    dispatch(obj[`didFetch${CapitalizedName}Err`]('Error parsing json'));
                    console.error(e);
                })
            );
        };
    };

    // for performance reasons not all data are returned with fetchAll action from backed
    // more detailed object info is returned when getting concrete object
    // therefore this action is needed

    obj['fetchConcrete'] = function(id) {

        return function (dispatch) {
            dispatch(obj[`willFetchConcrete${CapitalizedName}`](id));

            return (
                call(fetchConcrete_url(id))
                    .then(response => response.json())
                    .then( responseData => {
                        if (responseData.status === 'ok') {
                            //console.info("RECEIVED UPDATE OK", data)
                            dispatch(obj[`didFetchConcrete${CapitalizedName}Succ`](id, responseData.data));
                            return responseData;
                        } else {
                            //console.info("RECEIVED UPDATE ERR", responseData)
                            dispatch(obj[`didFetchConcrete${CapitalizedName}Err`](id, responseData.message));
                            return responseData;
                        }
                    }).catch( e => {
                        dispatch(obj[`didFetchConcrete${CapitalizedName}Err`](id, 'Error parsing json'));
                        console.error(e);
                })
            );
        };
    };

    return obj;
}