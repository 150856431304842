function getResource(resourceName, resourceActions, state={data:{}, isFetching: false, err: null}, action) {
    let UPPERCASED = resourceName.toUpperCase();

    switch (action.type) {
        case resourceActions[`FETCHING_CONCRETE_${UPPERCASED}`]:
            return Object.assign({}, state, {
                isFetching: true
            });
        case resourceActions[`FETCHING_CONCRETE_${UPPERCASED}_SUCC`]:
            return Object.assign({}, state, {data: action.data, isFetching: false});
        case resourceActions[`FETCHING_CONCRETE_${UPPERCASED}_ERR`]:
            return Object.assign({}, state, {
                isFetching: false,
                err: action.err
            });
        default: 
            return state;     
    }
}

export default function(resource_name, initialState = {}) {
    
    if (resource_name[resource_name.length - 1] === 's') {
        console.warn('Resource name shouldnt end with \'s\'...\n for example roles should be role, employees = employee etc.');
    }
    
    return function (state = initialState, action) {
        const resourceActions = require(`actions/${resource_name}`).default;
        return getResource(resource_name, resourceActions, state, action);
    };
}