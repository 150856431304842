import easterTime from './easter';

const holidays = {
	'0': [1, 6], //januar
	'1': [], // februar
	'2': [], // marec
	'3': [], // april
	'4': [1, 8], // maj
	'5': [], // jun
	'6': [5], // jul
	'7': [29], // aug
	'8': [1, 15], // sep
	'9': [], // okt
	'10': [1, 17], // nov
	'11': [24,25,26] //dec
};

export default function getHolidays(year /*:number*/, month /*:number*/) /*:Array<number>*/ {
	const easter = easterTime(year);
	const easterMonth = easter.getMonth();

	// Friday before and Monday after Easter
	const easterDays = [easter.getDate() + 1, easter.getDate() - 2];

	return [].concat(
		holidays[month],
		easterMonth === month ? easterDays : []
	);
}
