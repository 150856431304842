import React from 'react';
import { withFormsy } from 'formsy-react';
import PT from 'prop-types';
import { Form, Row } from 'react-bootstrap';
import classNames from 'classnames';

class MyCheckBox extends React.Component {
    changeValue(e) {
        this.props.setValue(e.target.checked);
        if (this.props.onChange) {
            this.props.onChange(e.target.checked);
        }
    }

    render() {
        const { props: P } = this;

        const errorMessage = P.errorMessage;
        const show_error = !P.isValid && (!P.isPristine || P.isFormSubmitted);

        return <Row className={'mb-2'}>
            <label htmlFor={P.name} className={
                classNames({
                    'col-form-label': true,
                    'col-sm-4': true,
                    'text-danger': Boolean(!P.isValid)
                })}>
                {P.label}
                {P.required ?
                    <span className={'required-symbol'}> *</span> : null
                }
            </label>
            <div className={'col-sm-8'}>
                <Form.Check
                    style={{
                        marginTop: '7px',
                        'zIndex': 0,
                    }}
                    onChange={ e => this.changeValue(e)}
                    checked={P.value}
                    name={P.name}
                    id={P.name}
                    type={'checkbox'}
                    label={''}
                />
                { show_error ?
                    <span
                        className={'invalid-feedback'}
                        style={{display: 'block'}}
                    >
                        { errorMessage }
                    </span> : null
                }
            </div>
        </Row>;
    }
}

MyCheckBox.propTypes = {
    checked: PT.bool,
};

MyCheckBox.defaultProps = {
    checked: false,
};

export default withFormsy(MyCheckBox);
