import React from 'react';
import ReactLoading from 'react-loading';

const containerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const Loader = (props) => {

    const type = props.type || 'spin';
    const color = props.color || '#c1c0c0';
    const height = props.size || 30;
    const width = props.size || 30;

    return (
        <div style={containerStyle}>
            <ReactLoading height={height} width={width} type={type} color={color}/>
        </div>
    );
};

export default Loader;