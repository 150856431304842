import React from 'react';
import PropTypes from 'prop-types';
import Formsy, {withFormsy} from 'formsy-react';
import Input from 'components/Input';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {MdClose} from 'react-icons/md';
import '../Employees/style.css';
import call from '../../ajax/call';
import {Spinner} from 'react-activity';
import 'react-activity/dist/Spinner.css';
import classNames from 'classnames';
import Select, {createFilter} from 'react-select';
import {formatDate} from 'scripts/dateFormatter';
import FormCheckBox from 'components/CheckBox';

class MySelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showOptions: false
        };
    }

    changeValue(val) {
        this.props.setValue(val);
    }

    handleInputChange(typedOption) {
        if (typedOption.length > 0) {
            this.setState({ showOptions : true });
        }
        else {
            this.setState({ showOptions : false });
        }
    }

    render() {
        const {props: P} = this;
        const errorMessage = this.props.errorMessage;
        const show_error = !P.isValid && (!P.isPristine || P.isFormSubmitted);

        return <div className={'row'}>
            <label className={
                classNames({
                    'col-form-label': true,
                    'col-sm-4': true,
                    'text-danger': show_error
                })}>
                {P.label}
                {P.required ? <span className={'required-symbol'}> *</span> : null }
            </label>
            <div
                className={'col-sm-8'}
                style={{
                    ...P.style
                }}
            >
                <Select
                    options={ this.state.showOptions ? P.options : [] }
                    isClearable={true}
                    filterOption={createFilter({
                        trim: true,
                        matchFrom: 'start'
                    })}
                    value={P.value}
                    getOptionValue={option => P.getOptionValue(option)}
                    getOptionLabel={option => P.getOptionLabel(option)}
                    onChange={ val => this.changeValue(val)}
                    isDisabled={false}
                    placeholder={'Zvoľte číslo zákazky (zákaziek)'}
                    noOptionsMessage={() => 'Zadajte číslo zákazky'}
                    isMulti={true}
                    onInputChange = { (input) => this.handleInputChange(input) }
                    components={{
                        'DropdownIndicator': () => null,
                    }}
                />
                { show_error ?
                    <span
                        className={'invalid-feedback'}
                        style={{display: 'block'}}
                    >
                        { errorMessage }
                    </span> : null
                }
            </div>
        </div>;
    }
}
const FormSelect = withFormsy(MySelect);


class WorkCostsExportForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            useDateRange: false,
        };
    }

    submitHandler(model) {
        if (!model.orders) {
            return false;
        }

        this.setState({
            formSubmitted: true,
        });

        const selectedOrders = model.orders.map(o => o.id);

        let route = this.props.url;
        if (this.state.useDateRange) {
            route = route + `/${model.from}/${model.to}`;
        }
        call(route, {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({ selectedOrders }),
        }).then( () => {
            this.props.onClose(model);
            this.setState({formSubmitted: false});
        }).catch(e => console.log(e));
    }

    componentDidMount() {
        this.setState({
            formSubmitted: false,
            useDateRange: false,
        });
    }

    render() {
        const {props: P } = this;

        return (
            <Modal
                onHide={ () => P.onClose() }
                show={P.show}>
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <Formsy
                            onValidSubmit={ model => this.submitHandler(model) }
                        >
                            <FormSelect
                                name={'orders'}
                                label={'Zákazky'}
                                options={P.orders}
                                getOptionValue={ o => o.id}
                                getOptionLabel={ o => o.serial_number}
                                required={true}
                            />
                            <FormCheckBox
                                name={'use_date_range'}
                                label={'Zvoliť dátumové ohraničenie'}
                                value={false}
                                id={'use_date_range'}
                                onChange={ val => this.setState({
                                    useDateRange: val
                                })}
                            />
                            {
                                this.state.useDateRange ?
                                    <div>
                                        <Input
                                            name={'from'}
                                            label={'Od'}
                                            type={'date'}
                                            required
                                            value={formatDate(new Date('2010-01-01'))}
                                            //min={}
                                        />
                                        <Input
                                            name={'to'}
                                            key={'to'}
                                            label={'Do'}
                                            type={'date'}
                                            required
                                            value={formatDate(new Date())}
                                            max={formatDate(new Date())}
                                        />
                                    </div>
                                    : null
                            }
                            <Row className={'mt-2'}>
                                <Col md={{span: 4, offset: 4}}>
                                    <Button
                                        variant="danger"
                                        type="submit"
                                    >
                                        Exportovať
                                    </Button>
                                </Col>
                                <Col md={{span: 4, offset: 0}}>
                                    <Spinner animating={this.state.formSubmitted}/>
                                </Col>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

WorkCostsExportForm.propTypes = {
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func
};

WorkCostsExportForm.defaultProps = {
    onSubmit: () => {},
    updating: false,
};

const mapStateToProps = (state) => {
    return {
        orders: state.orders.items
            .filter(o => o.serial_number.toUpperCase().slice(-1) !== 'D')
            .sort((a, b) => a.serial_number.localeCompare(b.serial_number))
    };
};

export default connect(mapStateToProps)(WorkCostsExportForm);
