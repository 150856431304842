import React from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import 'css/custom-select-style.css';
import call from '../../ajax/call';
import Form from 'react-bootstrap/cjs/Form';
import store from '../../store';
import {setGlobalError} from '../../actions/globalError';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.state = {
            errors: {},
            formSubmitted: false,
            isFetching: false,
        };
        this.email = React.createRef();
        this.token = React.createRef();
        this.password = React.createRef();
        this.password_confirmation = React.createRef();
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.password.current.value) {
            this.setState({
                formSubmitted: true,
                isFetching: true,
            });

            call('/api/reset_password', {
                method: 'POST',
                headers: {'Content-type': 'application/json'},
                body: JSON.stringify({
                    'email': this.email.current.value,
                    'token': this.token.current.value,
                    'password': this.password.current.value,
                    'password_confirmation': this.password_confirmation.current.value,
                })
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        formSubmitted: false,
                        isFetching: false,
                    });
                    if (data.status === 'ok') {
                        store.dispatch(setGlobalError([data.message], 'info', false));
                        this.props.history.push('/login');
                    } else {
                        store.dispatch(setGlobalError([data.message], 'info'));
                    }
                });
        }
    }

    componentDidMount() {
        this.password.current.focus();
    }

    render() {
        const query =  new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const token = query.get('token');

        return (
            <Container className='login-page'>
                <Row>
                    <Col
                        lg={{span: 4, offset: 4}}
                        md={12}
                        className="login-form"
                    >
                        <div>
                            <Form
                                className="form"
                                onSubmit={this.handleFormSubmit.bind(this)}
                                autoComplete={'off'}
                            >
                                <h2>Zmena hesla</h2>
                                <div>
                                    <input
                                        ref={this.password}
                                        type='password'
                                        name='password'
                                        placeholder='Nové heslo'
                                    />
                                    <input
                                        ref={this.password_confirmation}
                                        type='password'
                                        name='password_confirmation'
                                        placeholder='Zopakujte nové heslo'
                                    />
                                    <input
                                        ref={this.token}
                                        name='token'
                                        type='hidden'
                                        value={token}
                                    />
                                    <input
                                        ref={this.email}
                                        name='email'
                                        type='hidden'
                                        value={email}
                                    />
                                </div>
                                <input
                                    className={this.state.isFetching ? 'btn disabled': ''}
                                    style={this.state.isFetching ? {cursor: 'not-allowed! important'} : {}}
                                    aria-disabled={this.state.isFetching}
                                    type="submit"
                                    value="Zmeniť heslo"
                                />
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ResetPassword;