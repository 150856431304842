import React from 'react';
import { SuperTable } from 'components/PaginationTable';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import trainingActions from 'actions/trainings';
import store from 'store/mainStore';
import { Button } from 'react-bootstrap';
import NeedWritePerm from 'components/NeedPermission';
import EditForm from './EditForm';
import {Link} from 'react-router-dom';
import { MdAssignment } from 'react-icons/md';
import {isNotEmpty} from '../../scripts/valueNormalizer';

class OrdersContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            objToUpdate: undefined
        };
    }

    componentDidMount() {
        store.dispatch(trainingActions.fetchAll());
    }

    closeFormHandler() {
        this.setState({
            formActive: false,
            objToUpdate: undefined
        });
    }

    submitHandler(obj) {
        let formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }

        let action = {};
        if (this.state.objToUpdate) {
            formData.append('_method', 'PUT');
            action = trainingActions.update(this.state.objToUpdate.id, formData, {
                stringify: false,
                contentType: false,
                method: 'POST',
            });
        } else {
            action = trainingActions.create(formData, {
                stringify: false,
                contentType: false,
            });
        }

        store.dispatch(action).then((data) => {
            if (data.status === 'ok') {
                this.setState({
                    formActive: false,
                    objToUpdate: undefined
                });
            }
        });
    }

    handleItemDelete(id) {
        store.dispatch(trainingActions.delete(id));
    }

    handleItemUpdate(id) {
        this.setState({
            objToUpdate: this.props.trainings.find(t => t.id === id),
            formActive: true
        });
    }

    render() {
        const { props: P, state: S} = this;

        const items = P.trainings.map(t => ({
            ...t,
            type: t.type.name,
            count: t.employees ? t.employees.length : 0,
        }));

        return (
            <Container className="buildings">
                <div>
                    <h3>
                        Školenia
                    </h3>
                </div>
                <NeedWritePerm type="button" need={['certifications']}>
                    <Button
                        variant="success"
                        onClick={() => {
                            this.setState({
                                formActive: true
                            });
                        }}
                        style={{ marginRight: 15 }}
                    >
                        Pridať školenie
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm
                    type={'button'}
                    need={['certifications']}
                >
                    <Link to='/employees/certification_types'>
                        <Button variant="success"><MdAssignment />Typy osvedčení</Button>
                    </Link>
                </NeedWritePerm>
                <NeedWritePerm type="table" need={['certifications']}>
                    <SuperTable
                        name={'trainings'}
                        items={items}
                        thead={[
                            {name: 'event_name', sk_name: 'Názov', type: 'string'},
                            {name: 'event_time', sk_name: 'Dátum konania', type: 'date'},
                            {name: 'valid_from', sk_name: 'Platnosť od', type: 'date'},
                            {name: 'type', sk_name: 'Typ', type: 'string'},
                            {name: 'valid_to', sk_name: 'Platnosť do', type: 'date'},
                            {name: 'count', sk_name: 'Účasť', type: 'number'},
                            {name: 'document', sk_name: 'Dokument', type: 'file'},
                        ]}
                        columnSelector
                        onDeleteItem={id => this.handleItemDelete(id)}
                        onEditItem={id => this.handleItemUpdate(id)}
                        onRowClick={id => this.props.history.push(`/trainings/${id}`)}
                        defaultSortKey='event_time'
                        defaultAsc={false}
                        delOps
                        editOps
                        searchable
                        is_loading = {P.is_loading}
                    />
                </NeedWritePerm>
                <EditForm
                    key={S.objToUpdate ? S.objToUpdate.id : 'new_id'}
                    show={S.formActive}
                    onClose={() => this.closeFormHandler()}
                    onSubmit={(model) => this.submitHandler(model)}
                    updating={ !!S.objToUpdate }
                    defaultValues={S.objToUpdate}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        trainings: state.trainings.items,
        is_loading: state.orders.isFetching,
    };
};

export default connect(mapStateToProps)(OrdersContainer);
