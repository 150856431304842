import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormSelect, FormLabel, Row, Col } from 'react-bootstrap';

class PageControl extends React.Component {
    render(){
        return (
            <Form>
                <Row>
                <Col md={6} sm={12}>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (this.props.currentPage > 0){
                                this.props.prevPage();
                            }
                        }}
                        style={{ marginRight: 20, marginTop: 20, marginBottom: 20 }}>
                        Predošlá
                    </Button>
                    Stránka {this.props.currentPage + 1} z {this.props.pagesCount}
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (this.props.currentPage < this.props.pagesCount - 1){
                                this.props.nextPage();
                            }
                        }}
                        style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}
                    >Ďalšia</Button>
                </Col>
                <Col md={3} sm={6} style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <FormLabel
                        style={{marginRight: 20}}
                    >
                        Počet riadkov na stránku:
                    </FormLabel>
                </Col>
                <Col md={3} sm={6} style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <FormSelect
                        value = {this.props.selected_value}
                        as="select"
                        onChange={o => this.props.onPageSizeChange(o.target.value)}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </FormSelect>
                </Col>
                </Row>
            </Form>
        );
    }
}

PageControl.propTypes = {
    prevPage: PropTypes.func,
    nextPage: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    current_page: PropTypes.number,
    pages_count: PropTypes.number,
    selected_value: PropTypes.number
};

PageControl.defaultProps = {
    prevPage: () => {},
    nextPage: () => {},
    onPageSizeChange: () => {},
    currentPage: 0,
    pagesCount: 0,
    selected_value: 10
};

export default PageControl;