import React, { useState } from 'react';
import styled from 'styled-components';
import ReactSlider from 'components/ReactSlider';
import ReactTooltip from 'react-tooltip';
import DV, { extractTime, parseSeconds} from 'scripts/dateFormatter';

import {Button} from 'react-bootstrap';
import store from 'store/mainStore';
import work_performance_actions from 'actions/work_performance';
import {deleteUnsavedChanges} from 'actions/unsaved_changes';

const StyledSlider = styled(ReactSlider)`
    width: calc(100% - 120px);
    height: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
`;

const StyledThumb = styled.div`
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
    top: -10px
`;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => {
        if (props.index === props.items) {
            return '#DDD';
        }
        return 'rgb(9,84,0)';
    }};
    border-radius: 999px;
`;

export {StyledSlider, StyledThumb, StyledTrack};

function prepareData(items) {
    let array = [];
    items.forEach( i => array.push( array.length ? array[array.length - 1] + i.percent_done : i.percent_done));

    return array;
}

function getValue(index, values) {
    if (index === 0) {
        return values[0];
    } else {
        return values[index] - values[index - 1];
    }
}

const getToolTip = (id, state, value, wp) => {
    if (wp && state.index < value.length) {
        return <ReactTooltip
            id={id}
            type='dark'
            place="top"
        >
            <div style={{fontSize: '1.2em'}}>
                {wp.group_names} <br/>
                {
                    DV(wp.started_at, false)
                    + ' ' + extractTime(wp.started_at)
                    + ' - ' + extractTime(wp.finished_at)
                } <br/>
                {' odpracované ' + getValue(state.index, value) + '%, časový rozdiel: ' + parseSeconds(wp.total_time)}
                <br/>
                {wp.description || ''}
            </div>
        </ReactTooltip>;
    } else {
        return null;
    }
};


const saveChanges = (values, op) => {
    if (!(values instanceof Array)) {
        values = [values];
    }

    let intervals = [];
    values.forEach( (v, i) => {
        intervals.push(getValue(i, values));
    });

    let records = [];
    op.items.forEach( (v, i) => {
        if (v.percent_done !== intervals[i]) {
            records.push({
                ...v,
                percent_done: intervals[i],
                diff: intervals[i] - v.percent_done
            });
        }
    });

    records.sort((a, b) => a.diff - b.diff)
        .forEach(r => store.dispatch(work_performance_actions.update(r.id, r)));

    store.dispatch(deleteUnsavedChanges());
};

const ButtonDiv = styled.div`
    margin-left: 20px;
    margin-top: 5px;
    display: inline-block;
`;

function hasChanged(values, index, original) {
    if (!(values instanceof Array)) {
        values = [values];
    }
    return values.some( (v, i) => original[i] !== v);
}

const Slider = (props) => {
    const initialData = prepareData(props.operation.items);
    const [value, setValue] = useState(initialData);
    const [changed, setChanged] = useState(false);
    const prefix = `operation-${props.operation.id}`;

    return (
        <div style={{display: 'flex'}}>
            <StyledSlider
                value={value}
                minDistance={1}
                onChange={(newVal) => setValue(newVal)}
                onAfterChange={ (newVal, index) => {
                    if (props.onChange) {
                        setChanged(hasChanged(newVal, index, initialData));
                    }
                }}
                renderTrack={(inner_props, state) =>
                    <div key = {`${prefix}-${state.index}`}>
                        <StyledTrack
                            {...inner_props}
                            index={state.index}
                            items={ state.value instanceof Array ? state.value.length : 1}
                            data-tip data-for={`${prefix}-${state.index}`}
                        />
                        {getToolTip(`${prefix}-${state.index}`, state, value, props.operation.items[state.index])}
                    </div>
                }
                renderThumb={(props, state) =>
                    <StyledThumb {...props} data-tip data-for={`${prefix}-${state.index}`}>
                        {state.valueNow}
                    </StyledThumb>
                }
                snapDragDisabled={true}
                disabled={value.length === 0}
            />
            {
                changed ? <ButtonDiv>
                    <Button
                        variant="success"
                        onClick={() => saveChanges(value, props.operation)}
                >
                    Uložiť
                </Button>
                </ButtonDiv>: null
            }
        </div>
    );
};

export default Slider;