import React from 'react';
import PropTypes from 'prop-types';
import call from 'ajax/call';
import { SuperTable} from 'components/PaginationTable';

class HistoryCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
		this._mounted = true;

        let { id } = this.props;
        call(`/api/employees/${id}/card_history`)
         .then(response => response.json())
         .then(data => {
             if (data.status === 'ok' && this._mounted) {
                  this.setState({
                     items: data.data
                 });
             }
         });
	}
	
	componentWillUnmount() {
		this._mounted = false;
	}

    render() {
        return (
            <div style={{ paddingTop: 40 }}>
                <SuperTable
                    name={'card_history'}
                    items={this.state.items}
                    thead={[{
                        name: 'from_date',
                        sk_name: 'od dátumu',
                        type: 'date',
                    },{
                        name: 'to_date',
                        sk_name: 'do dátumu',
                        type: 'date',
                    },{
                        name: 'card_num',
                        sk_name: 'číslo karty',
                        type: 'string',
                    }
                    ]}
                    columnSelector
                />

            </div>
         );
    }
}

HistoryCards.propTypes = {
    id: PropTypes.any.isRequired
};

HistoryCards.defaultProps = {

};

export default HistoryCards;