import React from 'react';
import { connect } from 'react-redux';
import store from 'store/mainStore';
import tabletsActions from 'actions/tablets';
import employeesActions from 'actions/employees';
import { withRouter } from 'react-router-dom';
import Form from './TabletForm';
import { Container, Button } from 'react-bootstrap';
import ND from 'scripts/normalizeDate';
import NeedWritePermission from 'components/NeedPermission';
import { addDependency } from 'actions/dependency';
import { SuperTable } from 'components/PaginationTable';

class Tablets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            objToUpdate: undefined
        };
    }

    componentDidMount() {
        store.dispatch(tabletsActions.fetchAll());
        store.dispatch(employeesActions.fetchAll());
    }

    handleRowClick(id) {
        this.props.history.push('/tablets/' + id);
    }

    handleShowForm() {
        this.setState({showForm: true});
    }

    handleHideForm() {
        this.setState({
            showForm: false,
            objToUpdate: undefined
        });
    }

    handleEditTablet(id) {
        this.setState({
            showForm: true,
            objToUpdate: this.props.tablets.find(t => t.id === id) 
        });
    }

    handleFormSubmit(obj) {
        const { objToUpdate } = this.state;

        let action = {};
        if (objToUpdate) {
            action = tabletsActions.update(objToUpdate.id, obj);
        } else {
            action = tabletsActions.create(obj);
        }

        store.dispatch(action).then(data => {
            if (data.status === 'ok'){
                this.setState({
                    showForm: false,
                    objToUpdate: undefined
                });
            }
        });
    }

    handleDeleteTablet(id) {
        store.dispatch(tabletsActions.delete(id));
    }

    render() {
        const { props:P, state:S } = this;
        const empl = P.employees;
        const items = P.tablets.map(tablet => {
            let found_owner = empl.find(emp => emp.id === parseInt(tablet.owner_id, 10));
            let name = found_owner ? found_owner.last_name + ' ' + found_owner.first_name : '' ;
            return Object.assign({}, tablet, {
                owner_name: name,
                from_date: tablet.from_date || ND((new Date()).toString())
            });
        });

        return (
            <div className="tablets"> 
                <Container>
                    <NeedWritePermission type="button" need={['tablets']}>
                        <Button 
                            variant="success"
                            onClick={this.handleShowForm.bind(this)}
                        >
                            Pridať tablet
                        </Button>
                    </NeedWritePermission>

                    <NeedWritePermission type="table" need={['tablets']}>
                        <SuperTable
                            name={'tablets'}
                            thead={
                                [
                                    {name: 'name', sk_name: 'názov', type: 'string'},
                                    {name: 'imei', sk_name: 'IMEI', type: 'string', hidden: true},
                                    {name: 'serial_num', sk_name: 'Sériové číslo', type: 'string', hidden: true},
                                    {name: 'owner_name', sk_name: 'Meno vlastníka', type: 'string'},
                                    {name: 'last_synchronization', sk_name: 'Posledná synchronizácia', type: 'date-time'},
                                    {name: 'login_name', sk_name: 'Prihlasovacie meno', type: 'string', hidden: true},
                                    {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true}
                                ]
                            }
                            columnSelector
                            defaultSortKey='name'
                            items={items} 
                            onRowClick={this.handleRowClick.bind(this)} 
                            onEditItem={this.handleEditTablet.bind(this)}
                            onDeleteItem={this.handleDeleteTablet.bind(this)}
                            delOps editOps searchable
                            export={'tablety'}
                            is_loading = {P.isFetching}
                        />
                    </NeedWritePermission>
                </Container>
                
                <Form
                    key={S.objToUpdate ? S.objToUpdate.id : 'new_id'}
                    tablets={P.tablets}
                    onSubmit={this.handleFormSubmit.bind(this)} 
                    updating={!!S.objToUpdate}
                    show={S.showForm} 
                    defaultValues={S.objToUpdate || {}}
                    onClose={this.handleHideForm.bind(this)} 
                    employees={P.employees} />
            </div>
        );
    }
}

addDependency('tablets', ['webapp_access', 'employees']);

const mapStateToProps = state => {
    
    return {
        employees: state.employees.items,
        tablets: state.tablets.items,
        isFetching: state.tablets.isFetching,
        err: state.tablets.err
    };
};

export default withRouter(connect(mapStateToProps)(Tablets));