import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import NewFileInput from 'components/NewFileInput';
import call from 'ajax/call';
import Spinner from '../Spinner';

class FilesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            counter: 1,
            files: [],
            loading: false,
        };
    }

    fetchFiles() {
        this.setState({loading: true});

        call(this.props.files_url, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    let counter = data.data.reduce((max, f) => Math.max(max, f.id), 0) + 1;

                    this.setState({
                        files: data.data,
                        loading: false,
                        counter
                    });
                }
            });
    }

    async sendFile(file) {
        let formData = new FormData();
        formData.append('file', file, file.name);

        return await call(this.props.files_url, {
            method: 'POST',
            body: formData
        });
    }

    async deleteFile(file_id) {
        return await call(`${this.props.files_url}/${file_id}`, {
            method: 'DELETE',
        });
    }

    onFileChange(file, originalFile) {
        if (originalFile || !file) {
            this.deleteFile(originalFile.id).then(() => this.props.afterChange());
        }

        if (file) {
            this.sendFile(file).then(() => this.props.afterChange());
        }
    }

    render() {
        const {props: P} = this;

        if (P.loading) {
            return <Spinner/>;
        }

        return (
            <div className="register-form">
                <Formsy>
                    <div style={{
                        height: 40,
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(0,0,0,0.1)',
                        borderRadius: 5,
                        marginTop: 10,
                        marginBottom: 10,
                    }}>
                        {P.heading}
                    </div>
                    {
                        this.props.files.map((file) => {
                            return (
                                <NewFileInput
                                    key={file.id}
                                    onChange={f => this.onFileChange(f, file)}
                                    file_name={file.name ? file.name : file.client_name}
                                    server_name={file.file_name}
                                    file={file}
                                    editable={P.editable}
                                />
                            );
                        })
                    }
                    {P.editable ?
                        <NewFileInput
                            key={this.state.counter}
                            onChange={file => this.sendFile(file, P.model_id)
                                .then(() => P.afterChange())
                            }
                            file_name={null}
                            server_name={null}
                        /> : null
                    }
                </Formsy>
            </div>
        );
    }
}

FilesView.propTypes = {
    heading: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(PropTypes.object).isRequired,
    editable: PropTypes.bool.isRequired,
    files_url: PropTypes.string.isRequired,
    afterChange: PropTypes.func,
    loading: PropTypes.bool.isRequired
};

FilesView.defaultProps = {
    files: [],
    editable: true,
    afterChange: () => {},
    loading: false,
};

export default FilesView;
