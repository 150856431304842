import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Row} from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import DF, {format} from 'scripts/dateFormatter';

class Form extends React.Component {

    render() {
        const { props:P } = this;

        let noData = '';
        if (P.records.length == 0) {
            noData = 'Žiadne záznamy';
        }
        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
                size={'md'}
            >
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '14pt',
                        }}
                    >
                        {P.title}
                        <MdClose
                            className = {'closeButton'}
                            size={24}
                            onClick={P.onClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        P.records.map( r => (
                            <Row key={r.work_performance_id}
                                style={{
                                    borderRadius: 10,
                                    background: 'rgba(176,202,241,0.51)',
                                    marginTop: 5,
                                    marginLeft: 0,
                                    marginRight: 0,
                                }}
                            >
                                <div style={{
                                    margin: 3,
                                }}>
                                    <div>
                                        <span style={{fontWeight: 'bold', marginRight: 15}}>
                                            {DF(r.started_at, true, {month: 'numeric'})} 
                                            {' - '}
                                            {format(r.finished_at, {
                                                hour: 'numeric', minute: 'numeric'
                                            })}
                                        </span>
                                        <span>
                                            {r.position_name} {r.description}
                                        </span>
                                    </div>
                                    <div style={{fontWeight: 'bold'}}>
                                        {r.operation_name}
                                    </div>
                                    <div>
                                        {r.group_members_names}
                                    </div>
                                </div>
                            </Row>
                        ))
                    }
                    {noData}
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    records: PropTypes.array.isRequired,
    title: PropTypes.string,
};

export default Form;
