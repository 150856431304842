import React, {Component} from 'react';
import {components} from 'react-select';
import AsyncSelect from 'react-select/async';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {Button, Col, Row} from 'react-bootstrap';
import call from 'ajax/call';

const searchAPI = text => {
    return call('/api/material_items/search?query=' + encodeURIComponent(text));
};
const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 300);

const CustomOption = ({ children, ...props }) => {
    return (
        <components.Option
            {...props}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <span>
                    <img
                        style={{height: 35, marginRight: 10}}
                        src={props.data.image_url}
                        alt={'obrazok'}
                    />
                </span>
                {children}
                <span style={{marginLeft: 'auto'}}>
                    (Skladom: {props.data.count} {props.data.mainunitcode})
                </span>
            </div>
        </components.Option>
    );
};

class MySelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            input: '',
            options: []
        };
    }

    async loadOptions(input, callback) {
        this.setState({input});

        if (input.length >= 3) {
            let response = await searchAPIDebounced(input);
            let data = await response.json();

            if (data.status === 'ok') {
                callback(data.data);
                this.setState({options: data.data});
            }
        }
        callback(this.state.options);
    }

    render() {
        const {props: P} = this;

        return (
        <div>
            <Row className={'mb-2'}>
                <Col sm={2}>
                    {P.label}
                </Col>
                <Col sm={8}>
                    <AsyncSelect
                        cacheOptions={true}
                        getOptionValue={o => o.id}
                        getOptionLabel={o => o.name}
                        onChange={ val => this.setState({value: val})}
                        value={this.state.value}
                        placeholder={'Zadajte hľadaný výraz'}
                        noOptionsMessage={() => 'Žiadne výsledky'}
                        loadOptions={this.loadOptions.bind(this)}
                        components={{
                            'DropdownIndicator': () => null,
                            'Option': CustomOption
                        }}
                    />
                </Col>
                <Col sm={2} style={{
                    textAlign: 'right'
                }}>
                    <Button
                        variant="success"
                        type="submit"
                        disabled={!this.state.value}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.onMaterialSelect(this.state.value);
                        }}
                    >
                        Pridať
                    </Button>
                </Col>
            </Row>
        </div>
        );
    }
}

export default MySelect;
