import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import MaterialSelect from './MaterialSelect';
import ProductItem from './ProductItem';
import NewFileInput from 'components/NewFileInput';
import handleExport from 'scripts/exportToExcel';

class ProductForm extends Component {

    constructor(props) {
        super(props);

        let items = [];
        let files = [];

        if (props.updating) {
            items = props.objectToUpdate.items.map(i => ({
                ...i.store_card,
                image_url: `/api/pictures/${i.store_card.picture_id}`,
                quantity: i.quantity,
            }));
            files = props.objectToUpdate.files;
        }

        this.state = {
            formSubmitted: false,
            items,
            files,
            name: '',
            deleted_files: [],
            counter: files.reduce((max, f) => Math.max(max, f.id), 0) + 1,
        };
    }

    onSubmit(model) {
        this.setState({
            formSubmitted: true,
        });

        this.props.onSubmit({
            name: model.name,
            quantity: model.quantity,
            comment: model.comment,
            files: this.state.files,
            deleted_files: this.state.deleted_files,
            items: this.state.items.map(item => ({
                    storecard_id: item.id,
                    quantity: item.quantity
            }))
        });
    }

    onQuantityChange(id, value) {
        let items = this.state.items.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    quantity: value
                };
            } else {
                return item;
            }
        });

        this.setState({items});
    }

    onFileChange(file, originalFile) {
        let id = originalFile.id;

        this.setState(oldState => {
            let files = [];
            let deleted_files = oldState.deleted_files;
            if (originalFile.created_at) {
                deleted_files.push(id);
            }

            if (!file) {
                files = oldState.files.filter(f => f.id !== id);
            } else {
                file.id = id;
                files = oldState.files.map(f => f.id === file.id ? file : f);
            }
            return {
                files,
                deleted_files
            };
        });
    }

    render() {
        const {props: P, state: S} = this;
        let exportColumns = [
            {name: 'code', sk_name: 'Kod sklad. karty', type: 'string'},
            {name: 'name', sk_name: 'Nazov', type: 'string'},
            {name: 'quantity', sk_name: 'Pocet', type: 'number'},
            {name: 'mainunitcode', sk_name: 'Jednotka', type: 'string'},
        ];
        let exportName = this.state.name;
        if (!exportName && P.updating) {
            exportName = P.objectToUpdate.name;
        }

        return (
            <Modal
                onHide={() => P.onClose()}
                show={P.show}
                size={'xl'}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={this.props.onClose}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <Formsy
                            onValidSubmit={model => this.onSubmit(model)}
                        >
                            <Input
                                type={'text'}
                                label={'Názov'}
                                name={'name'}
                                value={P.updating ? P.objectToUpdate.name : ''}
                                onChange={val => this.setState({name: val})}
                                required
                            />
                            <Input
                                type={'number'}
                                value={P.updating ? String(P.objectToUpdate.quantity) : '1'}
                                min={1}
                                max={1000}
                                label={'Počet kusov'}
                                name={'quantity'}
                                required
                            />
                            <TextArea
                                type={'text'}
                                label={'Poznámka'}
                                name={'comment'}
                                required={false}
                                value={P.updating ? (P.objectToUpdate.comment || '') : ''}
                            />
                            <div style={{
                                height: 40,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0,0,0,0.1)',
                                borderRadius: 5,
                                marginTop: 10,
                                marginBottom: 10,
                            }}>
                                Výkresy:
                            </div>
                            {
                                this.state.files.map((file) => {
                                    return (
                                        <NewFileInput
                                            key={file.id}
                                            onChange={f => this.onFileChange(f, file)}
                                            file_name={file.name ? file.name : file.client_name }
                                            server_name={file.created_at ? file.file_name : null}
                                            file={file}
                                            editable={true}
                                        />
                                    );
                                })
                            }
                            <NewFileInput
                                key={this.state.counter}
                                onChange={ (file) => {
                                    this.setState( (oldState) => {

                                        let files = oldState.files.slice();
                                        file.id = oldState.counter;
                                        files.push(file);

                                        return {
                                            files,
                                            counter: oldState.counter + 1
                                        };
                                    });
                                }}
                                editable={true}
                                file_name={null}
                                server_name={null}
                            />
                            <div style={{
                                height: 40,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0,0,0,0.1)',
                                borderRadius: 5,
                                marginTop: 10,
                                marginBottom: 10,
                            }}>
                                Polozky:
                            </div>
                            <div className={'mb-4'}/>
                            <MaterialSelect
                                name={'material'}
                                getOptionValue={o => o.id}
                                getOptionLabel={o => o.name}
                                isFormSubmitted={() => S.formSubmitted}
                                isClearable={true}
                                onMaterialSelect={val => {
                                    if (val) {
                                        let item = S.items.find(i => i.id === val.id);
                                        if (item === undefined) {
                                            val.quantity = 1;
                                            this.setState({items: [...S.items, val]});
                                        } else {
                                            let items = this.state.items.map(i => i.id === val.id ? ({
                                                ...i,
                                                quantity: i.quantity + 1
                                            }) : i);

                                            this.setState({items});
                                        }
                                    }
                                }}
                            />
                            {
                                S.items.map( (i) => {
                                    return (
                                        <ProductItem
                                            key={i.id}
                                            onDelete={(id) => this.setState({
                                                items: S.items.filter(i => i.id !== id)
                                            })}
                                            onQuantityChange={val => this.onQuantityChange(i.id, val)}
                                            {...i}
                                        />
                                    );
                                })
                            }
                            <Row>
                                <span style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <Button variant="success" type="submit">
                                        {P.updating ? 'Upraviť' : 'Vytvoriť'}
                                    </Button>
                                    <Button onClick={(e) => {
                                        handleExport(e, exportColumns, S.items, exportName);
                                    }}>
                                        {'Exportovať'}
                                    </Button>
                                </span>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

ProductForm.propTypes = {
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    objectToUpdate: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

ProductForm.defaultProps = {
    objectToUpdate: {}
};

export default ProductForm;
