export const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES';
export const DELETE_UNSAVED_CHANGES = 'DELETE_UNSAVED_CHANGES';


export function setUnsavedChanges(obj) {
    return {
        type: SET_UNSAVED_CHANGES,
        data: obj,
    };
}

export function deleteUnsavedChanges() {
    return {
        type: DELETE_UNSAVED_CHANGES,
    };
}