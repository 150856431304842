import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Container, Tabs, Tab, Row, Col, Alert} from 'react-bootstrap';
import store from 'store/mainStore';
import storeCardActions from 'actions/storecard';
import Spinner from 'components/Spinner';
import dateFormat from 'scripts/dateFormatter';
import {SuperTable} from 'components/PaginationTable';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import ImageViewer from 'components/ImageViewer';
import getSelector from '../typeSelector';
import './style.css';

class Detail extends React.Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        store.dispatch(storeCardActions.fetchId(id));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            store.dispatch(storeCardActions.fetchId(this.props.match.params.id));
        }
    }

    render() {
        const { props:P } = this;

        if (P.storeCard.isFetching || !P.storeCard.data.id) {
            return <Spinner/>;
        }

        let thead_items = [
            {name: 'date', sk_name: 'Dátum', type: 'short_date'},
            {name: 'time', sk_name: 'Čas', type: 'time'},
            {name: 'doc_code', sk_name: 'Číslo dokladu', type: 'string'},
            {name: 'type_name', sk_name: 'Typ', type: 'string'},
            {name: 'busorder_code', sk_name: 'Zákazka', type: 'string'},
            {name: 'quantity', sk_name: 'Počet', type: 'number'},
            {name: 'unit', sk_name: 'Jednotka', type: 'string'},
            {name: 'store', sk_name: 'Sklad', type: 'string'},
        ];
        if (P.permissions.material_prices) {
            thead_items.push({
                name: 'unit_price', sk_name: 'Skladová cena', type: 'number'
            });
        }
        thead_items.push({name: 'firm_name', sk_name: 'Firma', type: 'string', hidden: true});

        let images = P.storeCard.data.store_card_pictures.map( item => ({
            original: item.image_url,
            thumbnail: item.image_url
        }));
        let image = null;
        if (images.length === 1) {
            let url = images[0].original;
            image = <Row>
                <Col xs={12} className="text-center" style={{ marginBottom: 25 }}>
                    <ImageViewer
                        thumbnail={url}
                        full_size={url}
                    />
                </Col>
            </Row>;
        } else if (images.length > 1) {
            image = <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 20,
            }}>
                <ImageGallery
                    items={images}
                    showIndex={true}
                    showPlayButton={false}
                    originalClass={'image-gallery'}
                />
            </div>;
        }
        let selector = getSelector();

        return (
            <Container className="tool-detail" >
                <Row style={{
                    marginBottom: 15,
                    marginTop: 15,
                }}>
                    <Col xs={6} className="text-right">Názov</Col>
                    <Col xs={6} className="text-left">{P.storeCard.data.name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Kód</Col>
                    <Col xs={6} className="text-left">{P.storeCard.data.code}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Na sklade (ABRA)</Col>
                    <Col xs={6} className="text-left">{P.storeCard.data.main_count} {P.storeCard.data.mainunitcode}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Na sklade (L-Servis)</Col>
                    <Col xs={6} className="text-left">{P.storeCard.data.services_count} {P.storeCard.data.mainunitcode}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Dodávateľ</Col>
                    <Col xs={6} className="text-left">{P.storeCard.data.supplier_name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Naposledy nakupované</Col>
                    <Col xs={6} className="text-left">{dateFormat(P.storeCard.data.purchase_date, false)}</Col>
                </Row>
                {
                    P.permissions.material_prices ?
                        <div>
                        <Row>
                            <Col xs={6} className="text-right">Posledná nákupná cena</Col>
                            <Col xs={6} className="text-left">{P.storeCard.data.last_purchase_price} {P.storeCard.data.purchase_curency}</Col>
                        </Row>
                            <Alert variant={'danger'} style={{textAlign: 'center', margin: 10}}>
                                <strong> Upozornenie: </strong> Všetky uvedené ceny sú bez DPH. <br/>
                                Jednotlivé ceny boli aktuálne v uvedenom čase a sú typicky dohodnuté iba pre konkrétnu zákazku.<br/>
                                Údaje majú čisto informačný charakter a nie sú použiteľné na tvorbu cien.
                            </Alert>
                        </div> : null
                }
                {image}
                <Tabs defaultActiveKey={1} key={'tabs'}>
                    <Tab key={'history'} eventKey={1} title="História skladových pohybov">
                        <SuperTable
                            name={'material_history'}
                            thead={thead_items}
                            items={P.storeCard.data.documents}
                            searchable
                            columnSelector={true}
                            selectors={[selector]}
                            is_loading={P.isLoading}
                            rowStyleFn={
                                (column) => ['name'].includes(column)
                                    ? {whiteSpace: 'normal'}
                                    : {}
                            }
                        />
                    </Tab>
                    {
                        P.storeCard.data.isproduct ?
                        <Tab
                            key={'items'}
                            eventKey={2}
                            title={'Položky'}
                        >
                            <SuperTable
                                name={'product_items'}
                                thead={[
                                    {name: 'image_url', sk_name: 'Foto', type: 'image'},
                                    {name: 'name', sk_name: 'Názov', type: 'string'},
                                    {name: 'code', sk_name: 'Kód', type: 'string'},
                                    {name: 'quantity', sk_name: 'Počet', type: 'number'},
                                    {name: 'unit', sk_name: 'Jednotka', type: 'string'},
                                ]}
                                items={P.storeCard.data.items}
                                searchable
                                is_loading={P.isLoading}
                                rowStyleFn={(column) => ['name'].includes(column) ? {whiteSpace: 'normal'} : {}}
                                onRowClick={id => {
                                    this.props.history.push('/storecards/' + id);
                                }}
                            />
                        </Tab> : null
                    }
                </Tabs>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        identity: state.identity,
        storeCard: state.storecard,
        permissions: state.permissions,
    };
};

export default withRouter(connect(mapStateToProps)(Detail));
