import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {MdClose} from 'react-icons/md';
import '../Employees/style.css';
import call from 'ajax/call';
import {FormSelect} from 'components/CustomSelect';
import {Spinner} from 'react-activity';
import 'react-activity/dist/Spinner.css';
import {formatDate} from 'scripts/dateFormatter';
import FormCheckBox from 'components/CheckBox';

class WorkCostsExportForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            useDateRange: false,
        };
    }

    submitHandler(model) {
        if (!model.buildings) {
            return false;
        }

        this.setState({
            formSubmitted: true,
        });

        const selectedBuildings = model.buildings.map(o => o.id);
        let route = this.props.url;
        if (this.state.useDateRange) {
            route = route + `/${model.from}/${model.to}`;
        }

        call(route, {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({selectedBuildings}),
        }).then( () => {
            this.props.onClose(model);
            this.setState({formSubmitted: false});
        }).catch(e => console.log(e));
    }

    render() {
        const {props: P } = this;

        return (
            <Modal
                onHide={ () => P.onClose() }
                show={P.show}>
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <Formsy
                            onValidSubmit={ model => this.submitHandler(model) }
                        >
                            <FormSelect
                                name={'buildings'}
                                label={'Budovy'}
                                options={P.buildings}
                                getOptionValue={ o => o.id}
                                getOptionLabel={ o => o.name}
                                required={true}
                                isClearable={true}
                                isMulti={true}
                            />
                            <FormCheckBox
                                name={'use_date_range'}
                                label={'Zvoliť dátumové ohraničenie'}
                                value={false}
                                id={'use_date_range'}
                                onChange={ val => this.setState({
                                    useDateRange: val
                                })}
                            />
                            {
                                this.state.useDateRange ?
                                    <div>
                                        <Input
                                            name={'from'}
                                            label={'Od'}
                                            type={'date'}
                                            required
                                            value={formatDate(new Date('2010-01-01'))}
                                        />
                                        <Input
                                            name={'to'}
                                            key={'to'}
                                            label={'Do'}
                                            type={'date'}
                                            required
                                            value={formatDate(new Date())}
                                        />
                                    </div> : null
                            }
                            <Row>
                                <Col md={{span: 4, offset: 4}}>
                                    <Button
                                        variant="danger"
                                        type="submit"
                                    >
                                        Exportovať
                                    </Button>
                                </Col>
                                <Col md={{span: 4, offset: 0}}>
                                    <Spinner animating={this.state.formSubmitted}/>
                                </Col>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

WorkCostsExportForm.propTypes = {
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    url: PropTypes.string.isRequired,
};

WorkCostsExportForm.defaultProps = {
    onSubmit: () => {},
    updating: false,
};

const mapStateToProps = (state) => {
    return {
        buildings: state.buildings.items.sort((a, b) => a.name.localeCompare(b.name))
    };
};

export default connect(mapStateToProps)(WorkCostsExportForm);