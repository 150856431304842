import React from 'react';
import {connect} from 'react-redux';
import {FormSelect} from 'components/CustomSelect';
import Spinner from 'components/Spinner';
import './style.css';
import PT from 'prop-types';
import {mapStateToProps} from './reselect';
import {sortItems} from '../Planning/utils';
import store from 'store';
import buildingsActions from 'actions/buildings';
import entityTypesActions from 'actions/entity_types';
import buildingEntitiesActions from 'actions/building_entities';

class SectionForm extends React.Component {

    constructor(props) {
        super(props);

        this.mounted = false;
        this.state = {
            width: window.innerWidth,
        };
    }
    
    componentDidMount() {
        let { building_id } = this.props.params;

        if (this.props.buildings.length === 0) {
            store.dispatch(buildingsActions.fetchAll());
        }
        if (this.props.entityTypes.length === 0) {
            store.dispatch(entityTypesActions.fetchAll());
        }

        if (building_id) {
            if (this.props.entities.length === 0 || this.props.entities[0].building_id !== building_id) {
                store.dispatch(buildingEntitiesActions.fetchAll({building_id}));
            }
        }

        window.addEventListener('resize', this.handleWindowSizeChange);
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange() {
        if (this.mounted) {
            this.setState({width: window.innerWidth});
        }
    }

    // returns the stored item_id for given prefix, or the first item in the array
    getItemId(items, prefix, id) {
        if (items.length > 0) {
            let lastUsedID = localStorage.getItem(`${prefix}_${id}`);

            if (lastUsedID !== null && lastUsedID !== 'undefined') {
                let idx = parseInt(lastUsedID, 10);

                if (items.findIndex(item => item.id === idx) < 0) {
                    return items[0].id;
                } else {
                    return idx;
                }
            } else {
                return items[0].id;
            }
        }
        return null;
    }

    setItemId(prefix, id, value) {
        localStorage.setItem(`${prefix}_${id}`, value);
    }

    objectIsBuilding(object) {
        return object.entity_type_id === undefined && object.max_level !== undefined;
    }

    handleChange(object) {
        let old_building_id = this.props.params.building_id;

        const {entities, entitiesMap} = this.props;
        let result = this.props.params;

        // update the last opened ids in localstorage
        if (!this.objectIsBuilding(object)) {
            if (object.parent_id === null) {
                this.setItemId('building', object.building_id, object.id);
            } else {
                this.setItemId('recent', object.parent_id, object.id);
            }
        } else {
            if (old_building_id !== object.id) {
                store.dispatch(buildingEntitiesActions.fetchAll({building_id: object.id}));
            }
        }

        // traverse the tree down to the leaves
        for (let currentItem = object, items = []; currentItem;) {
            if (this.objectIsBuilding(currentItem)) {
                result = {
                    building_id: currentItem.id
                };

                localStorage.setItem('building_id', currentItem.id);
                items = entities.filter(e => e.building_id === currentItem.id && e.parent_id === null);
                let next_id = this.getItemId(items, 'building', currentItem.id);
                if (next_id) {
                    this.setItemId('building', currentItem.id, next_id);
                }
                currentItem = entitiesMap.get(next_id);
            } else {
                result['entity_id'] = currentItem.id;

                items = entities.filter(e => e.parent_id === currentItem.id);
                let next_id = this.getItemId(items, 'recent', currentItem.id);
                if (next_id) {
                    this.setItemId('recent', currentItem.id, next_id);
                }
                currentItem = entitiesMap.get(next_id);
            }

            if (!this.props.leaf_required) {
                break;
            }
        }

        localStorage.setItem('entity_id', result['entity_id']);
        this.props.onChange(result);
    }

    render() {
        const {
            entities,
            entitiesMap,
            entityTypes,
            isLoading,
            buildings,
            buildingsMap,
            onlyBuildingsWithPerformances,
            buildingsWithPerformances,
            onlyBuildingsWithEntities,
            buildingsWithPerformancesMap,
            locked,
            leaf_required
        } = this.props;

        if (isLoading) {
            return <Spinner/>;
        }

        let filteredBuildings = buildings;
        let filteredBuildingsMap = buildingsMap;
        const isDesktop = this.state.width >= 768;

        if (onlyBuildingsWithEntities) {
            //Filter buildings with entities
            filteredBuildings = buildings.filter(b => b.active && b.has_entities);
        }

        if (onlyBuildingsWithPerformances) {
            filteredBuildings = buildingsWithPerformances;
            filteredBuildingsMap = buildingsWithPerformancesMap;
        }

        let {building_id, entity_id} = this.props.params;
        let entity = null;

        if (entity_id) {
            entity = entitiesMap.get(entity_id);
            if (entity && entity.building_id !== building_id) {
                entity_id = null;
                entity = null;
            }
        }
        let selectBoxes = [];

        if (!building_id && filteredBuildings.length) {
            building_id = filteredBuildings[0].id;
        }

        let building_val = undefined;
        if (building_id) {
            building_val = filteredBuildingsMap.get(building_id);
        }

        if (entity_id) {
            // traverse the tree up to the root
            for (let current_entity = entitiesMap.get(entity_id);
                 current_entity;
                 current_entity = entitiesMap.get(current_entity.parent_id)
            ) {
                let options = entities.filter(
                    e => e.parent_id === current_entity.parent_id && e.building_id === building_id
                );
                let type = entityTypes.find(t => t.id === current_entity.entity_type_id);
                options = sortItems(options, type.name);

                selectBoxes.unshift(<FormSelect
                    key={`entity_${current_entity.id}`}
                    name={`entity_${current_entity.id}`}
                    label={type.name}
                    options={options}
                    placeholder={'Vyberte možnosť'}
                    getOptionValue={b => b.id}
                    getOptionLabel={b => b.name}
                    value={current_entity}
                    onChange={b => this.handleChange(b)}
                    isDisabled={locked}
                    searchable={isDesktop}
                />);
            }

            selectBoxes.unshift(<FormSelect
                key={'building'}
                name={'building'}
                label={'Budova'}
                options={filteredBuildings}
                placeholder={'Vyberte budovu'}
                getOptionValue={b => b.id}
                getOptionLabel={b => b.name}
                value={building_val}
                onChange={b => this.handleChange(b)}
                isDisabled={locked}
                searchable={isDesktop}
            />);
        } else if (building_val) {
            selectBoxes.push(<FormSelect
                key={'building'}
                name={'building'}
                label={'Budova'}
                options={filteredBuildings}
                placeholder={'Vyberte budovu'}
                getOptionValue={b => b.id}
                getOptionLabel={b => b.name}
                value={building_val}
                onChange={b => this.handleChange(b)}
                isDisabled={locked}
                searchable={isDesktop}
            />);

            // traverse the tree down to the leaves
            for (let currentItem = building_val, items = []; currentItem; ) {
                if (this.objectIsBuilding(currentItem)) {
                    localStorage.setItem('building_id', currentItem.id);

                    items = entities.filter(e => e.building_id === currentItem.id && e.parent_id === null);
                    let next_id = this.getItemId(items, 'building', currentItem.id);
                    if (next_id) {
                        this.setItemId('building', currentItem.id, next_id);
                        currentItem = entitiesMap.get(next_id);
                    } else {
                        break;
                    }

                    let options = items;
                    let type = entityTypes.find(t => t.id === currentItem.entity_type_id);
                    options = sortItems(options, type.name);

                    selectBoxes.push(<FormSelect
                        key={`entity_${currentItem.id}`}
                        name={`entity_${currentItem.id}`}
                        label={type.name}
                        options={options}
                        placeholder={'Vyberte možnosť'}
                        getOptionValue={b => b.id}
                        getOptionLabel={b => b.name}
                        value={currentItem}
                        onChange={b => this.handleChange(b)}
                        isDisabled={locked}
                        searchable={isDesktop}
                    />);

                } else {
                    items = entities.filter(e => e.parent_id === currentItem.id);
                    let next_id = this.getItemId(items, 'recent', currentItem.id);
                    if (next_id) {
                        this.setItemId('recent', currentItem.id, next_id);
                        currentItem = entitiesMap.get(next_id);
                    } else {
                        break;
                    }

                    currentItem = entitiesMap.get(next_id);
                    let type = entityTypes.find(t => t.id === currentItem.entity_type_id);
                    let options = sortItems(items, type.name);

                    selectBoxes.push(<FormSelect
                        name={`entity_${currentItem.id}`}
                        key={`entity_${currentItem.id}`}
                        label={type.name}
                        options={options}
                        placeholder={'Vyberte možnosť'}
                        getOptionValue={b => b.id}
                        getOptionLabel={b => b.name}
                        value={currentItem}
                        onChange={b => this.handleChange(b)}
                        isDisabled={locked}
                        searchable={isDesktop}
                    />);
                }

                if (!this.props.leaf_required) {
                    break;
                }
            }
        }

        if (leaf_required && (!entity || (!entity.is_leaf))) {
            selectBoxes.push(<FormSelect
                key={'empty_select'}
                name={'empty_select'}
                label={''}
                options={[]}
                placeholder={'Vyberte možnosť'}
                getOptionValue={b => b.id}
                getOptionLabel={b => b.name}
                isDisabled={locked}
                required={true}
                searchable={isDesktop}
            />);
        }

        return (
            <div>
                {selectBoxes}
            </div>
        );
    }
}

export const getStoredParams = () => {
    let obj = {};
    ['building_id', 'entity_id'].forEach(p => {
        let val = localStorage.getItem(p);
        if (val) {
            obj[p] = parseInt(val);
        }
    });

    return obj;
};

SectionForm.propTypes = {
    onChange: PT.func.isRequired,
    params: PT.object,
    locked: PT.bool.isRequired,
    leaf_required: PT.bool.isRequired,
    onlyBuildingsWithEntities: PT.bool,
    onlyBuildingsWithPerformances: PT.bool,
};

SectionForm.defaultProps = {
    locked: false,
    params: {},
    leaf_required: false,
    onlyBuildingsWithEntities: false,
    onlyBuildingsWithPerformances: false,
};

export default connect(mapStateToProps)(SectionForm);
