import React, {Component} from 'react';
import PropTypes, {object} from 'prop-types';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import Formsy from 'formsy-react';
import {FormSelect} from 'components/CustomSelect';
import Input from 'components/Input';
import {formatDate} from 'scripts/dateFormatter';

class EmployeeOrderForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
        };
    }

    onSubmit(model){
        const {props: P} = this;

        const data = {
            employee_id: model.employee.id,
            order_id: P.order.id,
            date_from: model.date_from || null,
            date_to: model.date_to || null,
        };

        this.setState({
            formSubmitted: true,
        });

        this.props.onSubmit(P.objectToUpdate ? P.objectToUpdate.id : null, data, P.updating);
    }

    render() {
        const {props: P, state: S} = this;

        return (
            <Modal
                onHide={() => P.onClose()}
                show={P.show}>
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={this.props.onClose}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <Formsy
                            onValidSubmit={model => this.onSubmit(model)}
                        >
                            <FormSelect
                                name={'employee'}
                                label={'Meno a priezvisko'}
                                options={P.employees}
                                getOptionValue={e => e.id}
                                getOptionLabel={e => e.full_name}
                                value={P.objectToUpdate.employee ? P.objectToUpdate.employee : null}
                                isFormSubmitted={() => S.formSubmitted}
                                required={true}
                                isClearable={true}
                            />
                            <Input
                                name="date_from"
                                label="Od"
                                type={'date'}
                                value={formatDate(P.objectToUpdate.date_from || '')}
                            />
                            <Input
                                name="date_to"
                                label="Do"
                                type={'date'}
                                value={formatDate(P.objectToUpdate.date_to || '')}
                            />
                            <Row>
                                <Col md={{span: 8, offset: 4}}>
                                    <Button variant="success" type="submit">
                                        {P.updating ? 'Upraviť' : 'Vytvoriť'}
                                    </Button>
                                </Col>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

EmployeeOrderForm.propTypes = {
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    objectToUpdate: PropTypes.object.isRequired,
    employees: PropTypes.arrayOf(object).isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EmployeeOrderForm;
