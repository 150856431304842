import {Col, FormControl, Row} from 'react-bootstrap';
import {TiDelete} from 'react-icons/ti';
import React from 'react';

class ProductItem extends React.Component{
    render() {
        let {props:P} = this;

        return (
            <Row className={'mb-2'}>
                <Col sm={3} md={2} lg={1}
                    style={{
                        textAlign: 'right'
                    }}
                >
                    <img
                        style={{height: 35}}
                        src={P.image_url}
                        alt={'obrazok'}
                    />
                </Col>
                <Col sm={9} md={6} lg={8}>
                    <input
                        key={P.id}
                        name={P.name}
                        value={P.name}
                        //readOnly={true}
                        disabled
                        style={{
                            borderStyle: 'solid',
                            borderRadius: 5,
                            textAlign: 'left',
                            color: '#303030',
                            height: 40,
                            width: '100%'
                        }}
                    />
                </Col>
                <Col sm={9} md={3} lg={2}
                     style={{
                         textAlign: 'right',
                         display: 'flex',
                         flexDirection: 'row',
                         justifyContent: 'space-between'
                     }}
                >
                    <span>
                        <FormControl
                            type={'number'}
                            value={P.quantity}
                            onChange={e => this.props.onQuantityChange(e.target.value)}
                            step={P.mainunitcode === 'ks' ? 1 : 0.1}
                        />
                    </span>
                    <span style={{
                        paddingLeft: 10
                    }}>
                        {P.mainunitcode}
                    </span>
                </Col>
                <Col sm={3} md={1} lg={1}
                     style={{
                    textAlign: 'right',
                }}>
                    <TiDelete
                        //key={'clear' + e.id}
                        color={'red'}
                        size={30}
                        style={{
                            marginLeft: 5,
                            marginBottom: 2,
                            cursor: 'pointer',
                        }}
                        onClick={() => P.onDelete(P.id)}
                    />
                </Col>
            </Row>
        );
    }
}

export default ProductItem;
