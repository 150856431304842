import React from 'react';
import ReactDOM from 'react-dom';
import App from './views';
import { Provider } from 'react-redux';
import store from 'store/mainStore.js';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import sk from 'date-fns/locale/sk';

registerLocale('sk', sk);
setDefaultLocale('sk');

ReactDOM.render(
	<Provider store={ store }>
		<App />
	</Provider>, 
	
	document.getElementById('root')
);
