import mapActions from './mapActions';

export default mapActions('employee_orders', {
    fetchAll_url: data => {
        if (data.type === 'employee') {
            return `/api/employee_orders/employee/${data.employee_id}`;
        } else {
            return `/api/employee_orders/order/${data.order_id}`;
        }
    }
});
