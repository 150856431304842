import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {TiGroup, TiKey,} from 'react-icons/ti';
import ChangePasswd from '../Employees/EmployeeDetail/PasswordForm';
import {
    MdAccessTime,
    MdAlarmOn,
    MdAssessment,
    MdBusiness,
    MdDirectionsCar,
    MdHistory,
    MdPeople,
    MdTabletMac,
    MdBuild
} from 'react-icons/md';
import {GoChecklist} from 'react-icons/go';
import {FaChartLine, FaRegListAlt, FaThumbsDown, FaWarehouse} from 'react-icons/fa';
import {CgToolbox} from 'react-icons/cg';
import {GiPipes} from 'react-icons/gi';
import {ImStatsBars} from 'react-icons/im';
import {IoIosConstruct} from 'react-icons/io';
import store from 'store/mainStore';
import {logout} from 'actions/login';

const MyMenuItem = ({title, divStyle, captionStyle, iconStyle, icon}) => {
    return (
        <div className="menu-item trans" style={divStyle}>
            <h3 style={captionStyle}>{title}</h3>
            <span className="menu-icon" style={iconStyle}>{icon}</span>
        </div>
    );
};

const noAccessStyle = {
    width: '100%',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
};

const NoAccess = () => {
    return (
        <div style={noAccessStyle}>
            <h2>
                <FaThumbsDown size={60}/>
                <br/>
                Nemáte oprávnenie ku žiadnej položke v menu.
                Kontaktujte administrátora pre zmenu Vašich oprávnení
            </h2>
        </div>
    );
};

class Menu extends React.Component {

    constructor(props) {
        super(props);

        let groups_link = '/groups';
        if (props.permissions.groups === 5) {
            groups_link = `groups/${props.identity.id}`;
        }
        let operations_link = '/my_operations';
        let operations_name = 'Pracovné operácie';

        let date = new Date();
        if (props.permissions.operations === 5) {
            operations_link = `/my_operations/${props.identity.id}-${date.getMonth()}-${date.getFullYear()}`;
            operations_name = 'Moje operácie';
        }

        this.itemsMap = {
            'roles': <Link key='roles' to='/roles'><MyMenuItem title="Správa rolí" icon={<TiKey />} /></Link>,
            'employees': <Link key='employees' to='/employees'><MyMenuItem title="Zamestnanci" icon={<MdPeople />} /></Link>,
            'working_hours': <Link key='attendance' to='/attendance'><MyMenuItem title="Dochádzka" icon={<MdAccessTime />} /></Link>,
            'my_operations': <Link key='my_operations' to={operations_link}><MyMenuItem title={operations_name} icon={<MdBuild />} /></Link>,
            'tools': <Link key='implements' to='/tools'><MyMenuItem title="Náradie" icon={<IoIosConstruct />}/></Link>,
            'buildings': <Link key='buildings' to='/buildings'><MyMenuItem title="Budovy" icon={<MdBusiness />}/></Link>,
            'orders': <Link key='orders' to='/orders'><MyMenuItem title="Zákazky" icon={<GoChecklist />} /></Link>,
            'tablets': (<Link key='tablets' to='/tablets'><MyMenuItem title="Tablety" icon={<MdTabletMac />}/></Link>),
            'cars': (<Link key='cars' to='/cars'><MyMenuItem title="Autá" icon={<MdDirectionsCar />}/></Link>),
            'logs': (<Link key='logs' to='/logs'><MyMenuItem title="História" icon={<MdHistory />}/></Link>),
            'certifications': (<Link key='trainings' to='/trainings'><MyMenuItem title="Školenia" icon={<FaRegListAlt />}/></Link>),
            'operations': (<Link key='operations' to='/operations'><MyMenuItem title="Zápis operácií" icon={<MdAlarmOn />}/></Link>),
            'planning': (<Link key='planning' to='/planning'><MyMenuItem title="Plánovanie" icon={<FaChartLine />}/></Link>),
            'performances_overview': (
                <Link key='performances_overview' to='/performances_overview'>
                    <MyMenuItem title='Prehľad operácií' icon={<ImStatsBars/>}/>
                </Link>
            ),
            'work_today': (
                <Link key='work_today' to='worktoday'>
                    <MyMenuItem title="Aktuálny prehľad" icon={<MdAssessment />} />
                </Link>
            ),
            'groups': (<Link key='groups' to={groups_link}><MyMenuItem title="Skupiny" icon={<TiGroup />}/></Link>),
            'material':
                <Link key='material_overview' to='/material_overview'>
                    <MyMenuItem title="Prehľad materiálu" icon={<GiPipes/>}/>
                </Link>,
            'storage':
                <Link key='storage' to='/stores'>
                    <MyMenuItem title="Sklady" icon={<FaWarehouse />}/>
                </Link>,
            'operation_types': (
                <Link key='operation_stypes' to='operation_types'>
                    <MyMenuItem title="Správa operácií" icon={<CgToolbox />}/>
                </Link>
            ),
        };
    }

    render() {
        let { permissions } = this.props;
        permissions.my_operations = permissions.operations;
        permissions.performances_overview = permissions.planning;

        const AllowedMenuItems = Object.keys(this.itemsMap)
            .filter(k => permissions[k] > 0)
            .map(k => this.itemsMap[k]);

        let toShow = AllowedMenuItems;
        if (AllowedMenuItems.length === 0) {
            toShow = <NoAccess/>;
        }

        return (
            <div className="menu">
                { toShow }
                {this.props.identity.force_password_change ?
                    <ChangePasswd
                        employee_id={this.props.identity.id}
                        force = {true}
                        onClose={ () => this.setState({ changePasswd: false }) }
                        onSubmit={ () => {
                            store.dispatch(logout());
                        }}
                /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => {

    const permissions = {
        ...state.permissions,
        work_today: state.permissions.current_view
    };

    return {
        permissions,
        identity: state.identity
    };
};

export default withRouter(connect(mapStateToProps)(Menu));
