import { ADD_DEPENDENCY } from 'actions/dependency';
import { permissions } from '../config/permissions';

const en_names = permissions.reduce((acc, val) => {
    acc[val.name] = [];
    return acc;
}, {});

function uniqueArray(array) {
    return [...new Set(array)];
}

export default (state = en_names, action) => {
    if (action.type === ADD_DEPENDENCY) {
        let new_obj = {};
        new_obj[action.roleName] = uniqueArray(state[action.roleName].concat(action.arrOfDeps));
        return Object.assign({}, state, new_obj);
    } else {
        return state;
    }
};