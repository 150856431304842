import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MdPerson } from 'react-icons/md';
import DV from '../../../scripts/dateFormatter';

class OwnerView extends React.Component {

    render() {
        const { owner } = this.props;
        return (
            <div className="owner-panel">
                <div className="flexy-panel">
                    <h2><MdPerson size={32} /> Vlastník tabletu:</h2>
                </div>
                <hr />
                <div className="flexy-panel">
                    <h2>{owner.first_name + ' ' + owner.last_name}</h2>
                </div>
                <div className="flexy-panel">
                    <img className="owner-photo"
                         src={owner.photo}
                         alt={''}
                    />
                </div>
                <div className="flexy-panel">
                    <Link to={`/employees/detail/${owner.id}`}>Zobraziť detaily o zamestnancovi</Link>
                </div>
                <div className="flexy-panel">
                    Dátum prevzatia tabletu: {DV(this.props.fromDate, true)}
                </div>
            </div>
        );
    }
}

OwnerView.propTypes = {
    owner: PropTypes.object.isRequired,
    fromDate: PropTypes.string,
};

OwnerView.defaultProps = {
    owner: {
        photo: '/storage/employees/avatar.jpeg'
    },
    fromDate: new Date().toISOString(),
};

export default OwnerView;
