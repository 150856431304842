import React from 'react';
import {connect} from 'react-redux';

import store from 'store/mainStore';
import employeesTypes from 'actions/employee_types';
import unlockedActions from 'actions/operations_employees_list';

import View from 'components/MonthChooser';
import {Button, Container} from 'react-bootstrap';
import Tern from 'components/Or';
import 'url-search-params-polyfill';
import {addDependency} from 'actions/dependency';
import {SuperTable} from 'components/PaginationTable';
import {updateQueryParams} from 'scripts/queryFunctions';

class WorkingHours extends React.Component {
    constructor(props) {
        super(props);

        const url = new URLSearchParams(props.location.search);
        const year = url.get('year') || 0;
        const month = url.get('month') || 0;

        const showAllParam = !!url.get('show_all');
        const showAll = url.get('show_all') ? url.get('show_all') === 'true' : false;
        const dateNow = new Date();

        const isForPrivateView = props.permissions['operations'] === 5;

        this.state = {
            month: month || dateNow.getMonth() + 1,
            year: year || (dateNow.getFullYear()),
            showAll: showAllParam ? showAll :
                (isForPrivateView || props.permissions['is_accountant'] === 2)
        };
    }

    componentDidMount() {
        store.dispatch(employeesTypes.fetchAll());
        store.dispatch(unlockedActions.fetchAll({
            year: this.state.year,
            month: this.state.month
        }));
    }

    onDateChange(obj) {
        const {state: S} = this;

        store.dispatch(unlockedActions.fetchAll({
            month: obj.month,
            year: obj.year
        }));

        this.setState({
            year: obj.year,
            month: obj.month
        }, () => {
            const {location, history} = this.props;

            updateQueryParams(location, history, {
                year: obj.year,
                month: obj.month,
                show_all: S.showAll
            });
        });
    }

    handleClickUnlocked(id) {
        this.props.history.push(`/my_operations/${id}-${this.state.month}-${this.state.year}`);
    }

    selectorFilterFuncGenerator(attribute, desired_value) {
        return function (obj) {
            return obj[attribute] === desired_value;
        };
    }

    render() {
        const {state: S, props: P} = this;
        const isAccountant = P.permissions['is_accountant'] === 2;
        const isForPrivateView = P.permissions['working_hours'] === 5;

        let unlockedItems = P.unlocked.filter(u => {
            if (isAccountant || S.showAll) return true; // ACCOUNTANT CAN SEE ALL OF THEM
            return u.should_show;
        }).map(i => ({
            ...i,
            highlighted: Boolean(i.approved),
        }));

        let selector_values = [];
        this.props.employeesTypes.forEach(item => {
            selector_values.push({
                value: item.id,
                name: item.name,
                filter: this.selectorFilterFuncGenerator('type_id', item.id)
            });
        });

        selector_values.push({
            value: 0,
            name: 'Zobraziť všetkých',
            filter: () => true
        });

        let selectors = [
            {
                name: 'type_selector',
                default_value: 0,
                values: selector_values
            }];

        if (isForPrivateView) {
            selectors = [];
        }

        return (
            <div className="working-hours">
                <Container>
                    <h3> Pracovné operácie </h3>
                    <View
                        year={String(S.year)}
                        month={String(S.month)}
                        onDateChange={obj => this.onDateChange(obj)}
                    />
                    <div>
                        <Tern expression={!isAccountant && !isForPrivateView}>
                            <Button
                                style={{marginBottom: 15, marginRight: 15}}
                                variant="success"
                                onClick={() => {
                                    const {location, history} = this.props;
                                    updateQueryParams(location, history, {
                                        year: S.year,
                                        month: S.month,
                                        show_all: !S.showAll
                                    });
                                    this.setState({showAll: !S.showAll});
                                }}
                            >
                                {S.showAll ? 'Zobraziť len mojich zamestnancov' : 'Zobraziť všetkých zamestnancov'}
                            </Button>
                        </Tern>
                    </div>
                    <SuperTable
                        name={'employees'}
                        items={unlockedItems}
                        defaultSortKey="employee_fullname"
                        thead={[
                            {name: 'full_name', sk_name: 'Meno zamestnanca', type: 'string'},
                            {name: 'type_name', sk_name: 'Typ', type: 'string'},
                            {name: 'master_full_name', sk_name: 'Meno majstra', type: 'string', search: false},
                            {name: 'sum', sk_name: 'Čas', type: 'seconds'},
                            {name: 'approved', sk_name: 'Schvalený', type: 'boolean'},
                        ]}
                        columnSelector
                        onRowClick={id => this.handleClickUnlocked(id)}
                        selectors={selectors}
                        searchable={!isForPrivateView}
                        is_loading={P.isLoading}
                        highlighting={true}
                    />
                </Container>
            </div>
        );
    }
}

addDependency('working_hours', ['webapp_access', 'employees', 'orders', 'cars']);

const mapStateToProps = state => {
    return {
        unlocked: state.operations_employees_list.items,
        isLoading: state.operations_employees_list.isFetching
            || state.employeeTypes.isFetching
            || state.permissions.isFetching,
        employeesTypes: state.employeeTypes.items,
        permissions: state.permissions,
    };
};

export default connect(mapStateToProps)(WorkingHours);