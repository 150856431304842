import React from 'react';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';
import store from 'store/mainStore';
import View from './View';
import carsActions from 'actions/cars';
import employeesActions from 'actions/employees';
import carsHistoryActions from 'actions/cars_history';
import ModalForm from 'components/ModalForm';

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            id: parseInt(props.match.params.id, 10),
        };
    }

    componentDidMount() {
        store.dispatch(carsActions.fetchConcrete(this.state.id));
        store.dispatch(carsHistoryActions.fetchAll({car_id: this.state.id}));
        store.dispatch(employeesActions.fetchAll());
    }

    render() {
        const {props: P, state: S} = this;

        return (
            <Container style={{paddingBottom: 50}}>
                {P.car && P.car.history ?
                    (<div>
                        <View
                            data={P.car}
                            carsHistory={P.carsHistory}
                            employees={P.employees}
                            onEdit={() => this.setState({formActive: true})}
                        />
                        <ModalForm
                            show={S.formActive}
                            formElements={[
                                {name: 'spz', label: 'ŠPZ', type: 'text', required: true},
                                {name: 'color', label: 'Farba', type: 'text', placeholder: 'Biela'},
                                {
                                    name: 'type',
                                    label: 'Typ',
                                    type: 'text',
                                    placeholder: 'Citroen Jumper',
                                    required: true,
                                },
                                {name: 'name', label: 'Názov', type: 'text', placeholder: 'Citroen', required: true},
                                {name: 'power', label: 'Výkon', type: 'text', placeholder: '75KW'},
                                {name: 'consumption', label: 'Spotreba', type: 'text', placeholder: '6.5L/100km'},
                                {name: 'technical_control', label: 'Platnosť TK do', type: 'date'},
                                {name: 'emission_control', label: 'Platnosť EK do', type: 'date'},
                                {
                                    name: 'owner',
                                    label: 'Vlastník',
                                    type: 'select',
                                    options: {
                                        items: P.employees,
                                        labelKey: 'full_name',
                                    },
                                    selectKey: 'owner_id',
                                    required: true,
                                },
                                {name: 'file', label: 'Dokument', type: 'file'},
                                {name: 'active', label: 'Aktívne', type: 'checkbox', defaultValue: true},
                            ]}
                            updatingObj={P.car}
                            action={carsActions}
                            afterSave={() => this.setState({formActive: false})}
                            onClose={() => this.setState({formActive: false})}
                        />
                    </div>) : (<div>Loading...</div>)
                }
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id, 10);

    return {
        car: state.cars.items.find(c => c.id === id),
        carsHistory: state.carsHistory.items,
        loading: state.cars.isFetching,
        employees: state.employees.items
    };
};

export default connect(mapStateToProps)(Detail);