import React from 'react';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
import {Button, Modal} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import PT from 'prop-types';
import {FormSelect} from 'components/CustomSelect';
import BuildingForm from '../../WorkOperations/SectionForm';
import work_performance_actions from 'actions/work_performance';
import store from 'store/mainStore';
import Slider from './InputMultiSlider';
import {extractTime, formatDate} from 'scripts/dateFormatter';
import Form from 'formsy-react';

class SectionForm extends React.Component {
    constructor(props) {
        super(props);

        let now = new Date();
        this.startTime = extractTime(now);
        now.setMinutes(now.getMinutes() + 1);
        this.endTime = extractTime(now);

        this.state = {
            errors: {},
            formSubmitted: false,
            building_id: null,
            entity_id: null,
            operation_type_id: null,
            changed: false,
            operation_changed: false,
            date: null,
            startTime: null,
            endTime: null,
            percent_done: 0,
        };
    }

    getDate(datestr, time) {
        let date = new Date(datestr);

        let parts = time.split(':');
        date.setHours(parts[0]);
        date.setMinutes(parts[1]);

        if (parts.length > 2) {
            date.setSeconds(parts[2]);
        } else {
            date.setSeconds(0);
        }

        return date;
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true
        });

        if (this.validateForm(model)) {
            return;
        }

        let params = this.getCurrentParams();

        let obj = {
            operation_type_id: model.operation.id,
            started_at: this.getDate(model.date, model.started_at).toISOString(),
            finished_at: null,
            building_id: params.building_id,
            entity_id: params.entity_id,
            group_id: model.group.id,
            percent_done: 0,
            description: model.description
        };

        if (this.state.percent_done) {
            obj.percent_done = this.state.percent_done;
        } else {
            if (this.props.updating && this.props.obj) {
                obj.percent_done = this.props.obj.percent_done;
            }
        }
        if (model.finished_at) {
            obj.finished_at = this.getDate(model.date, model.finished_at).toISOString();
        }

        this.props.onSubmit(obj);
    }

    validateForm(model) {
        let validations = {
            operation: 'Zvoľte operáciu',
            started_at: 'Zadajte začiatok',
            building: 'Zvoľte budovu',
            group: 'Vyberte skupinu',
        };

        let errors = {};

        Object.keys(validations).forEach( key => {
            if (!model[key]) {
                errors[key] = validations[key];
            }
        });

        this.setState({
            errors: errors
        });

        return (Object.keys(errors).length > 0);
    }

    getCurrentParams(){
        let params = [
            'building_id',
            'entity_id',
        ];

        let result = {};
        params.forEach( p => {
            if (this.state.changed) {
                result[p] = this.state[p];
            } else {
                result[p] = this.props.obj[p];
            }
        });
        return result;
    }

    onDateChange(date){
        this.setState({
            date: date
        });
    }

    onStartTimeChange(time){
        this.setState({
            startTime: time
        });
    }

    onEndTimeChange(time){
        this.setState({
            endTime: time
        });
    }

    getWorkOperation(id){
        return this.props.work_operations.find(o => o.id === id) || null;
    }

    onBuildingFormChange(params) {
        let state = {...this.state};

        if (params.entity_id && state.entity_id !== params.entity_id) {
            store.dispatch(work_performance_actions.fetchAll({id: params.entity_id}));
        }

        Object.keys(params).forEach( k => {
            state[k] = params[k];
        });
        state.changed = true;
        this.setState(state);
    }

    keyBy(array, key) {
        return (array || []).reduce((r, x) => ({ ...r, [ x[key] ]: x }), {});
    }

    render() {
        const { props: P, state: S } = this;

        let groups = [];
        let dateString = null;

        let dateValue = S.date || formatDate(P.updating ? P.obj.started_at : new Date());
        let startTimeValue = S.startTime || (P.updating ? extractTime(P.obj.started_at) : this.startTime);
        let endTimeValue = S.finished_at || (P.updating ? extractTime(P.obj.finished_at) : this.endTime);

        if (startTimeValue && dateValue) {
            dateString = new Date(`${dateValue}T${startTimeValue}`).toISOString();
        }
        if (dateString) {
            groups = P.group_history.filter( g => {
                return g.from_date <= dateString && (g.to_date == null || g.to_date > dateString);
            });
        }
        let startedAt = dateString || P.obj.started_at;

        let entity_id = S.changed ? S.entity_id : P.obj.entity_id;
        let entity_operations = P.entity_operations.filter(s => s.entity_id === entity_id);

        let operations = P.work_operations.filter( o => {
            return o.overhead || entity_operations.some(s => s.operation_type_id === o.id);
        }).sort( (a, b) => a.number - b.number);

        let operation_type_id = (S.operation_changed || S.changed) ? S.operation_type_id : P.obj.operation_type_id;
        let operation = this.getWorkOperation(operation_type_id);

        let intervals = P.work_performance.filter(w => w.entity_id === entity_id && w.operation_type_id === operation_type_id);
        let index = intervals.findIndex(i => i.id === P.obj.id);

        if (index === -1) {
            let sum = intervals.reduce( (acc, val) => acc + val.percent_done, 0);
            intervals.push({
                ...P.obj,
                percent_done: Math.min(( P.obj.percent_done || 0), 100 - sum),
                started_at: startedAt,
            });
        } else {
            intervals[index] = {
                ...P.obj,
                started_at: startedAt,
            };
        }

        if (intervals.every((item) => item.started_at)) {
            intervals.sort((a, b) => a.started_at.localeCompare(b.started_at));
        }

        // find the index again from sorted intervals
        index = intervals.findIndex(i => i.id === P.obj.id);
        let slider_key = intervals.reduce((acc, item) => acc + String(item.id), '');

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Form
                    onValidSubmit={model => this.submitHandler(model)}
                    onInvalidSubmit={model => this.submitHandler(model)}
                    onChange={ values => this.validateForm(values)}
                    validationErrors={S.errors}
                >
                    <Modal.Header>
                        <div
                            className="closeBox"
                            style={{ float: 'right', height: 25}}
                        >
                            <MdClose
                                size={24}
                                onClick={ P.onClose }
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="register-form">
                            <BuildingForm
                                onChange={ params => this.onBuildingFormChange(params) }
                                locked={true}
                                params={this.getCurrentParams()}
                                leaf_required={true}
                            />
                            <FormSelect
                                key={`${entity_id}`}
                                name={'operation'}
                                label={'Operácia'}
                                options={operations}
                                placeholder={'Vyberte operáciu'}
                                getOptionValue={ o => o.id}
                                getOptionLabel={ o => o.name}
                                value={operation}
                                isClearable={true}
                                onChange={ o => {
                                    this.setState({
                                        operation_type_id: o ? o.id : null,
                                        operation_changed: true,
                                    });
                                }}
                            />
                            <Input
                                type={'date'}
                                value={dateValue}
                                onChange={ val => this.onDateChange(val)}
                                label={'Dátum'}
                                name={'date'}
                                required
                                //disabled={P.updating}
                            />
                            <Input
                                type={'time'}
                                step={1}
                                style={{width: '100%', display: 'block'}}
                                name="started_at"
                                label="Začiatok"
                                value={startTimeValue}
                                onChange={ val => this.onStartTimeChange(val)}
                            />
                            <Input
                                style={{width: '100%', display: 'block'}}
                                name="finished_at"
                                label="Koniec"
                                value={endTimeValue}
                                type={'time'}
                                step={1}
                                onChange={val => this.onEndTimeChange(val)}
                            />
                            <FormSelect
                                name={'group'}
                                label={'Skupina'}
                                options={groups}
                                placeholder={'Vyberte skupinu'}
                                getOptionValue={ g => g.id }
                                getOptionLabel={ g => g.group_members_names }
                                value={ P.obj ? P.obj.group : null}
                            />
                            <Textarea
                                value={P.obj ? P.obj.description : ''}
                                label={'Poznámka'}
                                name={'description'}
                            />
                            { (!operation || !operation.overhead) ?
                                <Slider
                                    key={slider_key}
                                    items={intervals}
                                    myIndex={index}
                                    onChange={val => this.setState({percent_done: val})}
                                    step={1}
                                /> : null
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Uložiť
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

SectionForm.propTypes = {
    show: PT.bool.isRequired,
    updating: PT.bool.isRequired,
    obj: PT.object,
    onSubmit: PT.func.isRequired,
    onClose: PT.func.isRequired,
    work_operations: PT.arrayOf(PT.object).isRequired,
    entity_operations: PT.arrayOf(PT.object).isRequired,
    work_performance: PT.arrayOf(PT.object).isRequired,
};

SectionForm.defaultProps = {
    onSubmit: () => {},
    onClose: () => {},
    updating: false,
    obj: {},
    work_operations: [],
    work_performance: [],
};

export default SectionForm;
