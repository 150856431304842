import call from 'ajax/call';

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function (resource_name) {
    
    let obj = {};
    const upercasedName = resource_name.toUpperCase();
    const CapitalizedName = capitalize(resource_name);
    

    obj[`FETCHING_CONCRETE_${upercasedName}`] = `FETCHING_CONCRETE_${upercasedName}`;
    obj[`FETCHING_CONCRETE_${upercasedName}_SUCC`] = `FETCHING_CONCRETE_${upercasedName}_SUCC`;
    obj[`FETCHING_CONCRETE_${upercasedName}_ERR`] = `FETCHING_CONCRETE_${upercasedName}_ERR`;

    obj[`willFetchConcrete${CapitalizedName}`] = function() { return { type: obj[`FETCHING_CONCRETE_${upercasedName}`]};};
    obj[`didFetchConcrete${CapitalizedName}Succ`] = function(data) { return { type: obj[`FETCHING_CONCRETE_${upercasedName}_SUCC`], data};};
    obj[`didFetchConcrete${CapitalizedName}Err`] = function(err) { return { type: obj[`FETCHING_CONCRETE_${upercasedName}_ERR`], err};};


    /*****************************************************************/ 

    obj['fetchId'] = function(id) {
        return function (dispatch) {
            dispatch(obj[`willFetchConcrete${CapitalizedName}`]());

            return ( 
                call(`/api/${resource_name}s/${id}`)
                .then(response => response.json())
                .then( responseData => {
                    if (responseData.status === 'ok') {
                        //console.info("RECEIVED CREATE OK", data)
                        return dispatch(obj[`didFetchConcrete${CapitalizedName}Succ`](responseData.data)); // TODO , will we need new_id param ? probably yes
                    }
                    else { 
                        //console.info("RECEIVED CREATE ERR", responseData)
                        return dispatch(obj[`didFetchConcrete${CapitalizedName}Err`](responseData.message));
                    }
                })
            );
        };
    };

    return obj;
}