import React from 'react';
import {Button, ButtonToolbar, Container} from 'react-bootstrap';
import {connect} from 'react-redux';
import {SuperTable} from 'components/PaginationTable';
import NeedWritePerm from 'components/NeedPermission';
import ModalForm from 'components/ModalForm';
import CertificationTypesActions from 'actions/certification_types';
import EmployeesActions from 'actions/employees';
import store from 'store/mainStore';

class CertificationTypes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            objToUpdate: undefined,
        };
    }

    componentDidMount() {
        store.dispatch(CertificationTypesActions.fetchAll());
        store.dispatch(EmployeesActions.fetchAll());
    }

    render() {
        const {state} = this;
        return (
            <Container className="certification-types">
                <ButtonToolbar style={{marginBottom: 25}}>
                    <NeedWritePerm type="button" need={['certifications']}>
                        <Button
                            onClick={() => this.setState({objToUpdate: undefined, formActive: true})}
                            variant="success"
                            style={{marginRight: 15}}
                        >
                            Pridať typ osvedčenia
                        </Button>
                    </NeedWritePerm>
                </ButtonToolbar>
                <ModalForm
                    show={state.formActive}
                    formElements={[
                        {name: 'name', label: 'Názov', type: 'text'},
                        {name: 'duration', label: 'Platnosť v dňoch', type: 'number', min: 0, required: true},
                    ]}
                    action={CertificationTypesActions}
                    afterSave={() => this.setState({formActive: false, objToUpdate: undefined})}
                    onClose={() => this.setState({formActive: false, objToUpdate: undefined})}
                    updatingObj={state.objToUpdate}
                />
                <NeedWritePerm type="table" need={['certifications']}>
                    <SuperTable
                        name={'certification_types'}
                        thead={[
                            {name: 'name', sk_name: 'Názov', type: 'string'},
                            {name: 'duration', sk_name: 'Platnosť (dni)', type: 'number'},
                        ]}
                        columnSelector
                        items={this.props.certificationTypes}
                        onEditItem={id => this.setState({
                            formActive: true,
                            objToUpdate: this.props.certificationTypes.find(ct => ct.id === id)
                        })}
                        onDeleteItem={id => store.dispatch(CertificationTypesActions.delete(id))}
                        delOps
                        editOps
                    />
                </NeedWritePerm>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        certificationTypes: state.certificationTypes.items,
        employees: state.employees.items,
    };
};

export default connect(mapStateToProps)(CertificationTypes);
