import React from 'react';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
import {FormSelect} from '../../components/CustomSelect';
import {Button, Modal} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import PT from 'prop-types';
import CustomFileInput from '../../components/FormsyFileInput';
import FormCheckBox from '../../components/CheckBox';
import {formatDate} from 'scripts/dateFormatter';
import SectionForm from '../WorkOperations/SectionForm';

class LevelForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            errors: {},
            is_leaf: false,
            leaf_changed: false
        };
    }

    submitHandler(model) {
        if (this.validateForm(model)) {
            return;
        }
        this.props.onSubmit({
            ...model,
            picture: this.state.file,
        });
    }

    validateForm(model) {
        const {props: P} = this;

        let validations = {
            name: 'Zadajte názov',
        };

        if (!P.updating) {
            validations = {
                ...validations,
                building: 'Vyberte budovu',
                type: 'Zvoľte typ',
            };
        }

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!model[key]) {
                errors[key] = validations[key];
            }
        });

        this.setState({
            errors: errors
        });

        return (Object.keys(errors).length > 0);
    }

    render() {
        const { props: P, state: S } = this;
        let formComponents = [];

        if (!P.updating){
            formComponents.push(
                <SectionForm
                    key={'section_form'}
                    locked={true}
                    params={P.params}
                    leaf_required={false}
                    onChange={() => {}}
                />);

            formComponents.push(
                <FormSelect
                    name={'type'}
                    key={'type'}
                    label={'Typ'}
                    options={P.entity_types}
                    placeholder={'Vyberte typ'}
                    isClearable
                    getOptionValue={ b => b.id}
                    getOptionLabel={ b => b.name}
                    required
                />);
        }
        let date_value = formatDate(P.obj.planned_finish_date);
        let is_leaf_value = this.state.leaf_changed ? this.state.is_leaf : (P.obj.is_leaf || false);
        let default_operations = (!P.updating || (is_leaf_value && !P.obj.has_children && !P.obj.has_operations));

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Formsy
                    onChange={ values => this.validateForm(values)}
                    onSubmit={ model => this.submitHandler(model)}
                    validationErrors={S.errors}
                >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ P.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                    <Modal.Body>
                        <div className="register-form">
                            {
                                formComponents
                            }
                            <Input
                                style={{width: '100%', display: 'block'}}
                                name="name"
                                key="name"
                                label="Názov"
                                value = {P.obj.name || ''}
                            />
                            <Textarea
                                style={{width: '100%', display: 'block'}}
                                name="description"
                                label="Popis"
                                value={P.obj.description || ''}
                            />
                            <Input
                                name={'planned_finish_date'}
                                key={'planned_finish_date'}
                                value={date_value}
                                label={'Plánovaný dátum ukončenia'}
                                type={'date'}
                            />
                            <CustomFileInput
                                label={'Výkres'}
                                onChange={ file => this.setState({ file })}
                                document={P.obj.picture}
                            />
                            { (!P.updating || !P.obj.has_children) ?
                                <FormCheckBox
                                    name="is_leaf"
                                    label="Zapisovať operácie ?"
                                    onChange={ v => this.setState({
                                        leaf_changed: true,
                                        is_leaf: v
                                    })}
                                    value={is_leaf_value}
                                    id={'is_leaf'}
                                /> : null
                            }
                            {
                                is_leaf_value ?
                                    <div>
                                        <Input
                                            name={'difficulty_level'}
                                            key={'difficulty_level'}
                                            value={P.obj.difficulty_level || 1}
                                            label={'Náročnosť'}
                                            type={'number'}
                                            min={1}
                                            max={100}
                                        />
                                        <Input
                                            name={'length'}
                                            key={'length'}
                                            value={P.obj.length || 0}
                                            label={'Dĺžka'}
                                            type={'number'}
                                        />
                                        {
                                            default_operations ?
                                                <FormCheckBox
                                                    name="init_operations"
                                                    value={false}
                                                    label="Vložiť predvolené operácie"
                                                    id={'init_operations'}
                                                />: null
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Uložiť
                        </Button>
                    </Modal.Footer>
                </Formsy>
            </Modal>
        );
    }
}

LevelForm.propTypes = {
    params: PT.object,
    show: PT.bool.isRequired,
    updating: PT.bool.isRequired,
    obj: PT.object,
    onSubmit: PT.func.isRequired,
    onClose: PT.func.isRequired,
    entity_types: PT.arrayOf(PT.object).isRequired,
};

LevelForm.defaultProps = {
    params: {},
    onSubmit: () => {},
    onClose: () => {},
    updating: false,
    entity_types: [],
    obj: {
        planned_finish_date: null,
        building_id: null,
        has_children: false,
        has_operations: false,
    }
};

export default LevelForm;
