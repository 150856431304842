import React from 'react';
import {getNumber} from 'components/PaginationTable/TableRow';
import {Button} from 'react-bootstrap';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import call from 'ajax/call';
import {SuperTable} from 'components/PaginationTable';
import ProgressBar from './ProgressBar';

class HiltiBudget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,
            items: [],
            used_budget_1: 0,
            used_budget_2: 0,
        };
    }

    onLoadItems(e) {
        e.preventDefault();
        this.setState({
            loading: true,
        });

        call(`/api/orders/${this.props.order_id}/hilti_items`)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    items: json.data,
                    loaded: true,
                    loading: false,
                    used_budget_1: json.data.reduce( (acc, item) => acc + item.price_1, 0),
                    used_budget_2: json.data.reduce( (acc, item) => acc + item.price_2, 0)
                });
            })
            .catch(err => {
                console.log('something bad happened', err);
            });
    }

    render() {
        let { props: P, state: S } = this;

        if (!S.loaded) {
            if (S.loading) {
                return <Spinner />;
            } else return (
                <Button
                    variant="success"
                    type="submit"
                    onClick={ e => this.onLoadItems(e)}
                    className={'mt-3'}
                >
                    Načítať hilti položky
                </Button>
            );
        }

        return (
        <div>
            <h5 className={classNames({'text-center': true, 'mt-5': true, 'mb-2': true})}>
                Súhrn expedovaných HILTI položiek na zákazku {P.serial_number}
            </h5>

            Aktuálne čerpanie rozpočtu na základe dodaného materiálu: {getNumber(S.used_budget_2)} € / {getNumber(P.hilti_budget)} € <br/>
            Aktuálne čerpanie rozpočtu na základe fakturovaných objednávok: {getNumber(S.used_budget_1)} €

            <ProgressBar
                used_budget={S.used_budget_2}
                reserved_budget={P.hilti_budget}
            />

            <SuperTable
                name={'hilti_budget'}
                thead={[
                    {name: 'image_url', sk_name: 'Foto', type: 'image', sort: false, search: false},
                    {name: 'code', sk_name: 'Kód', type: 'string'},
                    {name: 'name', sk_name: 'Nazov', type: 'string'},
                    {name: 'unit', sk_name: 'Jednotka', type: 'string', sort: false, search: false},
                    {name: 'quantity_1', sk_name: 'Počet - príjemky', type: 'number', search: false},
                    {name: 'quantity_2', sk_name: 'Počet - dodacie listy', type: 'number', search: false},
                    {name: 'price_1', sk_name: 'Cena - príjemky', type: 'number', search: false},
                    {name: 'price_2', sk_name: 'Cena - dodacie listy', type: 'number', search: false},
                ]}
                items={S.items}
                searchable
                is_loading={S.loading}
                export={P.serial_number + '_hilti_material'}
            />
        </div>
        );
    }
}

export default HiltiBudget;
