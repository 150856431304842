import React from 'react';
import store from '../../store';
import subscriptionActions from 'actions/subscriptions';
import {Button, Container} from 'react-bootstrap';
import {SuperTable} from 'components/PaginationTable';
import SubscriptionForm from './SubscriptionForm';
import WorkingHoursSubscriptionForm from './WorkingHoursSubscriptionForm';
import {connect} from 'react-redux';
import call from '../../ajax/call';

class Subscriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            formWorkingHoursActive: false,
            subscriptionToUpdate: null,
            metadata: {},
        };
    }

    componentDidMount() {
        if (this.props.subscriptions.length === 0) {
            store.dispatch(subscriptionActions.fetchAll());
        }
        if (Object.keys(this.state.metadata).length === 0) {
            call('/api/subscriptions/metadata')
                .then(response => response.json())
                .then(json => json.data)
                .then(data => {
                    this.setState({
                        metadata: data,
                    });
                });
        }
    }

    handleCreate() {
        this.setState({
            formActive: true
        });
    }

    handleCreateWorkingHours() {
        this.setState({
            formWorkingHoursActive: true,
        });
    }

    handleUpdate(id, workingHoursNotification) {
        this.setState({
            subscriptionToUpdate: this.props.subscriptions.find(b => b.id === id),
            [workingHoursNotification ? 'formWorkingHoursActive' : 'formActive']: true
        });
    }

    handleDelete(id) {
        store.dispatch(subscriptionActions.delete(id));
    }

    handleFormSubmit(obj) {
        let action = this.state.subscriptionToUpdate
            ? subscriptionActions.update(this.state.subscriptionToUpdate.id, obj)
            : subscriptionActions.create(obj);

        store.dispatch(action).then(data => {
            if (data.status === 'ok') {
                this.handleCloseForm();
            }
        });
    }

    handleCloseForm() {
        this.setState({
            formActive: false,
            formWorkingHoursActive: false,
            subscriptionToUpdate: null,
        });
    }


    render() {
        const {props: P, state: S} = this;
        const allNotifications = Object.keys(S.metadata).length > 1;
        const workingHoursNotification = Object.keys(S.metadata).length === 1;

        return (
            <Container className={'wide'}>
                <h3>
                    Správa notifikácii
                </h3>
                <Button
                    style={{marginRight: 15}}
                    onClick={() => this.props.history.push('/notifications')}
                    variant="success"
                >
                    Späť na notifikácie
                </Button>
                {
                    allNotifications && <Button
                        style={{marginRight: 15}}
                        onClick={() => this.handleCreate()}
                        variant="success"
                    >
                        Pridať notifikáciu
                    </Button>
                }
                {
                    workingHoursNotification && <Button
                        style={{marginRight: 15}}
                        onClick={() => this.handleCreateWorkingHours()}
                        variant="success"
                    >
                        Pridať notifikáciu na kontrolu dochádzky
                    </Button>
                }
                <SuperTable
                    name={'subscriptions'}
                    items={P.subscriptions}
                    thead={[
                        {name: 'active', sk_name: 'Aktívne', type: 'boolean'},
                        {name: 'name', sk_name: 'Názov', type: 'string', sort: false},
                        {name: 'object_name', sk_name: 'Predmet', type: 'string', sort: false},
                        {name: 'attribute_name', sk_name: 'Atribút', type: 'string', sort: false},
                        {name: 'attribute_type_name', sk_name: 'Frekvencia', type: 'string', sort: false},
                        {name: 'interval', sk_name: 'Notifikovať vopred', type: 'string', sort: false},
                        {name: 'value2_full_name', sk_name: 'Filter', type: 'string', sort: false},
                        {name: 'notify_database', sk_name: 'Notifikácia', type: 'boolean', sort: false},
                        {name: 'notify_email', sk_name: 'Email', type: 'boolean', sort: false},
                        {name: 'notify_sms', sk_name: 'SMS', type: 'boolean', sort: false},
                    ]}
                    columnSelector
                    defaultSortKey={'object'}
                    onRowClick={id => this.handleUpdate(id, workingHoursNotification)}
                    is_loading={P.is_loading}
                    delOps
                    onDeleteItem={id => this.handleDelete(id)}
                />
                <WorkingHoursSubscriptionForm
                    subscription={S.subscriptionToUpdate}
                    show={S.formWorkingHoursActive}
                    updating={!!S.subscriptionToUpdate}
                    onSubmit={(obj) => this.handleFormSubmit(obj)}
                    onClose={() => this.handleCloseForm()}
                />
                <SubscriptionForm
                    subscription={S.subscriptionToUpdate}
                    metadata={S.metadata}
                    show={S.formActive}
                    updating={!!S.subscriptionToUpdate}
                    onSubmit={(obj) => this.handleFormSubmit(obj)}
                    onClose={() => this.handleCloseForm()}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    subscriptions: state.subscriptions.items,
    is_loading: state.subscriptions.isFetching,
});

export default connect(mapStateToProps)(Subscriptions);