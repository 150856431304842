import React from 'react';
import store from 'store';
import ProfessionsActions from 'actions/professions';
import { connect } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import Form from './Form';
import NeedWritePerm from 'components/NeedPermission';
import { SuperTable } from 'components/PaginationTable';

class Professions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
        };
    }

    componentDidMount() {
        store.dispatch(ProfessionsActions.fetchAll());
    }

    addProfessyHandler(obj) {
        let action = {};

        if (this.state.objToUpdate) {
            action = ProfessionsActions.update(this.state.objToUpdate.id, obj);
        } else {
            action = ProfessionsActions.create(obj);
        }

        store.dispatch(action).then(data => {
            if (data.status === 'ok') {
                this.setState({
                    formActive: false,
                    objToUpdate: undefined
                });
            }
        });
   }

    changeHandler(e) {
        this.setState({
            professy_name: e.target.value   
        });
    }

    handleEditItem(id) {
        this.setState({
            objToUpdate: this.props.professions.find(p => p.id === id),
            formActive: true
        });
    }

    handleDeleteItem(id) {
        store.dispatch(ProfessionsActions.delete(id));
    }

    showForm() {
        this.setState({
            formActive: true
        });
    }

    render() {
        const { props: P, state: S } = this;
        return (
            <Container>
                <NeedWritePerm type="button" need={['employees']}>
                    <Button variant="success" onClick={this.showForm.bind(this)}>
                        Pridať profesiu
                    </Button>
                </NeedWritePerm>
                { S.formActive && 
                    <Form 
                        onSubmit={this.addProfessyHandler.bind(this)}
                        updating={!!S.objToUpdate} 
                        defaultValues={S.objToUpdate} 
                        onClose={ () => {
                            this.setState({
                                formActive: false,
                                objToUpdate: undefined
                            });}
                        }
                    />
                }
                <NeedWritePerm type="table" need={['employees']}>
                    <SuperTable
                        name={'professions'}
                        items={P.professions}
                        thead={[{name: 'name', sk_name: 'Názov', type: 'string'}]}
                        onEditItem={this.handleEditItem.bind(this)}
                        onDeleteItem={this.handleDeleteItem.bind(this)}
                        delOps
                        editOps
                        searchable
                    />
                </NeedWritePerm>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        professions: state.professions.items,
        err: state.professions.err
    };
};

export default connect(mapStateToProps)(Professions);