import React from 'react';
import {DefaultSelector, SuperTable} from 'components/PaginationTable';
import {Button, Container} from 'react-bootstrap';
import {connect} from 'react-redux';
import orderActions from 'actions/orders';
import buildingsActions from 'actions/buildings';
import store from 'store/mainStore';
import NeedWritePerm from 'components/NeedPermission';
import ModalForm from 'components/ModalForm';
import {addDependency} from 'actions/dependency';
import WorkCostsExportForm from './WorkCostsExportForm';
import './style.css';

function isEmpty(array) {
    return !array || array.length === 0;
}

function getResult(number, asc) {
    return asc ? number : -1*number;
}

function sortRows(a, b, sort_by_key, asc = true) {
    if (sort_by_key === 'serial_number') {
        let first = parseInt(a.serial_number);
        let second = parseInt(b.serial_number);

        if (!isNaN(first) && !isNaN(second)) {
            return getResult(first - second, asc);
        }
        if (isNaN(first) && isNaN(second)) {
            return getResult(a.serial_number.toLowerCase().localeCompare(b.serial_number.toLowerCase()), asc);
        }
        if (isNaN(first)) {
            return getResult(-1, asc);
        }
        if (isNaN(second)) {
            return getResult(1, asc);
        }
    } else {
        let first = a[sort_by_key];
        let second = b[sort_by_key];

        if (typeof first === 'string') {
            return getResult(first.toLowerCase().localeCompare(second.toLowerCase()), asc);
        } else {
            if (a[sort_by_key] > b[sort_by_key]) {
                return asc ? 1 : -1;
            }
            if (a[sort_by_key] < b[sort_by_key]) {
                return asc ? -1 : 1;
            }
            return 0;
        }
    }
}

class OrdersContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            exportFormActive: false,
            exportUrl: '/api/orders/expenses_export',
        };
    }

    componentDidMount() {
        if (isEmpty(this.props.orders) || this.props.orders.length === 1) {
            store.dispatch(orderActions.fetchAll());
        }
        store.dispatch(buildingsActions.fetchAll());
    }

    render() {
        const {state: S, props: P} = this;
        let items = P.orders;

        return (
            <Container className="buildings">
                <NeedWritePerm type="button" need={['orders']}>
                    <Button
                        variant="success"
                        onClick={() => {
                            this.setState({
                                formActive: true
                            });
                        }}
                    >
                        Pridať zákazku
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm
                    type={'button'}
                    need={['is_accountant']}
                >
                    <Button
                        variant="danger"
                        onClick={() => this.setState({
                            exportFormActive: true,
                            exportUrl: '/api/orders/expenses_export',
                        })}
                        style={{marginLeft: 40}}
                    >
                        Export nákladov
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm
                    type={'button'}
                    need={['material', 'material_prices']}
                >
                    <Button
                        variant="danger"
                        onClick={() => this.setState({
                            exportFormActive: true,
                            exportUrl: '/api/orders/material_export',
                        })}
                        style={{marginLeft: 40}}
                    >
                        Export materiálu
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm type="table" need={['orders']}>
                    <SuperTable
                        name={'orders'}
                        items={items}
                        thead={[
                            {name: 'building_name', sk_name: 'Budova', type: 'string'},
                            {name: 'serial_number', sk_name: 'Číslo', type: 'string'},
                            {name: 'name', sk_name: 'Názov', type: 'string'},
                            {name: 'active', sk_name: 'Aktívna', type: 'boolean'},
                            {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true},
                            {name: 'bonus', sk_name: 'Bonusovaná', type: 'boolean', hidden: true},
                            {name: 'time_budget', sk_name: 'Predpokladaný čas (hod.)', type: 'number', hidden: false},
                        ]}
                        columnSelector={true}
                        selectors={DefaultSelector}
                        onDeleteItem={id => store.dispatch(orderActions.delete(id))}
                        onRowClick={id => this.props.history.push(`/orders/detail/${id}`)}
                        defaultSortKey='serial_number'
                        customSortingFn={sortRows}
                        delOps
                        searchable
                        export={'zakazky'}
                        is_loading={P.is_loading}
                    />
                </NeedWritePerm>
                <WorkCostsExportForm
                    show={S.exportFormActive}
                    onClose={() => this.setState({exportFormActive: false})}
                    url={S.exportUrl}
                />
                <ModalForm
                    show={S.formActive}
                    formElements={[
                        {name: 'name', label: 'Názov', type: 'text', required: true},
                        {name: 'serial_number', label: 'Sériové číslo', type: 'text', required: true},
                        {
                            name: 'building',
                            label: 'Budova',
                            type: 'select',
                            options: {items: P.buildings},
                            selectKey: 'building_id',
                            required: true,
                        },
                        {name: 'comment', label: 'Komentár', type: 'text'},
                        {name: 'bonus', label: 'Externý príplatok', type: 'checkbox'},
                        {name: 'active', label: 'Aktívna', type: 'checkbox'},
                        {name: 'requires_operations', label: 'Vyžadovať operácie', type: 'checkbox'},
                        {name: 'hilti_budget', label: 'Hilti rozpočet', type: 'number', min: 0, step: 0.01},
                        {name: 'time_budget', label: 'Predpokladaný čas', type: 'number', min: 0, step: 0.5},
                    ]}
                    action={orderActions}
                    afterSave={() => this.setState({formActive: false, objectToUpdate: undefined})}
                    onClose={() => this.setState({formActive: false, objectToUpdate: undefined})}
                />
            </Container>
        );
    }
}

addDependency('orders', ['webapp_access']);

const mapStateToProps = state => ({
    buildings: state.buildings.items.filter(b => b.active),
    orders: state.orders.items,
    is_loading: state.orders.isFetching || state.buildings.isFetching,
});

export default connect(mapStateToProps)(OrdersContainer);
