import {createSelector} from 'reselect';

const getBuildings = (state) => {
    return state.buildings.items.filter(b => b.active).sort((a, b) => a.name.localeCompare(b.name));
};

const getBuildingsWithPerformances = (state) => {
    return state.buildingsWithPerformances.items;
};

const getEntityTypes = (state) => state.entity_types.items;

const getBuildingEntities = (state) => {
    return state.building_entities.items;
};

const getLoading = (state) => {
    return state.buildings.isFetching
        || state.buildingsWithPerformances.isFetching
        || state.entity_types.isFetching
        || state.building_entities.isFetching;
};

const getPermissions = (state) => state.permissions;

export const mapStateToProps = createSelector(
    [getBuildings, getBuildingsWithPerformances, getEntityTypes, getBuildingEntities, getLoading, getPermissions],
    (buildings, buildingsWithPerformances, entityTypes, entities, isLoading, permissions) => {
        let entitiesMap = new Map();
        entities.forEach(e => entitiesMap.set(e.id, e));

        let buildingsMap = new Map();
        buildings.forEach(b => buildingsMap.set(b.id, b));

        let buildingsWithPerformancesMap = new Map();
        buildingsWithPerformances.forEach(b => buildingsWithPerformancesMap.set(b.id, b));

        return {
            buildings,
            buildingsWithPerformances,
            entityTypes,
            entities,
            entitiesMap,
            buildingsMap,
            buildingsWithPerformancesMap,
            isLoading,
            permissions,
        };
    }
);
