import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Container, Row} from 'react-bootstrap';
import buildingsActions from 'actions/buildings';
import entityTypesActions from 'actions/entity_types';
import buildingEntitiesActions from 'actions/building_entities';
import {setGlobalError} from 'actions/globalError';

import store from 'store/mainStore';
import NeedWritePerm from 'components/NeedPermission';
import {SuperTable} from 'components/PaginationTable';
import Formsy from 'formsy-react';
import Spinner from 'components/Spinner';
import LevelForm from './LevelForm';

import {updateQueryParams} from 'scripts/queryFunctions';
import {getUrlParam, compareLevels} from './utils';
import SectionForm from '../WorkOperations/SectionForm';
import DV, {parseSeconds} from 'scripts/dateFormatter';
import {mapStateToProps} from '../WorkOperations/reselect';
import {isNotEmpty} from 'scripts/valueNormalizer';
import call from 'ajax/call';
import {compareRows} from 'components/PaginationTable/utils';
import FilesForm from 'components/FilesForm';
import {deletePicture} from './utils';

class Planning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            objToUpdate: {},
            updating: false,
            changed: false,
            params: {},
            loading_files: false,
        };
    }

    componentDidMount() {
        store.dispatch(buildingsActions.fetchAll());
        store.dispatch(entityTypesActions.fetchAll());

        let building_id = localStorage.getItem('planning_building_id');
        if (building_id) {
            building_id = parseInt(building_id, 10);
            const {location, history} = this.props;
            updateQueryParams(location, history, {building_id}, true);

            store.dispatch(buildingEntitiesActions.fetchAll({building_id}));
        }
    }

    formSubmit(model) {
        let parent_id = getUrlParam(this.props.location, 'parent_id');

        let obj = {
            planned_finish_date: model.planned_finish_date,
            name: model.name || '',
            description: model.description || '',
            picture: model.picture,
            difficulty_level: model.difficulty_level || 1,
            length: model.length || null,
            is_leaf: model.is_leaf,
        };

        if (!this.state.updating) {
            obj['building_id'] = model.building.id;
            obj['entity_type_id'] = model.type.id;
            obj['parent_id'] = parent_id;
        }

        const formData = new FormData();
        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }
        let options = {
            stringify: false,
            contentType: false,
        };

        let action;
        if (this.state.updating) {
            formData.append('_method', 'PUT');
            action = buildingEntitiesActions.update(this.state.objToUpdate.id, formData, {
                ...options,
                method: 'POST'
            });
        } else {
            action = buildingEntitiesActions.create(formData, options);
        }

        store.dispatch(action)
            .then(data => {
                if (data.status === 'ok') {
                    if (data.data.id && model.init_operations) {
                        setTimeout(() => {
                            call('/api/building_entities/init_operations', {
                                method: 'POST',
                                headers: {'Content-type': 'application/json'},
                                body: JSON.stringify({
                                    entity_id: data.data.id
                                })
                            }).then(response => response.json())
                                .then(reponse_data => {
                                    store.dispatch(setGlobalError([reponse_data.message], 'info', false));
                                    this.props.history.push(`/planning/entity?building_id=${data.data.building_id}&entity_id=${data.data.id}`);
                                });
                        }, 1000);
                    }
                    this.setState({
                        formActive: false,
                        objToUpdate: undefined,
                        updating: false,
                    });
                }
            });
    }

    handleChange(model) {
        if (!model.building) {
            return;
        }
        const {location, history} = this.props;
        updateQueryParams(location, history, {building_id: model.building.id});
        localStorage.setItem('planning_building_id', model.building.id);
    }

    redirect(id) {
        let building_id = getUrlParam(this.props.location, 'building_id');

        let entity = this.props.entitiesMap.get(id);
        if (entity && entity.is_leaf) {
            this.props.history.push(`/planning/entity?building_id=${building_id}&entity_id=${id}`);
        } else {
            this.props.history.push(`/planning?building_id=${building_id}&parent_id=${id}`);
        }
    }

    updateQueryParams(newParams) {
        const {location, history} = this.props;
        updateQueryParams(location, history, newParams);
    }

    customSortingFn(a, b, sort_by_key, asc) {
        if (sort_by_key == 'name') {
            let result = compareLevels(a, b);
            return asc ? result : -1*result;
        } else {
            return compareRows(a, b, sort_by_key, asc);
        }
    }

    fetchFiles(entity_id) {
        this.setState({loading_files: true});

        call(`/api/building_entities/${entity_id}/files`, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.setState({
                        files: data.data,
                        loading_files: false,
                    });
                }
            });
    }

    render() {
        const {props: P, state: S} = this;

        if (P.is_loading || P.entityTypes.size === 0) {
            return <Spinner/>;
        }

        let building_id = getUrlParam(P.location, 'building_id');
        let parent_id = getUrlParam(P.location, 'parent_id');
        let params = {
            building_id,
            entity_id: parent_id,
        };

        let parent = null;
        let type_parent = null;

        if (parent_id) {
            parent = P.entitiesMap.get(parent_id);
            if (parent) {
                type_parent = P.entityTypes.find(t => t.id === parent.entity_type_id);
            }
        }

        let type_parent_id = type_parent ? type_parent.id : null;
        let entity_types = P.entityTypes.filter(t => t.parent_id === type_parent_id)
            .map(t => {
                let items = P.entities.filter(
                    e => e.parent_id === parent_id && e.entity_type_id === t.id && e.building_id === building_id
                ).map(e => ({...e, pie_chart: e.status_percent}));

                if (items.length === 0) {
                    return null;
                }
                let sortingFn = undefined;
                if (t.name.toLowerCase() == 'poschodie') {
                    sortingFn = this.customSortingFn;
                }

                return (<div key={t.id}>
                    <h4>{t.plural_name}</h4>
                        <NeedWritePerm type="table" need={['planning']}>
                            <SuperTable
                                name={t.plural_name}
                                thead={[
                                    {name: 'name', sk_name: t.name, type: 'string'},
                                    {name: 'pie_chart', sk_name: 'Stav', type: 'pie_chart'},
                                    {name: 'status_percent', sk_name: '%', type: 'integer'},
                                    {name: 'started_at', sk_name: 'Začiatok prác', type: 'short_date'},
                                    {name: 'finished_at', sk_name: 'Koniec prác', type: 'short_date'},
                                    {name: 'planned_finish_date', sk_name: 'Plán. dátum ukončenia', type: 'short_date'},
                                    {name: 'total_time', sk_name: 'Odpracované hodiny', type: 'seconds'},
                                    {name: 'picture', sk_name: 'Výkres', type: 'file'},
                                    {name: 'length', sk_name: 'Dĺžka', type: 'integer', hidden: true},
                                    {name: 'difficulty_level', sk_name: 'Úroveň náročnosti', type: 'integer', hidden: true},
                                    {name: 'estimated_time', sk_name: 'Odhadovaný čas', type: 'seconds', hidden: true},
                                ]}
                                columnSelector
                                items={items}
                                is_loading={P.is_loading}
                                customSortingFn={sortingFn}
                                editOps
                                delOps
                                onEditItem={id => {
                                    this.setState({
                                        formActive: true,
                                        objToUpdate: P.entitiesMap.get(id),
                                        updating: true,
                                    });
                                }}
                                onDeleteItem={id => store.dispatch(buildingEntitiesActions.delete(id))}
                                rowStyleFn={(column, obj) => {
                                    return obj.finished_at ? {backgroundColor: 'rgba(48,172,28,0.46)'} : {};
                                }}
                                onRowClick={id => this.redirect(id)}
                            />
                        </NeedWritePerm>
                    </div>
                );
            });

        let view = null;
        if (parent && type_parent) {
            let type_name = type_parent.name;
            type_name = type_name.charAt(0).toUpperCase() + type_name.slice(1);

            view = (
                <div className="info">
                <Row style={{
                    margin: 15,
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '24pt'
                }}>
                    {type_name} {parent.name}
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Začiatok prác</Col>
                    <Col xs={6} className="text-left">{DV(parent.started_at, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Koniec prác</Col>
                    <Col xs={6} className="text-left">{DV(parent.finished_at, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Plán. dátum ukončenia</Col>
                    <Col xs={6} className="text-left">{DV(parent.planned_finish_date, false)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Stav prác</Col>
                    <Col xs={6} className="text-left">{`${parent.status_percent} %`}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Odpracované hodiny</Col>
                    <Col xs={6} className="text-left">{parseSeconds(parent.total_time)}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Celkový odhadovaný čas</Col>
                    <Col xs={6} className="text-left">{parseSeconds(parent.estimated_time)}</Col>
                </Row>
                {
                    parent.description ?
                        <Row>
                            <Col xs={6} className="text-right">Popis</Col>
                            <Col xs={6} className="text-left">{parent.description}</Col>
                        </Row> : null
                }
                {
                    parent.picture ?
                        <Row style={{
                            textAlign: 'center',
                            margin: 15
                        }}>
                            <Col>
                                <a href={parent.picture}>
                                    <Button type={'normal'}>
                                        Zobraziť výkres
                                    </Button>
                                </a>
                                <Button
                                    style={{marginLeft: 15}}
                                    variant="danger"
                                    onClick={() => deletePicture(parent)}
                                >
                                    Vymazať výkres
                                </Button>
                            </Col>
                        </Row>
                        : null
                }
                <div style={{marginTop: 15, textAlign: 'center',}}>
                    <NeedWritePerm type="button" need={['planning']}>
                        <Button
                            variant="success"
                            onClick={() => this.setState({
                                formActive: true,
                                updating: true,
                                objToUpdate: parent,
                            })}
                        >
                            Upraviť
                        </Button>
                    </NeedWritePerm>
                </div>
                <div style={{
                    marginTop: 15,
                    marginBottom: 15,
                    textAlign: 'left'
                }}>
                    <FilesForm
                        heading={'Výkresy'}
                        files={this.state.files}
                        files_url={`/api/building_entities/${parent.id}/files`}
                        editable={P.permissions.planning == 2}
                        afterChange={() => this.fetchFiles(parent.id)}
                        loading={this.state.loading_files}
                    />
                </div>
            </div>
            );
        }

        let addButton = null;
        if (entity_types.length > 0) {
            addButton = <NeedWritePerm type="button" need={['planning']} key={'add_button'}>
                <Button
                    variant="success"
                    onClick={() => this.setState({formActive: true})}
                    style={{
                        marginRight: 15,
                        marginBottom: 15,
                        width: 50,
                        height: 50,
                        borderRadius: '50%'
                    }}
                >
                    <svg className="icon  icon--plus" viewBox="0 0 11 11"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 2 h1 v3 h3 v1 h-3 v3 h-1 v-3 h-3 v-1 h3 z"/>
                    </svg>
                </Button>
            </NeedWritePerm>;
        }

        entity_types = entity_types.filter(i => i);
        if (addButton) {
            if (entity_types.length === 0) {
                entity_types = [
                    <div key={'no_items'}
                         style={{
                             margin: 20,
                             display: 'flex',
                             justifyContent: 'center',
                             fontSize: '18pt'
                         }}
                    >
                        Žiadne položky
                    </div>,
                    addButton
                ];
            } else {
                entity_types.unshift(addButton);
            }
        }

        return (
            <Container
                className="cars"
            >
                <Formsy
                    onChange={model => this.handleChange(model)}
                >
                    <SectionForm
                        onChange={newParams => {
                            this.updateQueryParams({
                                building_id: newParams.building_id,
                                parent_id: newParams.entity_id
                            });
                        }}
                        locked={false}
                        params={{
                            building_id,
                            entity_id: parent_id,
                        }}
                        leaf_required={false}
                    />
                </Formsy>
                {view}
                <div style={{
                    marginBottom: 15
                }}>
                    <Button
                        variant="success"
                        onClick={() => this.props.history.push(`/operation_statistics?building_id=${building_id}&entity_id=${parent_id}`)}
                    >
                        Štatistika prac. operácií
                    </Button>
                </div>
                {entity_types}
                <LevelForm
                    key={S.updating ? S.objToUpdate.id : 'new_key'}
                    params={params}
                    show={S.formActive}
                    onClose={() => {
                        this.setState({
                            formActive: false,
                            updating: false,
                        });
                    }}

                    onSubmit={(model) => this.formSubmit(model)}
                    updating={S.updating}
                    obj={
                        S.updating ? S.objToUpdate :
                            {building_id: building_id ? building_id : null}
                    }
                    entity_types={P.entityTypes.filter(t => t.parent_id === type_parent_id)}
                />
            </Container>
        );
    }
}

export default connect(mapStateToProps)(Planning);
