import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
import {Link} from 'react-router-dom';

class NotificationForm extends React.Component {
    render() {
		const { notification } = this.props;
		if (!notification){
            return null;
        }
		const body = notification.data.body;

		return (
            <Modal
                size="md"
                show={this.props.show}
                onHide={this.props.onClose}
            >
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: 25,
                            width: '100%',
                        }}
                    >
                        {notification.data.type}
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy>
                        <Textarea
                            name={'message'}
                            label={'Správa'}
                            value={notification.message}
                            readOnly={true}
                        />
                        {
                            Object.keys(body).map( k => {
                                let value = body[k] || '';
                                if (value.length <35) {
                                    return <Input
                                        key={k}
                                        name={k}
                                        label={k}
                                        value={value}
                                        readOnly={true}
                                    />;
                                } else {
                                    return <Textarea
                                        key={k}
                                        name={k}
                                        label={k}
                                        value={value}
                                        readOnly={true}
                                    />;
                                }
                            })
                        }
                        <Row className={'mb-2'}>
                            <Col sm={4} md={4}>
                                <Button
                                    type="submit"
                                    variant="success"
                                    onClick={ () => this.props.onClose()}
                                >
                                    Zatvoriť
                                </Button>
                            </Col>
                            <Col sm={4} md={8}>
                                <Button
                                    type="submit"
                                    variant="success"
                                    onClick={ () => this.props.onRead()}
                                >
                                    Označiť ako prečítané
                                </Button>
                            </Col>
                        </Row>
                        <Row className={'mb-2'}>
                            <Col sm={{span: 4, offset: 4}}>
                                <Link to={notification.data.link}>
                                    <Button
                                        type="submit"
                                    >
                                        Zobraziť detail
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

NotificationForm.propTypes = {
    show: PropTypes.bool,
	onClose: PropTypes.func,
	onRead: PropTypes.func,
};

NotificationForm.defaultProps = {
    show: false,
    onSubmit: () => {},
    onClose: () => {},
    defaultValues: {
        name: '',
        address: '',
        comment: '',
    }
};

export default NotificationForm;