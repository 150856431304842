import React from 'react';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import { Button,  Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import PT from 'prop-types';
import { FormSelect } from 'components/CustomSelect';

class MaterialForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            errors: {},
            formSubmitted: false,
            material: null,
            dimension: null,
            secondary_dimension: null,
            unit: null,
            two_dimensions: false,
        };
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true
        });

        const { props: P} = this;

        if (!this.state.two_dimensions && (!P.updating || !P.obj.secondary_dimension_id)){
            model['secondary_dimension'] = null;
        }

        if (this.validateForm(model)) {
            return;
        }
        this.props.onSubmit(model);
    }

    validateForm(model) {
        let validations = {
            material_type: 'Zvoľte typ',
            main_dimension: 'Zvoľte dimenziu 1',
            quantity: 'Zadajte počet',
        };
        if (this.state.two_dimensions){
            validations['secondary_dimension'] = 'Zvoľte dimenziu 2';
        }

        let errors = {};

        Object.keys(validations).forEach( key => {
            if (!model[key]) {
                errors[key] = validations[key];
            }
        });

        this.setState({
            errors: errors
        });

        return (Object.keys(errors).length > 0);
    }

    onTypeChange(type){
        if (!type){
            this.setState({
                material: type,
                two_dimensions: false,
                unit: null,
            });
            return;
        }

        let newState = {
            material: type,
            two_dimensions: type.number_of_dimensions === 2,
            unit: type.unit_label,
        };

        if (!newState.two_dimensions){
            newState['secondary_dimension'] = null;
        }

        this.setState(newState);
    }

    render() {
        const { props: P, state: S } = this;
        let dimensions = P.dimensions.sort( (a, b) => a.millimeters - b.millimeters);
        let types = P.material_types.sort((a, b) => a.name.localeCompare(b.name));

        let default_type = types.find( t => t.id === 1) || null;
        let default_dimension = dimensions.find( d => d.id === 4);
        let secondary_dimension = dimensions.find( d => d.id === 5);
        let unit = (S.unit || 'metrov');

        if (P.updating){
            default_type = types.find(t => t.id === P.obj.material_type_id);
            default_dimension = dimensions.find( d => d.id === P.obj.dimension_id);
            if (P.obj.secondary_dimension_id){
                secondary_dimension = dimensions.find( d => d.id === P.obj.secondary_dimension_id);
            }
            unit = P.obj.type.unit_label;
        }

         return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Formsy
                    onValidSubmit={model => this.submitHandler(model)}
                    onInvalidSubmit={model => this.submitHandler(model)}
                    onChange={ values => this.validateForm(values)}
                    validationErrors={S.errors}
                >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ P.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <FormSelect
                            name={'material_type'}
                            label={'Typ'}
                            options={types}
                            placeholder={'Vyberte typ'}
                            isClearable
                            getOptionValue={ t => t.id}
                            getOptionLabel={ t => t.name}
                            required
                            value={S.material || default_type}
                            onChange={ val => this.onTypeChange(val)}
                        />
                        <FormSelect
                            name={'main_dimension'}
                            label={'DN'}
                            options={dimensions}
                            placeholder={'Zvoľte dimenziu'}
                            isClearable
                            getOptionValue={ d => d.id}
                            getOptionLabel={ d => d.name}
                            required
                            value={S.dimension || default_dimension}
                            onChange={ val => this.setState({dimension: val})}
                        />
                        { (S.two_dimensions || (P.updating && P.obj.secondary_dimension_id )) ?
                            <FormSelect
                                name={'secondary_dimension'}
                                label={'DN2'}
                                options={dimensions}
                                placeholder={'Zvoľte dimenziu 2'}
                                isClearable
                                getOptionValue={ d => d.id}
                                getOptionLabel={ d => d.name}
                                required
                                value={S.secondary_dimension || secondary_dimension}
                                onChange={ val => this.setState({secondary_dimension: val})}
                            /> : null
                        }
                        <Input
                            name="quantity"
                            label={'Počet ' + unit}
                            value={String(P.obj.quantity) || '1'}
                            type={'number'}
                            step={unit === 'metrov' ? '0.01' : '1'}
                            min={0}
                            max={1000}
                        />
                        <Input
                            name="comment"
                            label="Poznámka"
                            value={P.obj.comment || ''}
                            type={'string'}
                        />
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Uložiť
                        </Button>
                    </Modal.Footer>
                </Formsy>
            </Modal>
        );
    }
}

MaterialForm.propTypes = {
    show: PT.bool.isRequired,
    updating: PT.bool.isRequired,
    obj: PT.object,
    onSubmit: PT.func.isRequired,
    onClose: PT.func.isRequired,
    material_types: PT.arrayOf(PT.object).isRequired,
    dimensions: PT.arrayOf(PT.object).isRequired,
};

MaterialForm.defaultProps = {
    onSubmit: () => {},
    onClose: () => {},
    updating: false,
    obj: {},
};

export default MaterialForm;
