import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Row, Col } from 'react-bootstrap';

import toolCategoriesActions from 'actions/tool_categories';
import toolStatesActions from 'actions/tool_states';
import employeesActions from 'actions/employees';
import toolsActions from 'actions/tools';
import store from 'store/mainStore';

import Form from './Form';
import { addDependency } from 'actions/dependency';
import propsReselect from './propsReselect';
import NeedWritePermission from 'components/NeedPermission';
import { SuperTable } from 'components/PaginationTable';
import Spinner from 'components/Spinner';
import {isNotEmpty} from 'scripts/valueNormalizer';

class ToolsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            objToUpdate: null,
            searching: false,
            toolToDelete: null,
        };
    }

    toolClickHandle(id) {
        this.props.history.push(`/tools/detail/${id}`);
    }

    handleSubmit(obj) {
        const {state: S} = this;
        const formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }

        let options = {
            stringify: false,
            contentType: false,
        };

        let action;
        if (S.objToUpdate) {
            formData.append('_method', 'PUT');
            action = toolsActions.update(S.objToUpdate.id, formData, {...options, method: 'POST'});
        } else {
            action = toolsActions.create(formData, options);
        }

        store.dispatch(action).then(data => {
            if (data.status === 'ok') {
                store.dispatch(toolsActions.fetchAll());
                this.setState({
                    objToUpdate: null,
                    formActive: false
                });
            }
        });
    }

    componentDidMount() {
        store.dispatch(toolCategoriesActions.fetchAll());
        store.dispatch(toolStatesActions.fetchAll());
        store.dispatch(employeesActions.fetchAll());
        store.dispatch(toolsActions.fetchAll());
    }

    static funcGenerator(attribute, desired_value){
        return function (obj) {
            return obj[attribute] === desired_value;
        };
    }

    createSelectors(){
        let category_selector_values = [];
        this.props.categories.forEach( item => {

            category_selector_values.push({
                value: item.id,
                name: item.name,
                filter: ToolsContainer.funcGenerator('category_id', item.id)
            });
        });

        let state_selector_values = [];
        let default_state_value = 0;
        this.props.toolStates.forEach( item => {
            if (item.name === 'aktívne'){
                default_state_value = item.id;
            }

            state_selector_values.push({
                value: item.id,
                name: item.name,
                filter: ToolsContainer.funcGenerator('state_id', item.id)
            });
        });

        return [
            {
                placeholder: 'Zvoľte kategóriu',
                name: 'category_selector',
                values: category_selector_values,
                isMulti: true,
            },
            {
                placeholder: 'Zvoľte stav',
                name: 'state_selector',
                default_value: default_state_value,
                values: state_selector_values,
                isMulti: true,
            }
        ];
    }

    render() {
        const { props: P, state: S } = this;
        if (P.is_loading) {
            return <Spinner/>;
        }

        return (
            <div className="tools">
                <Container xl={12} lg={12} md={12}>
                    <Row>
                        <Col md={12}>
                            <NeedWritePermission type="button" need={['tools']}>
                                <Button
                                    variant="success"
                                    onClick={() => this.setState({ formActive: true, objToUpdate: undefined} )}
                                    style={{ marginRight: 15 }}
                                >
                                    Pridať nástroj
                                </Button>
                            </NeedWritePermission>
                            <NeedWritePermission type="button" need={['tools']}>
                                <Button
                                    style={{ marginRight: 15 }}
                                    variant="success"
                                    onClick={() => this.props.history.push('/tools/categories')}
                                >
                                    Kategórie náradia
                                </Button>
                            </NeedWritePermission>
                        </Col>
                    </Row>
                    <Form
                        key={S.objToUpdate ? 'index' + S.objToUpdate.id : 'new_obj'}
                        onClose={() => this.setState({ formActive: false })}
                        show={S.formActive}
                        employees={P.employees.filter(e => e.active)}
                        toolStates={P.toolStates}
                        updating={!!S.objToUpdate}
                        tool={ S.objToUpdate }
                        tools={P.tools}
                        parent={(S.objToUpdate && S.objToUpdate.parent_id)
                            ? P.tools.find( t => t.id === S.objToUpdate.parent_id)
                            : null
                        }
                        onSubmit={obj => this.handleSubmit(obj)}
                        categories={P.categories}
                        identity={P.identity}
                    />
                    <SuperTable
                        name={'tools'}
                        items={P.tools}
                        thead={[
                            {name: 'thumbnail', sk_name: 'Fotka', type: 'image'},
                            {name: 'name', sk_name: 'Názov', type: 'string'},
                            {name: 'has_children', sk_name: 'Sada', type: 'boolean'},
                            {name: 'category_name', sk_name: 'Kategória', type: 'string'},
                            {name: 'state_name', sk_name: 'Stav', type: 'string'},
                            {name: 'owner_name', sk_name: 'Vlastník', type: 'string'},
                            {name: 'warranty_expiration', sk_name: 'Koniec záruky', type: 'date'},
                            {name: 'parent_name', sk_name: 'Rodičovský nástroj', type: 'string', hidden: true},
                            {name: 'serial_num', sk_name: 'Sériové číslo', type: 'string', hidden: true},
                            {name: 'chip_number', sk_name: 'Číslo čipu', type: 'string', hidden: true},
                            {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true},
                            {name: 'history_comment', sk_name: 'Komentár kvality', type: 'string', hidden: true},
                            {name: 'from_date', sk_name: 'Od dátumu', type: 'date', hidden: true},
                            {name: 'last_revision', sk_name: 'Posledná revízia', type: 'date', hidden: true},
                            {name: 'next_revision', sk_name: 'Ďalšia revízia', type: 'date', hidden: true},
                            {name: 'revision_document', sk_name: 'Revízny dokument', type: 'file', hidden: true},
                            {name: 'aux_document', sk_name: 'Dodatočný dokument', type: 'file', hidden: true},
                            {name: 'quality', sk_name: 'Kvalita', type: 'string', hidden: true},
                            {name: 'label_thumbnail', sk_name: 'Štítok', type: 'image', hidden: true},
                        ]}
                        columnSelector={true}
                        onDeleteItem={id => store.dispatch(toolsActions.delete(id))}
                        onRowClick={id => this.toolClickHandle(id)}
                        className="custom-order-style"
                        selectors = {this.createSelectors()}
                        showRowNumbers={true}
                        searchable={true}
                        searchAllItems={true}
                        export={'naradie'}
                        //treeStructure={false}
                        is_loading = {P.is_loading}
                    />
                </Container>
            </div>
        );
    }
}

addDependency('tools', ['webapp_access', 'employees']);
const mapStateToProps = propsReselect;

export default connect(mapStateToProps)(ToolsContainer);
