import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'components/Spinner';
import './style.css';
import PT from 'prop-types';
import { Button, FormControl } from 'react-bootstrap';
import Slider from '../Planning/OperationDetail/InputMultiSlider';
import DV from 'scripts/dateFormatter';
import work_performance_actions from 'actions/work_performance';
import store from '../../store';

class WorkReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status_percent: 0,
        };

        this.inputRef = React.createRef();
    }

    fetchSectionData(entity_id){
        if (entity_id) {
            store.dispatch(work_performance_actions.fetchAll({id: entity_id}));
        }
    }

    componentDidMount() {
        this.fetchSectionData(this.props.entity_id);
    }

    render() {
        const { props:P, state:S } = this;

        if (P.is_loading){
            return <Spinner/>;
        }
        let intervals = P.work_performance.filter( i => i.operation_type_id === P.operation_type_id);

        if (P.started && P.obj && !intervals.find(i => i.id === P.obj.id)) {
            intervals.push(P.obj);
        }
        intervals.sort((a, b) => a.started_at.localeCompare(b.started_at));
        let slider_key = intervals.reduce((acc, item) => acc + String(item.id), '');
        let my_index = (P.started && P.obj) ? intervals.findIndex(w => w.id === P.obj.id) : null;

        let info = null;
        if (P.started) {
            info = 'Začiatok operácie ' + DV(P.obj.started_at, true, {month: 'numeric'});
        }

        return (
            <div
                style={{
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <h5 style={{marginBottom: 20}}>
                    Pracovníci: {P.group_names}
                </h5>
                <h5 style={{marginBottom: 15}}> {info}</h5>
                {!P.isOverhead ?
                    <h5>{P.started ? 'Zapísať a' : 'A'}ktuálny stav prác (počet %): </h5> : null
                }
                <div>
                    {
                        !P.isOverhead ?
                        <div
                            style={{
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >{
                            <Slider
                                key={slider_key}
                                items={intervals}
                                myIndex={my_index}
                                onChange={val => this.setState({
                                    status_percent: P.min_status + val
                                })}
                            />
                        }
                        </div> : null
                    }
                </div>
                {P.started ?
                        (<div>
                            <div>
                                <h5>Poznámka:</h5>
                                <FormControl
                                    as={'textarea'}
                                    ref={this.inputRef}
                                    style={{width: '100%'}}
                                />
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'right'
                            }}>
                                <span>
                                    <Button
                                        style={{margin: 5}}
                                        variant="danger"
                                        onClick={() => {
                                            P.onPause(
                                                Math.max(P.min_status, S.status_percent),
                                                this.inputRef.current ? this.inputRef.current.value : null
                                            );
                                        }}
                                    >
                                        Ukončiť
                                    </Button>
                                </span>
                            </div>
                        </div>) :
                        (
                            <Button
                                style={{margin: 5}}
                                variant="danger"
                                onClick={() => P.onStart()}
                            >
                                Začať operáciu
                            </Button>
                        )
                    }
                </div>
            );
        }
}

WorkReport.propTypes = {
    operation_name: PT.string.isRequired,
    min_status: PT.number.isRequired,
    onPause: PT.func.isRequired,
    onStart: PT.func.isRequired,
    obj: PT.object,
    group_names: PT.string.isRequired,
    entity_id: PT.number.isRequired,
    operation_type_id: PT.number.isRequired,
    started: PT.bool.isRequired,
};

WorkReport.defaultProps = {
    operation_name: '',
    min_status: 0,
    group_names: '',
    obj: null,
    started: false
};

const mapStateToProps = state => {
    return {
        work_performance: state.work_performance.items,
        is_loading: state.work_performance.isFetching
    };
};

export default connect(mapStateToProps)(WorkReport);
