import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Table} from 'react-bootstrap';
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from 'react-icons/ti';
import {booleanValuesArrayToObject, compareRows} from './utils';
import classNames from 'classnames';
import Search from './Searcher';
import searcher from 'scripts/searcher';
import PageControl from './PageControl';
import './style.css';
import Selector from './Selector';
import ChooseColumnsSelector from './ChooseColumns';
import TableRow from './TableRow';
import {ConfirmModal} from 'components/modals';
import {withRouter} from 'react-router-dom';
import Spinner from 'components/Spinner';
import handleExport from 'scripts/exportToExcel';

const DefaultSelector = [{
    placeholder: 'Všetky',
    name: 'active_selector',
    default_value: 1,
    values: [
        {
            value: 0,
            name: 'Zobraziť všetky',
            filter: () => true,
        },
        {
            value: 1,
            name: 'Zobraziť aktívne',
            filter: obj => obj.active
        },
        {
            value: 2,
            name: 'Zobraziť neaktívne',
            filter: obj => !obj.active
        }
    ]
}];

const NoResults = (props) => {
    return (
        <div className="no-results">
            <span>
                {props.message || 'Žiadne výsledky'}
                {props.searchText && ' pre hľadaný výraz ' + props.searchText}
            </span>
        </div>
    );
};

const searchable_types = [
    'string',
    'short_date',
    'date',
    'date-time',
    'int',
    'number',
    'float',
    'hours',
    'seconds'
];

const defaultTableKey = 'table';

const Thead = (props) => {
    const full_thead_items = props.thead_items.map(thead_item => {

        let isSortingCol = props.sortActive && thead_item.name === props.sortBy;
        let icon;

        if (isSortingCol) {
            icon = props.asc ? <TiArrowSortedDown/> : <TiArrowSortedUp/>;
        } else {
            icon = <TiArrowUnsorted/>;
        }

        let is_sortable = (thead_item.sort !== false && searchable_types.some(t => t === thead_item.type));

        /* Not every column can be sortable*/
        let onClickFn = is_sortable ? () => props.onSortClick(thead_item.name) : () => {};

        return (
            <th
                key={thead_item.name}
                onClick={onClickFn}
                className={classNames({'sorting-th': is_sortable})}
            >
                <p style={{textAlign: 'left'}}><span>{thead_item.sk_name}</span> {is_sortable ? icon : null}</p>
            </th>
        );
    });

    if (props.showRowNumber) {
        full_thead_items.unshift(
            <th key={'row_number'}>
                <p style={{textAlign: 'left'}}>#</p>
            </th>
        );
    }
    let keys = ['delOps', 'editOps', 'showOps'];
    keys.forEach(k => {
        if (props[k]) {
            full_thead_items.push(
                <th key={k} />
            );
        }
    });

    return <thead>
    <tr>{full_thead_items}</tr>
    </thead>;
};

Thead.propTypes = {
    thead_items: PropTypes.array.isRequired,
    onSortClick: PropTypes.func.isRequired,
    asc: PropTypes.bool.isRequired,
    sortActive: PropTypes.bool.isRequired,
    sortBy: PropTypes.string
};

class MyTable extends React.Component {
    constructor(props) {
        super(props);

        let settings = {
            sorted_active: typeof this.getSetting('sorted_active') === 'boolean' ? this.getSetting('sorted_active') : !!props.defaultSortKey,
            sort_by_key: this.getSetting('sort_by_key') ?? props.defaultSortKey,
            asc: typeof this.getSetting('asc') === 'boolean' ? this.getSetting('asc') : props.defaultAsc,
            search_query: this.getSetting('search_query') ?? (props.defaultSearch || ''),
            current_page: this.getSetting('current_page') ??  0,
            page_size: (this.getSetting('page_size') ?? props.defaultPageSize) ?? 10,
            hidden_columns: this.getSetting('hidden_columns') ?? booleanValuesArrayToObject(props.thead, 'name', 'hidden'),
        };

        props.selectors.forEach(selector => {
                if (this.getSetting(selector.name)) {
                    settings[selector.name] = this.getSetting(selector.name);
                } else {
                    settings[selector.name] = selector.default_value === undefined ? '' : selector.default_value;
                }
            }
        );
        if (settings.asc === undefined) {
            settings.asc = true;
        }

        this.state = {
            confirmModal: null,
            confirm_show: false,
            confirm_message: '',
            chooseColumnsSelectorActive: false,
            ...settings,
        };

        this.updateParams(settings, false);
    }

    onSelectorValueChanged(selector_name, value) {
        this.updateParams({[selector_name]: value});
    }

    handleSortClick(key) {
        let asc = this.state.asc === true;
        let active = this.state.sorted_active === true;

        if (asc && active){
            asc = false; //desc
        } else {
            if (!active) {
                active = true; //asc
                asc = true;
            } else {
                active = false; //both
                asc = false;
            }
        }

        this.updateParams({
            asc: asc,
            sort_by_key: key,
            sorted_active: active,
            current_page: 0,
        });
    }

    handleSearchChange(query) {
        let params;
        if (query === '') {
            params = {search_query: ''};
        } else {
            params = {
                search_query: query,
                current_page: 0,
            };
        }
        this.updateParams(params);
    }

    handlePageSizeChange(value) {
        this.updateParams({current_page: 0, page_size: value});
    }

    previousPage() {
        let current_page = parseInt(this.state.current_page, 10);
        this.updateParams({current_page: current_page - 1});
    }

    nextPage() {
        let current_page = parseInt(this.state.current_page, 10);
        this.updateParams({current_page: current_page + 1});
    }

    handleDelete(id) {
        const toDelete = this.props.items.find(item => item.id === id);
        const nameKey = this.props.thead.filter(p => (p.type === 'string' && !p.hidden))[0].name;

        this.setState({
            confirm_show: true,
            confirm_action: () => this.props.onDeleteItem(id),
            confirm_message: `Vymazať záznam: ${toDelete[nameKey]}`
        });
    }

    getSearchKeys() {
        let search_keys = this.props.thead.filter(item => {
                return item.search !== false && searchable_types.some(t => t === item.type);
            }
        );
        return search_keys.map(item => item.name);
    }

    getSelectorValue(selector) {
        let val = this.state[selector.name];
        if (!val) {
            return [];
        } else {
            let result = [];
            val.toString().split(',').forEach(id => {
                let found = selector.values.find(o => o.value === parseInt(id, 10));
                if (found !== undefined) {
                    result.push(found);
                }
            });
            return result;
        }
    }

    onColumnSelectorSubmit(values) {
        this.updateParams({hidden_columns: booleanValuesArrayToObject(values, 'name', 'hidden')});
        this.setState({
            chooseColumnsSelectorActive: false,
        });
    }

    updateParams(params, updateState = true) {
        Object.keys(params).forEach((key) => {
            this.setStorageItem(key, typeof params[key], params[key]);
        });

        if (updateState) {
            this.setState({
                ...params
            });
        }
    }

    getSetting(key) {
        return this.getStorageItem(key);
    }

    getPathKey() {
        const pathname = this.props.history.location.pathname;

        if (pathname.startsWith('/orders/detail/')) {
            if (pathname.endsWith('documents')) {
                return '/orders/detail/documents';
            }
            if (pathname.endsWith('material_items')) {
                return '/orders/detail/material_items';
            }
        }
        if (pathname.startsWith('/storecards/')) {
            return '/storecards';
        }
        return pathname.replace(/[0-9]/g, '');
    }

    getStorageItem(paramName) {
        const pathKey = this.getPathKey();
        const tableKey = this.props.name || defaultTableKey;

        const storageItem = localStorage.getItem(pathKey);
        const storageItemObject = JSON.parse(storageItem) || {};
        if (!storageItemObject) {
            return null;
        }

        const valueObject = storageItemObject?.[tableKey]?.[paramName];
        return valueObject !== undefined ? valueObject.value : null;
    }

    setStorageItem(paramName, type, value) {
        const pathKey = this.getPathKey();

        const tableName = this.props.name || defaultTableKey;
        const oldStorageItem = localStorage.getItem(pathKey);
        let oldStorageItemObject = JSON.parse(oldStorageItem) || {};

        const newStorageItemObject = {
            ...oldStorageItemObject,
            [tableName]: {
                ...(oldStorageItemObject?.[tableName]),
                [paramName]: {
                    type: type,
                    value: value
                }
            }
        };
        localStorage.setItem(pathKey, JSON.stringify(newStorageItemObject));
    }

    filterSearchItems(items, search_query) {
        let matched_items = searcher(items, search_query, this.getSearchKeys());

        // if it's a tree structure let's add all missing parents into search results
        if (this.props.treeStructure) {
            for (let i = 0; i < matched_items.length; i++) {
                const current = matched_items[i];
                // if the item has a parent
                if (current.parent_id) {
                    const parent = this.props.items.find(item => item.id === current.parent_id);
                    // if the parent is not already present in matched_items, insert it to the end
                    if (!matched_items.some(item => item.id === parent.id)) {
                        matched_items.push(parent);
                    }
                }
            }
        }
        return matched_items;
    }

    filterSelectorItems(items) {
        this.props.selectors.forEach(selector => {
            let values = this.getSelectorValue(selector);
            if (values && Array.isArray(values) && values.length > 0) {
                items = items.filter(item => {
                    return values.some(v => v.filter(item));
                });
            }
        });

        return items;
    }

    getSearchFilteredItems(searching, search_query) {
        let items = this.props.items;

        if (searching && this.props.searchAllItems) {
            return this.filterSearchItems(items, search_query);
        }

        items = this.filterSelectorItems(items);
        if (searching) {
            items = this.filterSearchItems(items, search_query);
        }

        return items;
    }

    render() {
        const {props: P, state: S} = this;
        let Component;

        //Search
        const search_query = S.search_query || '';
        const searching = search_query !== '';
        let items = this.getSearchFilteredItems(searching, search_query);

        //columns
        const visibleColumns = P.thead.filter(i => !S.hidden_columns[i.name]);
        let thead_items = visibleColumns;

        //tree structure
        if (P.treeStructure) {
            items = items.map(i => {
                return {
                    ...i,
                    children: items.filter(item => item.parent_id === i.id),
                };
            });
            items = items.filter(i => i.parent_id === null);

            thead_items = [
                // this is just an empty column name for the first column in table
                // where expand/collapse button is displayed in the tree structure
                {name: '*', sk_name: ' ', type: 'image'},
                ...thead_items
            ];
        }

        //sorted
        if (S.sorted_active === true) {
            let sort_by_key = S.sort_by_key;
            let asc = (S.asc === true);
            if (P.customSortingFn) {
                items = items.sort((a, b) => P.customSortingFn(a, b, sort_by_key, asc));
            } else {
                items = items.sort((a, b) => compareRows(a, b, sort_by_key, asc));
            }
        }

        //all items for export
        const allItems = items;

        //pagination
        let current_page = parseInt(S.current_page, 10) || 0;
        let page_size = parseInt(S.page_size, 10) || 10;
        let pages_count = Math.ceil(items.length / page_size);

        if (pages_count > 0 && current_page > pages_count - 1) {
            current_page = 0;
            this.updateParams({current_page}, false);
        }
        let index = current_page * page_size;

        //show pages
        const show_pages = P.showPages && items.length > 10;
        if (show_pages) {
            items = items.slice(index, index + page_size);
        }

        if (items.length === 0) {
            Component = <NoResults message={this.props.noData} searchText={search_query}/>;
        } else {
            let page_control = (
                <PageControl
                    currentPage={current_page}
                    selected_value={page_size}
                    pagesCount={pages_count}
                    prevPage={this.previousPage.bind(this)}
                    nextPage={this.nextPage.bind(this)}
                    onPageSizeChange={value => this.handlePageSizeChange(value)}
                />
            );

            Component = (
                <div>
                    {show_pages && page_control}
                    <Table responsive className={classNames(P.className)} striped={P.striped}>
                        <Thead
                            thead_items={thead_items}
                            sortActive={S.sorted_active}
                            onSortClick={key => this.handleSortClick(key)}
                            asc={S.asc === true}
                            sortBy={S.sort_by_key}
                            showRowNumber={P.showRowNumbers}
                            editOps={P.editOps}
                            delOps={P.delOps}
                            showOps={P.showOps}
                        />
                        <tbody>
                        {
                            items.map((item, index) => {
                                return (
                                    <TableRow
                                        key={item.id}
                                        onClick={P.onRowClick ? (id => P.onRowClick(id)) : null}
                                        onShow={id => P.onShowItem(id)}
                                        onEdit={id => P.onEditItem(id)}
                                        onDelete={id => this.handleDelete(id)}
                                        values={thead_items}
                                        obj={item}
                                        showOps={P.showOps}
                                        editOps={P.editOps}
                                        delOps={P.delOps}
                                        paddingLevel={0}
                                        treeStructure={P.treeStructure}
                                        searching={searching}
                                        highlighting={P.highlighting}
                                        highlightColor={P.highlightColor || '#cdffc7'}
                                        rowStyleFn={P.rowStyleFn}
                                        showRowNumbers={P.showRowNumbers}
                                        rowNumber={index + (current_page * page_size) + 1}
                                    />
                                );
                            })
                        }
                        </tbody>
                    </Table>
                    {show_pages && page_control}
                </div>
            );
        }

        if (P.is_loading) {
            Component = <Spinner />;
        }

        return (
            <div className="result-table">
                <Form style={{marginBottom: 20}}>
                    <div className="row">
                        {
                            P.selectors && P.selectors.map((sel, i) => {
                                return (
                                    <div className={'col-sm-6 col-md-4 p-2'} key={i}>
                                        <Selector
                                            onChange={(name, value) => this.onSelectorValueChanged(name, value)}
                                            value={this.getSelectorValue(sel)}
                                            name={sel.name}
                                            values={sel.values}
                                            placeholder={sel.placeholder}
                                            isMulti={sel.isMulti || false}
                                        />
                                    </div>
                                );
                            })
                        }
                        {
                            P.searchable &&
                            <div className={'col-sm-6 col-md-4 p-2'}>
                                <Search
                                    value={search_query}
                                    onChange={val => this.handleSearchChange(val)}
                                    onClear={() => this.handleSearchChange('')}
                                    show
                                />
                            </div>
                        }
                        {
                            P.columnSelector && allItems.length > 0 &&
                            <div className={'col-sm-4 col-md-3 p-2'}>
                                <Button
                                    onClick={() => this.setState({chooseColumnsSelectorActive: true})}
                                >
                                    Vybrať stĺpce
                                </Button>
                            </div>
                        }
                        {
                            P.export && allItems.length > 0 &&
                            <div className={'col-sm-4 col-md-1 p-2'}>
                                <Button
                                    onClick={(event) =>
                                        handleExport(event, visibleColumns, allItems, P.export)}
                                    variant={'success'}>
                                    Exportovať
                                </Button>
                            </div>
                        }
                    </div>
                </Form>
                {searching ? <div
                    style={{
                        marginTop: 30,
                        marginBottom: 15,
                        fontWeight: 'bold'
                    }}
                >
                    Počet nájdených výsledkov hľadania: {allItems.length}
                </div> : null
                }
                {Component}
                <ConfirmModal
                    message={S.confirm_message}
                    show={S.confirm_show}
                    onClose={() => this.setState({confirm_show: false})}
                    onAccept={this.state.confirm_action}
                    acceptTitle="Vymazať"
                />
                <ChooseColumnsSelector
                    show={S.chooseColumnsSelectorActive}
                    columns={P.thead}
                    selectedColumns={visibleColumns}
                    onClose={() => this.setState({chooseColumnsSelectorActive: false})}
                    onSubmit={(value) => this.onColumnSelectorSubmit(value)}
                />
            </div>
        );
    }
}

MyTable.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    thead: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectors: PropTypes.arrayOf(PropTypes.object).isRequired,
    onShowItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onRowClick: PropTypes.func,
    defaultSortKey: PropTypes.string,
    defaultAsc: PropTypes.bool,
    searchable: PropTypes.bool,
    searchAllItems: PropTypes.bool,
    showPages: PropTypes.bool,
    striped: PropTypes.bool,
    rowStyleFn: PropTypes.func,
    defaultSearch: PropTypes.string,
    defaultPageSize: PropTypes.number,
    showOps: PropTypes.bool,
    editOps: PropTypes.bool,
    delOps: PropTypes.bool,
    treeStructure: PropTypes.bool,
    is_loading: PropTypes.bool,
    highlighting: PropTypes.bool,
    highlightColor: PropTypes.string,
    showRowNumbers: PropTypes.bool,
    noData: PropTypes.string,
    columnSelector: PropTypes.bool,
    columnSortingFn: PropTypes.func,
    export: PropTypes.string
};

MyTable.defaultProps = {
    items: [],
    selectors: [],
    showOps: false,
    editOps: false,
    delOps: false,
    onShowItem: () => {},
    onEditItem: () => {},
    onDeleteItem: () => {},
    onRowClick: null,
    searchable: false,
    searchAllItems: false,
    rowStyleFn: null,
    defaultSearch: '',
    defaultAsc: true,
    defaultPageSize: 10,
    showPages: true,
    striped: false,
    treeStructure: false,
    is_loading: false,
    highlighting: false,
    highlightColor: '#cdffc7',
    showRowNumbers: false,
    noData: 'Žiadne výsledky',
    columnSelector: false,
    customSortingFn: undefined,
    export: null,
};

const SuperTable = withRouter(MyTable);

export {
    DefaultSelector,
    SuperTable
};
