import React from 'react';
import PT from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';
import {SuperTable} from 'components/PaginationTable';
import ordersActions from 'actions/orders';
import order_material_documents from 'actions/order_material_documents';
import order_document_material_items from 'actions/order_document_material_items';
import store from 'store/mainStore';
import {withRouter} from 'react-router-dom';
import DF from 'scripts/dateFormatter';
import './style.css';
import {connect} from 'react-redux';
import Spinner from 'components/Spinner';
import classNames from 'classnames';

class OrderDocumentMaterialItems extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        store.dispatch(ordersActions.fetchConcrete(parseInt(this.props.match.params.id)));
        store.dispatch(order_material_documents.fetchAll({orderId: this.props.match.params.id}));
        store.dispatch(order_document_material_items.fetchAll({
            orderId: this.props.match.params.id,
            documentId: this.props.match.params.documentId,
            schema: this.props.match.params.schema,
            documentType: this.props.match.params.documentType,
        }));
    }

    render() {
        const {props: P} = this;

        if (P.isLoading) {
            return <Spinner/>;
        }

        return (
            <div>
                <div className={'info'}>
                    <Row style={{
                        marginTop: 15,
                    }}>
                        <Col xs={6} className="text-right">Doklad</Col>
                        <Col xs={6} className="text-left">{P.document.document_code}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Typ dokladu</Col>
                        <Col xs={6} className="text-left">{P.document.type_name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Dátum dokladu</Col>
                        <Col xs={6} className="text-left">{DF(P.document.date, true)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Popis dokladu</Col>
                        <Col xs={6} className="text-left">{P.document.name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Zákazka</Col>
                        <Col xs={6} className="text-left">{P.order.name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Číslo zákazky</Col>
                        <Col xs={6} className="text-left">{P.order.serial_number}</Col>
                    </Row>
                    {
                        P.document.store ?
                            <Row>
                                <Col xs={6} className="text-right">Sklad</Col>
                                <Col xs={6} className="text-left">{P.document.store}</Col>
                            </Row> : null
                    }
                    {
                        P.document.firm_name ?
                            <Row>
                                <Col xs={6} className="text-right">Firma</Col>
                                <Col xs={6} className="text-left">{P.document.firm_name}</Col>
                            </Row> : null
                    }
                    <h5 className={classNames({'text-center': true, 'mt-5': true})}>
                        Zoznam položiek pre doklad: {P.document.document_code}
                    </h5>
                    <Container>
                        {<SuperTable
                            name={'document-material-items'}
                            thead={[
                                {name: 'item_name', sk_name: 'Položka', type: 'string'},
                                {name: 'quantity', sk_name: 'Množstvo', type: 'string'},
                                {name: 'unit', sk_name: 'Jednotka', type: 'string'},
                                {name: 'bus_order_code', sk_name: 'Číslo zákazky', type: 'string'},
                                {name: 'bus_order_name', sk_name: 'Názov zákazky', type: 'string'},
                            ]}
                            items={P.material}
                            searchable
                            striped
                            is_loading={P.isLoading}
                            rowStyleFn={
                                (column) =>
                                    ['item_name', 'bus_order_name'].includes(column)
                                        ? {whiteSpace: 'normal'}
                                        : {}
                            }
                        />}
                    </Container>
                </div>
            </div>
        );
    }
}

OrderDocumentMaterialItems.propTypes = {
    order: PT.object,
    document: PT.object,
    material: PT.arrayOf(PT.object).isRequired,
    isLoading: PT.bool,
};

const mapStateToProps = (state, ownProps) => {
    const orderId = parseInt(ownProps.match.params.id);
    const documentId = ownProps.match.params.documentId;

    return {
        order: state.orders.items.find(item => item.id === orderId),
        document: state.order_material_documents.items.find(x => x.document_id === documentId),
        material: state.order_document_material_items.items,
        isLoading:
            !state.orders.items.length ||
            !state.order_material_documents.items.length ||
            !state.order_document_material_items.items.length
    };
};

export default withRouter(connect(mapStateToProps)(OrderDocumentMaterialItems));
