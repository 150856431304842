import React from 'react';
import store from 'store';
import PT from 'prop-types';
import {connect} from 'react-redux';
import ordersActions from 'actions/orders';
import order_material_item_documents from 'actions/order_material_item_documents';
import order_material_quantity from 'actions/order_material_quantity';
import {withRouter} from 'react-router-dom';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {SuperTable} from 'components/PaginationTable';
import Spinner from 'components/Spinner';
import classNames from 'classnames';
import ImageViewer from 'components/ImageViewer';
import getSelector from '../../MaterialOverview/typeSelector';

class OrderMaterialItemDocuments extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let order_id = parseInt(this.props.match.params.id);

        store.dispatch(ordersActions.fetchConcrete(order_id));
        store.dispatch(order_material_quantity.fetchAll({orderId: order_id}));

        store.dispatch(order_material_item_documents.fetchAll({
            orderId: order_id,
            code: decodeURIComponent(this.props.match.params.code),
        }));
    }

    render() {
        const {props: P} = this;

        if (P.isLoading) {
            return <Spinner/>;
        }

        let id = null;
        if (P.materialItem.schema == 'abra_db') {
            id = P.materialItem.id;
        }

        return (
            <div>
                <div className={'info'}>
                    <Row style={{marginTop: 15}}>
                        <Col xs={6} className="text-right">Názov</Col>
                        <Col xs={6} className="text-left">{P.materialItem.name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Kód skl. karty</Col>
                        <Col xs={6} className="text-left">{P.materialItem.code}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Zákazka</Col>
                        <Col xs={6} className="text-left">{P.order.serial_number} - {P.order.name}</Col>
                    </Row>
                    {
                        P.materialItem.received_order > 0 &&
                        <Row>
                            <Col xs={6} className="text-right">Prijatá objednávka</Col>
                            <Col xs={6}
                                 className="text-left">{P.materialItem.received_order} {P.materialItem.unit}</Col>
                        </Row>
                    }
                    {
                        P.materialItem.issued_order > 0 &&
                        <Row>
                            <Col xs={6} className="text-right">Objednané</Col>
                            <Col xs={6} className="text-left">{P.materialItem.issued_order} {P.materialItem.unit}</Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={6} className="text-right">Na sklade</Col>
                        <Col xs={6} className="text-left">{P.materialItem.stored} {P.materialItem.unit}</Col>
                    </Row>
                    {
                        P.materialItem.stock_receipt > 0 &&
                        <Row>
                            <Col xs={6} className="text-right">Prijaté na sklad</Col>
                            <Col xs={6} className="text-left">{P.materialItem.stock_receipt} {P.materialItem.unit}</Col>
                        </Row>
                    }
                    {
                        P.materialItem.delivery_note > 0 &&
                        <Row>
                            <Col xs={6} className="text-right">Vyskladnené</Col>
                            <Col xs={6} className="text-left">{P.materialItem.delivery_note} {P.materialItem.unit}</Col>
                        </Row>
                    }
                    {
                        P.materialItem.delivery_returned > 0 &&
                        <Row>
                            <Col xs={6} className="text-right">Vrátené</Col>
                            <Col xs={6} className="text-left">{P.materialItem.delivery_returned} {P.materialItem.unit}</Col>
                        </Row>
                    }
                    {
                        P.materialItem.picture_id ?
                        <Row>
                            <Col xs={12} className="text-center" style={{marginBottom: 25}}>
                                <ImageViewer
                                    thumbnail={P.materialItem.image_url}
                                    full_size={P.materialItem.image_url}
                                />
                            </Col>
                        </Row> : null
                    }
                    {
                        (P.permissions.material > 0 && id)
                            ? <div className={'mt-2'} style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button
                                    variant="success"
                                    onClick={() => this.props.history.push(`/storecards/${id}`)}
                                >
                                    Zobraziť celú históriu skl. karty
                                </Button>
                        </div> : null
                    }
                    <h5 className={classNames({'text-center': true, 'mt-4': true})}>
                        História pohybov skladovej karty na zákazke {P.order.serial_number}
                    </h5>
                    <Container>
                        {<SuperTable
                            name={'material-item-documents'}
                            thead={[
                                {name: 'date', sk_name: 'Dátum', type: 'short_date'},
                                {name: 'time', sk_name: 'Čas', type: 'time'},
                                {name: 'doc_code', sk_name: 'Číslo dokladu', type: 'string'},
                                {name: 'type_name', sk_name: 'Typ', type: 'string'},
                                {name: 'name', sk_name: 'Popis', type: 'string'},
                                {name: 'store', sk_name: 'Sklad', type: 'string'},
                                {name: 'sum_quantity', sk_name: 'Množstvo', type: 'string'},
                                {name: 'unit', sk_name: 'Jednotka', type: 'string'},
                                {name: 'firm_name', sk_name: 'Firma', type: 'string', hidden: true},
                            ]}
                            items={P.documents}
                            columnSelector={true}
                            selectors={[getSelector()]}
                            searchable
                            striped
                            is_loading={P.isLoading}
                            rowStyleFn={(column) => column === 'store' ? {whiteSpace: 'normal'} : {}}
                        />}
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const orderId = parseInt(ownProps.match.params.id);
    const code = decodeURIComponent(ownProps.match.params.code);
    return {
        permissions: state.permissions,
        order: state.orders.items.find(item => item.id === orderId),
        materialItem: state.order_material_quantity.items.find(item => item.code === code),
        documents: state.order_material_item_documents.items,
        isLoading:
            !state.orders.items.length ||
            !state.order_material_item_documents.items.length ||
            !state.order_material_quantity.items.length
    };
};

OrderMaterialItemDocuments.propTypes = {
    materialItem: PT.object,
    order: PT.object,
    documents: PT.arrayOf(PT.object).isRequired,
    isLoading: PT.bool,
};

export default withRouter(connect(mapStateToProps)(OrderMaterialItemDocuments));
