import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button } from 'react-bootstrap';
import Formsy from 'formsy-react';
import { MdClose } from 'react-icons/md';
import SectionForm, { getStoredParams } from '../../WorkOperations/SectionForm';

class Form extends React.Component {

    constructor(props) {
        super(props);

        this.employeeRef = React.createRef();
        this.state = {
            validationErrors: {},
            formSubmitted: false,
            changed: false,
            params: {},
        };
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)) {
            return;
        }

        let name = '';
        Object.keys(model).forEach(k => {
            if (name !== '') {
                name += ' ';
            }
            name += model[k].name;
        });

        let params = this.getCurrentParams();
        this.props.onSubmit({
            ...params,
            name
        });

        this.setState({
            validationErrors: {},
            formSubmitted: false,
        });
    }

    validateForm(model) {
        if ('empty_select' in model) {
            this.setState({
                validationErrors: {
                    empty_select: 'Vyberte možnosť'
                }
            });
            return true;
        }

        return false;
    }

    getCurrentParams() {
        let defaultParams = getStoredParams();
        return this.state.changed ? this.state.params : this.props.params || defaultParams;
    }

    render() {
        const { props:P, state:S } = this;

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Modal.Header>
                    <div className={'closeBox'}
                         style={{
                             justifyContent: 'space-between'
                         }}
                    >
                        Priradiť skupinu na pracovnú pozíciu
                        <MdClose
                            className = {'closeButton'}
                            size={24}
                            onClick={P.onClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onSubmit={(model) => this.submitHandler(model)}
                        validationErrors={S.validationErrors}
                    >
                        <SectionForm
                            onChange={ newParams => this.setState({
                                changed: true,
                                params: newParams
                            })}
                            locked={false}
                            params={this.getCurrentParams()}
                            leaf_required={true}
                        />
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Potvrdiť
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    params: PropTypes.object
};

Form.defaultProps = {
    show: false,
};

export default Form;
