import React from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';

class NoPermissions extends React.Component {
    render() {
         return (
            <div className="no-access-page">
                <h2>Na toto zobrazenie nemáte oprávnenie</h2>

            </div>
        );
    }
}

export default withRouter(NoPermissions);
