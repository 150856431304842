import React from 'react';
import CompanyLogo from 'imgs/logo.png';
import { Link } from 'react-router-dom';
import store from 'store';
import { withRouter } from 'react-router-dom';
import {
    MdPersonOutline,
    MdPowerSettingsNew,
    MdReply,
    MdSettings,
    MdNotifications,
} from 'react-icons/md';
import { Dropdown } from 'react-bootstrap';
import GlobalError from 'views/components/TopPanel/GlobalError';
import './style.css';
import {connect} from 'react-redux';
import {ConfirmModal} from 'components/modals';
import {deleteUnsavedChanges} from 'actions/unsaved_changes';
import {logout} from 'actions/login';

const Notification = (props) => {
    if (!props.count) {
        return null;
    }

    return (
        <span style={{margin: 5}}>
			({props.count})
			<MdNotifications
                color={'orange'}
                size={'1.7em'}
                style={{marginLeft: 5}}
            />
		</span>
    );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={e => onClick(e)}
    >
        {children}
    </div>
));

class TopPanel extends React.Component {

    constructor(props) {
        super(props);

        this.logoRef = React.createRef();
        this.state = {
            confirm_show: false,
            redirectAction: () => {},
        };
    }

    redirectToPath(path) {
        if (this.props.location.pathname !== path) {
            this.props.history.push(path);
        }
    }

    redirect(action = () => {}) {
        if (this.props.unsavedChanges.status) {
            this.setState({
                confirm_show: true,
                redirectAction: action
            });
        } else {
            action();
        }
    }

    render() {
        const { pathname } = this.props.location;
        const isMenuActive = (pathname === '/' || pathname === '/login');
        const isLogged = this.props.login.logged;

        return (
            <div className="topPanel">
                {!isMenuActive ?
                    <div
                        onClick={() => this.redirect(() => this.props.history.goBack())}
                        className="back-panel"
                    >
                        <span><MdReply /> Späť</span>
                    </div> : <div/>
                }
                <div className="company-logo">
                    <Link to='/' onClick={(e) => {
                        e.preventDefault();
                        if (isLogged) {
                            this.redirect(() => this.redirectToPath('/'));
                        }
                    }}>
                        <img src={CompanyLogo} alt={''}/>
                    </Link>
                </div>
                {
                    (isLogged) ?
                        <Dropdown>
                            <Dropdown.Toggle
                                as={CustomToggle}
                                id={'dropdown-basic'}
                                innerProps={{count: this.props.unreadCount}}
                            >
                                <div className={'back-panel'}>
                                    <Notification count={this.props.unreadCount}/>
                                    <MdSettings size={'2.0em'}/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() => {
                                        this.redirect(() =>
                                            this.redirectToPath(`/employees/detail/${this.props.identity.id}`));
                                    }}
                                >
                                    <MdPersonOutline/> Profil
                                </Dropdown.Item>
                                <Dropdown.Item
                                    eventKey="2"
                                    onClick={() => this.redirect(() => this.redirectToPath('/notifications'))}
                                >
                                    <MdNotifications/> Notifikácie ({this.props.unreadCount})
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item
                                    onClick={() => this.redirect(() => store.dispatch(logout()))}
                                    eventKey="3"
                                >
                                    <MdPowerSettingsNew/> Odhlásiť sa</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> : <div/>
                }
                <GlobalError/>
                <ConfirmModal
                    title={'Máte neuložené zmeny'}
                    declineTitle={'Nie'}
                    message={'Pokračovaním sa vymažú neuložené zmeny. Chcete pokračovať ?'}
                    show={this.state.confirm_show}
                    onClose={() => this.setState({ confirm_show: false })}
                    onAccept={() => {
                        store.dispatch(deleteUnsavedChanges());
                        this.state.redirectAction();
                    }}
                    acceptTitle="Áno"
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let unreadCount = state.notifications.items.reduce( (acc, val) => {
        if (val.read_at) {
            return acc;
        }
        return acc + 1;
    }, 0);

    return {
        login: state.login,
        identity: state.identity,
        notifications: state.notifications.items,
        unreadCount: unreadCount,
        unsavedChanges: state.unsavedChanges,
    };
};

export default withRouter(connect(mapStateToProps)(TopPanel));