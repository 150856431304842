import {Tabs} from 'react-bootstrap';
import React from 'react';
import {withRouter} from 'react-router-dom';

export default withRouter(function UrlTabs(props) {
    return <Tabs className={'mt-3'}
        defaultActiveKey={() => props.location.hash.split('#tab=')[1]}
        onSelect={(eventKey) => props.history.replace('#tab=' + eventKey)}>
        {props.children}
    </Tabs>;
});