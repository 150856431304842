import React from 'react';
import { Container, Button } from 'react-bootstrap';
import store from 'store/mainStore';
import { connect } from 'react-redux';
import rolesActions from 'actions/roles';
import NeedWritePerm from 'components/NeedPermission';
import RoleForm from './Form';
import { permissions } from 'config/permissions';
import { addDependency } from 'actions/dependency';
import { SuperTable } from 'components/PaginationTable';
import './styles.css';

class Roles extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			formActive: false,
			objToUpdate: undefined,
		};
	}

	showForm() {
		this.setState({
			formActive: true
		});
	}
	
	hideHandler() {
		this.setState({
			formActive: false
		});
	}

	handleEditRole(id) {
		this.setState({
			formActive: true,
			objToUpdate: this.props.roles.find(r => r.id === id)
		});
	}

	formSubmit(obj) {
		const S = this.state;
		
		let action = {};
			if (S.objToUpdate) {
				action = rolesActions.update(S.objToUpdate.id, obj);
			} else {
				action = rolesActions.create(obj);
			}

		store.dispatch(action).then(data => {
			if (data.status === 'ok'){
				this.setState({
					formActive: false,
					objToUpdate: undefined
				});
			}
		});
	}

	componentDidMount() {
		store.dispatch(rolesActions.fetchAll());
	}

	render() {
		const { props: P, state: S} = this;

		return (
			<Container className="roles">
				<NeedWritePerm type="button" need={['roles']}>
					<Button 
						className="showFormBtn" 
						onClick={() => this.setState({
                            formActive: true,
                            objToUpdate: undefined
						}) }
						variant={'success'}
					>
						Pridať Rolu
					</Button>
				</NeedWritePerm>

				<SuperTable
					name={'roles'}
					thead={[
						{name: 'name', sk_name: 'Názov', type: 'string'}
					]} 
					items={P.roles}
					onEditItem={id => this.handleEditRole(id)}
					onDeleteItem={id => store.dispatch(rolesActions.delete(id)) }
					delOps editOps
					searchable={true}
					is_loading = {P.isFetching}
				/>

				<RoleForm
                    key={ S.objToUpdate ? S.objToUpdate.id : 'new_role' }
					updating={!!S.objToUpdate}
					permissions={permissions}
					roles={P.roles}
					show={S.formActive}
					defaultValues={S.objToUpdate}
					onSubmit={obj => this.formSubmit(obj)} 
					onClose={() => this.setState({
                        formActive: false,
                        objToUpdate: undefined
					})}
				/>
			</Container>
		);
	}
}

addDependency('roles', ['webapp_access']);

const mapStateToProps = (state) => {
	return {
		roles: state.roles.items,
		isFetching: state.roles.isFetching,
		err: state.roles.err
	};
};

export default connect(mapStateToProps)(Roles);