import store from 'store/mainStore';
import {setGlobalError} from 'actions/globalError';
import { getCookie } from 'scripts/cookie';
import { setLogout } from 'actions/login';

/** 
 * Convert keys in obj to lowercase, this is immutable. Function returns new object
 * Input object should not contain arguments like "Content-type" and "content-Type"
 * @param {object} obj  
 * @return {object} new_obj  
 */
function objKeysToLowerCase(obj) {
	if (typeof obj !== 'object')
		return {};

	let new_obj = {};

	Object.keys(obj).forEach( key => {
		new_obj[key.toLowerCase()] = obj[key];
	});

	return new_obj;
}

function getErrorType(options) {
	if (options.body
		&& options.body instanceof FormData
		&& options.body.get('_method') === 'PUT')
	{
		return 'update';
	}

	const types = {
		GET: 'get',
		POST: 'create',
		PUT: 'update',
		DELETE: 'delete'
	};

	let type = types[options.method];
	if (type !== undefined) {
		return type;
	}

	return 'info';
}

function downloadData(response, data, contentType) {
	//https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
	const file = new Blob([data], {type: contentType});
	const url = window.URL.createObjectURL(file);
	const filename = response
		.headers
		.get('content-disposition')
		.split('filename=')[1];
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
}

const call = (url, options = {}) => {
	if (options.headers instanceof Headers) {
		throw Error('You cannot pass Headers to call, instead pass Object');
	}

	// tokens and headers
	let header = new Headers(objKeysToLowerCase(options.headers));
	header.append('X-XSRF-TOKEN', getCookie('XSRF-TOKEN'));

	// Assign header with token
	options = Object.assign({}, options, {
		headers: header
	});

	const type = getErrorType(options);

	return fetch(url, options)
		.then(response => {
			const contentType = response.headers.get('content-type');
			let result = contentType === 'application/json'
				? response.clone().json()
				: response.clone().blob();

			result.then(data => {
				if (data.status !== 'ok') {
					if (data.validation_errors) {
						store.dispatch(setGlobalError(
							Object.keys(data.validation_errors).map( k => data.validation_errors[k][0])
							, type, true));
					} else if (data.message) {
						if (url !== '/api/login' && data.code !== 411) {
							store.dispatch(setGlobalError([data.message], type, true));
						}
					} else if (contentType !== 'application/json') {
						downloadData(response, data, contentType);
					} else {
						throw Error(`
							Status in request is error, but no message provided, 
							please provide "validation_errors" or "message" as key in body response`
						);
					}
				}

				if (data.code && data.code === 411) {
					store.dispatch(setLogout());
				}
			}).catch((err) => {
				console.error(err);
			});
			return response;
		});
};

export default call;
