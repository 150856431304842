export function unsetGlobalError() {
	return {
		type: 'UNSET_GLOBAL_ERROR'
	};
}

// error_type must be one of ['create', 'update', 'delete', 'get', 'info']
// for more info about displaying errors see
// components/ErrorModal/index.jsx
// components/TopPanel/GlobalError
export function setGlobalError(messages, error_type = 'info', is_error = true) {
	return {
		type: 'SET_GLOBAL_ERROR',
		payload: messages,
		error_type: error_type,
		is_error: is_error,
	};
}

