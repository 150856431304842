export const SET_IDENTITY = 'SET_IDENTITY';
export const UNSET_IDENTITY = 'UNSET_IDENTITY';


export function setIdentity(obj) {
    if (typeof obj !== 'object') {
        throw Error('argument must be object');
    }

    return {
        type: SET_IDENTITY,
        obj
    };
}

export function unsetIdentity() {
    return {
        type: UNSET_IDENTITY
    };
}