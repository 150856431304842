import React from 'react';
import PT from 'prop-types';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import {
    selectorStyles,
    timeParser,
} from './recordUtils';
import { MdDelete } from 'react-icons/md';
import { FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';
import { parseSeconds } from 'scripts/dateFormatter';
import Tern from 'components/Or';
import Select, { createFilter, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Dots } from 'react-activity';
import 'react-activity/dist/Dots.css';
import workingHoursActions from 'actions/working_hours';
import store from 'store/mainStore';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { setGlobalError } from 'actions/globalError';

class CustomCheckBox extends React.Component {
    render() {
        const {props: P} = this;

        if (P.isLoading) {
            return <Dots />;
        }

        return <div
            onClick={() => {
                if (!P.disabled && !P.isLoading){
                    P.onChange();
                }
            }}
        >
            {P.checked ? <FaRegCheckSquare size={'1.5em'}/> : <FaRegSquare size={'1.5em'}/>}
        </div>;
    }
}

class Record extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            start: null,
            end: null,
            isDeleting: false,
            startFocused: false,
            endFocused: false,
        };
    }

    updateRecord(key, value) {
        this.props.updateWH(this.props.id, this.props.dayInMonth, key, value);
    }

    addRecord() {
        const { dayRecords } = this.props;

        let lastWorkTime = dayRecords.reduce((maxTime, record) => {
            if (record.end) {
                const recordEnd = new Date(record.end);
                return recordEnd > maxTime ? recordEnd : maxTime;
            }
            if (record.start){
                const recordStart = new Date(record.start);
                return recordStart > maxTime ? recordStart : maxTime;
            }
            return maxTime;
        }, new Date(0));

        this.props.createWH(lastWorkTime);
    }

    updateTime(type) {
        let timeInput = this.state[type];
        const { year, humanMonth, dayInMonth } = this.props.listInfo;
        let parsedTime = timeParser(timeInput);
        let originalValue = this.props[`${type}_time`];

        if (!parsedTime){
            this.setState({
                [type]: originalValue
            });
            return;
        }

        if (parsedTime === originalValue){
            this.setState({
                [type]: parsedTime
            });
            return;
        }

        this.updateRecord(type, `${year}-${humanMonth}-${dayInMonth} ${parsedTime}:00`);
        this.setState({
            [type]: parsedTime,
            startFocused: false,
            endFocused: false,
        });
    }

    getTimeValue(type) {
        const {state: S, props: P} = this;
        const key = `${type}Focused`;

        if (S[key]){
            return S[type];
        }

        return P[`${type}_time`];
    }

    moveAtTheBeginning(id, data) {
        if (id) {
            let obj = data.find(o => o.id === id);
            if (obj) {
                return [
                    obj,
                    ...data.filter(o => o.id !== id)
                ];
            }
        }

        return data;
    }

    compareFn(a, b) {
        let first = parseInt(a.serial_number);
        let second = parseInt(b.serial_number);

        if (!isNaN(first) && !isNaN(second)) {
            return first - second;
        }
        if (isNaN(first) && isNaN(second)) {
            return a.serial_number.toLowerCase().localeCompare(b.serial_number.toLowerCase());
        }
        if (isNaN(first)) {
            return -1;
        }
        if (isNaN(second)) {
            return 1;
        }
    }

    getOrdersOptions(currentValue) {
        let orders = this.props.mappings.orders
            .filter(o => o.active)
            .sort(this.compareFn);

        if (!currentValue || this.props.lastOrderId !== currentValue.id) {
            return this.moveAtTheBeginning(this.props.lastOrderId, orders);
        }

        return orders;
    }

    getCarsOptions(){
        return this.moveAtTheBeginning(
            this.props.lastCarId,
            this.props.mappings.cars.filter(c => c.active)
        );
    }

    onOrderChange(newValue) {
        this.updateRecord('order_id', newValue ? newValue.id : null);
        this.setState({
                lastOrder: newValue
            });
    }

    onOrderCreate(val) {
        let order = this.props.mappings.orders.find( o => {
            return o.serial_number === val;
        });

        if (order && !order.active) {
            //this.updateRecord('order_id', order.id);
            store.dispatch(setGlobalError(
                ['Zvolená zákazka už bola uzavretá', [order.serial_number + ' - ' + order.name]],
                'update',
                true)
            );
        }
    }

    onCarChange(newValue) {
        this.updateRecord('car_id', newValue ? newValue.id : null);
    }

    onTypeChange(newValue) {
        if (newValue) {
            this.updateRecord('type_id', newValue.id);
        }
    }

    onDelete(){
        this.setState({
            isDeleting: true
        });
        store.dispatch(workingHoursActions.delete(this.props.id));
    }

    render() {
        const { props:P } = this;

        const classForDay = classnames('work-in-day', {
            'weekend': P.isWeekend,
            'row-holiday': P.isWeekend ? false : P.isHoliday,
        });

        let timeCellsError = P.isInCollision;
        if (P.start !== null && P.end !== null) {
            let start_date = new Date(P.start);
            let end_date = new Date(P.end);

            timeCellsError = timeCellsError || start_date > end_date;
        }

        const filterOptions = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start',
        };

        const selectorComponents = {
            DropdownIndicator: null,
            IndicatorSeparator: null,
            ClearIndicator: null,
        };

        let orderSelectorStyles = selectorStyles;
        if (!P.order && P.workingHourType.required_order){
            orderSelectorStyles = {
                ...orderSelectorStyles,
                placeholder: styles => ({
                    ...styles,
                    textAlign: 'center',
                    width: '100%',
                    left: 0,
                    backgroundColor: 'rgba(255, 0, 10, 0.2)',
                })
            };
        }

        const SingleValue = (props) => (
            <components.SingleValue {...props}>
                {props.getValue()[0].serial_number}
            </components.SingleValue>
        );

        return (
            <tr>
                { P.firstInDay ? (
                    <td rowSpan={P.rowspan}
                        className={classForDay}
                    >
                        {P.dayLabel}
                    </td>
                ) : null
                }
                <td>
                    <Tern expression={P.updating && P.updateAttribute === 'order_id'}>
                        <Dots/>
                        <CreatableSelect
                            styles={{
                                ...orderSelectorStyles,
                                menu : (provided) => ({
                                    ...provided,
                                    width: '300%'
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    borderBottom: '1px solid grey',
                                    textAlign: 'left',
                                }),
                            }}
                            components={{
                                ...selectorComponents,
                                SingleValue
                            }}
                            options={this.getOrdersOptions(P.order)}
                            isClearable
                            placeholder={'---'}
                            filterOption={createFilter(filterOptions)}
                            onChange={ val => this.onOrderChange(val)}
                            onCreateOption={ val => this.onOrderCreate(val)}
                            value={P.order}
                            isMulti={false}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => `${option.serial_number} - ${option.name}`}
                            getNewOptionData={(inputValue, optionLabel) => ({
                                id: optionLabel,
                                serial_number: inputValue,
                                name: '',
                                __isNew__: true
                            })}
                            isDisabled={!P.canMakeActions}
                            escapeClearsValue={true}
                        />
                    </Tern>
                </td>
                <td>
                    <Tern expression={P.updating && P.updateAttribute === 'start'}>
                        <Dots/>
                        <input
                            className={classnames({ 'cell-error': P.start === null || timeCellsError })}
                            value={this.getTimeValue('start')}
                            onFocus={() => {
                                this.setState({
                                    startFocused: true,
                                    start: P.start_time
                                });
                            }}
                            onChange={e => this.setState({start: e.target.value})}
                            onKeyPress={e => e.key === 'Enter' && this.updateTime('start')}
                            onBlur={() => {
                                this.updateTime('start');
                            }}
                            disabled={!P.canMakeActions}
                        />
                    </Tern>
                </td>
                <td>
                    <Tern expression={P.updating && P.updateAttribute === 'end'}>
                        <Dots/>
                        <input
                            className={classnames({ 'cell-error': P.end === null || timeCellsError })}
                            onFocus={() => {
                                this.setState({
                                    endFocused: true,
                                    end: P.end_time
                                });
                            }}
                            value={this.getTimeValue('end')}
                            onChange={e => this.setState({end: e.target.value})}
                            onKeyPress={e => e.key === 'Enter' && this.updateTime('end')}
                            onBlur={() => {
                                this.updateTime('end');
                            }}
                            disabled={!P.canMakeActions}
                        />
                    </Tern>
                </td>
                <td
                    style={{minWidth: '100px'}}
                >
                    <Tern expression={P.updating && P.updateAttribute === 'type_id'}>
                        <Dots/>
                        <Select
                            styles={selectorStyles}
                            components={selectorComponents}
                            options={P.mappings.workingHoursTypes}
                            onChange={val => this.onTypeChange(val)}
                            value={P.workingHourType}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            isDisabled={!P.canMakeActions}
                        />
                    </Tern>
                </td>
                <Tern expression={P.firstInDay}>
                    <td
                        rowSpan={P.rowspan}
                    >
                        <Tern expression={P.isSumUpdating}>
                            <Dots />
                            <div>
                                {parseSeconds(P.time_sum)}
                            </div>
                        </Tern>
                    </td>
                </Tern>
                <td
                    className="lunch"
                >
                    <CustomCheckBox
                        isLoading={P.isLunchUpdating}
                        checked={P.lunch}
                        onChange={() => {
                            this.updateRecord('lunch_break', !P.lunch);
                        }}
                        disabled={!P.canMakeActions}
                    />
                </td>
                <td
                    style={{minWidth: '100px'}}
                >
                    <Tern expression={P.updating && P.updateAttribute === 'car_id'}>
                        <Dots/>
                        <Select
                            styles={selectorStyles}
                            components={selectorComponents}
                            options={this.getCarsOptions()}
                            onChange={val => this.onCarChange(val)}
                            value={P.car}
                            isClearable={true}
                            placeholder={'---'}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.spz}
                            isDisabled={!P.canMakeActions}
                            escapeClearsValue={true}
                        />
                    </Tern>
                </td>
                <Tern expression={P.canMakeActions}>
                    <td className="spz">
                        <Tern expression={this.state.isDeleting}>
                            <Dots/>
                            <Button
                                size={'sm'}
                                variant={'outline-danger'}
                                disabled={!P.canMakeActions}
                                onClick={() => this.onDelete()}
                            >
                                <MdDelete style={{padding: 0}}/>
                            </Button>
                        </Tern>
                    </td>
                </Tern>
                <Tern expression={P.canMakeActions && P.firstInDay}>
                    <td rowSpan={P.rowspan}
                        className="spz"
                    >
                        <Tern expression={P.isCreating}>
                            <Dots/>
                            <Button
                                size={'sm'}
                                onClick={this.addRecord.bind(this)}
                                variant="success"
                                disabled={!P.canMakeActions}
                            >
                                +
                            </Button>
                        </Tern>
                    </td>
                </Tern>
                <Tern expression={P.isAccountant}>
                    <td className="spz">
                        <IoIosHelpCircleOutline
                            style={{padding: 0}}
                            size={'1.5em'}
                            data-tip
                            data-for={`${P.id}_tool_tip`}
                            //data-event='click focus'
                        />
                        <ReactTooltip
                            id={`${P.id}_tool_tip`}
                            type='dark'
                            place="right"
                            effect="solid"
                            //globalEventOff='click'
                            clickable={true}
                            delayHide={500}
                            //delayShow={500}
                            delayUpdate={500}
                        >
                            {P.workInfo}
                        </ReactTooltip>
                    </td>
                </Tern>
            </tr>
        );
    }
}

Record.propTypes = {
    id: PT.number.isRequired,
    dayInMonth: PT.number.isRequired,
    rowspan: PT.number.isRequired,
    firstInDay: PT.bool.isRequired,
    dayLabel: PT.string.isRequired,
    start: PT.string,
    end: PT.string,
    start_time: PT.string.isRequired,
    end_time: PT.string.isRequired,
    time_sum: PT.number.isRequired,
    workingHourType: PT.object.isRequired,
    lunch: PT.bool.isRequired,
    order: PT.object,
    car: PT.object,
    canMakeActions: PT.bool.isRequired,
    isAccountant: PT.bool.isRequired,
    mappings: PT.shape({
        orders: PT.array.isRequired,
        cars: PT.array.isRequired,
        workingHoursTypes: PT.array.isRequired
    }).isRequired,
    isEmpty: PT.bool,
    dayRecords: PT.arrayOf(PT.object).isRequired,
    isHoliday: PT.bool.isRequired,
    isWeekend: PT.bool.isRequired,
    listInfo: PT.shape({
        year: PT.number.isRequired,
        humanMonth: PT.number.isRequired,
        dayInMonth: PT.number.isRequired,
        employeeId: PT.number.isRequired,
    }).isRequired,
    isCreating: PT.bool.isRequired,

    // functions
    onConfirm: PT.func,
    createWH: PT.func.isRequired,
    updateWH: PT.func.isRequired,
};

export default Record;