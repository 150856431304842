export default function expect(validateObject) {
    return {
        'toBeinstanceOf': (classname) => {
            if (!classname) return;
            if (!(validateObject instanceof classname)) {
                throw Error('Validating object is not instance of class');
            }
            return this;
        },
        'toBeTypeOf': (str) => {
            if (typeof validateObject !== str) {
                throw Error(`Validating object is not type of "${str}"`);
            }
            return this;
        },
        'toNotUndefined': () => {
            if (typeof validateObject === 'undefined') {
                throw Error('Validating object is undefined');
            }
        }
    };
}
