import React from 'react';
import { connect } from 'react-redux';
import store from 'store/mainStore';
import employeesActions from 'actions/employees';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import './style.css';
import { Link } from 'react-router-dom';

const EmployeeIcon = ({ full_name, icon }) => {
    return (
        <div className="group-item trans">
            <span className="group-icon">
                <img src={icon}
                     height={100}
                     alt="obrázok"
                />
            </span>
            <div className={'title'} >{ full_name }</div>
        </div>
    );
};

class Groups extends React.Component {

    componentDidMount() {
        store.dispatch(employeesActions.fetchAll());
    }

    render() {
        const { props:P } = this;
        const employees = P.employees
            .filter( e => (e.active === true && e.role.name === 'Majster'))
            .map( e => (
                    {
                        ...e,
                        profession: e.profession.name,
                        role: e.role.name
                    }))
            .sort( (a, b) => a.full_name.localeCompare(b.full_name));

        return (
            <div className="groups">
                <Container>
                    <h4
                        style={{
                            textAlign: 'center',
                            fontSize: '24pt',
                            fontWeight: 'bold',
                            marginBottom: 30
                        }}
                    >
                        Majstri
                    </h4>
                    <Row className={'p-2 g-2'}>
                        {
                            employees.map( e =>
                                <Link
                                    className={'col-xs-6 col-sm-4 col-md-3 col-lg-2'}
                                    key={`groups/${e.id}`}
                                    to={`groups/${e.id}`}
                                >
                                    <EmployeeIcon
                                        full_name={e.full_name}
                                        icon={e.thumbnail}
                                    />
                                </Link>
                            )
                        }
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        employees: state.employees.items,
        isFetching: state.employees.isFetching,
        err: state.employees.err,
        permissions: state.permissions,
    };
};

export default withRouter(connect(mapStateToProps)(Groups));
