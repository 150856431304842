export default function getSelector() {
    const types = {
        '20': 'Príjemka',
        '21': 'Dodací list',
        '22': 'Prevodka výdaj',
        '23': 'Vratka dodacieho listu',
        '24': 'Prevodka príjem',
        '25': 'Inventárny prebytok',
        '26': 'Inventárne manko',
        '27': 'Výdaj materiálu do výroby',
        '28': 'Príjem hotového výrobku',
        '29': 'Vratka materiálu z výroby',
        '30': 'Vratka príjemky',
        'issued_order': 'Objednávka vydaná',
        'received_order': 'Objednávka prijatá'
    };

    let selector = {
        name: 'type_selector',
        default_value: 0,
        values: [{
            value: 0,
            name: 'Zobraziť všetko',
            filter: () => true
        }]
    };

    Object.keys(types).forEach( (key, index) => {
        selector.values.push({
            value: index + 1,
            name: types[key],
            filter: (obj) => obj.type == key,
        });
    });

    return selector;
}
