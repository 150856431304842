import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactJson from 'react-json-view';

class Detail extends React.PureComponent {

    render() {
        return (
            <Modal 
                show={this.props.show} 
                onHide={() => this.props.onClose()}
            >
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <ReactJson
                        src={JSON.parse(this.props.body)}
                        iconStyle={'triangle'}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        sortKeys={false}
                        collapsed={false}
                        enableClipboard={false}
                        theme={'rjv-default'}
                        name={null}
                        displayArrayKey={false}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.onClose()}>Zavrieť</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Detail;
