import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import { Button, Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

class FormView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabledButton: true
        };
    }

    enableButton() {
        this.setState({
            disabledButton: false
        });
    }

    disableButton() {
        this.setState({
            disabledButton: true
        });
    }

    validSubmit(model) {
        this.props.onSubmit(model);
    }

    render() {
         return (
            <Modal
                size="lg"
                show
                onHide={() => this.props.onClose()}
            >
                <Modal.Header >
                    <div style={{
                        width: '100%',
                        textAlign: 'right'
                    }}>
                        <MdClose
                            onClick={() => this.props.onClose()}
                            size={24}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        ref="form"
                        onValidSubmit={ model => this.validSubmit(model)}
                        onValid={ () => this.enableButton()}
                        onInvalid={ () => this.disableButton()}
                    >
                        <Input
                            name="name"
                            value={this.props.defaultValues.name}
                            label="Názov"
                            required />
                        <Button 
                            variant="success"
                            disabled={this.state.disabledButton}
                            type="submit" >
                            { this.props.updating ? 'Upraviť' : 'Pridať' }
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

FormView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    updating: PropTypes.bool.isRequired,
    onClose: PropTypes.func
};

FormView.defaultProps = {
    onClose: () => {},
    defaultValues: {
        name: ''
    }
};

export default FormView;