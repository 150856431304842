import React from 'react';
import { connect } from 'react-redux';
import {Button, Col, Container, Row} from 'react-bootstrap';
import store from 'store/mainStore';
import buildingsActions from 'actions/buildings';
import { SuperTable } from 'components/PaginationTable';
import Spinner from 'components/Spinner';
import groupHistoryActions from 'actions/group_history';

import entity_operations from 'actions/entity_operations';
import DV, { parseSeconds } from '../../../scripts/dateFormatter';
import {getUrlParams, getUrlParam} from '../utils';
import EditForm from './EditForm';
import operationTypes from 'actions/work_operations';
import work_performance_actions from 'actions/work_performance';
import NeedWritePerm from '../../../components/NeedPermission';
import Slider from './Multislider';
import { setUnsavedChanges, deleteUnsavedChanges } from 'actions/unsaved_changes';
import SectionForm from '../../WorkOperations/SectionForm';
import Form from 'formsy-react';
import entityTypesActions from 'actions/entity_types';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            updating: false,
            objToUpdate: undefined,
            intervalsChanges: {}
        };
    }

    componentDidMount() {
        let entity_id = getUrlParam(this.props.location, 'entity_id');

        store.dispatch(buildingsActions.fetchAll());
        store.dispatch(entityTypesActions.fetchAll());
        store.dispatch(work_performance_actions.fetchAll({id: entity_id}, true));

        store.dispatch(operationTypes.fetchAll());
        store.dispatch(groupHistoryActions.fetchAll());

        store.dispatch(entity_operations.fetchAll({id: entity_id}));
    }

    formSubmit(model) {
        const {state: S} = this;

        let action = null;
        if (S.updating) {
            action = work_performance_actions.update(S.objToUpdate.id, model);
        } else {
            action = work_performance_actions.create(model);
        }

        store.dispatch(action)
            .then( data => {
                if (data.status === 'ok') {
                    this.setState({
                        formActive: false,
                        objToUpdate: undefined,
                        updating: false,
                    });
                }
            });
    }

    onSliderValueChange(id, change) {
        this.setState({
            intervalsChanges: {
                ...this.state.intervalsChanges,
                [id]: change
            }
        }, () => {
            let {intervalsChanges} = this.state;

            let changes = Object.values(intervalsChanges).some( c => c );
            if (changes) {
                store.dispatch(setUnsavedChanges({}));
            } else {
                store.dispatch(deleteUnsavedChanges());
            }
        });
    }

    render() {
        const { props:P, state:S } = this;

        if (P.is_loading) {
            return <Spinner/>;
        }

        let {building_id, entity_id} = getUrlParams(P.location, [
            'building_id',
            'entity_id'
        ]);

        let entity = P.building_entities.find( s => s.id === entity_id) || {};
        let entityOperations = P.entity_operations.sort((a, b) => a.operation.number - b.operation.number);

        let work_performance = P.work_performance.filter(w => w.entity_id === entity_id);
        let intervals = P.entity_operations.filter(o => !o.operation.overhead)
            .sort( (a, b) => a.number - b.number)
            .map( op => {
            let items = work_performance.filter(w => w.operation_type_id === op.operation_type_id)
                .sort((a, b) => a.started_at.localeCompare(b.started_at));

            return {
                ... op.operation,
                'items': items,
                'sum': items.reduce( (acc, val) => acc + val.percent_done, 0)
            };
        }).filter( op => op.items.length > 0);

        let selectors = [{
            placeholder: 'Operácia',
            name: 'operation_selector',
            values:
                entityOperations.map(item => (
                {
                    value: item.operation_type_id,
                    name: item.operation_name,
                    filter: (obj) => obj.operation_type_id === item.operation_type_id
                }
            ))
        }];

        return (
            <Container>
                <div className="info">
                    <Form>
                        <SectionForm
                            onChange={() => {}}
                            locked={true}
                            params={{building_id, entity_id}}
                            leaf_required={true}
                        />
                    </Form>
                    <Row style={{
                        margin: 15,
                        textAlign: 'center'
                    }}>
                        <h4 style={{fontWeight: 'bold'}}>{entity.name} - Zoznam prác</h4>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Náročnosť</Col>
                        <Col xs={6} className="text-left">{entity.difficulty_level || '0'}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Dĺžka</Col>
                        <Col xs={6} className="text-left">{entity.length  || '0'} m</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Začiatok prác</Col>
                        <Col xs={6} className="text-left">{DV(entity.started_at, false)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Koniec prác</Col>
                        <Col xs={6} className="text-left">{DV(entity.finished_at, false)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Stav prác</Col>
                        <Col xs={6} className="text-left">{`${entity.status_percent} %`}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Odpracované hodiny</Col>
                        <Col xs={6} className="text-left">{parseSeconds(entity.total_time)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Celkový odhadovaný čas</Col>
                        <Col xs={6} className="text-left">{parseSeconds(entity.estimated_time)}</Col>
                    </Row>
                    {
                        entity.picture ?
                            <Row style={{
                                justifyContent: 'center',
                                margin: 15
                            }}>
                                <a href={entity.picture}>
                                    <Button type={'normal'}>
                                        Zobraziť výkres
                                    </Button>
                                </a>
                            </Row>
                            : null
                    }
                </div>
                <div>
                    {intervals.map( o => (
                        <div
                            key={`${o.id}-${o.items.length}-${o.sum}`}
                        >
                            <h4>{o.name}</h4>
                            <Slider 
                                operation={o}
                                onChange={change => this.onSliderValueChange(o.id, change)}
                            />
                        </div>)
                    )}
                </div>
                <NeedWritePerm type="button" need={['operations']}>
                    <Button
                        variant="success"
                        onClick={() => this.setState({
                            formActive: true,
                            updating: false,
                            objToUpdate: undefined
                        })}
                        style={{ marginTop: 15 }}
                    >
                        Pridať záznam
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm type="table" need={['operations']}>
                    <SuperTable
                        name={'work_performance'}
                        thead={[
                            {name: 'group_names', sk_name: 'Skupina', type: 'string', 'hidden': false, 'sort': true},
                            {name: 'operation_name', sk_name: 'Operácia', type: 'string'},
                            {name: 'date', sk_name: 'Dátum', type: 'date'},
                            {name: 'started_at', sk_name: 'Začiatok', type: 'time'},
                            {name: 'finished_at', sk_name: 'Koniec', type: 'time'},
                            {name: 'total_time', sk_name: 'čas', type: 'seconds'},
                            {name: 'percent_done', sk_name: 'Počet %', type: 'integer'},
                            {name: 'description', sk_name: 'Poznámka', type: 'string', 'hidden': true},
                        ]}
                        selectors={selectors}
                        columnSelector
                        items={work_performance}
                        is_loading = {P.is_loading}
                        defaultSortKey={'date'}
                        defaultAsc={false}
                        editOps={true}
                        delOps={true}
                        onEditItem={id => this.setState({
                                formActive: true,
                                updating: true,
                                objToUpdate: work_performance.find( o => o.id === id)
                            })
                        }
                        onDeleteItem={id => {
                            store.dispatch(work_performance_actions.delete(id));
                        }}
                        rowStyleFn={(column, obj) => {
                            if (column === 'finished_at' && !obj.finished_at){
                                return ({
                                    whiteSpace: 'normal',
                                    backgroundColor: '#ff5353'
                                });
                            }
                            return {
                                whiteSpace: 'normal',
                            };
                        }}
                    />
                </NeedWritePerm>
                <EditForm
                    key={S.updating ? S.objToUpdate.id : 'new_id'}
                    show={S.formActive}
                    onClose={ () => this.setState({
                        formActive: false,
                        updating: false,
                        objToUpdate: undefined,
                    })}
                    onSubmit={ (model) => this.formSubmit(model)}
                    updating={S.updating}
                    obj={ S.updating ? S.objToUpdate : {id: -1, building_id, entity_id}}
                    work_operations={P.work_operations}
                    group_history={P.group_history}
                    entity_operations={P.entity_operations}
                    work_performance={P.work_performance}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildings: state.buildings.items.filter(e => e.active),
        operation_work: state.operation_work.items,
        entity_operations: state.entity_operations.items,
        work_performance: state.work_performance.items,
        work_operations: state.workOperations.items,
        group_history: state.group_history.items,
        building_entities: state.building_entities.items,
        is_loading: (
            state.buildings.isFetching
            || state.building_entities.isFetching
        ),
    };
};

export default connect(mapStateToProps)(Detail);