import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Tabs, Tab } from 'react-bootstrap';
import store from 'store/mainStore';
import employeesActions from 'actions/employees';
import toolsActions from 'actions/tools';
import MainInfo from './MainInfo';
import Form from '../Form';
import toolHistoryActions from 'actions/tool_history';
import toolStatesActions from 'actions/tool_states';
import toolCategoriesActions from 'actions/tool_categories';
import ToolHistory from './ToolHistory';
import { SuperTable } from 'components/PaginationTable';
import NeedWritePermission from 'components/NeedPermission';
import { isNotEmpty } from 'scripts/valueNormalizer';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            objToUpdate: undefined,
            updating: false,
        };
    }

    handleEdit(id) {
        this.setState({
            formActive: true,
            objToUpdate: this.props.tools.find(t => t.id === id),
			updating: true,
        });
    }

    handleCreate(tool) {
        this.setState({
            formActive: true,
            objToUpdate: {
				parent_id: tool.id,
                owner_id: tool.owner_id,
                category_id: tool.category_id,
				quality: 10,
				state_id: tool.state_id
            },
            updating: false
        });
    }

    handleCopy(tool) {
        this.setState({
            formActive: true,
            objToUpdate: Object.assign({}, tool, {
                id: null,
                serial_num: null,
                chip_number: null,
                photo: null,
                label: null,
                revision_document: null,
                aux_document: null,
            }),
            updating: false
        });
    }

    handleDelete(id){
        store.dispatch(toolsActions.delete(id))
            .then((data) => {
                if (data.status === 'ok') {
                    this.props.history.push('/tools');
                }
            });
    }

    handleSubmit(obj) {
		const { state:S } = this;
		const formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }

        let action = {};
		if (S.updating) {
			formData.append('_method', 'PUT');
			
			action = toolsActions.update(S.objToUpdate.id, formData, {
				stringify: false,
				contentType: false,
				method: 'POST'
			});
        } else {
            if (S.objToUpdate && S.objToUpdate.parent_id){
                formData.append('parent_id', S.objToUpdate.parent_id);
            }

            action = toolsActions.create(formData, {
				stringify: false,
				contentType: false
			});
		}

        store.dispatch(action).then(data => {
            if (data.status === 'ok') {
                store.dispatch(toolsActions.fetchAll());
                this.setState({
                    formActive: false,
                    updating: false,
                    objToUpdate: undefined,
                });
            }
        });
    }

    componentDidMount() {
        const id = parseInt(this.props.match.params.id, 10);
        store.dispatch(toolHistoryActions.fetchAll( {id} ));
        store.dispatch(toolsActions.fetchAll());
		store.dispatch(toolStatesActions.fetchAll());
		store.dispatch(employeesActions.fetchAll());
		store.dispatch(toolCategoriesActions.fetchAll());
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const id = parseInt(this.props.match.params.id, 10);
            store.dispatch(toolHistoryActions.fetchAll( {id} ));
        }
    }

    toolClickHandle(id) {
        //return <Redirect to={`/tools/detail/${id}`} />;
        this.props.history.push(`/tools/detail/${id}/`);
    }

    render() {
        const { props:P, state:S } = this;
        const id = parseInt(this.props.match.params.id, 10);
        const children = P.tools.filter(t => t.parent_id === id);
        const tool = P.tools.find(t => t.id === id) || {};

        let parent = null;
        if (tool) {
            parent = P.tools.find(t => t.id === tool.parent_id);
        }
        const thead = [
            {name: 'thumbnail', sk_name: 'Fotka', type: 'image'},
            {name: 'name', sk_name: 'Názov', type: 'string'},
            {name: 'category_name', sk_name: 'Kategória', type: 'string'},
            {name: 'state_name', sk_name: 'Stav', type: 'string'},
            {name: 'owner_name', sk_name: 'Vlastník', type: 'string'},
        ];

        return (
            <Container className="tool-detail" style={{ marginBottom: 200}} >
                <MainInfo
                    tool={tool}
                    parent={parent}
                    onEdit={() => this.handleEdit(id)} 
                    onCreate={() => this.handleCreate(tool)}
                    onDelete={ id => this.handleDelete(id)}
                    onCopy={ (tool) => this.handleCopy(tool)}
                />
                <Tabs defaultActiveKey={1} key={'tabs' + tool.id}>
                    <Tab key={'history' + tool.id} eventKey={1} title="História">
                        <ToolHistory
                            history={P.toolHistory}
                            employees={P.employees}
                            states={P.states} />
                    </Tab>
                    {(tool && children.length > 0) ?
                        (<Tab key={'children' + tool.id} eventKey={2} title="Položky">
                        <div className="tools-view">
                            <NeedWritePermission
                                need={['tools']}
                                type={'table'}
                            >
                                <SuperTable
                                    name={'tool_children'}
                                    items={children}
                                    thead={thead}
                                    columnSelector
                                    onDeleteItem={id => store.dispatch(toolsActions.delete(id))}
                                    onRowClick={id => this.toolClickHandle(id)}
                                    delOps
                                    searchable = {false}
                                    showPages = {false}
                                />
                            </NeedWritePermission>
                        </div>        
                    </Tab>): null}
                </Tabs>
                <Form
                    key={S.objToUpdate ? S.objToUpdate.id : 'new_obj'}
                    show={S.formActive}
                    toolStates={P.states}
                    employees={P.employees.filter(e => e.active)}
                    updating={S.updating} 
                    tool={S.objToUpdate}
                    tools={P.tools}
                    parent={(S.objToUpdate && S.objToUpdate.parent_id)
                        ? P.tools.find( t => t.id === S.objToUpdate.parent_id)
                        : null
                    }
                    onClose={() => this.setState({ formActive: false, objToUpdate: undefined })}
					onSubmit={obj => this.handleSubmit(obj) }
					categories={P.categories}
                    identity={P.identity}
				/>
            </Container>
        );
    }
}

const mapStateToProps = state => {
	return {
		toolHistory: state.toolHistory.items,
		tools: state.tools.items,
		employees: state.employees.items,
		states: state.toolStates.items,
		categories: state.toolCategories.items,
        identity: state.identity,
	};
};

export default withRouter(connect(mapStateToProps)(Detail));
