import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Container} from 'react-bootstrap';
import Tern from 'components/Or';
import NeedWritePerm from 'components/NeedPermission';

import store from 'store/mainStore';
import { MdClose } from 'react-icons/md';
import { validateOperations } from '../../WorkingHours/Detail/recordUtils';
import work_performance_actions from 'actions/work_performance';
import Spinner from 'components/Spinner';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import DV, {getmonthName} from 'scripts/dateFormatter';
import { setGlobalError } from 'actions/globalError';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { SuperTable } from 'components/PaginationTable';
import my_operations_actions from 'actions/my_operations';
import entity_operations from 'actions/entity_operations';
import EditForm from './EditForm';
import {round_value} from '../../WorkingHours/Detail/monthViewUtils';
import './style.css';

class ConfirmForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.mounted = false;
        this.state = {
            buttonDisabled: false,
        };
    }

    render() {
        const {props: P, state: S} = this;
        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
            >
                <Modal.Header>
                    Schválenie operácií
                    <div style={{ textAlign: 'right', height: 25}}>
                        <MdClose
                            style={{ cursor: 'pointer'}}
                            size={24}
                            onClick={() => P.onClose()}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        ref="form"
                        onInvalid={() => this.setState({ buttonDisabled: true })}
                        onValid={() => this.setState({ buttonDisabled: false })}
                        onValidSubmit={(model) => P.onSubmit(model)}
                    >
                        <Input
                            type={'text'}
                            name={'comment'}
                            label={'Komentár'}
                        />
                        <Button
                            variant="success"
                            type="submit"
                            disabled={S.buttonDisabled}
                        >
                            Potvrdiť
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

class MonthView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formShow: false,
            formActive: false,
        };
    }

    onConfirm() {
        let wh = this.props.operations;

        if (wh.some(w => !w.started_at)) {
            store.dispatch(setGlobalError(['V niektorých záznamoch chýba čas začiatku'], 'info'));
            return;
        }
        if (wh.some(w => !w.finished_at)) {
            store.dispatch(setGlobalError(['V niektorých záznamoch chýba čas konca'], 'info'));
            return;
        }

        let sortedDays = new Map();

        wh.forEach( w => {
            let date = w.start ? new Date(w.started_at) : new Date(w.finished_at);
            let index = date.getDate();
            if (!sortedDays.get(index)){
                sortedDays.set(index, {days: []});
            }
            sortedDays.get(index).days.push(w);
        });

        if ( Array.from(sortedDays.values()).some( d => {
            return (validateOperations(d.days).length > 0);
        })) {
            store.dispatch(setGlobalError(['V niektorých záznamoch sa prekrývajú časové intervaly'], 'info'));
            return;
        }

        this.setState({
            formShow: true
        });
    }

    formSubmit(model) {
        const {state: S} = this;

        let action = null;
        if (S.updating) {
            action = my_operations_actions.update(S.objToUpdate.id, model);
        } else {
            action = my_operations_actions.create(model);
        }

        store.dispatch(action)
            .then( data => {
                if (data.status === 'ok') {
                    this.setState({
                        formActive: false,
                        objToUpdate: undefined,
                        updating: false,
                    });
                }
            });
    }

    isCurrentMonth(){
        let date = new Date();

        return date.getFullYear() === this.props.year && date.getMonth() + 1 === this.props.month;
    }

    getToolTip(item) {
        return <div>
            <IoIosHelpCircleOutline
                style={{padding: 0}}
                size={'1.5em'}
                data-tip
                data-for={`${item.id}_tool_tip`}
                //data-event='click focus'
            />
            <ReactTooltip
                id={`${item.id}_tool_tip`}
                type='dark'
                place="right"
                effect="solid"
                //globalEventOff='click'
                clickable={true}
                delayHide={500}
                //delayShow={500}
                delayUpdate={500}
            >
                {this.getWorkInfo(item)}
            </ReactTooltip>
        </div>;
    }

    compare(date1, date2){
        if (!date1 || !date2) {return false; }

        // compares only the date and time portion of the string with minute precision
        // the date is expected to be in ISO8601 format in UTC timezone e.g.: "2020-04-08T09:12:14.000000Z"
        return date1.substring(0, 16) === date2.substring(0, 16);
    }

    getWorkInfo(work){
        let options = {
            second: 'numeric',
            timeZoneName: 'short'
        };
        let info = [];

        if (work.created_by_id) {
            let created_string = 'Tento záznam bol ';
            if (work.manually_created) {
                created_string += 'manuálne vytvorený';
            } else {
                created_string += 'zapísaný';
            }
            info.push(
                <span>
                    {created_string} zamestnancom {work.created_by_name} dňa {DV(work.created_at, true, options)}
                </span>
            );
        }

        if (work.updated_by_id) {
            let updated_string = 'Tento záznam bol ';
            if (work.manually_updated) {
                updated_string += 'naposledy manuálne upravený';
            } else {
                updated_string += 'ukončený';
            }
            info.push(
                <span>
                    {updated_string} zamestnancom {work.updated_by_name} dňa {DV(work.updated_at, true, options)}
                </span>
            );
        }

        if (info.length === 0) {
            return null;
        }

        return <ul
            style={{
                textAlign: 'left',
                listStyleType: 'none',
                fontSize: '1.2em',
            }}
        >
            {info.map( (val, key) => {
                return <li key={key}>{val}</li>;
            })}
        </ul>;
    }

    onMonthConfirm(model){
        this.setState({
            formShow: false
        });

        this.props.onSubmitAll({
            comment: model.comment
        });
    }

    render() {
        const { props: P, state: S } = this;
        //const { year, month, employee_id } = P;

        if (P.is_loading) {
            return <Spinner/>;
        }
        let total_sum = P.operations.reduce((acc, item) => acc + item.total_time, 0);
        let operations = P.operations.map( item => ({
            ...item,
            tool_tip: this.getToolTip(item)
        }));

        return (
            <Container>
                <Tern expression={P.canMakeActions}>
                    <Button
                        variant="success"
                        onClick={() => this.setState({
                            formActive: true,
                            updating: false,
                            objToUpdate: undefined
                        })}
                        style={{ marginTop: 15 }}
                    >
                        Pridať záznam
                    </Button>
                </Tern>
            <NeedWritePerm type="table" need={['operations']}>
            <SuperTable
                name={'work_performance'}
                thead={[
                    {name: 'date', sk_name: 'Dátum', type: 'short_date'},
                    {name: 'operation_name', sk_name: 'Operácia', type: 'string'},
                    {name: 'building_name', sk_name: 'Budova', type: 'string'},
                    {name: 'position_name', sk_name: 'Pozícia', type: 'string'},
                    {name: 'started_at', sk_name: 'Začiatok', type: 'long_time'},
                    {name: 'finished_at', sk_name: 'Koniec', type: 'long_time'},
                    {name: 'total_time', sk_name: 'Časový rozdiel', type: 'seconds'},
                    {name: 'percent_done', sk_name: 'Odpracované %', type: 'integer'},
                    {name: 'group_names', sk_name: 'Skupina', type: 'string', 'hidden': false, sort: true},
                    {name: 'description', sk_name: 'Poznámka', type: 'string', 'hidden': true},
                    {name: 'tool_tip', sk_name: '', type: 'html', sort: false, search: false}
                ]}
                columnSelector
                items={operations}
                is_loading = {P.is_loading}
                defaultSortKey={'date'}
                defaultAsc={true}
                showPages={false}
                editOps={P.canMakeActions}
                delOps={P.canMakeActions}
                onEditItem={id => {
                    let obj = P.operations.find( o => o.id === id);
                    store.dispatch(entity_operations.fetchAll({id: obj.entity_id}));
                    store.dispatch(work_performance_actions.fetchAll({id: obj.entity_id}))
                        .then(() =>
                            this.setState({
                                formActive: true,
                                updating: true,
                                objToUpdate: obj
                            })
                        );
                }}
                onDeleteItem={id => {
                    store.dispatch(my_operations_actions.delete(id));
                }}
                rowStyleFn={(column, obj) => {
                    if (column === 'finished_at' && !obj.finished_at){
                        return ({
                            whiteSpace: 'normal',
                            backgroundColor: '#ff5353'
                        });
                    }
                    return {
                        whiteSpace: 'normal',
                    };
                }}
            />
        </NeedWritePerm>
            <EditForm
                key={S.updating ? S.objToUpdate.id : 'new_id'}
                show={S.formActive}
                onClose={() => this.setState({
                    formActive: false,
                    updating: false,
                    objToUpdate: undefined,
                })}
                onSubmit={(model) => this.formSubmit(model)}
                updating={S.updating}
                obj={S.updating ? S.objToUpdate : {}}
                operation_types={P.operation_types}
                entity_operations={P.entity_operations}
                group_history={P.group_history}
                work_performance={P.entity_performance}
            />
            <Tern expression={!P.isApproved && P.show_approval}>
                <NeedWritePerm type="button" need={['operations']}>
                    <Button
                        variant="success"
                        onClick={() => this.onConfirm()}
                        style={{marginTop: 10}}
                    >
                        Schváliť operácie
                    </Button>
                </NeedWritePerm>
            </Tern>
            <ConfirmForm
                show={S.formShow}
                onSubmit={model => this.onMonthConfirm(model)}
                onClose={() => this.setState({formShow: false})}
            />
            {
                P.isApproved ?
                <div
                    className="statistics spacing"
                    style={{marginBottom: 20}}
                >
                    <span>
                        Tento mesiac bol schválený zamestnancom
                        <strong> {P.approval.approved_by ? P.approval.approved_by.full_name : ''} </strong>
                        dňa
                        <strong> {DV(P.approval.approved_at)} </strong>
                        <Tern expression={Boolean(P.approval.comment)}>
                            <div>
                                Komentár ku schváleniu: {P.approval.comment}
                            </div>
                        </Tern>
                    </span>
                    <Tern expression={P.permissions.is_accountant}>
                        <span style={{textAlign: 'right'}}>
                             <Button
                                 variant="danger"
                                 type="submit"
                                 onClick={() => {
                                     P.onDeleteApproval(P.approval.id);
                                 }}
                             >
                            Zrušiť schválenie
                        </Button>
                        </span>
                    </Tern>
                </div> : <div/>
            }
            <div className="statistics"
                style={{
                display: 'block'
            }}>
                <div style={{
                    marginBottom: '10px'
                }}>
                    <div>
                        Celkový súčet časov z pracovných operácií za mesiac {getmonthName(P.month)} : <strong>
                        {round_value(total_sum/3600)}
                    </strong> hodín
                    </div>
                </div>
            </div>
            </Container>
        );
    }
}

MonthView.propTypes = {
    holidays: PropTypes.array.isRequired,
    canMakeActions: PropTypes.bool.isRequired,
    operations: PropTypes.arrayOf(PropTypes.object).isRequired,
    operation_types: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.object.isRequired,
};

export default MonthView;
