import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormCheck, Row, Col, Button, Modal} from 'react-bootstrap';
import call from 'ajax/call';
import orderActions from 'actions/orders';
import store from 'store';
import Input from 'components/Input';
import {MdClose} from 'react-icons/md';
import Formsy from 'formsy-react';
import Tern from 'components/Or';
import {getNumber} from 'components/PaginationTable/TableRow';
import {IoIosHelpCircleOutline} from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import DF from 'scripts/dateFormatter';

class EditForm extends Component {
    render() {
        const {props: P} = this;

        return (
            <Modal
                onHide={() => P.onClose()}
                show={P.show}
            >
            <Modal.Header>
                <div
                    className="closeBox"
                    style={{ float: 'right', height: 25}}
                >
                    <MdClose
                        size={24}
                        onClick={ this.props.onClose }
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="register-form">
                    <Formsy
                        onValidSubmit={model => P.onSubmit(model)}
                    >
                        <Input
                            name={'quantity'}
                            label={'Počet ' + (P.type === 1 ? '%' : 'hod.')}
                            type={'number'}
                            min={0}
                            required={true}
                            value={this.props.objToUpdate ? this.props.objToUpdate.percentage : 0}
                        />
                        <Row>
                            <Col md={{span: 8, offset: 4}}>
                                <Button variant="success" type="submit">
                                    { this.props.updating ? 'Upraviť' : 'Vytvoriť' }
                                </Button>
                            </Col>
                        </Row>
                    </Formsy>
                </div>
            </Modal.Body>
            </Modal>
        );
    }
}

EditForm.propTypes = {
    type: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    objToUpdate: PropTypes.object,
};

class NotificationsSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            formActive: false,
            objToUpdate: null,
            items: [],
            formType: 1
        };
    }

    deleteNotification(order_id, id) {
        call(`/api/orders/${order_id}/time_spend_notifications/${id}`, {
            method: 'DELETE'
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(orderActions.didUpdateOrdersSucc(order_id, data.data));
                }
            });
    }

    updateNotification(order_id, id, obj) {
        call(`/api/orders/${order_id}/time_spend_notifications/${id}`, {
            method: 'PUT',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(obj)
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(orderActions.didUpdateOrdersSucc(order_id, data.data));
                    this.setState({
                        formActive: false,
                        objToUpdate: null,
                    });
                }
            });
    }

    createNewNotification(order_id, obj) {
        call(`/api/orders/${order_id}/time_spend_notifications`, {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(obj)
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(orderActions.didUpdateOrdersSucc(order_id, data.data));
                    this.setState({
                        formActive: false,
                        objToUpdate: null,
                    });
                }
            });
    }

    onFormSubmit(model) {
        let obj = this.state.objToUpdate;
        let order_id = this.props.order_id;

        if (this.state.formType === 1) {
            if (this.state.objToUpdate) {
                this.updateNotification(order_id, obj.id, {percentage: model.quantity});
            } else {
                this.createNewNotification(order_id, {percentage: model.quantity});
            }
        } else {
            store.dispatch(orderActions.update(order_id, {time_budget: model.quantity}));
        }
    }

    getToolTip(obj) {
        if (!obj || !obj.send_at) {
            return null;
        }

        let id = String(obj.id);
        return (
            <span>
                <IoIosHelpCircleOutline
                    size = {'1.5em'}
                    style={{
                        margin: '0.5em',
                        marginTop: '0.1em'
                    }}
                    data-tip data-for={id}
                />
                <ReactTooltip
                    id={id}
                    type='dark'
                    place="right"
                    effect="solid"
                >
                    <div style={{fontSize: '1.2em'}}>
                        Notifikácia bola odoslaná dňa {DF(obj.send_at)}
                    </div>
                </ReactTooltip>
            </span>
        );
    }

    render() {
        const {props: P, state: S} = this;
        let percentage = 0;
        if (P.time_budget) {
            percentage = P.total_time * 100.0 / P.time_budget;
        }

        return (
            <div className={'mt-3'}>
                <Row className={'mt-2'}>
                    <Col><strong>Predpokladaný čas na zákazku: </strong></Col>
                    <Col>{getNumber(P.time_budget)} hod.</Col>
                    <Col>
                        <Button
                            variant={'primary'}
                            onClick={() => this.setState({
                                formActive: true,
                                objToUpdate: {percentage: P.time_budget},
                                formType: 2,
                            })}
                        >
                            {'Upraviť'}
                        </Button>
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <div>
                        <strong>Aktuálny počet odpracovaných hodín: </strong> {getNumber(P.total_time)}/{getNumber(P.time_budget)} ({getNumber(percentage)} %)
                    </div>
                </Row>
                <FormCheck
                    className={'mt-4'}
                    type={'switch'}
                    id={'send_periodic_notifications'}
                    label={'Posielať pravidelné mesačné notifikácie'}
                    onChange={e => P.onSettingsChange('send_periodic_notifications', e.target.checked)}
                    checked={P.send_periodic_notifications}
                />
                <FormCheck
                    className={'mt-2'}
                    type={'switch'}
                    id={'send_percentage_notifications'}
                    label={'Posielať notifikácie pri dosiahnutí určitého počtu percent hodín'}
                    onChange={e => P.onSettingsChange('send_percentage_notifications', e.target.checked)}
                    checked={P.send_percentage_notifications}
                />
                <Tern expression={P.send_percentage_notifications}>
                    <div>
                        {
                            P.time_notifications
                                .sort((a, b) => a.percentage - b.percentage)
                                .map( obj => {
                                    return <Row
                                        className={'mt-2'}
                                        key={obj.id}
                                    >
                                        <Col md={3} sm={3} xs={3}>{obj.percentage} % {this.getToolTip(obj)}</Col>
                                        <Col md={6} sm={9} xs={9}>
                                            <Button
                                                className={'mx-2'}
                                                variant={'primary'}
                                                onClick={() => this.setState({
                                                    formActive: true,
                                                    objToUpdate: obj,
                                                    formType: 1,
                                                })}
                                            >
                                                {'Upraviť'}
                                            </Button>
                                            <Button
                                                className={'mx-2'}
                                                variant={'danger'}
                                                onClick={() => this.deleteNotification(P.order_id, obj.id)}
                                            >
                                                {'Vymazať'}
                                            </Button>
                                        </Col>
                                    </Row>;
                                })
                        }
                        <Row className={'mt-2'} style={{
                            textAlign: 'right'
                        }}>
                            <Col>
                                <Button
                                    variant={'primary'}
                                    onClick={() => this.setState({
                                        formActive: true,
                                        objToUpdate: null,
                                        formType: 1,
                                    })}
                                >
                                    {'Pridať'}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Tern>
                <EditForm
                    show={S.formActive}
                    onClose={() => this.setState({formActive: false})}
                    updating={S.objToUpdate !== null}
                    objToUpdate={S.objToUpdate}
                    onSubmit={model => this.onFormSubmit(model)}
                    type={S.formType}
                />
            </div>
        );
    }
}

NotificationsSettings.propTypes = {
    order_id: PropTypes.number.isRequired,
    time_notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSettingsChange: PropTypes.func.isRequired,
    send_periodic_notifications: PropTypes.bool.isRequired,
    send_percentage_notifications: PropTypes.bool.isRequired,
    total_time: PropTypes.number.isRequired,
    time_budget: PropTypes.number,
};

NotificationsSettings.defaultProps = {
    time_notifications: [],
    onSettingsChange: () => {}
};

export default NotificationsSettings;
