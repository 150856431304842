import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Container, Row} from 'react-bootstrap';
import store from 'store/mainStore';
import Spinner from 'components/Spinner';
import buildingsActions from 'actions/buildings';
import buildingEntitiesActions from 'actions/building_entities';
import entityTypesActions from 'actions/entity_types';
import {updateQueryParams} from 'scripts/queryFunctions';
import {getUrlParams,} from '../Planning/utils';

import work_performance_actions from 'actions/work_performance';
import workOperationActions from 'actions/work_operations';
import call from 'ajax/call';
import './style.css';
import SectionDetail from './sectionDetail';
import SectionMaterial from './SectionMaterial';
import WorkReport from './WorkReport';
import SectionForm from './SectionForm';
import Formsy from 'formsy-react';
import FilesForm from 'components/FilesForm';

class Planning extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMaster: false,
            isWorking: false,
            entity_operation_id: null,
            entity_operation_description: null,
            work_operation_description: null,
            work_performance_id: null,
            operation_name: null,
            min_status: 0,
            building_id: null,
            entity_id: null,
            operation_type_id: null,
            obj: null,
            group_names: '',
            showWorkReport: false,
            started: true,
            overhead: false,
            is_driving: false,
            files: [],
        };
    }

    fetchFiles(entity_id) {
        if (!entity_id) {
            this.setState({files: []});
            return;
        }

        call(`/api/building_entities/${entity_id}/files`, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.setState({
                        files: data.data,
                    });
                }
            });
    }

    setup() {
        call('/api/entity_operations/is_working')
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    let params = [
                        'building_id',
                        'entity_id',
                        'operation_type_id',
                    ];
                    let paramsObj = {
                        is_working: 'false'
                    };
                    const {location, history} = this.props;
                    this.setState({
                        isMaster: data.data.is_master,
                        group_names: data.data.group_names,
                    });

                    if (data.data.is_working) {
                        let stateObj = {
                            showWorkReport: true,
                            isWorking: true,
                            entity_operation_id: data.data.entity_operation_id,
                            operation_name: data.data.operation_name,
                            min_status: data.data.status_percent,
                            work_performance_id: data.data.work_performance_id,
                            obj: data.data.work_performance,
                            overhead: data.data.overhead,
                            is_driving: data.data.is_driving,
                        };
                        paramsObj['is_working'] = 'true';
                        params.forEach(p => {
                            paramsObj[p] = parseInt(data.data[p], 10);
                            stateObj[p] = paramsObj[p];
                        });
                        this.setState(stateObj);
                    } else {
                        params.forEach(p => {
                            paramsObj[p] = null;
                            let value = data.data[p] || localStorage.getItem(p);
                            if (value) {
                                paramsObj[p] = parseInt(value, 10);
                            }
                        });
                    }
                    updateQueryParams(location, history, paramsObj, false);
                    this.fetchFiles(paramsObj.entity_id);
                    if (paramsObj.building_id) {
                        store.dispatch(buildingEntitiesActions.fetchAll({building_id: paramsObj.building_id}));
                    }
                }
            });
    }

    returnToCurrentOperation() {
        let params = [
            'building_id',
            'entity_id',
            'operation_type_id',
        ];
        let paramsObj = {};
        const {location, history} = this.props;

        params.forEach(p => {
            paramsObj[p] = this.state[p];
        });

        updateQueryParams(location, history, paramsObj, true);
    }

    getCurrentParams() {
        return getUrlParams(this.props.location, [
            'building_id',
            'entity_id',
            'operation_type_id'
        ]);
    }

    onStart() {
        call('/api/work_performance/write', {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                entity_id: this.state.entity_id,
                operation_type_id: this.state.operation_type_id,
                status: 'start',
            })
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    store.dispatch(work_performance_actions.didCreateWork_performanceSucc(data.data));
                    updateQueryParams(this.props.location, this.props.history, {is_working: 'true'});

                    this.setState({
                        obj: data.data,
                        started: true,
                        isWorking: true,
                    });
                }
            });
    }

    startOperation(entity_operation) {
        this.setState({
            showWorkReport: true,
            entity_operation_id: entity_operation.id,
            entity_operation_description: entity_operation.description,
            work_operation_description: entity_operation.operation.description,
            operation_name: entity_operation.operation_name,
            min_status: entity_operation.status_percent,
            entity_id: entity_operation.entity_id,
            operation_type_id: entity_operation.operation_type_id,
            overhead: false,
            is_driving: false,
        });

        updateQueryParams(this.props.location, this.props.history, {
            entity_id: entity_operation.entity_id,
            operation_type_id: entity_operation.operation_type_id
        }, true, true);
    }

    startOverhead(operation, is_driving = false) {
        let params = this.getCurrentParams();

        this.setState({
            showWorkReport: true,
            entity_operation_id: null,
            entity_operation_description: null,
            work_operation_description: operation.description,
            operation_name: operation.name,
            min_status: 0,
            operation_type_id: operation.id,
            entity_id: params.entity_id,
            overhead: true,
            is_driving: is_driving,
        });

        updateQueryParams(this.props.location, this.props.history, {
            operation_type_id: operation.id
        }, true, true);
    }

    pauseOperation(percent_done, description = null) {
        let {state: S} = this;

        let data = {
            entity_id: S.entity_id,
            operation_type_id: S.operation_type_id,
            status: 'finish',
            description: description
        };

        if (percent_done < 100) {
            data.status = 'pause';
            data.percent_done = percent_done;
        }

        call('/api/work_performance/write', {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    let obj = data.data;
                    store.dispatch(work_performance_actions.didUpdateWork_performanceSucc(obj.id, obj));

                    updateQueryParams(this.props.location, this.props.history, {
                        is_working: 'false',
                        operation_type_id: null,
                    });

                    this.setState({
                        isWorking: false,
                        started: false,
                        min_status: percent_done,
                        operation_type_id: null,
                        overhead: false,
                        is_driving: false,
                    });
                }
            });
    }

    componentDidMount() {
        store.dispatch(buildingsActions.fetchAll());
        store.dispatch(entityTypesActions.fetchAll());
        store.dispatch(workOperationActions.fetchAll());

        this.setup();
    }

    redirect() {
        let params = this.getCurrentParams();
        let stringParams = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');

        this.props.history.push(`/operation_work/?${stringParams}`);
    }

    render() {
        const {props: P, state: S} = this;

        if (P.is_loading || P.entity_types.length === 0) {
            return <Spinner/>;
        }

        let params = this.getCurrentParams();
        let entity_id = params.entity_id;
        let entity = P.building_entities.find(s => s.id === entity_id) || null;

        let type_name = null;
        let show_driving_operation = false;

        if (entity) {
            let type = P.entity_types.find(t => t.id === entity.entity_type_id);
            type_name = type.name;
            show_driving_operation = type.show_driving_operation;
        }

        let showWorkReport = (params.operation_type_id !== null && S.entity_id === entity_id);
        if (showWorkReport && S.overhead) {
            params.entity_id = null;
        }

        let form =
            <Formsy>
                <SectionForm
                    key={`${params.building_id}-${params.entity_id}`}
                    onChange={ newParams => {
                        const {location, history} = this.props;
                        if (!newParams.entity_id) {
                            newParams.entity_id = null;
                        }
                        updateQueryParams(location, history, newParams, true);
                        this.fetchFiles(newParams.entity_id);
                    }}
                    locked={showWorkReport}
                    params={params}
                    leaf_required={!showWorkReport || !S.overhead}
                    onlyBuildingsWithEntities={true}
                />
            </Formsy>;

        if (!entity || !entity.is_leaf) {
            return <Container className="cars">
                {form}
            </Container>;
        }
        let showWorkRecords = S.isMaster;

        return (
            <Container>
                {form}
                <div className="info">
                    {
                        (!S.overhead || S.is_driving) ? <Row style={{
                            margin: 15,
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '16pt',
                        }}>
                            {type_name} {entity.name || ''}
                        </Row> : null
                    }
                    {showWorkReport ?
                        <Row style={{
                            margin: 15,
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '16pt',
                        }}>
                            Operácia {S.operation_name}
                        </Row> : null
                    }
                    {
                        (!S.overhead || !showWorkReport) ?
                            <Row>
                                <Col xs={6} className="text-right">Dĺžka</Col>
                                <Col xs={6} className="text-left">{entity.length || '0'} m</Col>
                            </Row> : null
                    }
                    {
                        (!S.overhead && entity.description) ?
                        <Row>
                            <Col xs={6} className="text-right">Popis</Col>
                            <Col xs={6} className="text-left">{entity.description}</Col>
                        </Row> : null
                    }
                    {
                        (showWorkReport && S.work_operation_description) ?
                            <Row>
                                <Col xs={6} className="text-right">Popis operácie</Col>
                                <Col xs={6} className="text-left">{S.work_operation_description}</Col>
                            </Row> : null
                    }
                    {
                        (showWorkReport && S.entity_operation_description) ?
                            <Row>
                                <Col xs={6} className="text-right">Poznámka ku operácii</Col>
                                <Col xs={6} className="text-left">{S.entity_operation_description}</Col>
                            </Row> : null
                    }
                    {
                        (!S.overhead && entity.picture) ?
                            <Row style={{
                                justifyContent: 'center',
                                margin: 15
                            }}>
                                <a href={entity.picture}>
                                    <Button type={'normal'}>
                                        Zobraziť výkres
                                    </Button>
                                </a>
                            </Row>
                            : null
                    }
                    {
                        (!S.overhead && S.files.length) ?
                            <FilesForm
                                heading={'Výkresy'}
                                files={this.state.files}
                                files_url={`/api/building_entities/${entity_id}/files`}
                                editable={false}
                                afterChange={() => this.fetchFiles(entity_id)}
                                loading={false}
                            />
                           : null
                    }
                    {showWorkRecords ?
                        <Button
                            variant="success"
                            onClick={() => this.redirect()}
                            style={{marginTop: 15}}
                        >
                            Zobraziť pracovné záznamy
                        </Button> : null
                    }
                    {(showWorkReport) ?
                        (<WorkReport
                            operation_name={S.operation_name}
                            min_status={S.min_status}
                            onPause={ (value, description) => this.pauseOperation(value, description)}
                            onStart={() => this.onStart()}
                            group_names={show_driving_operation ? P.identity.full_name : S.group_names}
                            entity_id={S.entity_id}
                            operation_type_id={S.operation_type_id}
                            isOverhead={S.overhead}
                            obj={S.obj}
                            started={S.isWorking}
                            user_name={P.identity.full_name}
                        />) :
                        (<div style={{marginTop: 20}}>
                            <h5>Skupina: {S.group_names}</h5>
                            <SectionDetail
                                show_driving_operation={show_driving_operation}
                                operation_types={P.operation_types}
                                block_start={S.isWorking === true}
                                entity_id={entity_id}
                                key={entity_id}
                                onStart={obj => this.startOperation(obj)}
                                onStartOverhead={ op => this.startOverhead(op, show_driving_operation)}
                                isWorking={S.isWorking}
                                entityOperationId={S.entity_operation_id}
                                operation_type_id={S.operation_type_id}
                            />
                            {S.isWorking ?
                                <Button
                                    variant="success"
                                    onClick={() => this.returnToCurrentOperation()}
                                    style={{
                                        marginTop: 15,
                                        marginBottom: 15,
                                    }}
                                >
                                    Späť na aktuálnu operáciu
                                </Button> : null
                            }
                        </div>)
                    }
                    { !S.overhead ?
                        <SectionMaterial
                            key={params.entity_id}
                            entity_id={entity_id}
                        /> : null
                    }
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildings: state.buildings.items.filter(e => e.active),
        building_entities: state.building_entities.items,
        entity_types: state.entity_types.items,
        operation_types: state.workOperations.items,
        identity: state.identity,
        is_loading:
            state.buildings.isFetching
            || state.building_entities.isFetching
            || state.entity_types.isFetching
    };
};

export default connect(mapStateToProps)(Planning);
