import React from 'react';
import {connect} from 'react-redux';
import {Button, Container} from 'react-bootstrap';
import OperationTypeForm from './OperationTypeForm';
import store from 'store/mainStore';
import NeedWritePerm from 'components/NeedPermission';
import {SuperTable} from 'components/PaginationTable';
import operationTypesActions from 'actions/work_operations';
import entityTypesActions from 'actions/entity_types';
import Spinner from 'components/Spinner';

class OperationTypes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            typeFormActive: false,
            defaultOperationFormActive: false,
            operationType: {},
            defaultOperation: {},
            updating: false,
            current_type: null,
            type_changed: false,
            entity_type: undefined,
        };
    }

    onOperationTypeSubmit(obj) {
        let action;

        if (this.state.updating) {
            action = operationTypesActions.update(this.state.operationType.id, obj);
        } else {
            action = operationTypesActions.create(obj);
        }
        store.dispatch(action).then(() => {
            this.setState({
                typeFormActive: false,
                operationType: {},
            });
        });
    }

    componentDidMount() {
        store.dispatch(operationTypesActions.fetchAll());
        store.dispatch(entityTypesActions.fetchAll());
    }

    render() {
        const { props:P, state:S } = this;

        if (P.is_loading) {
            return <Spinner/>;
        }

        return (
            <Container className='operationTypes'>
                <h4>Typy operácií:</h4>
                <div style={{ marginBottom: 10, marginTop: 20 }} >
                    <NeedWritePerm type="button" need={['operation_types']}>
                        <Button 
                            variant="success"
                            onClick={() => this.setState({
                                    operationType: {},
                                    updating: false,
                                    typeFormActive: true,
                                }
                            )}
                        >
                            Pridať typ operácie
                        </Button>
                    </NeedWritePerm>
                </div>
                <NeedWritePerm type="table" need={['operation_types']}>
                    <SuperTable
                        name={'operations_types'}
                        thead={[
                            {name: 'name', sk_name: 'Názov', type: 'string'},
                            {name: 'difficulty_level', sk_name: 'Náročnosť', type: 'number'},
                            {name: 'unit', sk_name: 'Merná jednotka', type: 'string'},
                            //{name: 'photo', 'sk_name': 'Foto', type: 'image'}
                        ]}
                        searchable={true}
                        items={P.operation_types}
                        onRowClick={ id => {
                            this.setState({
                                operationType: P.operation_types.find(o => o.id === id),
                                updating: true,
                                typeFormActive: true
                            });
                        }}
                        onDeleteItem={ id => store.dispatch(operationTypesActions.delete(id)) }
                        defaultSortKey={'number'}
                        delOps
                        is_loading = {P.is_loading}
                    />
                </NeedWritePerm>
                <OperationTypeForm
                    key={S.operationType ? S.operationType.id : 'new_type'}
                    show={S.typeFormActive}
                    onClose={() => { this.setState({
                            typeFormActive: false,
                            operationType: {},
                        });
                    }}
                    operation={S.operationType}
                    onSubmit={obj => this.onOperationTypeSubmit(obj)}
                    updating={S.updating}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        operation_types: state.workOperations.items,
        is_loading: state.workOperations.isFetching
            || state.entity_types.isFetching
    };
};

export default connect(mapStateToProps)(OperationTypes);
