import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import DF from 'scripts/dateFormatter';
import {FaCheck} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import NeedWritePerm from 'components/NeedPermission';
import ImageViewer from 'components/ImageViewer';
import './style.css';
import {Link} from 'react-router-dom';
import {ConfirmModal} from 'components/modals';
import YearChooser from './YearChooser';
import Tern from 'components/Or';

const LastRecordInfo = props => {
    const {data} = props;
    const {start = null, end = null} = data;
    let message = '';
    let date = '';
    let withTime = true;

    if (!start && !end) {
        return null;
    }

    if (!data.order && data.type) {
        message = data.type.name;
        date = start ? (new Date(start)) : (new Date(end));
        withTime = false;
    } else {
        if (end) {
            date = new Date(end);
            message = 'Odchod zo zákazky: ';
        } else {
            date = new Date(start);
            message = 'Príchod na zákazku: ';
        }
    }

    let info_message = null;
    if (!data.tablet && data.master) {
        info_message = 'Záznam bol zapísaný ručne užívateľom: ' + data.master.full_name;
    }

    let order = null;
    if (data.order) {
        order = props.permissions['orders'] > 0 ?
            (<Link to={`/orders/detail/${data.order.id}`}>
                {data.order.serial_number}
            </Link>)
            : data.order.serial_number;
    }

    return (
        <Col className="last-record" md={12}>
            <h2>Posledný záznam</h2>
            <div>
                <p>{DF(date, withTime)}</p>
                <p>{message}
                    {order}
                </p>
                {info_message ? <p>{info_message}</p> : null}
            </div>
        </Col>
    );
};

class UserView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirm_show: false,
            confirm_message: '',
            show_year_chooser: false,
        };
    }

    render() {
        let { props, state: S } = this;

        return (
            <div className="employee-box">
                <Row>
                    <Col md={{span: 6, offset:3}}>
                        <div className="info-row">
                            <ImageViewer
                                thumbnail={props.thumbnail}
                                full_size={props.photo}
                            />
                        </div>
                        <Row className="user-info">
                            <Col md={12}>
                                <Row>
                                    <Col md={12}>
                                        <h3>{props.full_name}</h3>
                                    </Col>
                                    <Col md={12}>
                                        {
                                            (props.permissions.employees == 2) ?
                                            <Button
                                                onClick={() => this.props.onUpdate()}
                                                variant="success"
                                            >
                                                Upraviť
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => this.props.onUpdateOwn()}
                                                variant="success"
                                            >
                                                Upraviť profil
                                            </Button>
                                        }
                                        <NeedWritePerm type="button" need={['employees']}>
                                            <Button
                                                variant="danger"
                                                onClick={() => this.setState({
                                                    confirm_show: true,
                                                })}
                                                style={{marginLeft: 15}}
                                            >
                                                Vymazať
                                            </Button>
                                        </NeedWritePerm>
                                        {this.props.canChangePassword ?
                                            <Button
                                                variant="success"
                                                onClick={() => this.props.onChangePasswd()}
                                                style={{marginLeft: 15}}
                                            >
                                                Zmeniť heslo
                                            </Button> : null
                                        }
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={6} className="text-right">Prihlasovacie meno</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ props.username || '-' }</p>
                                    </Col>
                                </Row>
                                {
                                    props.permissions.gdpr > 0 ? <div>
                                        <Tern expression={!!props.email}>
                                            <Row>
                                                <Col xs={6} className="text-right">E-mail</Col>
                                                <Col xs={6} className="text-left">
                                                    <p>{ props.email || '-' }</p>
                                                </Col>
                                            </Row>
                                        </Tern>
                                        <Tern expression={!!props.work_email}>
                                            <Row>
                                                <Col xs={6} className="text-right">Pracovný mail</Col>
                                                <Col xs={6} className="text-left">
                                                    <p>{ props.work_email || '-' }</p>
                                                </Col>
                                            </Row>
                                        </Tern>
                                        <Row>
                                            <Col xs={6} className="text-right">Adresa</Col>
                                            <Col xs={6} className="text-left">
                                                <p>{ props.address || '-' }</p>
                                            </Col>
                                        </Row>
                                        <Tern expression={!!props.mobile_number}>
                                            <Row>
                                                <Col xs={6} className="text-right">Telefónne číslo</Col>
                                                <Col xs={6} className="text-left">
                                                    <p>{ props.mobile_number || '-' }</p>
                                                </Col>
                                            </Row>
                                        </Tern>
                                        <Tern expression={!!props.work_phone}>
                                            <Row>
                                                <Col xs={6} className="text-right">Pracovný telefón</Col>
                                                <Col xs={6} className="text-left">
                                                    <p>{ props.work_phone || '-' }</p>
                                                </Col>
                                            </Row>
                                        </Tern>
                                        <Row>
                                            <Col xs={6} className="text-right">Dátum narodenia</Col>
                                            <Col xs={6} className="text-left">
                                                <p>{ DF(props.birthday, false) || '-' }</p>
                                            </Col>
                                        </Row>
                                    </div> : null
                                }
                                <Row>
                                    <Col xs={6} className="text-right">Začiatok vlastníctva karty</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ DF(props.from_date) || '-' }</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} className="text-right">Profesia</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ props.profession ? props.profession.name : '-' }</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} className="text-right">Rola</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ props.role ? props.role.name : '-' }</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} className="text-right">Typ zamestnanca</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ props.type ? props.type.name : '-' }</p>
                                    </Col>
                                </Row>
								<Row>
                                    <Col xs={6} className="text-right">Skratka</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ props.abbreviation || '-' }</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} className="text-right">Komentár</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{ props.comment || '-' }</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} className="text-right">Aktívny</Col>
                                    <Col xs={6} className="text-left">
                                        <p>{
                                            props.active ?
                                                <FaCheck size={20} />
                                                : <MdClose size={20} />
                                        }
                                        </p>
                                    </Col>
                                </Row>
                                <Row style={{
                                    justifyContent: 'center'
                                }}>
                                    <NeedWritePerm type="button" need={['is_accountant']}>
                                        <span>
                                            <Button
                                                onClick={() => this.setState({
                                                    show_year_chooser: true,
                                                })}
                                                variant="success"
                                                style={{marginBottom: 15}}
                                            >
                                                Export dochádzky
                                            </Button>
                                        </span>
                                    </NeedWritePerm>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {
                        props.permissions['working_hours'] > 0 ?
                            <LastRecordInfo data={this.props.last_record || {}} permissions={props.permissions}/>
                            : null
                    }
                </Row>
                <ConfirmModal
                    message={'Vymazať zamestnanca ' + props.full_name}
                    show={S.confirm_show}
                    onClose={() => this.setState({ confirm_show: false })}
                    onAccept={ () => this.props.onDelete()}
                    acceptTitle="Vymazať"
                />
                <YearChooser
                    show={S.show_year_chooser}
                    onClose={() => this.setState({ show_year_chooser: false })}
                    emp_id={props.id}
                    //onAccept={(year) => this.fetchExport(year)}
                />
            </div>
        );
    }
}

export default UserView;
