import React from 'react';
import {Container} from 'react-bootstrap';
import store from 'store/mainStore';
import employees_masters_project_managers from 'actions/employees_masters_project_managers';
import employee_order from 'actions/employee_orders';
import ordersActions from 'actions/orders';
import order_material_quantity from 'actions/order_material_quantity';
import order_material_documents from 'actions/order_material_documents';
import products_actions from 'actions/products';
import buildingActions from 'actions/buildings';
import View from './View';
import {connect} from 'react-redux';
import Spinner from 'components/Spinner';

class Detail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
        };
    }

    componentDidMount() {
        const id = parseInt(this.props.match.params.id);

        store.dispatch(ordersActions.fetchConcrete(id));
        store.dispatch(order_material_quantity.fetchAll({orderId: id}));
        store.dispatch(order_material_documents.fetchAll({orderId: id}));
        store.dispatch(products_actions.fetchAll({order_id: id}));

        store.dispatch(employee_order.fetchAll({
            type: 'order',
            order_id: id
        }));
        store.dispatch(employees_masters_project_managers.fetchAll());
        store.dispatch(buildingActions.fetchAll());
    }

    handleEmployeeOrderSubmit(id, obj, updating) {
        store.dispatch(updating ? employee_order.update(id, obj) : employee_order.create(obj));
    }

    handleEmployeeOrderDelete(id) {
        store.dispatch(employee_order.delete(id));
    }

    render() {
        const {props: P} = this;
        const order = P.order;

        return (
            <Container className="order-detail" style={{paddingBottom: 50}}>
                {
                    P.loading ? <Spinner/> :
                        (<div>
                            <View
                                data={order}
                                work_summary={order.work_summary || []}
                                material_quantity={P.material_quantity}
                                material_documents={P.material_documents}
                                products={P.products}
                                employeeOrder={P.employeeOrder}
                                onEmployeeOrderSubmit={(id, obj, updating) => this.handleEmployeeOrderSubmit(id, obj, updating)}
                                onEmployeeOrderDelete={(id) => this.handleEmployeeOrderDelete(id)}
                                employees={P.employees}
                                buildings={P.buildings}
                                permissions={P.permissions}
                                onShowEmployee={id => this.props.history.push(`/orders/detail/${order.id}/employee/${id}`)}
                                isLoading={P.loading}
                            />
                        </div>)
                }
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id);
    let order = state.orders.items.find(b => b.id === id);

    return {
        order,
        buildings: state.buildings.items.filter(b => b.active),
        material_quantity: state.order_material_quantity.items,
        material_documents: state.order_material_documents.items,
        products: state.products.items,
        employees: state.employeesMastersProjectManagers.items,
        employeeOrder: state.employeeOrder.items.filter(eo => eo.order_id === id),
        permissions: state.permissions,
        loading: order === undefined
            || state.orders.isFetching
            || state.buildings.isFetching
            || state.permissions.isFetching
            || state.employeesMastersProjectManagers.isFetching
            || state.employeeOrder.isFetching
            || order.work_summary === undefined,
    };
};

export default connect(mapStateToProps)(Detail);
