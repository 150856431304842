import { FETCHING, FETCHED_SUCC, FETCHED_ERR } from 'actions/tool_history';

const defaultState = {
    items: [],
    err: null,
    fetching: false
};

export default function (state=defaultState, action) {

    switch(action.type) {
        case FETCHING:
            return Object.assign({}, {fetching: true});
        case FETCHED_SUCC:
            return Object.assign({}, { items: action.data });
        case FETCHED_ERR:
            return Object.assign({}, {
                err: action.data.message
            });
        default: return state;
    }

}