import React from 'react';
import {Button, Container} from 'react-bootstrap';
import {connect} from 'react-redux';
import buildingsActions from 'actions/buildings';
import ordersActions from 'actions/orders';
import store from 'store/mainStore';
import RegisterForm from './RegisterForm';
import NeedWritePerm from 'components/NeedPermission';
import {addDependency} from 'actions/dependency';
import {buildingHasActiveOrders} from './utils';
import WorkCostsExportForm from './WorkCostsExportForm';
import {DefaultSelector, SuperTable} from 'components/PaginationTable';
import {isNotEmpty} from 'scripts/valueNormalizer';
import './style.css';

class BuildingsContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            buildingToUpdate: undefined,
            showBuilding: {},
            showOrders: [],
            orderToUpdate: undefined,
            orderFormShow: false,
            exportFormActive: false,
            exportUrl: '/api/buildings/expenses_export',
        };
    }

    componentDidMount() {
        if (this.props.buildings.length === 0) {
            store.dispatch(buildingsActions.fetchAll());
        }
        if (this.props.orders.length === 0) {
            store.dispatch(ordersActions.fetchAll());
        }
    }

    clickHandler() {
        this.setState({
            formActive: true
        });
    }

    handleFormSubmit(obj) {
        const { buildingToUpdate } = this.state;
        const formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }
        let options = {
            stringify: false,
            contentType: false,
        };

        let action;
        if (buildingToUpdate) {
            formData.append('_method', 'PUT');
            action = buildingsActions.update(buildingToUpdate.id, formData, {...options, method: 'POST'});
        } else {
            action = buildingsActions.create(formData, options);
        }
        store.dispatch(action).then(data => {
            if (data.status === 'ok') {
                this.setState({
                    buildingToUpdate: undefined,
                    formActive: false
                });
            }
        });
    }

    handleDelete(id) {
        store.dispatch(buildingsActions.delete(id));
    }

    handleCloseForm() {
        this.setState({ formActive: false });
    }

    handleUpdate(id) {
        this.setState({
            buildingToUpdate: this.props.buildings.find(b => b.id === id),
            formActive: true
        });
    }

    render() {
		const {props: P, state: S} = this;
		let items = P.buildings;

        return (
            <Container className='buildings'>
                <NeedWritePerm type="button" need={['buildings']}>
                    <Button onClick={this.clickHandler.bind(this)} variant='success'>Pridať budovu</Button>
                </NeedWritePerm>
                <NeedWritePerm
                    type={'button'}
                    need={['is_accountant']}
                >
                    <Button
                        variant="danger"
                        onClick={() => this.setState({
                            exportFormActive: true,
                            exportUrl: '/api/buildings/expenses_export',
                        })}
                        style={{ marginLeft: 40 }}
                    >
                        Export nákladov
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm
                    type={'button'}
                    need={['material', 'material_prices']}
                >
                    <Button
                        variant="danger"
                        onClick={() => this.setState({
                            exportFormActive: true,
                            exportUrl: '/api/buildings/material_export',
                        })}
                        style={{ marginLeft: 40 }}
                    >
                        Export materiálu
                    </Button>
                </NeedWritePerm>
				<NeedWritePerm type="table" need={['buildings']}>
                    <SuperTable
                        name={'buildings'}
                        items={items}
						thead={[
                            {name: 'name', sk_name: 'Názov', type: 'string'},
                            {name: 'address', sk_name: 'Adresa', type: 'string'},
                            {name: 'country', sk_name: 'Krajina', type: 'string'},
                            {name: 'active', sk_name: 'Aktívna', type: 'boolean'},
                            {name: 'photo', sk_name: 'Obrázok', type: 'image', hidden: true},
                            {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true},
                        ]}
                        columnSelector
                        selectors = {DefaultSelector}
                        onRowClick={id => this.props.history.push(`/buildings/detail/${id}`)}
                        onDeleteItem={ id => this.handleDelete(id)}
                        onEditItem={ id => this.handleUpdate(id)}
                        defaultSortKey='name'
                        delOps
                        searchable
                        export={'budovy'}
                        is_loading = {P.is_loading}
					/>
                </NeedWritePerm>
                <WorkCostsExportForm
                    show={S.exportFormActive}
                    onClose={() => this.setState({ exportFormActive: false })}
                    url={S.exportUrl}
                />
                <RegisterForm
                    show={S.formActive}
                    defaultValues={S.buildingToUpdate}
                    onSubmit={this.handleFormSubmit.bind(this)}
                    onClose={this.handleCloseForm.bind(this)}
                    updating={ !!S.buildingToUpdate }
					hasActiveOrders={buildingHasActiveOrders(S.buildingToUpdate, P.orders)}
				/>
            </Container>
        );
    }
}

addDependency('buildings', ['webapp_access', 'orders']);

const mapStateToProps = state => {
    return {
        buildings: state.buildings.items,
        orders: state.orders.items,
        is_loading: state.orders.isFetching || state.buildings.isFetching,
    };
};

export default connect(mapStateToProps)(BuildingsContainer);
