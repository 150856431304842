import React from 'react';
import './styles.css';

class PieChart extends React.Component {

    render() {
        let value = this.props.value;
        let size = this.props.size;
        if (!size) {
            size = 30;
        }

        let over50 = value > 50 ? 1 : 0;

        return <div className="pie"
                    onMouseOut={this.props.onMouseOut}
                    onMouseOver={this.props.onMouseOver}
                    onClick={this.props.onClick}
                    style={{
                        '--size': size,
                        '--bg': '#a2a2a2',
                    }}
        >
            <div
                className="pie__segment"
                style={{
                    '--offset': 0,
                    '--value': value,
                    '--bg': '#198754',
                    '--over50': over50
                }}>
            </div>
        </div>;
    }
}

export default PieChart;
