import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'css/custom-select-style.css';
import { MdClose } from 'react-icons/md';
import Formsy from 'formsy-react';
import Input  from 'components/Input';
import call from 'ajax/call';

class PasswordChangeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formSubmitted: false
        };
    }

    changePassword(model){
        model = {
            employee_id: this.props.employee_id,
            ...model
        };

        call('/api/change_password', {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(model)})
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.props.onSubmit();
                } else {
                    this.setState({
                        errors: {
                            old_password: 'Nesprávne heslo'
                        }
                    });
                }
            });
    }

    handleFormSubmit(model) {
        this.setState({
            formSubmitted: true
        });

        if (this.validateForm(model)) {
            return;
        }

        this.changePassword(model);
    }

    validatePassword(password){
        if (!password){
            return 'Vyplňte nové heslo';
        }
        if (password.length < 8){
            return 'Nové heslo musí mať aspoň 8 znakov';
        }
        var regex = new RegExp('[A-Z]');
        if (!regex.test(password)){
            return 'Nové heslo musí obsahovať aspoň jedno veľké písmeno';
        }
        regex = new RegExp('[a-z]');
        if (!regex.test(password)){
            return 'Nové heslo musí obsahovať aspoň jedno malé písmeno';
        }
        regex = new RegExp('[0-9]');
        if (!regex.test(password)){
            return 'Nové heslo musí obsahovať aspoň jedno číslo';
        }

        return null;
    }

    validateForm(model){
        let errors = {};

        if (!model['old_password']){
            errors['old_password'] = 'Vyplňte staré heslo';
        }

        let error = this.validatePassword(model['new_password_1']);
        if (error){
            errors['new_password_1'] = error;
        }
        if (!model['new_password_2']){
            errors['new_password_2'] = 'Vyplňte nové heslo';
        }
        if (model['new_password_1'] !== model['new_password_2']){
            errors['new_password_2'] = 'Heslá sa nezhodujú';
        }

        this.setState({
            errors: errors
        });

        return (Object.keys(errors).length > 0);
    }

    render(){
        return (
            <Modal
                size="md"
                className="employees-form"
                show
                onHide={() => {
                    if (!this.props.force){
                        this.props.onClose();
                    }
                }}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: 25
                        }}
                    >
                        Zmena hesla
                        <MdClose
                            size={24}
                            onClick={ () => {
                                if (!this.props.force){
                                    this.props.onClose();
                                }
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={model => this.handleFormSubmit(model)}
                        onInvalidSubmit={ model => this.handleFormSubmit(model)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={this.state.errors}
                        autoComplete={'off'}
                    >
                        <Input
                            type="password"
                            name={'old_password'}
                            label={'Staré heslo'}
                            required
                            isFormSubmitted={ () => this.state.formSubmitted}
                            autoComplete={'nope'}
                        />
                        <Input
                            type="password"
                            name="new_password_1"
                            label="Nové heslo"
                            autoComplete={'new-password'}
                            required
                        />
                        <Input
                            type="password"
                            name="new_password_2"
                            label="Nové heslo znovu"
                            autoComplete={'new-password'}
                            required
                        />
                        <Button
                            variant="success"
                            type="submit"
                        >
                            Potvrdiť
                        </Button>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

export default PasswordChangeForm;
