import React from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
import { Row, Form } from 'react-bootstrap';

class TextArea extends React.Component {
    handleChange(val) {
        this.props.setValue(val);
        if (this.props.onChange) {
            this.props.onChange(val);
        }
    }

    render() {
        const {props: P} = this;

        const errorMessage = P.errorMessage;
        const show_error = !P.isValid && (!P.isPristine || P.isFormSubmitted);

        return <Row className={'mb-2'}>
            <label htmlFor={P.name} className={
                classNames({
                    'col-form-label': true,
                    'col-sm-4': true,
                    'text-danger': Boolean(!P.isValid)
                })}>
                {P.label}
                {P.required ?
                    <span className={'required-symbol'}> *</span> : null
                }
            </label>
            <div className={'col-sm-8'}>
                <Form.Control
                    id={P.id}
                    as={'textarea'}
                    onChange={e => this.handleChange(e.target.value)}
                    value={P.value}
                />
                { show_error ?
                    <span
                        className={'invalid-feedback'}
                        style={{display: 'block'}}
                    >
                        { errorMessage }
                    </span> : null
                }
            </div>
        </Row>;
    }
}

export default withFormsy(TextArea);
