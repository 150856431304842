import { createSelector } from 'reselect';

const toolStates = (state) => state.toolStates.items;
const tools = (state) => state.tools.items;

const employees = (state) => state.employees.items;
const categories = (state) => state.toolCategories.items;
const identity = (state) => state.identity;

const is_loading = (state) => {
    return state.toolStates.items.length === 0
    || state.employees.items.length === 0
    || state.toolCategories.items.length === 0;
};

export default createSelector (
    [tools, employees, toolStates, categories, identity, is_loading],
    (allTools, employees, toolStates, categories, identity, loading) => {

        if (allTools === undefined){
            allTools = [];
        }

        return {
            tools: allTools,
            employees,
            toolStates,
            categories,
            identity,
            is_loading: loading,
        };
    }
);
