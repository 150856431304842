import React from 'react';
import PT from 'prop-types';
import { SuperTable } from 'components/PaginationTable';
import store from 'store/mainStore';
import toolsAtEmployeesActions from 'actions/tools_at_employees';
import {connect} from 'react-redux';

class UsedTools extends React.Component {

    componentDidMount() {
        store.dispatch(toolsAtEmployeesActions.fetchAll({
            id: this.props.employeeId
        }));
    }

    render() {
        const { props:P } = this;

        return (
            <div style={{ marginTop: 25 }}>
                <SuperTable
                    name={'used_tools'}
                    thead={[
                        {name: 'thumbnail', sk_name: 'Fotka', type: 'image', hidden: false},
                        {name: 'label_thumbnail', sk_name: 'štítok', type: 'image', hidden: true},
                        {name: 'name', sk_name: 'Názov', type: 'string', hidden: false},
                        {name: 'serial_num', sk_name: 'Sériové číslo', type: 'string', hidden: false},
                        {name: 'from_date', sk_name: 'Prevzaté dňa', type: 'date', hidden: false},
                        {name: 'quality', sk_name: 'Kvalita', type: 'number', hidden: false},
                        {name: 'has_children', sk_name: 'Sada', type: 'boolean', hidden: true},
                        {name: 'category_name', sk_name: 'Kategória', type: 'string', hidden: true},
                        {name: 'state_name', sk_name: 'Stav', type: 'string', hidden: false},
                        {name: 'owner_name', sk_name: 'Vlastník', type: 'string', hidden: true},
                        {name: 'warranty_expiration', sk_name: 'Koniec záruky', type: 'date', hidden: true},
                        {name: 'chip_number', sk_name: 'Číslo čipu', type: 'string', hidden: true},
                        {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true},
                        {name: 'history_comment', sk_name: 'Komentár kvality', type: 'string', hidden: false},
                        {name: 'last_revision', sk_name: 'Posledná revízia', type: 'string', hidden: true},
                        {name: 'next_revision', sk_name: 'Ďalšia revízia', type: 'string', hidden: true},
                    ]}
                    columnSelector={true}
                    items={P.usedTools} 
                    onRowClick={id => P.onToolClick(id)} />
            </div>
        );
    }
}

UsedTools.propTypes = {
    usedTools: PT.array,
    onToolClick: PT.func
};

UsedTools.defaultProps = {
    usedTools: [],
    onToolClick: () => {}
};

const mapStateToProps = state => {
    return {
        usedTools: state.toolsAtEmployees.items,
    };
};

export default connect(mapStateToProps)(UsedTools);
