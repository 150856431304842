import React from 'react';
import {Button, Modal, Row, Col } from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import {FormSelect} from 'components/CustomSelect';
import FormCheckBox from 'components/CheckBox';
import {mapEntriesForSelect, mapValuesForSelect} from './utils';
import PT from 'prop-types';

class SubscriptionForm extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            active: true,
            name: '',
            modelClass: null,
            attribute: null,
            value: '',
            valueType: null,
            filterType: null,
            filterValue: null,
            notify_database: true,
            notify_email: false,
            notify_sms: false,
        };

        this.state = this.initialState;
    }

    componentDidUpdate(prevProps) {
        const {props: P} = this;
        if (prevProps.subscription !== P.subscription && P.subscription) {
            this.setState({
                active: P.subscription.active,
                name: P.subscription.name,
                modelClass: P.subscription.model_class,
                attribute: P.subscription.attribute,
                value: P.subscription.value,
                valueType: P.subscription.singularized_value_type,
                filterType: P.subscription.value2_type,
                filterValue: P.subscription.value2,
                notify_database: P.subscription.notify_database,
                notify_email: P.subscription.notify_email,
                notify_sms: P.subscription.notify_sms,
            });
        }
    }

    validateForm(values) {
        let validations = {
            object_object: 'Zadajte predmet',
            attribute_object: 'Zadajte atribút',
            singularized_value_type_object: 'Zadajte časovú jednotku',
        };

        let errors = {};
        Object.keys(validations).forEach(key => {
            if (!values[key]) {
                errors[key] = validations[key];
            }
        });

        return (Object.keys(errors).length === 0);
    }

    handleChangeObject(valueObject) {
        const {props: P} = this;
        const isInitialValue = P.subscription && valueObject.id === P.subscription.model_class;
        this.setState({
            attribute: isInitialValue ? P.subscription.attribute : null,
            valueType: isInitialValue ? P.subscription.singularized_value_type : null,
            filterType: isInitialValue ? P.subscription.value2_type : null,
            filterValue: isInitialValue ? P.subscription.value2 : null,
        });
        this.setState({
            modelClass: valueObject ? valueObject.id : null,
        });
    }

    handleChangeValue(value, name) {
        let val;
        if (typeof value === 'object' && value !== null) {
            val = value.id;
        } else {
            val = value;
        }
        this.setState({
            [name]: val,
        });
    }

    handleHide() {
        this.props.onClose();
    }

    handleSubmit(values) {
        if (!this.validateForm(values)) {
            return;
        }

        let {
            singularized_value_type_object,
            attribute_object,
            object_object,
            value2_type_object,
            value2_object,
            ...obj
        } = values;

        let newObj = {
            ...obj,
            attribute: attribute_object.id,
            value_type: singularized_value_type_object.id,
            model_class: object_object.id,
            value2: value2_object ? value2_object.id : null,
            value2_type: value2_type_object ? value2_type_object.id : null,
        };

        this.props.onSubmit(newObj);
    }

    resetState() {
        this.setState(this.initialState);
    }

    render() {
        const {props: P, state: S} = this;

        let attributes = [];
        let valueTypes = [];
        let filterTypes = [];
        let filterValues = [];

        let objects = mapValuesForSelect(P.metadata);

        if (S.modelClass) {
            attributes = mapEntriesForSelect(P.metadata[S.modelClass].attributes);

            if (S.attribute) {
                valueTypes = mapEntriesForSelect(P.metadata[S.modelClass].attributeValueTypes[S.attribute]);
            }

            filterTypes = mapEntriesForSelect(P.metadata[S.modelClass].filterTypes);

            if (S.filterType) {
                filterValues = mapEntriesForSelect(P.metadata[S.modelClass].filterValues[S.filterType]);
            }
        }

        return (
            <Modal
                size="lg"
                show={P.show}
                onHide={() => this.handleHide()}
                onExited={() => this.resetState()}
            >
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: 25,
                            width: '100%',
                        }}
                    >
                        {P.updating ? 'Úprava' : 'Vytváranie'} notifikácie
                        <MdClose
                            size={24}
                            onClick={() => this.handleHide()}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onSubmit={model => this.handleSubmit(model)}
                        onChange={values => this.validateForm(values)}
                        formNoValidate
                    >
                        <FormCheckBox
                            name={'active'}
                            label={'Aktívne'}
                            value={S.active}
                            onChange={value => this.handleChangeValue(value, 'active')}
                        />
                        <Input
                            name={'name'}
                            label={'Názov'}
                            required
                            min={1}
                            value={S.name}
                            onChange={value => this.handleChangeValue(value, 'name')}
                        />
                        <FormSelect
                            name={'object_object'}
                            label={'Predmet'}
                            options={objects}
                            getOptionValue={p => p.id}
                            getOptionLabel={p => p.name}
                            isClearable
                            required
                            value={objects.find(x => x.id === S.modelClass) || null}
                            onChange={value => this.handleChangeObject(value)}
                        />
                        <FormSelect
                            name={'attribute_object'}
                            label={'Atribút'}
                            options={attributes}
                            getOptionValue={p => p.id}
                            getOptionLabel={p => p.name}
                            isClearable
                            required
                            value={attributes.find(x => x.id === S.attribute) || null}
                            onChange={value => this.handleChangeValue(value, 'attribute')}
                        />
                        <Input
                            type={'number'}
                            name={'value'}
                            label={'Interval notifikácie vopred'}
                            required
                            min={0}
                            max={9999}
                            value={S.value}
                            onChange={value => this.handleChangeValue(value, 'value')}
                        />
                        <FormSelect
                            name={'singularized_value_type_object'}
                            label={'Časová jednotka'}
                            options={valueTypes}
                            getOptionValue={p => p.id}
                            getOptionLabel={p => p.name}
                            isClearable
                            required
                            value={valueTypes.find(x => x.id === S.valueType) || null}
                            onChange={value => this.handleChangeValue(value, 'valueType')}
                        />
                        {
                            filterTypes.length > 0 &&
                            <FormSelect
                                name={'value2_type_object'}
                                label={'Filter'}
                                options={filterTypes}
                                getOptionValue={p => p.id}
                                getOptionLabel={p => p.name}
                                isClearable
                                value={filterTypes.find(x => x.id === S.filterType) || null}
                                onChange={value => this.handleChangeValue(value, 'filterType')}
                            />
                        }
                        {
                            filterTypes.length > 0 && <FormSelect
                                name={'value2_object'}
                                label={'Hodnota filtra'}
                                options={filterValues}
                                getOptionValue={p => p.id}
                                getOptionLabel={p => p.name}
                                isClearable
                                value={filterValues.find(x => x.id === S.filterValue) || null}
                                onChange={value => this.handleChangeValue(value, 'filterValue')}
                            />
                        }
                        <FormCheckBox
                            name={'notify_database'}
                            label={'Notifikácia'}
                            value={S.notify_database}
                            onChange={value => this.handleChangeValue(value, 'notify_database')}
                        />
                        <FormCheckBox
                            name={'notify_email'}
                            label={'Email'}
                            value={S.notify_email}
                            onChange={value => this.handleChangeValue(value, 'notify_email')}
                        />
                        <FormCheckBox
                            name={'notify_sms'}
                            label={'SMS'}
                            value={S.notify_sms}
                            onChange={value => this.handleChangeValue(value, 'notify_sms')}
                        />
                        <Row>
                            <Col md={6}>
                                <Button
                                    onClick={() => this.handleHide()}
                                    style={{margin: '15px 15px 0 15px'}}
                                >
                                    Zavrieť
                                </Button>
                                <Button
                                    type={'submit'}
                                    variant={'success'}
                                    style={{margin: '15px 15px 0 15px'}}
                                >
                                    {P.updating ? 'Upraviť' : 'Vytvoriť'}
                                </Button>
                            </Col>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

SubscriptionForm.propTypes = {
    subscription: PT.object,
    metadata: PT.object.isRequired,
    show: PT.bool.isRequired,
    updating: PT.bool.isRequired,
    onSubmit: PT.func.isRequired,
    onClose: PT.func.isRequired,
};

SubscriptionForm.defaultProps = {
    subscription: {},
    metadata: {},
    show: false,
    updating: false,
    onSubmit: () => {},
    onClose: () => {},
};

export default SubscriptionForm;