import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from 'react-bootstrap';
import {connect} from 'react-redux';
import HistoryCards from './HistoryCards';
import Certifications from './Certifications';
import EmployeeOrders from './EmployeeOrders';
import UsedTools from './UsedTools';
import {addDependency} from 'actions/dependency';

class InfoPanel extends React.Component {
    render() {
        const {props: P} = this;

        let show_tools = P.permissions.tools === 1 || P.permissions.tools === 2;
        if (P.permissions.tools === 5 && P.isMyProfile) {
            show_tools = true;
        }

        return (
            <div style={{marginBottom: 100}}>
                <Tabs defaultActiveKey={1} id="employee-tabs">
                    {P.permissions['certifications'] === 0 ? null :
                        <Tab eventKey={1} title="Osvedčenia">
                            <Certifications employeeId={parseInt(P.id, 10)}/>
                        </Tab>
                    }
                    {P.permissions['employees'] === 0 ? null :
                        <Tab eventKey={2} title="Čipové karty">
                            <HistoryCards id={P.id}/>
                        </Tab>
                    }
                    {(show_tools) ?
                        <Tab eventKey={3} title="Nástroje">
                            <UsedTools
                                employeeId={parseInt(P.id, 10)}
                                onToolClick={id => P.onToolClick(id)}
                            />
                        </Tab> : null
                    }
                    {P.permissions['orders'] === 0 ? null :
                        <Tab eventKey={4} title="Zodpovednosť za zákazky">
                            <EmployeeOrders
                                employeeOrder={P.employeeOrder}
                                employee_id={parseInt(P.id, 10)}
                                orders={P.orders}
                                isMyProfile={P.isMyProfile}
                                permissionValue={P.permissions['orders']}
                            />
                        </Tab>
                    }
                </Tabs>
            </div>
        );
    }
}

InfoPanel.propTypes = {
    id: PropTypes.any.isRequired,
    onToolClick: PropTypes.func,
    isMyProfile: PropTypes.bool,
};

InfoPanel.defaultProps = {
    onToolClick: () => {},
    isMyProfile: false,
};

addDependency('employees', ['webapp_access', 'working_hours', 'tools']);

const mapStateToProps = (state, ownProps) => {
    const employeeId = parseInt(ownProps.id);
    return {
        employeeOrder: state.employeeOrder.items.filter(eo => eo.employee_id === employeeId),
        workingHours: state.workingHours.items,
        err: state.workingHours.err,
        permissions: state.permissions,
        orders: state.orders.items,
    };
};

export default connect(mapStateToProps)(InfoPanel);
