import { BsLockFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import React from 'react';

export default function ToolTip(props) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return <span>
            <BsLockFill
                size = {'1.5em'}
                style={{
                    margin: '0.5em',
                    marginTop: '0.1em',
                    ...props.style,
                }}
                data-tip data-for={props.id}
            />
            <ReactTooltip
                id={props.id}
                type='dark'
                place="right"
                effect="solid"
                //event="click"
                globalEventOff={ isMobile ? 'touchstart' : undefined }
            >
                <div style={{fontSize: '1.2em'}}>
                {props.text}
                </div>
            </ReactTooltip>
        </span>;
}