import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import notificationActions from 'actions/notifications';
import subscriptionActions from 'actions/subscriptions';
import store from 'store/mainStore';
import RegisterForm from './Form';
import { SuperTable } from 'components/PaginationTable';
import call from 'ajax/call';

const selector = [{
    name: 'read_status',
    default_value: 0,
    values: [
        {
            value: 0,
            name: 'Zobraziť neprečítané',
            filter: obj => !obj.read_at
        },
        {
            value: 1,
            name: 'Zobraziť prečítané',
            filter: obj => Boolean(obj.read_at)
        },
        {
            value: 2,
            name: 'Zobraziť všetky',
            filter: () => true
        },
    ]
}];

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            notification: undefined,
        };
    }

    componentDidMount() {
        if (this.props.notifications.length === 0) {
            store.dispatch(notificationActions.fetchAll());
            store.dispatch(subscriptionActions.fetchAll());
        }
    }

    clickHandler() {
        this.setState({
            formActive: true
        });
    }

    handleCloseForm() {
        this.setState({ formActive: false });
        store.dispatch(notificationActions.update(this.state.notification.id, {}));
    }

    render() {
        const { props, state } = this;
        const P = props;

        let message = '';
        if (props.unreadCount === 0 ){
            message = 'Nemáte žiadne nové notifikácie';
        }
        if (props.unreadCount === 1 ){
            message = 'Máte jednu novú notifikáciu';
        }
        if (props.unreadCount > 1 && props.unreadCount < 5){
            message = `Máte ${props.unreadCount} nové notifikácie`;
        }
        if (props.unreadCount >= 5 ){
            message = `Máte ${props.unreadCount} nových notifikácii`;
        }

        return (
            <Container >
                <h3
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {message}
                </h3>
                <Button
                    style={{marginRight: 15}}
                    onClick={() => this.props.history.push('/subscriptions')}
                    variant="success"
                >
                    Správa notifikácií
                </Button>
                <Button
                    onClick={() => {
                        call('/api/notifications/mark_as_read', {
                            method: 'POST',
                            body: JSON.stringify({_method: 'PUT'}),
                            headers: {'Content-Type': 'application/json'}
                        })
                            .then(response => response.json())
                            .then(data => {
                                if (data.status === 'ok') {
                                    store.dispatch(notificationActions.fetchAll());
                                }
                            });
                    }}
                    variant="success"
                >
                    Označiť všetko ako prečítané
                </Button>
                <SuperTable
                    name={'notifications'}
                    items={P.notifications}
                    thead={[
                        {name: 'created_at', sk_name: 'Dátum', type: 'date-time'},
                        {name: 'model_name', sk_name: 'Objekt', type: 'string'},
                        {name: 'message', sk_name: 'Správa', type: 'string'},
                        {name: 'read', sk_name: 'Prečítané', type: 'boolean'},
                    ]}
                    columnSelector
                    selectors={selector}
                    onRowClick={ id => {
                        this.setState({
                            formActive: true,
                            notification: P.notifications.find(n => n.id === id)
                        });
                    }}
                    onEditItem={id => {
                        store.dispatch(notificationActions.update(id, {body: {a: 'a'}}));
                    }}
                    //editOps
                    searchable
                    is_loading = {P.is_loading}
                />
                <RegisterForm
                    show={state.formActive}
                    notification={state.notification}
                    onRead={() => this.handleCloseForm()}
                    onClose={() => this.setState({ formActive: false })}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    let notifications = state.notifications.items.map(n => ({
        ...n,
        message: n.data.type,
        read: Boolean(n.read_at),
    }));

    return {
        unreadCount: state.notifications.items.reduce( (acc, val) => {
            if (val.read_at){
                return acc;
            }
            return acc + 1;
        }, 0),
        subscriptions: state.subscriptions.items,
        notifications: notifications,
        is_loading: state.notifications.isFetching || state.subscriptions.isFetching,
    };
};

export default connect(mapStateToProps)(Notifications);