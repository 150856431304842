import React from 'react';
import PT from 'prop-types';
import {Button, Col, Row, Tab} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import {SuperTable} from 'components/PaginationTable';
import DefaultSelector from 'components/PaginationTable/defaultSelector';
import Tern from 'components/Or';
import store from 'store/mainStore';
import orderActions from 'actions/orders';
import buildingIssues from 'actions/building_issues';
import ImageViewer from 'components/ImageViewer';
import NeedWritePerm from 'components/NeedPermission';
import {ConfirmModal} from 'components/modals';
import UrlTabs from 'components/UrlTabs';
import ModalForm from 'components/ModalForm';
import {withRouter} from 'react-router-dom';
import defaultOperationsActions from 'actions/default_entity_operations';
import Select, {createFilter} from 'react-select';
import DefaultOperationForm from './DefaultOperationForm';
import call from 'ajax/call';
import {setGlobalError} from 'actions/globalError';
import classNames from 'classnames';
import WorkCostsExportForm from './WorkCostsExportForm';
import buildingEquipmentActions from 'actions/building_equipment';

class View extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            ordersFormActive: false,
            orderObjectToUpdate: undefined,
            confirm_show: false,
            confirm_message: '',
            issuesFormActive: false,
            issueObjToUpdate: undefined,
            exportFormActive: false,
            exportUrl: '',
            equipmentObjToUpdate: undefined,
            equipmentFormActive: false,
        };
    }

    onDefaultOperationSubmit(obj) {
        let action;

        obj.building_id = this.props.data.id;
        if (this.state.updating) {
            action = defaultOperationsActions.update(this.state.defaultOperation.id, obj);
        } else {
            action = defaultOperationsActions.create(obj);
        }
        store.dispatch(action).then(data => {
            this.addToEntities(data, obj);
        });
    }

    addToEntities(data, obj) {
        if (data.status === 'ok') {
            if (data.data.id && obj.add_to_entities) {
                setTimeout(() => {
                    call('/api/work_operations/add_to_entities', {
                        method: 'POST',
                        headers: {'Content-type': 'application/json'},
                        body: JSON.stringify({
                            operation_type_id: data.data.operation_type_id,
                            entity_type_id: data.data.entity_type_id,
                            building_id: obj.building_id,
                        })
                    }).then(response => response.json())
                        .then(data => {
                            store.dispatch(setGlobalError([data.message], 'info', false));
                        });
                }, 1000);
            }

            store.dispatch(defaultOperationsActions.fetchAll({id: obj.building_id}));
            this.setState({
                defaultOperationFormActive: false,
                defaultOperation: {},
                updating: false,
            });
        }
    }

    render() {
        const {props: P, state: S} = this;

        const selectors = [{
            name: 'active_selector',
            default_value: 1,
            values: [
                {
                    value: 0,
                    name: 'Zobraziť všetky',
                    filter: () => true
                },
                {
                    value: 1,
                    name: 'Zobraziť nevyriešené',
                    filter: obj => obj.finished_at === null
                },
                {
                    value: 2,
                    name: 'Zobraziť vyriešené',
                    filter: obj => obj.finished_at !== null
                }
            ]
        }];

        const filterOptions = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start',
        };

        const customStyles = {
            control: (provided) => ({
                ...provided,
                minWidth: 250,
                marginRight: 15,
            })
        };

        let entityTypeValue = S.entity_type;
        let entity_type_id = localStorage.getItem('entity_type_id');

        if (entity_type_id) {
            entity_type_id = parseInt(entity_type_id);
            entityTypeValue = P.entity_types.find( i => i.id === entity_type_id);
        }

        let default_operations = [];
        if (entityTypeValue) {
            default_operations = P.default_operations.filter( o => o.entity_type_id === entityTypeValue.id);
        }

        let entity_types = P.entity_types
            .filter( t => P.default_operations.some( o => o.entity_type_id === t.id));

        return (
            <div>
                <Row>
                    <Col md={12} className="text-center">
                        <ImageViewer
                            thumbnail={P.data.thumbnail}
                            full_size={P.data.photo}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Názov</Col>
                    <Col xs={6} className="text-left">{P.data.name}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Adresa</Col>
                    <Col xs={6} className="text-left">{P.data.address}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Krajina</Col>
                    <Col xs={6} className="text-left">{P.data.country}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Komentár</Col>
                    <Col xs={6} className="text-left">{P.data.comment}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="text-right">Aktívna</Col>
                    <Col xs={6} className="text-left">{
                        P.data.active ? <FaCheck/> : <MdClose/>}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-center" style={{marginTop: 25}}>
                        <NeedWritePerm type="button" need={['buildings']}>
                            <Button
                                onClick={() => P.onBuildingEdit()}
                                variant="success"
                            >
                                Upraviť
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm type="button" need={['buildings']}>
                            <Button
                                style={{marginLeft: 15}}
                                variant="danger"
                                onClick={() => this.setState({
                                    confirm_show: true,
                                })}
                            >
                                Vymazať
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm
                            type={'button'}
                            need={['is_accountant']}
                        >
                            <Button
                                variant="danger"
                                onClick={() => this.setState({
                                    exportFormActive: true,
                                    exportUrl: '/api/buildings/expenses_export',
                                })}
                                style={{ marginLeft: 15 }}
                            >
                                Export nákladov
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm
                            type={'button'}
                            need={['material', 'material_prices']}
                        >
                            <Button
                                variant="danger"
                                onClick={() => this.setState({
                                    exportFormActive: true,
                                    exportUrl: '/api/buildings/material_export',
                                })}
                                style={{ marginLeft: 15 }}
                            >
                                Export materiálu
                            </Button>
                        </NeedWritePerm>
                        <Tern expression={P.permissions['planning'] && P.buildingHasPerformance}>
                            <Button
                                style={{marginLeft: 15}}
                                variant="success"
                                onClick={() => {
                                    this.props.history.push(`/performances_overview?building_id=${P.data.id}`);
                                }}
                            >
                                Prehľad operácii
                            </Button>
                        </Tern>
                    </Col>
                </Row>
                <UrlTabs>
                    <Tab eventKey={'orders'} title="Zákazky">
                        <NeedWritePerm
                            need={['orders']}
                            type={'table'}
                        >
                            <SuperTable
                                name={'orders'}
                                thead={[
                                    {name: 'name', sk_name: 'Názov', type: 'string'},
                                    {name: 'serial_number', sk_name: 'Sériové číslo', type: 'string'},
                                    {name: 'active', sk_name: 'Aktívna', type: 'boolean'},
                                    {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true},
                                    {name: 'bonus', sk_name: 'Bonusovaná', type: 'boolean', hidden: true},
                                ]}
                                columnSelector
                                items={P.orders}
                                onDeleteItem={id => store.dispatch(orderActions.delete(id))}
                                onEditItem={id => this.setState({
                                    orderObjectToUpdate: P.orders.find(order => order.id === id),
                                    ordersFormActive: true,
                                })}
                                onRowClick={id => this.props.history.push(`/orders/detail/${id}`)}
                                selectors={DefaultSelector}
                                searchable
                                editOps
                                delOps
                            />
                        </NeedWritePerm>
                    </Tab>
                    {P.permissions['tools'] === 0 ? null :
                        <Tab eventKey={'tools'} title="Nástroje">
                            <SuperTable
                                name={'tools'}
                                thead={[
                                    {name: 'name', sk_name: 'Názov', type: 'string'},
                                    {name: 'category_name', sk_name: 'Kategória', type: 'string'},
                                    {name: 'owner_name', sk_name: 'Vlastník', type: 'string'},
                                    {name: 'thumbnail', sk_name: 'Fotka', type: 'image', hidden: true},
                                    {name: 'has_children', sk_name: 'Sada', type: 'boolean', hidden: true},
                                    {name: 'state_name', sk_name: 'Stav', type: 'string', hidden: true},
                                    {name: 'warranty_expiration', sk_name: 'Koniec záruky', type: 'date', hidden: true},
                                    {name: 'serial_num', sk_name: 'Sériové číslo', type: 'string', hidden: true},
                                    {name: 'chip_number', sk_name: 'Číslo čipu', type: 'string', hidden: true},
                                    {name: 'comment', sk_name: 'Komentár', type: 'string', hidden: true},
                                    {
                                        name: 'history_comment',
                                        sk_name: 'Komentár kvality',
                                        type: 'string',
                                        hidden: true
                                    },
                                    {name: 'from_date', sk_name: 'Od dátumu', type: 'date', hidden: true},
                                    {name: 'last_revision', sk_name: 'Posledná revízia', type: 'string', hidden: true},
                                    {name: 'next_revision', sk_name: 'Ďalšia revízia', type: 'string', hidden: true},
                                    {name: 'quality', sk_name: 'Kvalita', type: 'string', hidden: true},
                                    {name: 'label', sk_name: 'Popisok', type: 'string', hidden: true},
                                    {name: 'photo', sk_name: 'Obrázok', type: 'image', hidden: true},
                                ]}
                                columnSelector
                                items={P.toolsAtBuildings}
                                onRowClick={id => this.props.history.push(`/tools/detail/${id}/`)}
                            />
                        </Tab>
                    }
                    <Tab eventKey={'issues'} title={'Servisné udalosti'}>
                        <Row>
                            <Col xs={12} style={{marginTop: 25}}>
                                <Button
                                    variant="success"
                                    onClick={() => this.setState({issuesFormActive: true})}
                                >
                                    Pridať záznam
                                </Button>
                            </Col>
                        </Row>
                        <SuperTable
                            name={'building_issues'}
                            thead={[
                                {name: 'name', sk_name: 'Názov', type: 'string'},
                                {name: 'note', sk_name: 'Poznámka', type: 'string', hidden: true},
                                {name: 'creator_name', sk_name: 'Vytvoril', type: 'string'},
                                {name: 'responsible_name', sk_name: 'Zodpovedný', type: 'string'},
                                {name: 'order_number', sk_name: 'Číslo zákazky', type: 'string'},
                                {name: 'attachment', sk_name: 'Príloha', type: 'file'},
                                {name: 'created_at', sk_name: 'Vytvorené', type: 'date-time'},
                                {name: 'issue_state_name', sk_name: 'Stav', type: 'string'},
                                {name: 'finished_at', sk_name: 'Vyriešené dňa', type: 'date-time'},
                            ]}
                            editOps
                            delOps
                            selectors={selectors}
                            columnSelector
                            defaultSortKey={'created_at'}
                            defaultAsc={false}
                            searchable
                            items={P.buildingIssues}
                            onRowClick={id => P.history.push(`/building_issues/detail/${id}/`)}
                            onEditItem={(id) => {
                                this.setState({
                                    issuesFormActive: true,
                                    issueObjToUpdate: P.buildingIssues.find(i => i.id === id)
                                });
                            }}
                            onDeleteItem={(id) => {
                                store.dispatch(buildingIssues.delete(id));
                            }}
                            rowStyleFn={
                                (column) => ['name', 'note'].includes(column)
                                    ? {whiteSpace: 'normal'}
                                    : {}
                            }
                        />
                        <ModalForm
                            show={S.issuesFormActive}
                            updatingObj={S.issueObjToUpdate}
                            action={buildingIssues}
                            afterSave={() => {
                                this.setState({
                                    issueObjToUpdate: undefined,
                                    issuesFormActive: false,
                                });
                            }}
                            onClose={() => {
                                this.setState({
                                    issueObjToUpdate: undefined,
                                    issuesFormActive: false,
                                });
                            }}
                            formElements={[
                                {name: 'name', label: 'Názov', type: 'text', required: true},
                                {name: 'note', label: 'Poznámka', type: 'textarea'},
                                {
                                    name: 'responsible',
                                    label: 'Zodpovedný',
                                    type: 'select',
                                    options: {'items': P.employees, labelKey: 'full_name'},
                                    selectKey: 'responsible_id',
                                    required: true,
                                },
                                {
                                    name: 'notify_employees',
                                    label: 'Upozorniť',
                                    type: 'multiselect',
                                    options: {'items': P.employees, labelKey: 'full_name'},
                                    selectKey: 'notify_employees',
                                    required: false,
                                },
                                {name: 'building_id', type: 'hidden', value: P.data.id},
                                {
                                    name: 'order',
                                    label: 'Zákazka',
                                    type: 'select',
                                    options: {'items': P.orders, 'labelKey': 'serial_number'},
                                    selectKey: 'order_id',
                                },
                                {
                                    name: 'issue_state',
                                    label: 'Stav',
                                    type: 'select',
                                    options: {'items': P.issueStates},
                                    selectKey: 'state_id',
                                    selectFirstValue: true,
                                    required: true,
                                },
                                {name: 'attachment', label: 'Príloha', type: 'file'},
                                {name: 'notify_database', label: 'Notifikovať v aplikácii', type: 'checkbox', defaultValue: true},
                                {name: 'notify_email', label: 'Notifikovať cez email', type: 'checkbox', defaultValue: true},
                                {name: 'notify_sms', type: 'hidden', value: false}
                            ]}
                        >
                        </ModalForm>
                    </Tab>
                    <Tab eventKey={'default_operations'} title={'Predvolené operácie'}>
                        <h4 className={'my-3'}>Predvolené operácie:</h4>
                        <Row>
                            <label className={
                                classNames({
                                    'col-form-label': true,
                                    'col-sm-4': true,
                                })}>
                                Časť budovy
                            </label>
                            <Select
                                className={'col-sm-8'}
                                width={'200px'}
                                options={entity_types}
                                isClearable={false}
                                filterOption={createFilter(filterOptions)}
                                getOptionValue={o => o.id}
                                getOptionLabel={o => o.full_name}
                                onChange={ o => {
                                    this.setState({entity_type: o});
                                    localStorage.setItem('entity_type_id', o.id);
                                }}
                                value={entityTypeValue}
                                isDisabled={false}
                                placeholder={'Vyberte časť budovy'}
                                styles={customStyles}
                                isMulti={false}
                                isSearchable={false}
                            />
                        </Row>
                        <NeedWritePerm type="button" need={['operation_types']}>
                            <Button
                                variant="success"
                                onClick={() => this.setState({
                                        defaultOperation: {
                                            entity_type_id: entityTypeValue ? entityTypeValue.id : null,
                                            number: entityTypeValue ? (
                                                P.default_operations.filter(o => o.entity_type_id === entityTypeValue.id)
                                                    .reduce((max, o) => Math.max(max, o.number), 0) + 1
                                            ) : 1,
                                        },
                                        defaultOperationFormActive: true,
                                        updating: false,
                                    }
                                )}
                                style={{marginTop: 20, marginBottom: 20}}
                            >
                                Pridať operáciu
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm type="table" need={['operation_types']}>
                            <SuperTable
                                name={'default_operations'}
                                thead={[
                                    {name: 'number', sk_name: 'Poradie', type: 'number'},
                                    {name: 'name', sk_name: 'Názov', type: 'string'},
                                    {name: 'entity_type_name', sk_name: 'Časť budovy', type: 'string'},
                                ]}
                                searchable={false}
                                items={default_operations}
                                onRowClick={ id => this.setState({
                                    defaultOperation: P.default_operations.find(o => o.id === id),
                                    updating: true,
                                    defaultOperationFormActive: true
                                })}
                                onDeleteItem={id => store.dispatch(defaultOperationsActions.delete(id))}
                                defaultSortKey={'number'}
                                delOps
                                is_loading = {P.is_loading}
                            />
                        </NeedWritePerm>
                        <DefaultOperationForm
                            key={S.objToUpdate ? S.objToUpdate.id : 'new_operation'}
                            show={S.defaultOperationFormActive}
                            onClose={() => this.setState({
                                defaultOperationFormActive: false,
                                defaultOperation: {}
                            })}
                            operation={S.defaultOperation}
                            operation_types={P.operation_types}
                            entity_types={P.entity_types}
                            onSubmit={obj => this.onDefaultOperationSubmit(obj)}
                            updating={S.updating}
                        />
                    </Tab>
                    <Tab eventKey={'equipment'} title={'Zariadenia'}>
                        <Row>
                            <Col xs={12} style={{marginTop: 25}}>
                                <Button
                                    variant="success"
                                    onClick={() => this.setState({equipmentFormActive: true})}
                                >
                                    Pridať záznam
                                </Button>
                            </Col>
                        </Row>
                        <SuperTable
                            name={'equipment'}
                            thead={[
                                {name: 'name', sk_name: 'Názov', type: 'string'},
                                {name: 'type', sk_name: 'Typ', type: 'string'},
                                {name: 'note', sk_name: 'Poznámka', type: 'string', hidden: true},
                                {name: 'installation_date', sk_name: 'Dátum inštalácie', type: 'short_date'},
                                {name: 'putting_into_operation_date', sk_name: 'Dátum uvedenia do prevádzky', type: 'short_date'},
                                {name: 'revision_date', sk_name: 'Dátum revízie', type: 'short_date', hidden: true},
                                {name: 'put_into_operation_by_name', sk_name: 'Uviedol do prevádzky', type: 'string'},
                            ]}
                            editOps
                            delOps
                            columnSelector
                            defaultSortKey={'name'}
                            defaultAsc={false}
                            searchable
                            items={P.building_equipment}
                            onEditItem={(id) => {
                                this.setState({
                                    equipmentFormActive: true,
                                    equipmentObjToUpdate: P.building_equipment.find(i => i.id === id)
                                });
                            }}
                            onRowClick={id => this.props.history.push(`/building_equipment/detail/${id}`)}
                            onDeleteItem={id => store.dispatch(buildingEquipmentActions.delete(id))}
                            rowStyleFn={
                                (column) => ['name', 'note'].includes(column)
                                    ? {whiteSpace: 'normal'}
                                    : {}
                            }
                        />
                        <ModalForm
                            key={S.equipmentObjToUpdate ? S.equipmentObjToUpdate.id : 'new_item'}
                            show={S.equipmentFormActive}
                            updatingObj={S.equipmentObjToUpdate}
                            action={buildingEquipmentActions}
                            afterSave={() => {
                                this.setState({
                                    equipmentObjToUpdate: undefined,
                                    equipmentFormActive: false,
                                });
                            }}
                            onClose={() => {
                                this.setState({
                                    equipmentObjToUpdate: undefined,
                                    equipmentFormActive: false,
                                });
                            }}
                            formElements={[
                                {name: 'name', label: 'Názov', type: 'text', required: true},
                                {name: 'type', label: 'Type', type: 'text', required: true},
                                {name: 'note', label: 'Poznámka', type: 'textarea'},
                                {name: 'building_id', type: 'hidden', value: P.data.id},
                                {
                                    name: 'installation_date',
                                    label: 'Dátum inštalácie',
                                    type: 'date',
                                },
                                {
                                    name: 'putting_into_operation_date',
                                    label: 'Dátum uvedenia do prevádzky',
                                    type: 'date',
                                },
                                {
                                    name: 'put_into_operation_by',
                                    label: 'Uviedol do prevádzky',
                                    type: 'select',
                                    options: {'items': P.employees, labelKey: 'full_name'},
                                    selectKey: 'put_into_operation_by',
                                    required: false,
                                },
                                {
                                    name: 'revision_date',
                                    label: 'Dátum poslednej revízie',
                                    type: 'date',
                                },
                                {
                                    name: 'photo',
                                    label: 'Fotka',
                                    type: 'file_input',
                                    accept: 'image/*'
                                }
                            ]}
                        />
                    </Tab>
                </UrlTabs>
                <ConfirmModal
                    message={'Vymazať budovu ' + P.data.name}
                    show={S.confirm_show}
                    onClose={() => this.setState({confirm_show: false})}
                    onAccept={() => this.props.onDeleteBuilding(P.data.id)}
                    acceptTitle="Vymazať"
                />
                <ModalForm
                    show={S.ordersFormActive}
                    formElements={[
                        {name: 'name', label: 'Názov', type: 'text', required: true},
                        {name: 'serial_number', label: 'Sériové číslo', type: 'text', required: true},
                        {name: 'comment', label: 'Komentár', type: 'text'},
                        {name: 'building_id', type: 'hidden', value: P.data.id},
                        {name: 'bonus', label: 'Externý príplatok', type: 'checkbox'},
                        {name: 'active', label: 'Aktívna', type: 'checkbox'},
                        {name: 'requires_operations', label: 'Vyžadovať operácie', type: 'checkbox'}
                    ]}
                    action={orderActions}
                    afterSave={() => {
                        this.setState({
                            ordersFormActive: false,
                            orderObjectToUpdate: undefined,
                        });
                    }}
                    onClose={() => {
                        this.setState({
                            ordersFormActive: false,
                            orderObjectToUpdate: undefined,
                        });
                    }}
                    updatingObj={S.orderObjectToUpdate}
                />
                <WorkCostsExportForm
                    show={S.exportFormActive}
                    onClose={() => this.setState({
                        exportFormActive: false
                    })}
                    heading={'Export dát z budovy: ' + P.data.name}
                    url={S.exportUrl}
                    building_id={P.data.id}
                />
            </div>
        );
    }
}

View.propTypes = {
    toolsAtBuildings: PT.array.isRequired,
    orders: PT.array.isRequired,
    employees: PT.array.isRequired,
    buildingIssues: PT.array.isRequired,
    issueStates: PT.array.isRequired,
    data: PT.object.isRequired,
    permissions: PT.object.isRequired,
    id: PT.number.isRequired,
    onBuildingEdit: PT.func,
    onOrderEdit: PT.func,
    buildingHasPerformance: PT.bool.isRequired,
    entity_types: PT.arrayOf(PT.object).isRequired,
    default_operations: PT.arrayOf(PT.object).isRequired
};

View.defaultProps = {
    onOrderEdit: () => {}
};

export default withRouter(View);
