function isGettingConcreteAction(resourceName, resourceActions, state, action) {
    let UPPERCASED = resourceName.toUpperCase();
    let updated = false;
    let new_items = [];

    switch (action.type) {
        case resourceActions[`FETCHING_CONCRETE_${UPPERCASED}`]:
            return Object.assign({}, state, {
                isFetching: true
            });
        case resourceActions[`FETCHING_CONCRETE_${UPPERCASED}_SUCC`]:
            new_items = state.items.map( item => {
                if (item.id === action.id){
                    updated = true;
                    return Object.assign({}, item, action.obj);
                }
                return item;
            });
            if (!updated) {
                new_items.push(action.obj);
            }

            return Object.assign({}, state, {
                items: new_items,
                isFetching: false
            });

        case resourceActions[`FETCHING_CONCRETE_${UPPERCASED}_ERR`]:
            return Object.assign({}, state, {
                isFetching: false,
                err: action.err
            });
        default:
            return null;
    }
}

const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [ x[key] ]: x }), {});

function isGettingAction(resourceName, resourceActions, state, action) {
    let UPPERCASED = resourceName.toUpperCase();

    switch (action.type) {
        case resourceActions[`FETCHING_${UPPERCASED}`]:
            return Object.assign({}, state, {
                isFetching: true
            });
        case resourceActions[`FETCHING_${UPPERCASED}_SUCC`]:
            if (action.append === true) {
                let items = {
                    ...keyBy(state.items, 'id'),
                    ...keyBy(action.items, 'id')
                };

                return {
                    items: Object.values(items),
                    isFetching: false
                };
            } else {
                return Object.assign({}, state, {
                    items: action.items,
                    isFetching: false
                });
            }
        case resourceActions[`FETCHING_${UPPERCASED}_ERR`]:
            return Object.assign({}, state, {
                isFetching: false,
                err: action.err
            });
        default: 
            return null;
    }
}

function isDeletingAction(resourceName, resourceActions, state, action) {
    let UPPERCASED = resourceName.toUpperCase();
    let new_items = [];

    switch (action.type) {
        case resourceActions[`DELETING_${UPPERCASED}`]:
            return Object.assign({}, state, {
                isFetching: true
            });
        case resourceActions[`DELETING_${UPPERCASED}_SUCC`]:
             new_items = state.items.filter( obj => {
                 return obj.id !== action.id;
             });

             return Object.assign({}, state, {
                 isFetching: false,
                 err: false,
                 items: new_items
             });

        case resourceActions[`DELETING_${UPPERCASED}_ERR`]:
            // in case of non successful deletion,
            // the backend can mark the object as non active or deleted
            // and responds with updated object
            if (action.data) {
                const new_items = state.items.map(obj => {
                    if (obj.id !== action.id) {
                        return obj;
                    } else {
                        return Object.assign({}, obj, action.data);
                    }
                });

                return Object.assign({}, state, {
                    isFetching: false,
                    err: action.err,
                    items: new_items
                });
            } else return Object.assign({}, state, {
                isFetching: false,
                err: action.err
            });
        default: 
            return null;
    }
}

function isUpdatingAction(resourceName, resourceActions, state, action) {
    let UPPERCASED = resourceName.toUpperCase();
    let new_items = [];

    switch (action.type) {
        case resourceActions[`UPDATING_${UPPERCASED}`]:
            return Object.assign({}, state, {
                isFetching: true
            });
        case resourceActions[`UPDATING_${UPPERCASED}_SUCC`]:
            new_items = state.items.map(obj => {
                    if (obj.id !== action.id) {
                        return obj;
                    } else {
                        return Object.assign({}, obj, action.data);
                    }
                });
            return Object.assign({}, state, {items: new_items, isFetching: false});
        case resourceActions[`UPDATING_${UPPERCASED}_ERR`]:
            return Object.assign({}, state, {
                isFetching: false,
                err: action.err
            });
        default: 
            return null;
    }
}

function isCreatingAction(resourceName, resourceActions, state, action) {
    let UPPERCASED = resourceName.toUpperCase();

    switch (action.type) {
        case resourceActions[`CREATING_${UPPERCASED}`]:
            return Object.assign({}, state, {
                isFetching: true
            });
        case resourceActions[`CREATING_${UPPERCASED}_SUCC`]: {
            let new_items = state.items.slice();
            new_items.push(action.data);
            return {
                isFetching: false,
                items: new_items
            };
        }
        case resourceActions[`CREATING_${UPPERCASED}_ERR`]:
            return Object.assign({}, state, {
                isFetching: false,
                err: action.err
            });
        default: 
            return null;
    }
}

function unsetingErrorAction(resource_name, resourceActions, state, action) {
    if (action.type === resourceActions['UNSET_ERR'])
        return Object.assign({}, state, {err: null});
    else
        return null;
}

export default function(resource_name, initialState = {}) {
    return function (state = initialState, action) {
        
        const resourceActions = require(`actions/${resource_name}`).default;

        return isGettingConcreteAction(resource_name, resourceActions, state, action) ||
            isGettingAction(resource_name, resourceActions, state, action) ||
            isUpdatingAction(resource_name, resourceActions, state, action) ||
            isCreatingAction(resource_name, resourceActions, state, action) ||
            isDeletingAction(resource_name, resourceActions, state, action) ||
            unsetingErrorAction(resource_name, resourceActions, state, action) ||
            state;
    };
}
