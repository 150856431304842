import React from 'react';
import { withFormsy } from 'formsy-react';
import PT from 'prop-types';
import { Form, Row } from 'react-bootstrap';
import classNames from 'classnames';

const SK = 'é+ľščťžýáí';
const charToNums = string => {
    return string.split('').map(c => {
        if (SK.indexOf(c) > -1)
            return '' + SK.indexOf(c);
        return c;
    }).join('');
};

class CardInput extends React.Component {
    changeValue(val) {
        this.props.setValue(charToNums(val));
    }

    render() {
        const {props: P} = this;

        const errorMessage = P.errorMessage;
        const show_error = !P.isValid && (!P.isPristine || P.isFormSubmitted);

        return <Row className={'mb-2'}>
            <label className={
                classNames({
                    'col-form-label': true,
                    'col-sm-4': true,
                    'text-danger': Boolean(!P.isValid)
                })}>
                {P.label}
                {P.required ?
                    <span className={'required-symbol'}> *</span> : null
                }
            </label>
            <div className={'col-sm-8'}>
                <Form.Control
                    onChange={ e => this.changeValue(e.target.value)}
                    value={ P.value }
                    name={P.name}
                    id={P.name}
                    type={'text'}
                />
                { show_error ?
                    <span
                        className={'invalid-feedback'}
                        style={{display: 'block'}}
                    >
                        { errorMessage }
                    </span> : null
                }
            </div>
        </Row>;
    }
}

CardInput.propTypes = {
    checked: PT.bool,
};

CardInput.defaultProps = {
    checked: false,
};

export default withFormsy(CardInput);
