import React from 'react';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import NeedWritePerm from 'components/NeedPermission';
import PT from 'prop-types';
import {FormSelect} from 'components/CustomSelect';
import FormCheckBox from 'components/CheckBox';

class DefaultOperationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validationErrors: {},
            add_to_entities: false
        };
    }

    onSubmit(model) {
        this.setState({
            formSubmitted: true,
        });

        if (this.validateForm(model)){
            return;
        }

        let {entity_type, operation_type, ...obj} = model;

        let newObj = {
            number: obj.number,
            entity_type_id: entity_type ? entity_type.id : null,
            operation_type_id: operation_type ? operation_type.id : null,
            add_to_entities: this.state.add_to_entities,
        };

        this.props.onSubmit(newObj);
    }

    validateForm(values) {
        let validations = {
            operation_type: 'Zvoľte operáciu',
            number: 'Zvoľte poradie',
            entity_type: 'Zvoľte časť budovy',
        };

        let errors = {};
        Object.keys(validations).forEach( key => {
            if (!values[key]){
                errors[key] = validations[key];
            }
        });

        this.setState({
            validationErrors: errors
        });
        return (Object.keys(errors).length > 0);
    }

    render() {
        const { props:P, state:S } = this;

        let entityTypeValue = P.operation.entity_type_id
            ? P.entity_types.find(item => item.id === P.operation.entity_type_id)
            : null;

        let operationTypeValue = P.operation.operation_type_id ?
            P.operation_types.find(item => item.id === P.operation.operation_type_id) : null;

        let numberValue = P.operation.number ?? 1;

        return (
            <Modal
                show={P.show}
                className="input-form"
                onHide={P.onClose}
            >
                <Modal.Header>
                    <div
                        className="closeBox"
                        style={{ float: 'right', height: 25}}
                    >
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onValidSubmit={ obj => this.onSubmit(obj)}
                        onInvalidSubmit={ obj => this.onSubmit(obj)}
                        onChange={ values => this.validateForm(values)}
                        validationErrors={S.validationErrors}
                    >
                        <FormSelect
                            name='operation_type'
                            label='Operácia'
                            options={P.operation_types}
                            value={operationTypeValue}
                            getOptionValue={p => p.id}
                            getOptionLabel={p => p.name}
                            isClearable={false}
                        />
                        <Input
                            min={1}
                            type="number"
                            name="number"
                            label="Poradie"
                            value={String(numberValue)}
                        />
                        <FormSelect
                            name='entity_type'
                            label='Časť budovy'
                            options={P.entity_types}
                            value={entityTypeValue}
                            getOptionValue={p => p.id}
                            getOptionLabel={p => p.full_name}
                            isClearable={false}
                        />
                        <FormCheckBox
                            name="add_to_entities"
                            label="Pridať na všetky časti v celej budove"
                            value={S.add_to_entities}
                            onChange={ val => this.setState({
                                add_to_entities: val
                            })}
                        />
                        <Row>
                            <Col md={{span: 8, offset: 4}}>
                                <NeedWritePerm type="button" need={['operation_types']}>
                                    <Button
                                        disabled={S.buttonDisabled}
                                        type="submit"
                                        variant="success"
                                    >
                                        { P.updating ? 'Upraviť' : 'Vytvoriť'}
                                    </Button>
                                </NeedWritePerm>
                            </Col>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

DefaultOperationForm.propTypes = {
    operation: PT.object,
    operation_types: PT.arrayOf(PT.object).isRequired,
};

DefaultOperationForm.defaultProps = {
    operation: {},
    operation_types: [],
};

export default DefaultOperationForm;