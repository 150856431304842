import React, { PureComponent } from 'react';
import PT from 'prop-types';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import Textarea from 'components/TextArea';
//import { GithubPicker } from 'react-color';

class Form extends PureComponent {
	constructor(props){
		super(props);

		this.state = {
			color: props.defaultValues.color || '#FFF'
		};
	}

	handleColorChange(color){
		this.setState({
			color: color.hex
		});
	}

	render() {
		const { props:P } = this;
		const { show, updating = false } = P;

		if (show === false) {
			return null;
		}

		return (
			<Modal
				show={show}
				onHide={() => P.onClose()}
			>
				<Modal.Header>
					<div className={'closeBox'}>
						<MdClose
							className = {'closeButton'}
							onClick={() => P.onClose()}
							size={'1.5em'}
						/>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Formsy
						onValidSubmit={P.onSubmit}
					>
						<Input
							label="Názov"
							name="name"
							value={P.defaultValues.name || ''}
							required
						/>
						<Textarea
							label="Popis"
							name="description"
							value={P.defaultValues.description || ''}
						/>
						<Row>
							<Col
								md={{span: 7, offset: 3}}
								sm={{offset: 3}}
								lg={{offset: 3}}
							>
								<Button
									type="submit"
									variant="success"
								>
									{ updating ? 'Upraviť' : 'Vytvoriť' }
								</Button>
							</Col>
						</Row>
					</Formsy>
				</Modal.Body>
			</Modal>
		);
	}
}

Form.propTypes = {
	show: PT.bool.isRequired,
	onSubmit: PT.func.isRequired,
	updating: PT.bool.isRequired,
	onClose: PT.func.isRequired,
	defaultValues: PT.shape({
		name: PT.string,
		description: PT.string
	}),
};

Form.defaultProps = {
	defaultValues: {
		name: '',
		description: ''
	}
};

export default Form;
