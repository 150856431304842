import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {TiDelete} from 'react-icons/ti';

class CustomFileInput extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.state = {
            fileName: null,
        };
    }

    onFileLoad(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];

            this.setState({
                fileName: file.name
            });
            this.props.onChange(file);
        }
    }

    deleteFile() {
        this.inputRef.current.value = '';
        this.setState({fileName: null});
        this.props.onChange(null);
    }

    render() {
        const {props: P} = this;

        let labelComponent = null;

        if (this.state.fileName) {
            labelComponent = <label className={'file-label'}>{this.state.fileName}</label>;
        } else if (P.document) {
            labelComponent =
                <a target="_blank"
                   href={P.document}
                   rel="noopener noreferrer"
                >
                    Zobraziť
                </a>;
        }

        return <Row className={'mb-2'}>
            <label
                className={'col-form-label col-sm-4'}
            >
                {P.label}
            </label>
            <Col sm={8}>
                <Row>
                    <span style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Button
                            variant={'primary'}
                            style={{marginRight: '5px'}}
                            onClick={() => this.inputRef.current.click()}
                        >
                            {P.document ? 'Zmeniť' : 'Vybrať'}
                        </Button>
                        <input
                            ref={this.inputRef}
                            type="file"
                            style={{display: 'none'}}
                            onChange={e => this.onFileLoad(e)}
                            accept={P.accept || '*'}
                        />
                        {labelComponent}
                        {this.inputRef.current && this.inputRef.current.value &&
                            <TiDelete
                                color={'red'}
                                size={30}
                                style={{
                                    marginLeft: 5,
                                    marginBottom: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={() => this.deleteFile()}
                            />
                        }
                    </span>
                </Row>
            </Col>
        </Row>;
    }
}

export default CustomFileInput;