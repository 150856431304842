import store from 'store/mainStore';

export const ADD_DEPENDENCY = 'ADD_DEPENDENCY';

export function getDependencies(roleName) {
    const deps = store.getState().dependency;
    return roleName ? deps[roleName] : deps;
}

export function addDependency(roleName, arrOfDeps) {
    if (roleName === undefined || arrOfDeps === undefined) {
        throw new Error('addDependency expecting roleName and arrOfDeps');
    }
    store.dispatch({
        type: ADD_DEPENDENCY,
        roleName,
        arrOfDeps
    });
}