import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';

class ConfirmModal extends React.Component {

    acceptHandler() {
        this.props.onAccept && this.props.onAccept();
        this.props.onClose();
    }

    declineHandler() {
        this.props.onDecline && this.props.onDecline();
        this.props.onClose();
    }

    render() {
        const { props:P } = this;
        const { message } = this.props;
         
         return (
            <Modal
                show={P.show}
                onHide={P.onClose}
                onClose={P.onClose}
            >
                <Modal.Header>
                    <FaQuestion
                        size={30}
                        color="#ffa62b"
                    />
                    <div style={{
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        { P.title }
                    </div>
                </Modal.Header>
                
                { P.message && 
                    <Modal.Body >
                        <div>
                            <p>{ message }</p>
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="success" onClick={() => this.acceptHandler()}>
                        {P.acceptTitle}
                    </Button>
                    <Button variant="danger" onClick={() => this.declineHandler()}>
                        {P.declineTitle}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ConfirmModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func, // not required
    onClose: PropTypes.func,
    acceptTitle: PropTypes.string,
    declineTitle: PropTypes.string
};

ConfirmModal.defaultProps = {
    show: false,
    onClose: () => {},
    message: 'Potvrdzujete operáciu ?',
    onAccept: () => {},
    onDecline: () => {},
    acceptTitle: 'Ok',
    declineTitle: 'Zrušiť',
    title: 'Ste si istý, že chcete danú operáciu vykonať ?'
};

export default ConfirmModal;