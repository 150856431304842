import React from 'react';
import PT from 'prop-types';
import {Button, Col, Modal, Row, Tab} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import EmployeeOrderForm from './EmployeeOrderForm';
import {SuperTable} from 'components/PaginationTable';
import FormCheckBox from 'components/CheckBox';
import UrlTabs from 'components/UrlTabs';
import {Link, withRouter} from 'react-router-dom';
import call from 'ajax/call';
import NeedWritePerm from 'components/NeedPermission';
import './style.css';
import classNames from 'classnames';
import orderActions from 'actions/orders';
import ModalForm from 'components/ModalForm';
import {getNumber} from 'components/PaginationTable/TableRow';
import store from 'store/mainStore';
import products_actions from 'actions/products';
import ProductsForm from './ProductsForm';
import HiltiBudget from './HiltiBudget';
import getSelector from '../../MaterialOverview/typeSelector';

import Formsy from 'formsy-react';
import Input from 'components/Input';
import {formatDate} from 'scripts/dateFormatter';
import {Spinner} from 'react-activity';
import PropTypes from 'prop-types';
import NotificationsSettings from './NotificationsSettings';
import ordersActions from 'actions/orders';

class WorkCostsExportForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            useDateRange: false
        };
    }

    submitHandler(model) {
        this.setState({
            formSubmitted: true,
        });

        let route = this.props.url;
        if (this.state.useDateRange) {
            route = route + `/${model.from}/${model.to}`;
        }

        call(route, {
            method: 'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({selectedOrders: [this.props.order_id]}),
        }).then( () => {
            this.props.onClose(model);
            this.setState({formSubmitted: false});
        }).catch(e => console.log(e));
    }

    render() {
        const {props: P } = this;
        const max = formatDate(new Date());
        const min = '2010-01-01';

        return (
            <Modal
                onHide={ () => P.onClose() }
                show={P.show}>
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            //height: 25,
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        Export zo zákazky: <br/> {P.heading}
                        <MdClose
                            size={24}
                            onClick={ this.props.onClose }
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="register-form">
                        <Formsy
                            onValidSubmit={model => this.submitHandler(model)}
                        >
                            <FormCheckBox
                                name={'use_date_range'}
                                label={'Zvoliť dátumové ohraničenie'}
                                value={false}
                                id={'use_date_range'}
                                onChange={ val => this.setState({
                                    useDateRange: val
                                })}
                            />
                            {
                                this.state.useDateRange ? <div>
                                    <Input
                                        name={'from'}
                                        label={'Od'}
                                        type={'date'}
                                        required
                                        value={this.props.from}
                                        min={min}
                                        max={max}
                                    />
                                    <Input
                                        name={'to'}
                                        key={'to'}
                                        label={'Do'}
                                        type={'date'}
                                        required
                                        value={this.props.to}
                                        min={min}
                                        max={max}
                                    />
                                </div> : null
                            }
                            <Row className={'mt-2'}>
                                <Col md={{span: 4, offset: 4}}>
                                    <Button
                                        variant="danger"
                                        type="submit"
                                    >
                                        Exportovať
                                    </Button>
                                </Col>
                                <Col md={{span: 4, offset: 0}}>
                                    <Spinner animating={this.state.formSubmitted}/>
                                </Col>
                            </Row>
                        </Formsy>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

WorkCostsExportForm.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    url: PropTypes.string.isRequired,
    order_id: PropTypes.number.isRequired,
    from: PropTypes.string,
    to: PropTypes.string
};

WorkCostsExportForm.defaultProps = {
    onSubmit: () => {},
    show: false,
};


class View extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            employeeOrderFormActive: false,
            productFormActive: false,
            productUpdating: false,
            product: null,
            employeeOrder: {},
            updating: false,
            updating_id: 0,
            exportUrl: '',
        };
    }

    materialDocumentRowClickHandle(orderId, item) {
        this.props.history.push(`/orders/detail/${orderId}/${item.type}/${item.schema}/${item.document_id}/material_items`);
    }

    materialQuantityRowClickHandle(orderId, item) {
        this.props.history.push(`/orders/detail/${orderId}/${encodeURIComponent(item.code)}/documents`);
    }

    handleEmployeeOrderCreate() {
        this.setState({
            employeeOrderFormActive: true,
        });
    }

    handleEmployeeOrderSubmit(id, obj, updating) {
        this.props.onEmployeeOrderSubmit(id, obj, updating);
    }

    handleProductFormSubmit(model) {
        let {state: S} = this;
        let action = {};

        if (S.productUpdating) {
            action = products_actions.update(S.product.id, model);
        } else {
            model.order_id = this.props.data.id;
            action = products_actions.create(model);
        }

        store.dispatch(action).then( response => {
            if (response.status === 'ok') {
                this.setState({
                    productFormActive: false,
                    productUpdating: false,
                    product: null,
                });

                if (model.files.length > 0) {
                    let promises = [];

                    model.files.forEach(f => {
                        if (!f.saved) {
                            promises.push(this.sendFile(f, response.data.id));
                        }
                    });

                    // refresh the product model in redux
                    Promise.allSettled(promises).then(() => {
                        store.dispatch(products_actions.fetchConcrete(response.data.id));
                    });
                }
            }
        });
    }

    async sendFile(file, product_id) {
        let formData = new FormData();

        formData.append('product_id', product_id);
        formData.append('file', file, file.name);

        return await call(`/api/products/${product_id}/files`, {
            method: 'POST',
            body: formData
        });
    }

    sendUpdateRequest(attribute, value) {
        let order_id = this.props.data.id;
        let obj = {
            [attribute]: value
        };

        store.dispatch(ordersActions.didUpdateOrdersSucc(order_id, obj));

        call(`/api/orders/${order_id}`, {
            method: 'PUT',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({[attribute]: value})
        });
    }

    getStartDate() {
        return formatDate(this.props.data.start_date ?? '2010-01-01');
    }

    getEndDate() {
        return formatDate(this.props.data.end_date ?? new Date());
    }

    render() {
        const {props: P, state: S} = this;
        const total_time = P.data.total_worked_time || 0.0;
        let budget = P.data.hilti_budget;

        return (
            <div className="register-form">
                <div className={'info'}>
                    <Row style={{
                        marginBottom: 15,
                        marginTop: 15,
                    }}>
                        <Col xs={6} className="text-right">Zákazka</Col>
                        <Col xs={6} className="text-left">{P.data.serial_number} - {P.data.name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Budova</Col>
                        <Col xs={6} className="text-left"><Link
                            to={`/buildings/detail/${P.data.building_id}`}>{P.data.building_name}</Link></Col>
                    </Row>
                    {
                        P.data.comment ?
                        <Row>
                            <Col xs={6} className="text-right">Komentár</Col>
                            <Col xs={6} className="text-left">{P.data.comment}</Col>
                        </Row> : null
                    }
                    {
                        P.permissions.working_hours ?
                            <Row>
                                <Col xs={6} className="text-right">Celkový odpracovaný čas</Col>
                                <Col xs={6} className="text-left">{getNumber(total_time)} hodín</Col>
                            </Row> : null
                    }
                    <Row>
                        <Col xs={6} className="text-right">Stav zákazky</Col>
                        <Col xs={6} className="text-left">{P.data.state_name}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Externý príplatok</Col>
                        <Col xs={6} className="text-left">{
                            P.data.bonus ? <FaCheck/> : <MdClose/>
                        }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Aktívna</Col>
                        <Col xs={6} className="text-left">{
                            P.data.active ? <FaCheck/> : <MdClose/>
                        }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Vyžadovať operácie</Col>
                        <Col xs={6} className="text-left">{
                            P.data.requires_operations ? <FaCheck/> : <MdClose/>
                        }
                        </Col>
                    </Row>
                    {
                        P.data.hilti_budget ?
                        <Row>
                            <Col xs={6} className="text-right">Hilti rozpočet</Col>
                            <Col xs={6} className="text-left">{
                                <Col xs={6} className="text-left">{getNumber(P.data.hilti_budget)} €</Col>
                            }
                            </Col>
                        </Row> : null
                    }
                    {
                        P.data.note ?
                            <Row>
                                <Col xs={6} className="text-right">Poznámka</Col>
                                <Col xs={6} className="text-left">
                                    {P.data.note.split('\n').map( (line, i) => (<p key={i}>{line}</p>))}
                                </Col>
                            </Row> : null
                    }
                    <Row>
                        <Col xs={12} className="text-center" style={{marginTop: 25, marginBottom: 25}}>
                            <NeedWritePerm type="button" need={['orders']}>
                                <Button
                                    variant="success"
                                    onClick={() => this.setState({formActive: true})}
                                >
                                    Upraviť
                                </Button>
                            </NeedWritePerm>
                            <NeedWritePerm
                                type={'button'}
                                need={['is_accountant']}
                            >
                                <Button
                                    variant="danger"
                                    onClick={() => this.setState({
                                        exportFormActive: true,
                                        exportUrl: '/api/orders/expenses_export',
                                    })}
                                    style={{marginLeft: 40}}
                                >
                                    Export nákladov
                                </Button>
                            </NeedWritePerm>
                            <NeedWritePerm
                                type={'button'}
                                need={['material', 'material_prices']}
                            >
                                <Button
                                    variant="danger"
                                    onClick={() => this.setState({
                                        exportFormActive: true,
                                        exportUrl: '/api/orders/material_export',
                                    })}
                                    style={{marginLeft: 40}}
                                >
                                    Export materiálu
                                </Button>
                            </NeedWritePerm>
                        </Col>
                        <ModalForm
                            show={S.formActive}
                            formElements={[
                                {name: 'name', label: 'Názov', type: 'text', required: true},
                                {name: 'serial_number', label: 'Sériové číslo', type: 'text', required: true},
                                {
                                    name: 'building',
                                    label: 'Budova',
                                    type: 'select',
                                    options: {items: P.buildings},
                                    selectKey: 'building_id',
                                    required: true
                                },
                                {name: 'comment', label: 'Komentár', type: 'text'},
                                {name: 'bonus', label: 'Externý príplatok', type: 'checkbox'},
                                {name: 'active', label: 'Aktívna', type: 'checkbox'},
                                {name: 'requires_operations', label: 'Vyžadovať operácie', type: 'checkbox'},
                                {name: 'hilti_budget', label: 'Hilti rozpočet', type: 'number', min: 0, step: 0.01},
                            ]}
                            action={orderActions}
                            afterSave={() => this.setState({formActive: false, objectToUpdate: undefined})}
                            onClose={() => this.setState({formActive: false, objectToUpdate: undefined})}
                            updatingObj={P.data}
                        />
                    </Row>
                </div>
                    <UrlTabs>
                        {P.permissions['working_hours'] ?
                            (<Tab eventKey={'work_summary'} title="Odpracovaný čas">
                                <SuperTable
                                    name={'work_summary'}
                                    thead={[
                                        {name: 'full_name', sk_name: 'Meno', type: 'string'},
                                        {
                                            name: 'work_sum',
                                            sk_name: 'Hodiny celkovo',
                                            type: 'number',
                                            bold: true,
                                            search: false
                                        },
                                    ]}
                                    columnselector
                                    items={P.work_summary}
                                    defaultSortKey={'full_name'}
                                    searchable
                                    onRowClick={id => this.props.onShowEmployee(id)}
                                    is_loading={P.isLoading}
                                />
                            </Tab>)
                            : null
                        }
                        {
                            <Tab eventKey={'material-quantity'} title="Množstvo materiálu">
                                <h5 className={classNames({'text-center': true, 'mt-5': true})}>
                                    Súhrn materiálu na zákazke {P.data.serial_number}
                                </h5>
                                <SuperTable
                                    name={'material-quantity'}
                                    thead={[
                                        {name: 'image_url', sk_name: 'foto', type: 'image', sort: false, search: false},
                                        {name: 'code', sk_name: 'Kód', type: 'string', sort: true, search: true},
                                        {name: 'name', sk_name: 'Položka', type: 'string', sort: true, search: true},
                                        {name: 'isproduct', sk_name: 'Výrobok', type: 'boolean', sort: false, search: false},
                                        {name: 'received_order', sk_name: 'Objednávka prijatá', type: 'number', sort: true, search: false},
                                        {name: 'issued_order', sk_name: 'Objednávka vydaná', type: 'number', sort: true, search: false},
                                        {name: 'stock_receipt', sk_name: 'Príjemka na sklad', type: 'number', sort: true, search: false},
                                        {name: 'delivery_note', sk_name: 'Vyskladnené', type: 'number', sort: true, search: false},
                                        {name: 'delivery_returned', sk_name: 'Vrátené', type: 'number', sort: true, search: false},
                                        {name: 'issued_into_production', sk_name: 'Vydané do výroby', type: 'number', sort: true, search: false},
                                        {name: 'returned_from_production', sk_name: 'Vrátené z výroby', type: 'number', sort: true, search: false},
                                        {name: 'transfer_outgoing', sk_name: 'Prevodka výdaj', type: 'number', sort: true, search: false, hidden: true},
                                        {name: 'transfer_receipt', sk_name: 'Prevodka príjem', type: 'number', sort: true, search: false, hidden: true},
                                        {name: 'inventory_surplus', sk_name: 'Inventárny prebytok', type: 'number', sort: true, search: false, hidden: true},
                                        {name: 'inventory_deficit', sk_name: 'Inventárne manko', type: 'number', sort: true, search: false, hidden: true},
                                        {name: 'receipt_returned', sk_name: 'Vratka príjemky', type: 'number', sort: true, search: false, hidden: true},

                                        {name: 'stored', sk_name: 'Na sklade', type: 'number', sort: true, search: false},
                                        {name: 'unit', sk_name: 'Jednotka', type: 'string', sort: true, search: false},
                                    ]}
                                    items={P.material_quantity}
                                    onRowClick={id => {
                                        this.materialQuantityRowClickHandle(P.data.id, P.material_quantity.find(x => x.id === id));
                                    }}
                                    export={'Súhrn materiálu na zákazke ' + P.data.serial_number}
                                    searchable
                                    striped
                                    is_loading={P.isLoading}
                                    rowStyleFn={column => column === 'name' ? {whiteSpace: 'normal'} : {}}
                                    columnSelector={true}
                                />
                            </Tab>
                        }
                        {
                            <Tab eventKey={'material-documents'} title="Pohyb materiálu">
                            <h5 className={classNames({'text-center': true, 'mt-5': true})}>
                                História pohybov materiálu na zákazke {P.data.serial_number}
                            </h5>
                            <SuperTable
                                name={'material-documents'}
                                thead={[
                                    {name: 'date', sk_name: 'Dátum', type: 'short_date'},
                                    {name: 'time', sk_name: 'Čas', type: 'time'},
                                    {name: 'document_code', sk_name: 'Číslo dokladu', type: 'string'},
                                    {name: 'name', sk_name: 'Popis', type: 'string'},
                                    {name: 'type_name', sk_name: 'Typ', type: 'string'},
                                    {name: 'store', sk_name: 'Sklad', type: 'string'},
                                    {name: 'firm_name', sk_name: 'Firma', type: 'string', hidden: true},
                                ]}
                                items={P.material_documents}
                                onRowClick={id => {
                                    this.materialDocumentRowClickHandle(P.data.id, P.material_documents.find(x => x.id === id));
                                }}
                                export={'História pohybov materiálu ' + P.data.serial_number}
                                searchable
                                striped
                                is_loading={P.isLoading}
                                selectors={[getSelector()]}
                                rowStyleFn={column => column === 'store' ? {whiteSpace: 'normal'} : {}}
                                columnSelector={true}
                            />
                            </Tab>
                        }
                        {
                            <Tab eventKey={'employee-order'} title="Zamestnanci zodpovední za zákazku">
                                <Button
                                    style={{marginTop: 15}}
                                    onClick={() => this.handleEmployeeOrderCreate()}
                                    variant="success"
                                >
                                    Pridať
                                </Button>
                                <SuperTable
                                    name={'order-employee-order'}
                                    thead={[
                                        {name: 'full_name', sk_name: 'Meno a priezvisko', type: 'string'},
                                        {name: 'date_from', sk_name: 'Od', type: 'date'},
                                        {name: 'date_to', sk_name: 'Do', type: 'date'},
                                    ]}
                                    onEditItem={(id) => this.setState({
                                        employeeOrder: P.employeeOrder.find(eo => eo.id === id),
                                        employeeOrderFormActive: true,
                                    })}
                                    onDeleteItem={id => P.onEmployeeOrderDelete(id)}
                                    editOps
                                    delOps
                                    items={P.employeeOrder}
                                    searchable
                                    is_loading={P.isLoading}
                                />
                                <EmployeeOrderForm
                                    show={S.employeeOrderFormActive}
                                    onSubmit={(id, obj, updating) => this.handleEmployeeOrderSubmit(id, obj, updating)}
                                    onClose={() => this.setState({
                                        employeeOrder: {},
                                        employeeOrderFormActive: false
                                    })}
                                    order={P.data}
                                    updating={Object.keys(S.employeeOrder).length > 0}
                                    employees={P.employees}
                                    objectToUpdate={S.employeeOrder}
                                />
                            </Tab>
                        }
                        {
                            <Tab eventKey={'products'} title={'Výrobky'}>
                                <Button
                                    style={{marginTop: 15}}
                                    onClick={() => this.setState({
                                        productFormActive: true,
                                        productUpdating: false,
                                    })}
                                    variant="success"
                                >
                                    Pridať
                                </Button>
                                <SuperTable
                                    name={'order-products'}
                                    thead={[
                                        {name: 'name', sk_name: 'Meno', type: 'string'},
                                        {name: 'comment', sk_name: 'Poznámka', type: 'string'},
                                        {name: 'created_at', sk_name: 'Vytvorené', type: 'date-time'},
                                        {name: 'created_by_name', sk_name: 'Vytvoril', type: 'string'},
                                    ]}
                                    onEditItem={(id) => this.setState({
                                        productFormActive: true,
                                        product: P.products.find( p => p.id === id),
                                        productUpdating: true,
                                    })}
                                    onDeleteItem={id => store.dispatch(products_actions.delete(id))}
                                    editOps
                                    delOps
                                    items={P.products}
                                    searchable
                                    is_loading={P.isLoading}
                                />
                                <ProductsForm
                                    key={S.product ? S.product.id : 'new_id'}
                                    show={S.productFormActive}
                                    updating={S.productUpdating}
                                    onClose={() => this.setState({
                                        productFormActive: false,
                                        productUpdating: false,
                                        product: null,
                                    })}
                                    objectToUpdate={S.product}
                                    onSubmit={model => this.handleProductFormSubmit(model)}
                                >
                                </ProductsForm>
                            </Tab>
                        }
                        {
                            budget ? <Tab eventKey={'hilti_budget'} title={'Hilti rozpočet'}>
                                <HiltiBudget
                                    serial_number={P.data.serial_number}
                                    hilti_budget={budget}
                                    order_id={P.data.id}
                                />
                            </Tab> : null
                        }
                        {
                            P.permissions.time_notifications > 0 ?
                                <Tab title={'Notifikácie z dochádzky'} eventKey={'notifications'}>
                                    <NotificationsSettings
                                        total_time={total_time}
                                        time_budget={P.data.time_budget}
                                        order_id={P.data.id}
                                        time_notifications={P.data.time_notifications}
                                        onSettingsChange={(attribute, value) => this.sendUpdateRequest(attribute, value)}
                                        send_periodic_notifications={P.data.send_periodic_notifications}
                                        send_percentage_notifications={P.data.send_percentage_notifications}
                                    />
                                </Tab> : null
                        }
                    </UrlTabs>
                <WorkCostsExportForm
                    show={S.exportFormActive}
                    onClose={() => this.setState({
                        exportFormActive: false
                    })}
                    url={S.exportUrl}
                    heading={P.data.serial_number + ' ' + P.data.name}
                    order_id={P.data.id}
                    from={this.getStartDate()}
                    to={this.getEndDate()}
                />
            </div>
        );
    }
}

View.propTypes = {
    data: PT.object.isRequired,
    work_summary: PT.arrayOf(PT.object).isRequired,
    material_quantity: PT.arrayOf(PT.object).isRequired,
    material_documents: PT.arrayOf(PT.object).isRequired,
    employeeOrder: PT.arrayOf(PT.object).isRequired,
    products: PT.arrayOf(PT.object).isRequired,
    onEmployeeOrderSubmit: PT.func.isRequired,
    onEmployeeOrderDelete: PT.func.isRequired,
    employees: PT.arrayOf(PT.object).isRequired,
    permissions: PT.object.isRequired,
    onShowEmployee: PT.func,
    isLoading: PT.bool.isRequired,
};

View.defaultProps = {
    work_summary: [],
};

export default withRouter(View);
