import React from 'react';
import {Button, Modal, Row} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import FormCheckBox from 'components/CheckBox';
import PT from 'prop-types';
import SubscriptionForm from './SubscriptionForm';

class WorkingHoursSubscriptionForm extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            active: true,
            value: '',
            notify_database: true,
            notify_email: false,
            notify_sms: false,
        };

        this.state = this.initialState;
    }

    componentDidUpdate(prevProps) {
        const {props: P} = this;
        if (prevProps.subscription !== P.subscription && P.subscription) {
            this.setState({
                active: P.subscription.active,
                value: P.subscription.value,
                notify_database: P.subscription.notify_database,
                notify_email: P.subscription.notify_email,
                notify_sms: P.subscription.notify_sms,
            });
        }
    }

    handleChangeValue(value, name) {
        let val;
        if (typeof value === 'object' && value !== null) {
            val = value.id;
        } else {
            val = value;
        }
        this.setState({
            [name]: val,
        });
    }

    handleHide() {
        this.props.onClose();
    }

    handleSubmit(values) {
        this.setState({
            formSubmitted: true,
        });

        let newObj = {
            ...values,
            name: 'Kontrola dochádzky',
            model_class: 'App\\Models\\WorkingHour',
            attribute: 'start',
            value_type: 'days',
        };

        this.props.onSubmit(newObj);
    }

    resetState() {
        this.setState({
            filterUpdated: false,
        });
    }

    render() {
        const {props: P, state: S} = this;

        return (
            <Modal
                size="lg"
                show={P.show}
                onHide={() => this.handleHide()}
                onExited={() => this.resetState()}
            >
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: 25,
                            width: '100%',
                        }}
                    >
                        {P.updating ? 'Úprava' : 'Vytváranie'} notifikácie na kontrolu dochádzky
                        <MdClose
                            size={24}
                            onClick={() => this.handleHide()}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Formsy
                        onSubmit={model => this.handleSubmit(model)}
                        formNoValidate
                    >
                        <FormCheckBox
                            name={'active'}
                            label={'Aktívne'}
                            value={S.active}
                            onChange={value => this.handleChangeValue(value, 'active')}
                        />
                        <Input
                            type={'number'}
                            name={'value'}
                            label={'Počet dní pred koncom mesiaca'}
                            required
                            min={0}
                            max={31}
                            value={S.value}
                            onChange={value => this.handleChangeValue(value, 'value')}
                        />
                        <FormCheckBox
                            name={'notify_database'}
                            label={'Notifikácia'}
                            value={S.notify_database}
                            onChange={value => this.handleChangeValue(value, 'notify_database')}
                        />
                        <FormCheckBox
                            name={'notify_email'}
                            label={'Email'}
                            value={S.notify_email}
                            onChange={value => this.handleChangeValue(value, 'notify_email')}
                        />
                        <FormCheckBox
                            name={'notify_sms'}
                            label={'SMS'}
                            value={S.notify_sms}
                            onChange={value => this.handleChangeValue(value, 'notify_sms')}
                        />
                        <Row style={{marginTop: 15}}>
                            <Button
                                onClick={() => this.handleHide()}
                                style={{
                                    marginLeft: 15,
                                    marginRight: 15,
                                }}
                            >
                                Zavrieť
                            </Button>
                            <Button
                                type={'submit'}
                                variant={'success'}
                            >
                                {P.updating ? 'Upraviť' : 'Vytvoriť'}
                            </Button>
                        </Row>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

SubscriptionForm.propTypes = {
    subscription: PT.object,
    show: PT.bool.isRequired,
    updating: PT.bool.isRequired,
    onSubmit: PT.func.isRequired,
    onClose: PT.func.isRequired,
};

SubscriptionForm.defaultProps = {
    subscription: {},
    show: false,
    updating: false,
    onSubmit: () => {},
    onClose: () => {},
};

export default WorkingHoursSubscriptionForm;