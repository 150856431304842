import {Draggable, Droppable} from 'react-beautiful-dnd';
import Tooltip from 'components/ToolTip';
import DF from 'scripts/dateFormatter';
import {AiOutlineRightCircle} from 'react-icons/ai';
import {BsReplyFill} from 'react-icons/bs';
import React from 'react';

function EmployeeItem({
                      name,
                      icon,
                      onClick = () => {},
                      isWorking = false,
                      onShowHistory = () => {}
}) {
    return(
        <div>
        <span className="group-icon">
            <img src={icon}
                 height={100}
                 alt="obrázok"
                 onClick={onShowHistory}
            />
        </span>
            <div className={'title'} >
                { name }
                {isWorking ? null :
                    <BsReplyFill
                        size={'1.5em'}
                        className={'play-icon'}
                        onClick={onClick}
                        style={{
                            marginLeft: 5
                        }}
                    />
                }
            </div>
        </div>
    );
}

class Row extends React.Component {

    getBackground(group_id, emp_id){
        if (group_id.slice(6) === emp_id){
            return ({
                backgroundColor: 'rgba(0,38,150,0.54)',
                color: '#ffffff',
            });
        } else {
            return ({backgroundColor: '#FFF'});
        }
    }

    render() {
        let {
            group_id,
            is_working,
            heading,
            scrollbarWidth,
            work_interval,
            employee_map,
            group,
            assignable,
            withDropZone
        } = this.props;

        let assignmentsButtons = null;
        if (assignable) {
            if (is_working){
                assignmentsButtons = (<Tooltip
                    id={group_id}
                    text={
                        <div>
                            {'Skupina momentálne pracuje na pozícii: '} <br/>
                            {work_interval.position  + ' - ' + work_interval.operation_name}<br/>
                            {' od ' + DF(work_interval.started_at)}
                        </div>
                    }
                    style={{
                        position: 'absolute',
                        right: 30 + scrollbarWidth,
                        marginRight: 15,
                        marginTop: 10,
                    }}
                />);
            } else {
                assignmentsButtons = (
                    <div
                        style={{
                            position: 'absolute',
                            right: 30 + scrollbarWidth,
                            height: 130,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                        }}
                    >
                        <AiOutlineRightCircle
                            size={'2em'}
                            className={'play-icon'}
                            onClick={() => this.props.onAssignGroupToPosition()}
                            style={{
                                display: 'block',
                                marginRight: 6,
                                marginTop: 6,
                            }}
                        />
                        <BsReplyFill
                            size={'1.5em'}
                            className={'play-icon'}
                            onClick={() => this.props.onAssignGroupToMaster()}
                            style={{
                                //position: 'absolute',
                                display: 'block',
                                marginRight: 6,
                                marginBottom: 6,
                            }}
                        />
                    </div>
                );
            }
        }

        let dropZone = null;
        if (withDropZone){
            dropZone = (
                <Droppable
                    droppableId={'add_new_group' + group_id}
                    direction='horizontal'
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={'new_group'}
                        >
                            {
                                provided.placeholder
                            }
                        </div>
                    )}
                </Droppable>
            );
        }

        return (
            <div key={`droppable-${group_id}`}>
                { dropZone }
                <h5 style={{
                    margin: '0 15px',
                }}>{heading}</h5>
                <Droppable
                    key={group_id}
                    droppableId={group_id}
                    direction='horizontal'
                    isDropDisabled={is_working}
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            className={'my-droppable'}
                            style={{
                                backgroundColor: is_working
                                    ? 'rgb(238,238,238)'
                                    : 'rgba(184,209,255,0.51)',

                            }}
                            {...provided.droppableProps}
                        >
                            {group.map((id, index) => {
                                const emp = employee_map.get(id);
                                return (
                                    <Draggable
                                        key={emp.id}
                                        draggableId={String(emp.id)}
                                        index={index}
                                        isDragDisabled={is_working}
                                    >
                                        {(provided) => (
                                            <div
                                                className="group-item trans"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    ...this.getBackground(group_id, String(emp.id)),
                                                    //backgroundColor: is_working ? '#e20000' : '#82B8D1FF'
                                                }}
                                            >
                                                <EmployeeItem
                                                    name={emp.full_name}
                                                    icon={emp.thumbnail}
                                                    isWorking={is_working}
                                                    onClick={ () => this.props.onAssignEmpToMaster(id) }
                                                    onShowHistory={ () => this.props.onShowEmpHistory(id) }
                                                />
                                            </div>
                                        )}
                                    </Draggable>);
                            })}
                            {provided.placeholder}
                            { assignmentsButtons }
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}

export default Row;