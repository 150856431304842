import React from 'react';
import {connect} from 'react-redux';

import store from 'store/mainStore';
import employeesTypes from 'actions/employee_types';
import unlockedActions from 'actions/unlocked_employees';
import call from 'ajax/call';

import View from 'components/MonthChooser';
import {Button, Container} from 'react-bootstrap';
import Tern from 'components/Or';
import 'url-search-params-polyfill';
import {addDependency} from 'actions/dependency';
import {MdFileDownload} from 'react-icons/md';
import {SuperTable} from 'components/PaginationTable';
import {updateQueryParams} from 'scripts/queryFunctions';
import './style.css';

class WorkingHours extends React.Component {

    constructor(props) {
        super(props);

        const url = new URLSearchParams(props.location.search);
        const year = url.get('year') || 0;
        const month = url.get('month') || 0;
        const dateNow = new Date();

        this.state = {
            month: month || dateNow.getMonth() + 1,
            year: year || (dateNow.getFullYear()),
        };
    }

    componentDidMount() {
        store.dispatch(employeesTypes.fetchAll());
        store.dispatch(unlockedActions.fetchAll({
            year: this.state.year,
            month: this.state.month
        }));
    }

    onDateChange(obj) {
        store.dispatch(unlockedActions.fetchAll({
            month: obj.month,
            year: obj.year
        }));

        this.setState({
            year: obj.year,
            month: obj.month
        }, () => {
            const {location, history} = this.props;

            updateQueryParams(location, history, {
                year: obj.year,
                month: obj.month,
            });
        });
    }

    handleClickUnlocked(id) {
        this.props.history.push(`/attendance/${id}-${this.state.month}-${this.state.year}`);
    }

    fetchFile() {
        const {month, year} = this.state;

        call(`/api/working_hours/export/${year}/${month}`)
            .catch(err => {
                console.log('something bad happened', err);
            });
    }

    fetchYear() {
        const {year} = this.state;

        call(`/api/working_hours/year_export/${year}`)
            .catch(err => {
                console.log('something bad happened', err);
            });
    }

    selectorFilterFuncGenerator(attribute, desired_value) {
        return function (obj) {
            return obj[attribute] === desired_value;
        };
    }

    render() {
        const {state: S, props: P} = this;
        const isAccountant = P.permissions['is_accountant'] === 2;
        const isLogistics = P.permissions['is_logistics'] === 2;
        const isForPrivateView = P.permissions['working_hours'] === 5;

        let employees = P.employees.filter(e => {
            return (isAccountant || e.should_show);
        }).map(e => ({
            ...e,
            highlighted: Boolean(e.approved),
        }));

        let selector_values = [];
        this.props.employeesTypes.forEach(item => {

            selector_values.push({
                value: item.id,
                name: item.name,
                filter: this.selectorFilterFuncGenerator('type_id', item.id)
            });
        });

        selector_values.push({
            value: 0,
            name: 'Zobraziť všetkých',
            filter: () => true
        });

        let selectors = [
            {
                name: 'type_selector',
                default_value: 0,
                values: selector_values
            }];

        if (isForPrivateView) {
            selectors = [];
        }

        return (
            <div className="working-hours">
                <Container>
                    <h3> Dochádzka </h3>
                    <View
                        year={String(S.year)}
                        month={String(S.month)}
                        onDateChange={obj => this.onDateChange(obj)}
                    />
                    <div>
                        <Tern expression={isAccountant}>
                            <div>
                                <Button
                                    style={{marginBottom: 15, marginRight: 15, display: 'inline-block'}}
                                    variant="success"
                                    onClick={() => this.fetchFile()}
                                >
                                    <MdFileDownload/> Mesačný export
                                </Button>
                                <Button
                                    style={{marginBottom: 15, marginRight: 15, display: 'inline-block'}}
                                    variant="success"
                                    onClick={() => this.fetchYear()}
                                >
                                    <MdFileDownload/> Ročný export
                                </Button>
                            </div>
                        </Tern>
                        <Tern expression={isLogistics}>
                            <Button
                                style={{marginBottom: 15, marginRight: 15, display: 'inline-block'}}
                                variant="danger"
                                onClick={() => this.props.history.push('/attendance/cars/')}
                            >
                                Služobné cesty - autá
                            </Button>
                        </Tern>
                    </div>
                    <SuperTable
                        name={'employees'}
                        items={employees}
                        defaultSortKey="employee_fullname"
                        thead={[
                            {name: 'employee_full_name', sk_name: 'Meno zamestnanca', type: 'string'},
                            {name: 'type', sk_name: 'Typ', type: 'string'},
                            {name: 'master_full_name', sk_name: 'Meno majstra', type: 'string', search: false},
                            {name: 'sum', sk_name: 'Hodiny', type: 'seconds'},
                            {name: 'approved', sk_name: 'Schvalený', type: 'boolean'},
                            {name: 'comment', sk_name: 'Poznámka', type: 'string'},
                        ]}
                        columnSelector
                        onRowClick={id => this.handleClickUnlocked(id)}
                        selectors={selectors}
                        searchable={!isForPrivateView}
                        is_loading={P.isLoading}
                        highlighting={true}
                    />
                </Container>
            </div>
        );
    }
}

addDependency('working_hours', ['webapp_access', 'employees', 'orders', 'cars']);

const mapStateToProps = state => {
    return {
        employees: state.unlockedEmployees.items,
        isLoading: state.unlockedEmployees.isFetching
            || state.employeeTypes.isFetching
            || state.permissions.isFetching,
        employeesTypes: state.employeeTypes.items,
        permissions: state.permissions,
    };
};

export default connect(mapStateToProps)(WorkingHours);
