import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import InvertedCheckBox from '../InvertedCheckBox';
import {MdClose} from 'react-icons/md';
import Formsy from 'formsy-react';

class ChooseColumns extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            validationErrors: {},
        };
    }

    handleFormSubmit(model) {
        if (!this.validateForm(model)) {
            return false;
        }

        this.setState({
            formSubmitted: true
        });

        let newColumns = [...this.props.columns];

        Object.keys(model).forEach((name) => {
            const value = model[name];
            const index = this.props.columns.findIndex(element => element.name === name);
            newColumns[index] = {
                ...newColumns[index],
                hidden: !value
            };
        });

        this.props.onSubmit(newColumns);
    }

    validateForm(model) {
        let errors = {};

        const someSelected = Object.values(model).some((value) => value);
        if (!someSelected) {
            errors['someSelected'] = 'Vyberte aspoň jeden stĺpec';
        }

        this.setState({
            validationErrors: errors
        });

        return someSelected;
    }

    render() {
        const {props: P, state: S} = this;

        return (
            <Modal
                show={P.show}
                onHide={P.onClose}
                size={'lg'}
            >
                <Modal.Header>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            fontSize: '14pt',
                        }}
                    >
                        Vybrať stĺpce
                        <MdClose
                            className={'closeButton'}
                            size={24}
                            onClick={P.onClose}/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(S.validationErrors).map((key) =>
                        <span className="invalid-feedback" style={{display: 'block'}} key={key}>
                            {S.validationErrors[key]}
                        </span>
                    )}
                    <Formsy
                        onValidSubmit={model => this.handleFormSubmit(model)}
                        onChange={values => this.validateForm(values)}
                    >
                        <Row key={'row-columns'}>
                            {
                                P.columns && P.columns
                                    .map(c => (
                                        <Col sm={6} key={c.name}>
                                            <InvertedCheckBox
                                                style={{marginLeft: '100px'}}
                                                name={c.name}
                                                label={c.sk_name}
                                                value={P.selectedColumns.some(sc => sc.name === c.name)}
                                            />
                                        </Col>
                                    ))
                            }
                        </Row>
                        <div style={{
                            marginTop: 20,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Button
                                variant="success"
                                type="submit"
                            >
                                Potvrdiť
                            </Button>
                            <Button
                                variant="success"
                                type={'button'}
                                onClick={() => this.props.onClose() }
                            >
                                Zrušiť
                            </Button>
                        </div>
                    </Formsy>
                </Modal.Body>
            </Modal>
        );
    }
}

ChooseColumns.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    selectedColumns: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
};

export default ChooseColumns;
