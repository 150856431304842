import React from 'react';
import { Link } from 'react-router-dom';

class NotFoundPage extends React.Component{
    render(){
        return <div style={{
            fontSize: '32pt',
            fontFamily: 'Arial',
            textAlign: 'center',
            marginTop: '100px',
            fontWeight: 'bold',
        }}>
            Stránka nenájdená
            <div>
                <Link
                    to={'/'}
                    style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        marginTop: '100px'
                    }}
                >
                    Späť na hlavnú stránku
                </Link>
            </div>
        </div>;
    }
}

export default NotFoundPage;