import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

import store from 'store/mainStore';
import MonthView from './MonthView';
import getHolidays from 'scripts/getHolidays';
import { getmonthName } from 'scripts/dateFormatter';
import my_operations_actions from 'actions/my_operations';
import approvalActions from 'actions/approvals';
import { getApproval, checkParams } from '../../WorkingHours/Detail/monthViewUtils';
import operation_types_actions from 'actions/work_operations';
import employees_actions from 'actions/employees';
import View from 'components/MonthChooser';
import my_group_history_actions from 'actions/my_group_history';

class Detail extends React.Component {

	constructor(props) {
		super(props);

		checkParams(props.match.params.id);
		const [id, month, year] = props.match.params.id.split('-');

		this.state = {
			id: parseInt(id, 10),
			month: parseInt(month, 10),
			year: parseInt(year, 10),
		};
	}

	canMakeActions() {
		const { state: S, props: P } = this;

		if (P.permissions['operations'] === 5) {
			return false;
		}

		const is_approved = getApproval(P.approvals, S.year, S.month, S.id, 2) !== undefined;
		return (P.permissions['operations'] === 2) && !is_approved;
	}

	componentDidMount() {
		const [id, month, year] = this.props.match.params.id.split('-');

		store.dispatch(my_operations_actions.fetchAll({
			employee_id: id,
			month,
			year
		}));

		store.dispatch(approvalActions.fetchAll({
			year: year,
			month: month,
			type: 2
		}));

		store.dispatch(operation_types_actions.fetchAll());
		store.dispatch(employees_actions.fetchAll());
		store.dispatch(my_group_history_actions.fetchAll(id));
	}

	getEmployeeName(id){
		let employee = this.props.employees.find((e) => e.id === id);
		if (employee){
			return employee.full_name;
		}
		return '';
	}

	onDateChange(obj){
		const { state: S } = this;

		store.dispatch(my_operations_actions.fetchAll({
			year: obj.year,
			month: obj.month,
			employee_id: S.id,
		}));

		store.dispatch(approvalActions.fetchAll({
			year: obj.year,
			month: obj.month,
			type: 2
		}));

		this.setState({...obj});
		this.props.history.replace(`/my_operations/${S.id}-${obj.month}-${obj.year}`);
	}

	onSubmitAll(obj) {
		const S = this.state;
		store.dispatch(
			approvalActions.create({
				year: S.year,
				month: S.month,
				comment: obj.comment,
				employee_id: S.id,
				approval_type: 2,
			})
		);
	}

	render() {
		const { props: P, state: S } = this;
		let approval = getApproval(P.approvals, S.year, S.month, S.id, 2);
		let is_approved = approval !== undefined;

		return (
			<Container className="working-hour-detail">
				<div>
					<h3> Pracovné operácie: </h3>
					<h3>
						{this.getEmployeeName(S.id)} - {getmonthName(S.month)} {S.year}
					</h3>
				</div>
				<View
					year={String(S.year)}
					month={String(S.month)}
					onDateChange={obj => this.onDateChange(obj)}
				/>
				<MonthView
					isFullTimeEmployee={P.identity.type_id === 2}
					canMakeActions={this.canMakeActions()}
					operations={P.my_operations}
					entity_performance={P.entity_performance}
					entity_operations={P.entity_operations}
					holidays={getHolidays(S.year, S.month - 1)}
					employee_id={S.id}
					is_loading={P.is_loading}
					permissions={P.permissions}
					approval={approval}
					operation_types={P.operation_types}
					group_history={P.group_history}
					isApproved={is_approved}
					show_approval={true}
					onSubmitAll={obj => this.onSubmitAll(obj)}
					onDeleteApproval={id => store.dispatch(approvalActions.delete(id))}
					{...S}
				/>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {

	let is_loading = state.employees.isFetching
		|| state.workOperations.isFetching
		|| state.my_operations.isFetching
		|| state.permissions.isFetching
		|| state.identity.isFetching
		|| state.approvals.isFetching
	;

	return {
		employees: state.employees.items,
		approvals: state.approvals.items,
		my_operations: state.my_operations.items,
		entity_performance: state.work_performance.items,
		entity_operations: state.entity_operations.items,
		operation_types: state.workOperations.items,
		permissions: state.permissions,
		identity: state.identity,
		group_history: state.my_group_history.items,
		is_loading: is_loading
	};
};

export default connect(mapStateToProps)(Detail);
