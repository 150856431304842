import React from 'react';
import PropTypes from 'prop-types';
import Select, {createFilter} from 'react-select';

class Selector extends React.Component {

    onChange(o) {
        const {props: P} = this;

        if (Array.isArray(o)){
            let ids = o.reduce((acc, current) => {
                if (acc === ''){return String(current.value);}
                return `${acc},${current.value}`;
            }, '');
            P.onChange(P.name, ids);
            return;
        }
        if (o){
            P.onChange(P.name, String(o.value));
            return;
        }
        P.onChange(P.name, '');
    }

    render() {
        const { props: P} = this;

        const filterOptions = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start',
        };

        const customStyles = {
            control: (provided) => ({
                ...provided,
                marginRight: 15,
            })
        };

        return (
            <Select
                options={P.values}
                isClearable={true}
                filterOption={createFilter(filterOptions)}
                value={P.value}
                getOptionValue={o => o.value}
                getOptionLabel={o => o.name}
                onChange={o => this.onChange(o)}
                isDisabled={false}
                placeholder={P.placeholder || 'Vyberte...'}
                styles={customStyles}
                isMulti={P.isMulti}
                isSearchable={false}
            />
        );
    }
}

Selector.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    placeholder: PropTypes.string.isRequired,
    isMulti: PropTypes.bool.isRequired,
};

Selector.defaultProps = {
    onChange: () => {},
    value: [],
    values: [],
    placeholder: 'Vyberte',
    isMulti: false,
};

export default Selector;
