import React, {Component} from 'react';
import {SuperTable} from 'components/PaginationTable';
import store from 'store';
import {Container, Form} from 'react-bootstrap';
import categoryMaterialItemsActions from 'actions/category_material_items';
import materialCategoriesActions from 'actions/material_categories';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MaterialCategoriesForm from './MaterialCategoriesForm';
import { updateQueryParams } from 'scripts/queryFunctions';
import Searcher from 'components/PaginationTable/Searcher';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import material_search_actions from 'actions/material_search_results';
import Formsy from 'formsy-react';

const searchAPI = data => store.dispatch(material_search_actions.fetchAll(data));
const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

class MaterialOverview extends Component {
    constructor(props) {
        super(props);

        let categories = [];

        let categories_str = localStorage.getItem('material_categories');
        if (!categories_str) {
            // if there is nothing in the localstorage, then try to fetch it from url params
            let currentParams = new URLSearchParams(this.props.location.search);
            categories_str = currentParams.get('categories');
        }

        if (categories_str && categories_str.length > 0) {
            const {location, history} = props;
            updateQueryParams(location, history, {categories: categories_str, query: ''}, false);
        }

        this.state = {
            filter_category: false,
            categories
        };
    }

    static loadCategoriesFromString(categories_str, props) {
        let categories_ids = categories_str.split(',');
        let categories = [];

        let all_categories = props.categories;
        categories_ids.forEach(id => {
            let item = all_categories.find(c => c.id === id);
            if (item) {
                categories.push(item);
            }
        });

        return categories;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.categories.length > 0 && state.categories.length === 0) {
            let categories_str = localStorage.getItem('material_categories');
            if (categories_str) {
                let selected = categories_str.split(',').slice(-1)[0];
                if (selected && props.material_items.length === 0) {
                    store.dispatch(categoryMaterialItemsActions.fetchAll({id: selected}));
                }
                return {
                    categories: MaterialOverview.loadCategoriesFromString(categories_str, props)
                };
            }
        }

        return null;
    }

    componentDidMount() {
        if (this.props.categories.length === 0) {
            store.dispatch(materialCategoriesActions.fetchAll());
        }
    }

    redirect(id) {
        this.props.history.push('/storecards/' + id);
    }

    changeHandler(category, index) {
        const {state: S} = this;

        let categories = S.categories.slice();
        if (!category) {
            categories.splice(index);
        } else {
            categories.splice(index);
            categories[index] = category;
        }

        if (categories.length > 0) {
            let categories_str = categories.map(c => c.id).join(',');
            let params = {categories: categories_str};

            const { location, history } = this.props;
            updateQueryParams(location, history, params, true);
            localStorage.setItem('material_categories', categories_str);

            let selectedCategory = categories.slice(-1)[0];
            store.dispatch(categoryMaterialItemsActions.fetchAll({id: selectedCategory.id}));
        }

        this.setState({categories});
    }

    async callSearchAPI(queryStr) {
        if (queryStr && queryStr.length >= 3) {
            let data = {query: queryStr};
            if (this.state.filter_category && this.state.categories.length > 0) {
                data.category_id = this.state.categories.slice(-1)[0].id;
            }

            await searchAPIDebounced(data);
        }
    }

    render() {
        const {props: P, state: S} = this;
        const {location, history} = P;

        const query =  new URLSearchParams(this.props.location.search);
        const searchVal = decodeURIComponent(query.get('query'));

        const selectors = [{
            name: 'stored_selector',
            default_value: 0,
            values: [{
                    value: 0,
                    name: 'Zobraziť všetky',
                    filter: () => true
                },
                {
                    value: 1,
                    name: 'Zobraziť len na sklade',
                    filter: obj => obj.count > 0
                },
            ]
        }];

        return (
            <div>
                <h2 className={'text-center'}>Prehľad materiálu</h2>
                <Container>
                    <h3>Prehľadávať skladové karty</h3>
                    <div className={'mb-4'}>
                        <Searcher
                            placeholder={'Zadajte hľadaný výraz'}
                            value={searchVal}
                            onChange={val => {
                                updateQueryParams(location, history, {query: val}, true);
                                this.callSearchAPI(val);
                            }}
                            onClear={() => updateQueryParams(location, history, {query: ''}, true)}
                        />
                    </div>
                    <Formsy className={'mb-3'}>
                        <Form.Check
                            checked={!S.filter_category}
                            name={'limit_category'}
                            id={'dont_limit_category'}
                            type={'radio'}
                            label={'Vyhľadávať vo všetkých skladových kartách'}
                            inline={true}
                            onChange={e => {
                                if (S.filter_category) {
                                    console.log('value 2 changed', e.target.value);
                                    this.setState({filter_category: false},
                                        () => this.callSearchAPI(searchVal));
                                }
                            }}
                        />
                        <Form.Check
                            checked={S.filter_category}
                            name={'limit_category'}
                            id={'limit_category'}
                            type={'radio'}
                            label={'Hľadať iba vo zvolenej kategórií'}
                            inline={true}
                            onChange={e => {
                                if (!S.filter_category) {
                                    console.log('value 1 changed', e.target.value);
                                    this.setState({filter_category: true},
                                        () => this.callSearchAPI(searchVal));
                                }
                            }}
                        />
                    </Formsy>
                    <MaterialCategoriesForm
                        categories={P.categories}
                        value={S.categories}
                        changeHandler={(c, i) => this.changeHandler(c, i)}
                    />
                    <SuperTable
                        name={'material_overview'}
                        thead={[
                            {name: 'image_url', sk_name: 'Foto', type: 'image'},
                            {name: 'name', sk_name: 'Názov', type: 'string'},
                            {name: 'code', sk_name: 'Kód', type: 'string'},
                            {name: 'count', sk_name: 'Na sklade', type: 'string'},
                            {name: 'mainunitcode', sk_name: 'Jednotka', type: 'string'},
                            {name: 'category_name', sk_name: 'Kategoria', type: 'string'},
                        ]}
                        columnSelector={true}
                        selectors={selectors}
                        items={searchVal ? P.material_search_results : P.material_items || []}
                        onRowClick={id => this.redirect(id)}
                        rowStyleFn={
                            (column) => ['name'].includes(column)
                                ? {whiteSpace: 'normal'}
                                : {}
                        }
                        is_loading={P.isLoading}
                    />
                </Container>
            </div>
        );
    }
}

MaterialOverview.propTypes = {
    material_items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
    return {
        material_items: state.category_material_items.items,
        material_search_results: state.material_search_results.items,
        categories: state.material_categories.items,
        isLoading: state.category_material_items.isFetching || state.material_categories.isFetching,
    };
};

export default connect(mapStateToProps)(MaterialOverview);
