import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../views/Tools/Form';

const defaultImg = '/storage/no-image.jpg';

class ImageViewer extends React.PureComponent {
    constructor(props){
        super(props);

        this.state = {
            error: false
        };
    }

    getHref(src){
        if (!src || this.state.error){
            return defaultImg;
        }
        return src;
    }

    onError() {
        let {state: S} = this;

        if (!S.error){
            this.setState({
                error: true
            });
        }
    }

    render() {
        let {props: P} = this;

        return (
            <a
                target="_blank"
                href={this.getHref(P.full_size)}
                rel="noopener noreferrer"
            >
                <img src={this.getHref(P.thumbnail)}
                     height={180}
                     alt="obrázok"
                     style={{
                         marginRight: 15,
                         marginBottom: 15,
                         marginTop: 15,
                         marginLeft: 15,
                     }}
                     onError={() => this.onError()}
                />
            </a>
        );
    }
}

ImageViewer.propTypes = {
    thumbnail: PropTypes.string,
    full_size: PropTypes.string
};

Form.defaultProps = {
    thumbnail: '',
    full_size: ''
};

export default ImageViewer;