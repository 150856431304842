import React from 'react';
import PT from 'prop-types';
import { SuperTable } from 'components/PaginationTable';

class ToolHistory extends React.Component {

    render() {
        const { props:P } = this;

        const items = P.history.map(h => {
            let e = P.employees.find(e => e.id === h.employee_id);
            e = e ? `${e.last_name} ${e.first_name}` : '' ;

            let state = P.states.find(s => s.id === h.state_id);
            state = state && state.name;

            return Object.assign({}, h, {
                employee_fullname: e,
                state
            });
        }).sort((a, b) => new Date(a.from_date) > new Date(b.from_date) ? -1 : 1);

        return (
            <div>
                <SuperTable
                    name={'tool_history'}
                    items={items}
                    thead={[
                        {name: 'employee_fullname', sk_name: 'Meno', type: 'string'},
                        {name: 'from_date', sk_name: 'Dátum prevzatia', type: 'date-time'},
                        {name: 'state', sk_name: 'Stav', type: 'string'},
						{name: 'quality', sk_name: 'Kvalita', type: 'number'},
						{name: 'comment', sk_name: 'Komentár', type: 'string'},
                    ]} 
                    searchable />
            </div>            
        );
    }
}

ToolHistory.propTypes = {
    employees: PT.array,
    states: PT.array,
    history: PT.array
};

ToolHistory.defaultProps = {
    employees: [],
    states: [],
    history: []
};

export default ToolHistory;