import mapActions from './mapActions';

/** TODO: PLS FIX THIS,
 *
 * For these types of actions,
 * it is totally unnecessary to map ALL actions for a resource
 *
 * Just one action to fetch tools is needed
 *
 * This is wasting browser resources !
 * (Unless the compilator is smart enough to remove all unused actions, which I doubt)
 */

export default mapActions('tools_at_employees', {
    fetchAll_url: data => {
        if (data.id === undefined)
            throw new Error('id of employee is undefined');
        return `/api/employees/${data.id}/tools`;
    }
});