import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Container, Row} from 'react-bootstrap';
import store from 'store/mainStore';
import buildingsActions from 'actions/buildings';
import NeedWritePerm from 'components/NeedPermission';
import {SuperTable} from 'components/PaginationTable';
import Spinner from 'components/Spinner';
import MaterialForm from './MaterialForm';
import OperationForm from './OperationForm';
import material_actions from 'actions/material';
import entity_operation_actions from 'actions/entity_operations';
import material_types from 'actions/material_types';
import pipe_dimensions from 'actions/pipe_dimensions';
import DV, {parseSeconds} from 'scripts/dateFormatter';
import {getUrlParam, getUrlParams} from '../utils';
import operationTypes from 'actions/work_operations';
import SectionForm from '../LevelForm';
import {isNotEmpty} from 'scripts/valueNormalizer';
import {Link} from 'react-router-dom';
import {MdAssistant} from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import {IoIosHelpCircleOutline} from 'react-icons/io';
import buildingEntitiesActions from 'actions/building_entities';
import entityTypesActions from 'actions/entity_types';
import BuildingForm from '../../WorkOperations/SectionForm';
import {updateQueryParams} from 'scripts/queryFunctions';
import call from 'ajax/call';
import {setGlobalError} from 'actions/globalError';
import Form from 'formsy-react';
import FilesForm from 'components/FilesForm';
import {deletePicture} from '../utils';

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formActive: false,
            editFormActive: false,
            operationFormActive: false,
            objToUpdate: undefined,
            updating: false,
            files: [],
            loading_files: false,
        };
    }

    componentDidMount() {
        let entity_id = getUrlParam(this.props.location, 'entity_id');
        if (entity_id) {
            store.dispatch(material_actions.fetchAll({id: entity_id}));
            store.dispatch(entity_operation_actions.fetchAll({id: entity_id}));
            this.fetchFiles(entity_id);
        }

        store.dispatch(buildingsActions.fetchAll());
        store.dispatch(entityTypesActions.fetchAll());
        store.dispatch(material_types.fetchAll());
        store.dispatch(pipe_dimensions.fetchAll());
        store.dispatch(operationTypes.fetchAll());
    }

    fetchFiles(entity_id) {
        this.setState({loading_files: true});

        call(`/api/building_entities/${entity_id}/files`, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.setState({
                        files: data.data,
                        loading_files: false,
                    });
                }
            });
    }

    formSubmit(model) {
        const { state: S } = this;
        let entity_id = getUrlParam(this.props.location, 'entity_id');

        let obj = {
            entity_id: entity_id,
            dimension_id: model.main_dimension.id,
            secondary_dimension_id: model.secondary_dimension ? model.secondary_dimension.id : null,
            material_type_id: model.material_type.id,
            quantity: model.quantity,
            comment: model.comment
        };

        let action;
        if (S.updating) {
            action = material_actions.update(S.objToUpdate.id, obj);
        } else {
            action = material_actions.create(obj);
        }

        store.dispatch(action)
            .then(data => {
                if (data.status === 'ok') {
                    this.setState({
                        formActive: false,
                        objToUpdate: undefined,
                        updating: false,
                    });
                }
            });
    }

    operationFormSubmit(model) {
        const { props: P, state: S } = this;
        let {building_id, entity_id} = getUrlParams(P.location, [
            'building_id',
            'entity_id'
        ]);

        let obj = {
            entity_id,
            estimated_time: model.estimated_time ? parseFloat(model.estimated_time)*60*60 : null,
            building_id,
            operation_type_id: model.operation ? model.operation.id : null,
            number: model.number || 1,
            description: model.description || '',
            quantity: model.quantity || 0.0,
        };

        let action;
        if (S.updating) {
            action = entity_operation_actions.update(S.objToUpdate.id, obj);
        } else {
            action = entity_operation_actions.create(obj);
        }

        store.dispatch(action)
            .then( data => {
                if (data.status === 'ok') {
                    this.setState({operationFormActive: false});
                    store.dispatch(buildingEntitiesActions.fetchAll());
                }
            });
    }

    sectionFormSubmit(model){
        const {props: P } = this;

        let entity_id = getUrlParam(P.location, 'entity_id');
        let entity = P.building_entities.find( s => s.id === entity_id);

        let obj = {
            ...entity,
            name: model.name || '',
            description: model.description || '',
            picture: model.picture,
            difficulty_level: model.difficulty_level || 0,
            length: model.length || 0,
        };

        const formData = new FormData();
        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }
        //formData.append('picture', model.picture);

        formData.append('_method', 'PUT');
        let action = buildingEntitiesActions.update(entity.id, formData, {
            stringify: false,
            contentType: false,
            method: 'POST'
        });

        store.dispatch(action)
            .then(data => {
                if (data.status === 'ok') {
                    if (data.data.id && model.init_operations) {
                        setTimeout(() => {
                            call('/api/building_entities/init_operations', {
                                method: 'POST',
                                headers: {'Content-type': 'application/json'},
                                body: JSON.stringify({
                                    entity_id: data.data.id
                                })
                            }).then(response => response.json())
                                .then(data => {
                                    store.dispatch(setGlobalError([data.message], 'info', false));
                                    store.dispatch(entity_operation_actions.fetchAll({id: entity_id}));
                                });
                        }, 1000);
                    }
                    this.setState({
                        editFormActive: false,
                        objToUpdate: undefined,
                        updating: false,
                    });
                }
            });
    }

    getToolTip(item) {
        if (item.current_work){
            return 'Aktuálne na operácii pracuje: ' + item.current_work.group_names;
        } else {
            switch (item.status){
                case 'new' : return 'Operácia ešte nie ja začatá';
                case 'paused': return 'Na operácii sa momentálne nepracuje';
                case 'finished': return 'Operácia je dokončená';
                default: return '';
            }
        }
    }

    getItems() {
        const { props:P } = this;
        return P.entity_operations
            .sort( (a, b) => a.number - b.number)
            .map( item => ({
                ...item,
                pie_chart: item.status_percent,
                info: <span>
                    <IoIosHelpCircleOutline
                        size={'2em'}
                        //color={'orange'}
                        data-tip
                        data-for={`${item.id}_tool_tip`}
                    />
                    <ReactTooltip
                        id={`${item.id}_tool_tip`}
                        type='dark'
                        place="top"
                        effect="solid"
                        delayUpdate={500}
                    >
                        {this.getToolTip(item)}
                    </ReactTooltip>
                </span>
            }));
    }

    redirect(){
        let params = getUrlParams(this.props.location, [
            'building_id',
            'entity_id'
        ]);

        let stringParams = Object.keys(params)
            .map( key => `${key}=${params[key]}`)
            .join('&');

        this.props.history.push(`/operation_work/?${stringParams}`);
    }

    render() {
        const { props:P, state:S } = this;

        if (P.is_loading || P.building_entities.length === 0){
            return <Spinner/>;
        }

        let entity_id = getUrlParam(P.location, 'entity_id');
        let building_id = getUrlParam(P.location, 'building_id');
        let entity = P.building_entities.find(e => e.id === entity_id);

        let params = {};
        let type_name = null;

        if (entity) {
            let type = P.entity_types.find(t => t.id === entity.entity_type_id);
            if (type) {
                type_name = type.name;
            }
            params = {
                entity_id,
                building_id,
            };
        }

        let form = <Form>
            <BuildingForm
                onChange={params => {
                    const { location, history } = this.props;
                    let paramsToUpdate = params;
                    if (!params.entity_id) {
                        paramsToUpdate.entity_id = null;
                    }

                    updateQueryParams(location, history, paramsToUpdate);
                    const {entity_id} = params;
                    if (entity_id) {
                        store.dispatch(material_actions.fetchAll({id: entity_id}));
                        store.dispatch(entity_operation_actions.fetchAll({id: entity_id}));
                        this.fetchFiles(entity_id);
                    }
                }}
                locked={false}
                params={params}
                leaf_required={true}
            />
        </Form>;

        if (!entity || !entity.is_leaf){
            return <Container className="cars">
                <div className="info">
                    {form}
                </div>
            </Container>;
        }

        return (
            <Container className="cars">
                <div className="info">
                    {form}
                    <Row style={{
                        margin: 15,
                        justifyContent: 'center',
                        fontWeight: 'bold',
                    }}>
                        {type_name} {entity.name}
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Náročnosť</Col>
                        <Col xs={6} className="text-left">{entity.difficulty_level || '0'}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Dĺžka</Col>
                        <Col xs={6} className="text-left">{entity.length  || '--'} m</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Začiatok prác</Col>
                        <Col xs={6} className="text-left">{DV(entity.started_at, false)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Koniec prác</Col>
                        <Col xs={6} className="text-left">{DV(entity.finished_at, false)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Stav prác</Col>
                        <Col xs={6} className="text-left">{`${entity.status_percent} %`}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Odpracované hodiny</Col>
                        <Col xs={6} className="text-left">{parseSeconds(entity.total_time)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-right">Celkový odhadovaný čas</Col>
                        <Col xs={6} className="text-left">{parseSeconds(entity.estimated_time)}</Col>
                    </Row>
                    {
                        entity.description ?
                        <Row>
                            <Col xs={6} className="text-right">Popis</Col>
                            <Col xs={6} className="text-left">{entity.description}</Col>
                        </Row> : null
                    }
                    {
                        entity.picture ?
                            <Row style={{
                                textAlign: 'center',
                                margin: 15
                            }}>
                                <Col>
                                    <a href={entity.picture}>
                                        <Button type={'normal'}>
                                            Zobraziť výkres
                                        </Button>
                                    </a>
                                    <Button
                                        style={{marginLeft: 15}}
                                        variant="danger"
                                        onClick={() => deletePicture(entity)}
                                    >
                                        Vymazať výkres
                                    </Button>
                                </Col>
                            </Row>
                            : null
                    }
                    <div style={{
                        marginTop: 15,
                        marginBottom: 15,
                        textAlign: 'center'
                    }}>
                        <NeedWritePerm type="button" need={['planning']}>
                            <Button
                                style={{ marginRight: 15}}
                                variant="success"
                                onClick={() => this.setState({
                                    editFormActive: true,
                                    updating: false,
                                    objToUpdate: undefined,
                                })}
                            >
                                Upraviť
                            </Button>
                        </NeedWritePerm>
                        { (P.permissions.planning == 2 || P.permissions.operations == 2) ?
                            <Button
                                variant="success"
                                onClick={() => this.redirect()}
                            >
                                Zobraziť pracovné záznamy
                            </Button> : null
                        }
                    </div>
                    <div style={{
                        marginTop: 15,
                        marginBottom: 15,
                        textAlign: 'left'
                    }}>
                        <FilesForm
                            heading={'Výkresy'}
                            files={this.state.files}
                            files_url={`/api/building_entities/${entity_id}/files`}
                            editable={P.permissions.planning == 2}
                            afterChange={() => this.fetchFiles(entity_id)}
                            loading={this.state.loading_files}
                        />
                    </div>
                </div>

                <h4>Operácie:</h4>
                <div style={{
                    marginTop: 15,
                    marginBottom: 15,
                    textAlign: 'left'
                }}>
                    <Link to='/operation_types'>
                        <Button variant="success"><MdAssistant /> Správa operácií</Button>
                    </Link>
                    <NeedWritePerm type="button" need={['planning']}>
                        <Button
                            variant="success"
                            onClick={() => {
                                this.setState({
                                    operationFormActive: true,
                                    updating: false,
                                    objToUpdate: undefined,
                                });
                            }}
                            style={{margin: 15}}
                        >
                            Pridať operáciu
                        </Button>
                    </NeedWritePerm>
                </div>
                <SuperTable
                    name={'operations'}
                    thead={[
                        {name: 'number', sk_name: 'Poradie', type: 'number', sort: true},
                        {name: 'operation_name', sk_name: 'Názov operácie', type: 'string', sort: false},
                        {name: 'quantity', sk_name: 'Počet', type: 'number', sort: true},
                        {name: 'operation_unit', sk_name: 'Jednotka', type: 'string', sort: false},
                        {name: 'description', sk_name: 'Popis', type: 'string', sort: false},
                        {name: 'pie_chart', sk_name: 'Stav', type: 'pie_chart', sort: false},
                        {name: 'status_percent', sk_name: '%', type: 'integer', sort: false},
                        {name: 'started_at', sk_name: 'Začiatok prác', type: 'short_date', sort: false},
                        {name: 'finished_at', sk_name: 'Koniec prác', type: 'short_date', sort: false},
                        {name: 'estimated_time', sk_name: 'Odhadovaný čas', type: 'seconds', sort: false, hidden: true},
                        {name: 'total_time', sk_name: 'Celkový čas [normohodiny]', type: 'seconds', sort: false},
                        {name: 'info', sk_name: 'info', type: 'html', sort: false},
                    ]}
                    items={this.getItems()}
                    is_loading = {P.is_loading}
                    onDeleteItem={ id => {
                            store.dispatch(entity_operation_actions.delete(id)).then( data => {
                                if (data.status === 'ok') {
                                    store.dispatch(buildingEntitiesActions.fetchAll());
                                }
                            });
                        }
                    }
                    columnSelector={true}
                    delOps={true}
                    editOps={true}
                    onEditItem={ id => {
                        this.setState({
                            operationFormActive: true,
                            updating: true,
                            objToUpdate: P.entity_operations.find(o => o.id === id),
                        });
                    }}
                    noData={'Na úseku nie sú definované žiadne operácie'}
                />
                <h4 style={{marginTop: 10}}>Materiál na úseku:</h4>
                <NeedWritePerm type="button" need={['planning']}>
                    <Button
                        variant="success"
                        onClick={() => {
                            this.setState({
                                formActive: true,
                                updating: false,
                                objToUpdate: undefined,
                            });
                        }}
                        style={{marginRight: 15, marginTop: 10}}
                    >
                        Pridať materiál
                    </Button>
                </NeedWritePerm>
                <NeedWritePerm type="table" need={['planning']}>
                    <SuperTable
                        name={'material'}
                        thead={[
                            {name: 'short_name', sk_name: 'Názov', type: 'string'},
                            {name: 'dimension', sk_name: 'DN', type: 'string'},
                            {name: 'quantity', sk_name: 'Počet', type: 'number'},
                            {name: 'unit', sk_name: 'Jednotka', type: 'string'},
                            {name: 'comment', sk_name: 'Poznámka', type: 'string'},
                        ]}
                        columnSelector
                        items={P.material}
                        is_loading = {P.is_loading}
                        editOps
                        delOps
                        onEditItem={ id => {
                            this.setState({
                                formActive: true,
                                objToUpdate: P.material.find( m => m.id === id),
                                updating: true,
                            });
                        }}
                        onDeleteItem={ id => store.dispatch(material_actions.delete(id)) }
                        noData={'Na úseku nie je definovaný žiaden materiál'}
                    />
                </NeedWritePerm>
                {
                    S.formActive ?
                        <MaterialForm
                            key={ S.updating ? S.objToUpdate.id : 'new_key'}
                            show={S.formActive}
                            onClose={ () => this.setState({formActive: false})}
                            onSubmit={ (model) => this.formSubmit(model)}
                            updating={S.updating}
                            obj={ S.updating ? S.objToUpdate : undefined}
                            dimensions={P.dimensions}
                            material_types={P.material_types}
                        /> : null
                }
                {
                    S.operationFormActive ?
                    <OperationForm
                        show={S.operationFormActive}
                        onClose={ () => this.setState({operationFormActive: false})}
                        onSubmit={ (model) => this.operationFormSubmit(model) }
                        work_operations={
                            P.work_operations.filter(o => !o.overhead)
                                .sort( (a, b) => a.name.localeCompare(b.name))
                        }
                        updating={S.updating}
                        obj={ S.updating ? S.objToUpdate : {
                            id: null,
                            number: P.entity_operations.reduce((max, item) => Math.max(max, item.number), 0) + 1
                        }}
                    /> : null
                }
                {
                    S.editFormActive ?
                        <SectionForm
                            show={S.editFormActive}
                            onClose={ () => this.setState({
                                editFormActive: false,
                                updating: false,
                                objToUpdate: undefined,
                            })}
                            onSubmit={ (model) => this.sectionFormSubmit(model)}
                            updating={ true }
                            obj={ entity }
                            entity_types={[]}
                        /> : null
                }
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildings: state.buildings.items.filter(e => e.active),
        material_types: state.material_types.items,
        material: state.material.items,
        entity_operations: state.entity_operations.items,
        entity_types: state.entity_types.items,
        building_entities: state.building_entities.items,
        work_operations: state.workOperations.items,
        dimensions: state.pipe_dimensions.items,
        permissions: state.permissions,
        is_loading: (state.buildings.isFetching
            || state.building_entities.isFetching
            || state.material_types.isFetching
            || state.material.isFetching
        ),
    };
};

export default connect(mapStateToProps)(Detail);
