import React from 'react';
import { connect } from 'react-redux';
import categoriesActions from 'actions/tool_categories';
import { SuperTable} from '../../../components/PaginationTable';
import Form from './components/Form';
import ButtonsView from './components/ButtonsView';
import { Container } from 'react-bootstrap';
import store from 'store/mainStore';

class Categories extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formActive: false,
			objToUpdate: undefined,
		};
	}

	handleCreateClick() {
		this.setState({
			formActive: true,
			objToUpdate: undefined
		});
	}

	handleFormSubmit(obj) {
		const S = this.state;
		const updating = S.objToUpdate !== undefined;
		const updatedObj = S.objToUpdate;

		let action = {};
		if (updating) {
			const { id } = updatedObj;
			action = categoriesActions.update(id, obj);
		} else {
			action = categoriesActions.create(obj);
		}

		store.dispatch(action).then( data => {
			if (data.status === 'ok') {
				this.setState({
					formActive: false,
					objToUpdate: undefined,
				});
			}
		});
	}

	onDeleteItem(id){
		store.dispatch(categoriesActions.delete(id));
	}

	componentDidMount() {
		store.dispatch(categoriesActions.fetchAll());
	}

	handleEditItem(id) {
		this.setState({
			objToUpdate: this.props.toolCategories.find(tc => tc.id === id),
			formActive: true,
		});
	}

	render() {
		const { props:P, state:S } = this;
		if (P.loading) {
			return <div>Loading...</div>;
		}

		return (
			<Container>
				<ButtonsView onCreateClick={() => this.handleCreateClick()} />
				<SuperTable
					name={'tool_categories'}
					thead={[
						{sk_name: 'Názov', name: 'name', type: 'string'},
						{sk_name: 'Popis', name: 'description', type: 'string'},
					]}
					columnSelector
					items={P.toolCategories}
					onEditItem={(id) => this.handleEditItem(id)}
					onDeleteItem={(id) => this.onDeleteItem(id)}
					editOps delOps
					defaultSortKey={'name'}
				/>
				<Form
					key={S.objToUpdate ? S.objToUpdate.id : 'new'}
					updating={S.objToUpdate !== undefined}
					defaultValues={S.objToUpdate}
					show={S.formActive}
					onClose={() => this.setState({ formActive: false }) }
					onSubmit={ (obj) => this.handleFormSubmit(obj) }
				/>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		toolCategories: state.toolCategories.items,
		loading: false,
	};
};

export default connect(mapStateToProps)(Categories);