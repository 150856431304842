import React from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import store from 'store/mainStore';
import {Link, withRouter} from 'react-router-dom';
import Loader from 'components/Loader';
import {login} from 'actions/login';
import Tern from 'components/Or';
import './styles.css';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.username = React.createRef();
        this.password = React.createRef();

        this.state = {
            loginError: false,
            invalidCredentials: false,
        };
    }

    handleSubmit(e) {
        e.preventDefault();

        store.dispatch(login({
            username: this.username.current.value,
            password: this.password.current.value,
        }));
    }

    redirectWhenLoggedIn() {
        let login = this.props.login;
        if (!login.err && login.resolved && login.logged) {
            if (this.props.location.state && this.props.location.state.previous_url) {
                this.props.history.push(this.props.location.state.previous_url, {});
            } else {
                this.props.history.push('/');
            }
        }
    }

    componentDidUpdate() {
        this.redirectWhenLoggedIn();
    }

    componentDidMount() {
        this.username.current.focus();
        this.redirectWhenLoggedIn();
    }

    render() {
        const {login} = this.props;
        const invalidCredentials = login.err;

        const codePenLogin = (
            <Col
                lg={{span: 4, offset: 4}}
                md={12}
                className="login-form"
            >
                <div>
                    <form className="form" onSubmit={this.handleSubmit.bind(this)}>
                        <h2>Prihlásenie</h2>
                        <div>
                            <input
                                ref={this.username}
                                type="text"
                                placeholder="Prihlasovacie meno"
                            />
                            <input
                                ref={this.password}
                                type="password"
                                placeholder="Heslo"
                            />
                        </div>
                        <input type="submit" value="Prihlásiť"/>
                        <Tern expression={login.pending}>
                            <Loader/>
                        </Tern>
                        <p>
                            {invalidCredentials && 'Zlé meno alebo heslo, skúste to znova. '}
                            <Link to={'/forgot_password'}>Zabudli ste heslo?</Link>
                        </p>
                    </form>
                </div>
            </Col>
        );

        return (
            <Container className='login-page'>
                <Row>
                    {codePenLogin}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login
    };
};

export default withRouter(connect(mapStateToProps)(Login));
