import mapActions from './mapActions';

export default mapActions('material_search_results', {
    fetchAll_url: data => {
        let url = '/api/material_items/search?query=' + encodeURIComponent(data.query);
        if (data.category_id) {
            url += '&category_id=' + data.category_id;
        }
        return url;
    }
});