import { 
    SET_PENDING, 
    SET_RESOLVED_ERR ,
    SET_RESOLVED_DATA,
    UNSET_ERR,
    SET_LOGOUT,
} from 'actions/login';
import {getCookie} from 'scripts/cookie';

let cookie = getCookie('XSRF-TOKEN');
let is_logged = cookie !== null && localStorage.getItem('identity') !== null;

export default function login(
    state={
        resolved: false,
        logged: is_logged,
        err: false,
        permissions: [],
        pending: false
    }, 
    action) 
{
    switch (action.type) {
        case SET_PENDING:
            return Object.assign({}, state, {
                resolved: false,
                pending: true,
                err: false
            });
        case UNSET_ERR:
            return Object.assign({}, state, {
                err: false,
                pending: false
            });
        case SET_RESOLVED_DATA:
            return Object.assign({}, state, {
                resolved: true,
                logged: true,
                err: false,
                permissions: action.permissions,
                pending: false
            });
        case SET_RESOLVED_ERR:
            return Object.assign({}, state, {
                err: true,
                pending: false
            });
        case SET_LOGOUT:
            localStorage.removeItem('permissions');
            localStorage.removeItem('identity');
            removeDuplicateStorageItems();

            return Object.assign({}, state, {
                resolved: true,
                logged: false,
                err: false,
                pending: false,
                permissions: [],
            });
        default:
            return state;
    }
}

function removeDuplicateStorageItems() {
    let values = [];
    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).startsWith('/orders/detail//')) {
            values.push(localStorage.key(i));
        }
    }
    values.forEach(item => localStorage.removeItem(item));
}