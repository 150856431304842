import React from 'react';
import { MdInfo } from 'react-icons/md';

const NoData = () => (
	<div style={{textAlign: 'center'}}>
		<div>
			<MdInfo size={30} />
		</div>
		<div>Žiadne výsledky</div>
	</div>
);

export default NoData;
