import React from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap';
import RegisterEmployee from './RegisterEmployee';
import {connect} from 'react-redux';
import employeesActions from 'actions/employees';
import professionsActions from 'actions/professions';
import CertificationTypesActions from 'actions/certification_types';
import rolesActions from 'actions/roles';
import {reloadLoggedPermissions} from 'actions/permissions';
import store from 'store/mainStore';
import {Link} from 'react-router-dom';
import Tern from 'components/Or';
import NeedWritePerm from 'components/NeedPermission';
import {SuperTable} from 'components/PaginationTable';
import {MdAssistant} from 'react-icons/md';
import {isNotEmpty} from '../../scripts/valueNormalizer';
import './style.css';

class Employees extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible_employees: [],
            employeeFormActive: false,
            employeeFormUpdate: false,
            objToUpdate: undefined
        };
    }

    handleUpdate(id) {
        id = id && parseInt(id, 10);
        const obj = this.props.employees.find(e => e.id === id);
        this.setState({
            objToUpdate: obj,
            employeeFormUpdate: true,
            employeeFormActive: true
        });
    }

    componentDidMount() {
        store.dispatch(employeesActions.fetchAll());
        store.dispatch(rolesActions.fetchAll());
        store.dispatch(professionsActions.fetchAll());
        store.dispatch(CertificationTypesActions.fetchAll());
    }

    showRegisterForm() {
        this.setState({employeeFormActive: true, updating: false});
    }

    hideRegisterForm() {
        this.setState({employeeFormActive: false, updating: false, employeeToUpdate: {}});
    }

    closeFormHandler() {
        this.setState({
            employeeFormActive: false,
            objToUpdate: undefined,
            employeeFormUpdate: false
        });
    }

    submitFormHandler(obj) {
        const formData = new FormData();

        for (let key of Object.keys(obj)) {
            if (isNotEmpty(obj[key])) {
                formData.append(key, obj[key]);
            }
        }
        if (obj.photo) {
            formData.append('photo', obj.photo, 'photo.jpg');
        }
        let options = {
            stringify: false,
            contentType: false,
        };

        let action;
        if (this.state.objToUpdate) {
            formData.append('_method', 'PUT');
            action = employeesActions.update(this.state.objToUpdate.id, formData, {...options, method: 'POST'});
        } else {
            action = employeesActions.create(formData, options);
        }

        store.dispatch(action).then(data => {
            if (data.status === 'ok') {
                if (this.state.objToUpdate) {
                    if (this.state.objToUpdate.id === this.props.identity.id) {
                        reloadLoggedPermissions(obj.role_id);
                    }
                }
                this.closeFormHandler();
            }
        });
    }

    rowClickHandle(id) {
        this.props.history.push(`/employees/detail/${id}`);
    }

    render() {
        const {props: P, state: S} = this;
        let items = P.employees;

        const selectors = [{
            name: 'active_selector',
            default_value: 1,
            values: [{
                value: 0,
                name: 'Zobraziť všetkých',
                filter: () => true
            },
                {
                    value: 1,
                    name: 'Zobraziť aktívných',
                    filter: obj => Boolean(obj.active)
                },
                {
                    value: 2,
                    name: 'Zobraziť neaktívných',
                    filter: obj => !obj.active
                }
            ]
        }];

        let table_head = [
            {name: 'active', sk_name: 'Aktívny', type: 'boolean', hidden: true},
            {name: 'thumbnail', sk_name: 'Fotka', type: 'image'},
            {name: 'full_name', sk_name: 'Meno', type: 'string'},
            {name: 'profession_name', sk_name: 'Profesia', type: 'string'},
            {name: 'role_name', sk_name: 'Rola', type: 'string'},
            {name: 'curr_card', sk_name: 'Karta', type: 'string', hidden: true},
            {name: 'from_date', sk_name: 'Dátum priradenia', type: 'date', hidden: true},
            {name: 'type_name', sk_name: 'Typ pracovného pomeru', type: 'string', hidden: true},
            {name: 'work_phone', sk_name: 'Pracovný telefón', type: 'string', hidden: true},
            {name: 'work_email', sk_name: 'Pracovný mail', type: 'string', hidden: true},
        ];

        if (P.permissions.gdpr > 0) {
            table_head.splice(2,
                0,
                {name: 'mobile_number', sk_name: 'Telefónne číslo', type: 'string', hidden: true},
                {name: 'email', sk_name: 'Email', type: 'string', hidden: true},
                {name: 'birthday', sk_name: 'Dátum narodenia', type: 'date', hidden: true},
                {name: 'address', sk_name: 'Adresa', type: 'string', hidden: true},
            );
        }

        return (
            <Container className="employees">
                <Row style={{marginBottom: '15px'}}>
                    <Col md={12}>
                        <NeedWritePerm type="button" need={['employees']}>
                            <Button
                                onClick={() => this.showRegisterForm()}
                                style={{marginRight: 10}}
                                variant={'success'}
                            >
                                Pridať zamestnanca
                            </Button>
                        </NeedWritePerm>
                        <NeedWritePerm
                            type={'button'}
                            need={['employees']}
                        >
                            <Link to='/employees/professions' style={{marginRight: '15px'}}>
                                <Button variant="success"><MdAssistant/> Správa profesií</Button>
                            </Link>
                        </NeedWritePerm>
                    </Col>
                </Row>

                <Tern expression={S.employeeFormActive}>
                    <RegisterEmployee
                        onClose={() => this.closeFormHandler()}
                        onSubmit={obj => this.submitFormHandler(obj)}
                        hideHandler={() => this.hideRegisterForm()}
                        updating={!!S.objToUpdate}
                        defaultValues={S.objToUpdate}
                    />
                </Tern>

                <NeedWritePerm type="table" need={['employees']}>
                    <SuperTable
                        name={'employees'}
                        thead={table_head}
                        items={items}
                        onShowItem={(id) => this.rowClickHandle(id)}
                        onDeleteItem={id => store.dispatch(employeesActions.delete(id))}
                        defaultSortKey='full_name'
                        selectors={selectors}
                        columnSelector
                        delOps
                        showOps
                        searchable
                        is_loading={P.isFetching}
                        export={'zamestnanci'}
                    />
                </NeedWritePerm>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        employees: state.employees.items.map(e => (
            {
                ...e,
                profession_name: e.profession.name,
                type_name: e.type.name,
                role_name: e.role.name,
            })),
        isFetching:
            state.professions.isFetching
            || state.roles.isFetching
            || state.employees.isFetching,
        professions: state.professions.items,
        identity: state.identity,
        permissions: state.permissions,
    };
};

export default connect(mapStateToProps)(Employees);